/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Theme } from "globalStyles";
import { Layout, Breadcrumb, Card, Loading } from "element-react";
import { withTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import moment from "moment";

import ReactApexChart from "react-apexcharts";
import { columns, columns_simple } from "./columns";
import GraphIndicator from "components/GraphIndicator";

import ContainerMapMaintenances from "containers/ContainerMapMaintenances";
import IntervalPicker from "containers/IntervalPicker";
import StyledMobileTable from "containers/StyledMobileTable";
import DownloadDropDownContainer from "containers/DownloadDropDownContainer";

import StyledTable from "components/StyledTable";
import StyledButton from "components/StyledButton";
import ToggleFilter from "components/ToggleFilter";
import ResponsiveHandler from "components/ResponsiveHandler";
import DepositsTemplate from "components/DepositsTemplate";
import StyledSelect from "components/StyledSelect";

import { locales } from "./apexLocales";
import { ADDITIONAL_FEATURES } from "config";
import { getIntervalText } from "utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

const depositCompleteOptions = [
  {
    label: "closed",
    value: "True",
  },
  {
    label: "open",
    value: "False",
  },
];
class Deposits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      transitionEnded: false,
      filtersWidth: 20,
      inputWidth: "140px",
      triggerSet: false,
      changedForm: null,
      completeOptions: depositCompleteOptions,
    };
    this.tableRef = React.createRef();
    this.setState = this.setState.bind(this);
    this._autocompleteRef = null;
    this._userAutocompleteRef = null;
    this._areaAutocompleteRef = null;
  }

  _mapContainer = React.createRef();

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this._mapContainer).addEventListener(
      "transitionend",
      () => {
        this.setState({ transitionEnded: true });
      }
    );
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  autoCompleteFetch = (queryString) => {
    if (this._autocompleteRef) {
      clearTimeout(this._autocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._autocompleteRef = setTimeout(function () {
      search(queryString, null, "container");
    }, 500);
  };

  areaAutoCompleteFetch = (queryString) => {
    if (this._areaAutocompleteRef) {
      clearTimeout(this._areaAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._areaAutocompleteRef = setTimeout(function () {
      search(queryString, null, "area");
    }, 500);
  };

  render() {
    const {
      setLinkedArea,
      setEquipmentInDetail,
      resetFilter,
      changeFilter,
      setFiltersForm,
      resetFiltersForm,
      download,
    } = this.actions;

    const {
      areasList,
      linkedArea,
      filteredEquipments,
      equipmentInDetail,
      maintenancesList,
      filter,
      dropsListToPlot,
      t,
      filtersForm,
      maxDrops,
      averageDayDrops,
      numberOfDrops,
      responsiveHandlers,
      loadingPlot,
      i18n,
      loadingDownload,
      filteredEquipmentsNumber,
      searchResults,
      searchLoading,
      fillLevelFilterOptions,
      searchAreaResults,
      searchAreaLoading,
    } = this.props;

    const {
      filtersWidth,
      inputWidth,
      triggerSet,
      changedForm,
    } = this.state;

    const columnsToMap =
       ADDITIONAL_FEATURES.includes("transactions")
        ? columns
        : columns_simple;

    const areaEquipments = filteredEquipments
      ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow gutter="20">
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: "0 0px auto" }} separator="/">
                <Breadcrumb.Item>
                  <span
                    onClick={() => {
                      setLinkedArea(null);
                    }}
                  >
                    {t("deposits.title")}
                  </span>
                </Breadcrumb.Item>
                {linkedArea && (
                  <Breadcrumb.Item>
                    <span
                      onClick={() => setEquipmentInDetail(null)}
                    >
                      {linkedArea.name}
                    </span>
                  </Breadcrumb.Item>
                )}
                {equipmentInDetail && (
                    <Breadcrumb.Item>{equipmentInDetail.name}</Breadcrumb.Item>
                )}
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float="left"
              justifyContent={"flex-end"}
              paddingLeft={"14px"}
              paddingRight={0}
            >
              <React.Fragment>
                <StyledSelect
                  width={inputWidth}
                  placeholder={t("area_input_placeholder")}
                  value={filter.container__area__name}
                  onChange={(event) =>
                    changeFilter("container__area__name", event)
                  }
                  remoteMethod={this.areaAutoCompleteFetch}
                  options={searchAreaResults}
                  loading={searchAreaLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea != null}
                ></StyledSelect>
                <StyledSelect
                  width={inputWidth}
                  placeholder={t("container_input_placeholder")}
                  onChange={(event) => changeFilter("container__uuid", event)}
                  value={filter.container__uuid}
                  remoteMethod={this.autoCompleteFetch}
                  options={searchResults}
                  loading={searchLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea == null}
                ></StyledSelect>
                <StyledSelect
                  width={inputWidth}
                  value={filter.complete}
                  onChange={(value) => changeFilter("complete", value)}
                  placeholder={t("complete")}
                  clearable={true}
                  filterMethod={(val) => {
                    if (val != null && val !== "") {
                      this.setState({
                        completeOptions: depositCompleteOptions.filter(
                          (d) => t(d.label).toLowerCase() === val.toLowerCase()
                        ),
                      });
                    } else {
                      this.setState({
                        completeOptions: depositCompleteOptions,
                      });
                    }
                  }}
                  options={
                    this.state.completeOptions != null
                      ? this.state.completeOptions.map((el) => ({
                          ...el,
                          label: t(el.label),
                        }))
                      : []
                  }
                ></StyledSelect>
                <StyledSelect
                  width={"180px"}
                  placeholder={t("fill_level_input_placeholder")}
                  onChange={(event) => changeFilter("fill_level", event)}
                  value={filter.fill_level}
                  options={fillLevelFilterOptions}
                  remote={true}
                  clearable={true}
                ></StyledSelect>
              </React.Fragment>

              {changedForm != null && (
                <ToggleFilter
                  right={0}
                  text={getIntervalText(changedForm, t)}
                  variableToWatch={filtersForm}
                  zIndex={1001}
                >
                  <IntervalPicker
                    form={filtersForm}
                    intervalOptions={intervalOptions}
                    replaceForm={setFiltersForm}
                    resetForm={resetFiltersForm}
                    defaultForm={INTERVAL_FORM_DEFAULTS}
                    triggerSet={triggerSet}
                    resetTriggerSet={() => this.setState({ triggerSet: false })}
                    state={{ changedForm }}
                    setState={this.setState}
                  />
                </ToggleFilter>
              )}

              <ButtonWrapper>
                <StyledButton
                  margin={"0"}
                  onClick={() => {
                    resetFilter();
                    setLinkedArea(null);
                  }}
                >
                  {t("clear_filters")}
                </StyledButton>
                <StyledButton
                  type={"primary"}
                  margin={"0"}
                  onClick={() => this.setState({ triggerSet: true })}
                >
                  {t("apply")}
                </StyledButton>
                <ToggleFilter
                  order={10}
                  right={0}
                  text={
                    <div>
                      {!loadingDownload ? "Download" : <DownloadLoading />}
                    </div>
                  }
                  type={"button"}
                  loadingDownload={loadingDownload}
                >
                  <DownloadDropDownContainer
                    download={download}
                  ></DownloadDropDownContainer>
                </ToggleFilter>
              </ButtonWrapper>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <FirstRow
          style={{ marginBottom: "24px", marginTop: "24px" }}
          gutter="15"
        >
          <MapWrapper
            paddingLeft="0px"
            paddingRight="0px"
            ref={(elem) => (this._mapContainer = elem)}
            span={"22"}
          >
            <ContainerMapMaintenances
              areas={areasList}
              linkedArea={linkedArea}
              setLinkedArea={(area) => setLinkedArea(area)}
              areaEquipments={areaEquipments}
              equipmentInDetail={equipmentInDetail}
              globalNumberOfContainers={filteredEquipmentsNumber}
              setEquipmentInDetail={(equipment) => setEquipmentInDetail(equipment)}
            />
          </MapWrapper>
          <StatsWrapper
            paddingLeft="15px"
            paddingRight="0px"
            float="right"
            span={"2"}
          >
            <CustomCard height={"45vh"} width={"100%"}>
              <GraphIndicatorsWrapper>
                <GraphIndicator
                  name={t("dashboard.indicators_total")}
                  value={numberOfDrops}
                  border
                />
                <Divider></Divider>
                <GraphIndicator
                  name={t("dashboard.indicators_max")}
                  value={maxDrops}
                  border
                />
                <Divider></Divider>
                <GraphIndicator
                  name={t("dashboard.indicators_average")}
                  value={averageDayDrops}
                  border
                />
              </GraphIndicatorsWrapper>
            </CustomCard>
          </StatsWrapper>
        </FirstRow>

        <CustomLayoutSecondaryRow
          gutter="20"
          height={"calc(55vh - 64px - 36px - 48px - 4rem)"}
          minHeight={"320px"}
        >
          <TableName>{t("deposits.title")}</TableName>
          <div ref={this.tableRef}>
            <NoPaddingCol span={"12"}>
              {responsiveHandlers.isMobile || responsiveHandlers.isTablet ? (
                <StyledMobileTable
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({ ...equipment }))
                      : []
                  }
                  template={DepositsTemplate}
                  active={(row) =>
                    equipmentInDetail &&
                    row.container &&
                    equipmentInDetail.id === row.container.id
                  }
                  onRowClick={(entrance) => {
                    window.scrollTo({
                      left: 0,
                      top: 0,
                      behavior: "smooth",
                    });
                    this.setState({
                      transitionEnded: false,
                    });
                    
                    setEquipmentInDetail(entrance.container)
                  }}
                />
              ) : (
                <StyledTable
                  style={{ marginTop: ".5rem", minHeight: "100%" }}
                  height={"calc(55vh - 64px - 36px - 48px - 4rem - 42px)"}
                  columns={columnsToMap.map((column) => ({ t, ...column }))}
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({ ...equipment }))
                      : []
                  }
                  rowClassName={(row) => {
                    let toReturn = "";
                    if (
                      equipmentInDetail &&
                      row.container &&
                      equipmentInDetail.id === row.container.id
                    ) {
                      toReturn += " active";
                    }
                    if (!row.complete) {
                      toReturn += " error";
                    }
                    return toReturn;
                  }}
                  onRowClick={(entrance) => {
                    this.setState({
                      transitionEnded: false,
                    });
                    
                    setEquipmentInDetail(entrance.container);
                  }}
                />
              )}
            </NoPaddingCol>
            <StatsWrapper
              paddingLeft="16px"
              paddingRight="0px"
              float="right"
              span={"12"}
              style={{ marginTop: ".5rem", minHeight: "100%" }}
            >
              <NoPaddingCol padding="0">
                <GraphCustomCard
                  height={
                    "calc(55vh - 64px - 36px - 48px - 4rem - 42px)"
                  }
                >
                  <SmallCardTitle margin={"18px 0 0 0"}>
                    {t("dashboard.fill_graph_title")}
                  </SmallCardTitle>
                  <GraphWrapper>
                    {loadingPlot && <GraphLoading></GraphLoading>}
                    {!loadingPlot &&
                      (dropsListToPlot == null ||
                        dropsListToPlot.length < 2) && (
                        <NoDataText>{t("no_data")}</NoDataText>
                      )}
                    {!loadingPlot &&
                      dropsListToPlot &&
                      dropsListToPlot.length > 1 && (
                        <CustomReactApexChart
                          height={"100%"}
                          options={{
                            chart: {
                              defaultLocale: i18n.language,
                              locales: locales,
                              type: "area",
                              stacked: false,
                              zoom: {
                                type: "x",
                                enabled: true,
                                autoScaleYaxis: true,
                              },
                              toolbar: {
                                autoSelected: "zoom",
                              },
                            },
                            stroke: {
                              width: [2.5],
                              colors: [Theme.primaryColor],
                            },
                            colors: [Theme.primaryColor],
                            dataLabels: {
                              enabled: false,
                            },
                            markers: {
                              size: 0,
                            },

                            title: {
                              text: "",
                              align: "left",
                            },
                            fill: {
                              type: "gradient",
                              gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100],
                              },
                            },
                            yaxis: {
                              tickAmount: 5,
                              min: 0,
                              max: 100,
                              labels: {
                                formatter: function (val) {
                                  return parseInt(val * 100) / 100 + "%";
                                },
                                style: {
                                  fontSize: '15px'
                                }
                              },
                            },
                            xaxis: {
                              type: "datetime",
                              labels: {
                                style: {
                                  fontSize: '15px'
                                }
                              },
                            },
                            tooltip: {
                              shared: false,
                              y: {
                                formatter: function (val) {
                                  return parseInt(val * 100) / 100 + "%";
                                },
                              },
                              x: {
                                formatter: function (val) {
                                  return moment(val)
                                    .utcOffset(-1, true)
                                    .locale(i18n.language)
                                    .format("DD MMM HH:mm");
                                },
                              },
                            },
                          }}
                          series={[
                            {
                              name: t("dashboard.fill_graph_label"),
                              data: dropsListToPlot,
                            },
                          ]}
                          type="area"
                        />
                      )}
                  </GraphWrapper>
                </GraphCustomCard>
              </NoPaddingCol>
            </StatsWrapper>
          </div>
        </CustomLayoutSecondaryRow>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Deposits)));

const GraphIndicatorsWrapper = styled.div`
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Wrapper = styled.div`
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  & > div {
    ${({ theme }) => theme.smallDesktop`
      height: 100%;
  `}
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
  transition: width 0.4s;
  overflow: visible;
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;
    }
  `}
`;
const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`

  `}

  ${({ theme }) => theme.desktop`
    &.el-col-20{
        display: flex;
        justify-content: flex-end;
        width: 83.33333%;
        padding-left: 14px !important;
      }
  `}
`;

const SelectivePaddingColNoFixedHeightWithBoxShadow = styled(
  SelectivePaddingColNoFixedHeight
)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding: ${(props) => props.padding} !important;
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;

  &.el-col-12 {
    width: 100%;
    padding: 0 !important;
    height: calc(100% - 3rem);
  }

  ${({ theme }) => theme.smallDesktop`
   &.el-col-12{
     width: 50%;
    padding: ${(props) => props.padding} !important;
    padding-left: ${(props) => props.paddingLeft} !important;
    padding-right: ${(props) => props.paddingRight} !important;
  }
  `}
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;

const CustomCard = styled(Card)`
  height: ${(props) => props.height};

  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border};
  padding: ${(props) => props.padding};

  & .el-card__body {
    height: 100%;
  }

  @media (max-height: 768px) {
    height: 320px;
  }
`;

const GraphCustomCard = styled(CustomCard)`
  height: 15rem;
  height: 100%;

  ${({ theme }) => theme.smallDesktop`
    height: ${(props) => props.height};
    min-height: calc(100% - 3rem);

    and @media (max-height: 768px) {
      height: 100%;
    }

  `}
`;

const GraphWrapper = styled.div`
  position: relative;
  height: calc(15rem - 40px);
  width: 100%;
  width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  padding: 0 16px 16px;

  ${({ theme }) => theme.smallDesktop`
  height: calc(100% - 40px);

  `}
`;

const Divider = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${Theme.lightInactiveColor};
  margin: 0 auto;
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: span 2;
  justify-content: flex-end;

  ${({ theme }) => theme.desktop`
    display flex;
  `}
`;

const FirstRow = styled(CustomLayoutSecondaryRow)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const MapWrapper = styled(SelectivePaddingColNoFixedHeightWithBoxShadow)`
  &.el-col-22 {
    width: 100%;
  }
  ${({ theme }) => theme.smallDesktop`
  &.el-col-22{
    width: 83.33333%;
  }
  `}
  ${({ theme }) => theme.desktop`
  &.el-col-22{
    width: 91.66667%;
  }
  `}
`;

const StatsWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-0 {
    display: none;
  }
  &.el-col-2 {
    width: 100%;
    padding: 0 !important;
    margin-top: 15px;
  }

  &.el-col-12 {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 15px 0;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-2{
      width: 16.66667%;
      padding-left: 15px !important;
      margin-top: 0;
    }
    &.el-col-12{
      width: 50%;
      padding-left: 16px !important;
      margin: 0;
    }
  `}
  ${({ theme }) => theme.desktop`
    &.el-col-2{
      width: 8.33333%;
      padding-left: 15px !important;
      margin-top: 0;
    }
  `}
`;

const GraphLoading = styled(Loading)`
  height: 100%;
  .el-loading-spinner {
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const CustomReactApexChart = styled(ReactApexChart)`
  & .apexcharts-menu{
    min-width: initial !important;
  } 
`;

const DownloadLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const SmallCardTitle = styled.div`
  color: ${Theme.black};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin: 0;
  padding: ${(props) => props.margin};
`;

const NoDataText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #5e7382;
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;