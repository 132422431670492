/**
 * MaintenancesTemplate Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { Theme } from "globalStyles";
// import { useTranslation } from "react-i18next";

import moment from "moment";

export default ({ children, ...props }) => {
  const { data } = props;
  // const { t } = useTranslation();
  const getUserName = () => {
    if (data) {
      if (
        data.user != null &&
        data.user.fullName != null &&
        data.user.fullName !== ""
      ) {
        return <EllipsisDivName>{data.user.fullName}</EllipsisDivName>;
      }
    }
    return <EllipsisDivName>{"-"}</EllipsisDivName>;
  };
  return (
    <Wrapper active={props.active ? 1 : 0} onClick={props.onClick}>
      <DateTime>{moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}</DateTime>
      <User>{getUserName()}</User>
      <Container>
        {data.container && data.container.name ? data.container.name : "-"}
      </Container>
      <Status
        percentage={
          data.previousFillLevel != null ? data.previousFillLevel : 0
        }
      >
        {data.previousFillLevel != null
          ? ` ${data.previousFillLevel}%`
          : ` 0%`}
      </Status>
      <EllipsisDiv>
        {data.description
          ? data.description
          : "-"}
      </EllipsisDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  row-gap: 8px;
  column-gap: 8px;

  border-bottom: 1px solid ${Theme.inactiveColor};

  ${(props) =>
    props.error &&
    css`
      border-left: 3px solid ${Theme.errorColor};
      background-color: ${Theme.lightColor};
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.activeRowColor};
      background-color: ${Theme.activeRowColor};
    `}
`;

const DateTime = styled.div`
  font-weight: 300;
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: right;
  font-size: 12px;
  color: ${Theme.iconDarkColor};
`;
const User = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 16px;
`;
const Container = styled.div`
  font-size: 12px;
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 700;
  color: ${Theme.iconDarkColor};
`;
const Status = styled.div`
  color: ${Theme.textonSecondary};
  grid-column: 2/3;
  grid-row: 2/3;
  text-align: right;
  font-size: 12px;
  margin: auto 0;
  position: relative;
  z-index: 100;
  font-weight: bold;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 4px;
    width: ${(props) => (props.percentage ? `${props.percentage}%` : 0)};
    background-color: ${Theme.secondaryColor};
  }
`;

const EllipsisDiv = styled.div`
  font-size: 12px;
  rid-column: 1/3;
  grid-row: 3/4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsisDivName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
