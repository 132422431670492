/**
 * Navbar Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from "kea";
import { put, call } from "redux-saga/effects";
import { BASE_API_URL } from "config";
import axios from "axios";
import { toast } from "react-toastify";

//import { delay } from 'redux-saga'
//import { } from 'config'
//import { } from 'utils'
//import { } from './utils'
import AppLogic from "containers/App/logic";

import PropTypes from "prop-types";
//import * as Check from 'validations'
const NOTIFICATIONSURL = BASE_API_URL + "users/notifications/";
const USERINFOURL = BASE_API_URL + "users/me/myinfo/";

export default kea({
  path: () => ["scenes", "containers", "Navbar"],

  connect: {
    props: [AppLogic, ["user"]],
    actions: [],
  },

  actions: () => ({
    getNotifications: () => true,
    setNotifications: (notifications) => ({ notifications }),
    setNotificationsTotal: (total) => ({ total }),
    markAsRead: (id) => ({ id }),
    markAllAsRead: () => true,
    getUserInformation: () => true,
    finishUserInfo: () => true,

    setPagination: (pag) => ({ pag }),
    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    notifications: [
      [],
      PropTypes.array,
      {
        [actions.setNotifications]: (state, { notifications }) => notifications,
        [actions.reset]: () => [],
      },
    ],
    notificationsTotal: [
      0,
      PropTypes.number,
      {
        [actions.setNotificationsTotal]: (state, { total }) => total,
        [actions.reset]: () => 0,
      },
    ],
    pagination: [
      { page: 1, limit: 5 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.getNotifications]: () => ({ page: 1, limit: 5 }),
        [actions.reset]: () => ({ page: 1, limit: 5 }),
      },
    ],
    gettingUserInformation: [
      false,
      PropTypes.bool,
      {
        [actions.getUserInformation]: () => true,
        [actions.finishUserInfo]: () => false,
        [actions.reset]: () => false,
      },
    ],
  }),

  selectors: ({ selectors }) => ({
    unreadNotifications: [
      () => [selectors.notifications],
      (notifications) => notifications.filter((el) => el.read === false),
      PropTypes.array,
    ],
  }),

  start: function* () {
    const { getNotifications } = this.actions;
    yield put(getNotifications());
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.getNotifications]: workers.getNotifications,
    [actions.setPagination]: workers.getNotifications,
    [actions.markAllAsRead]: workers.markAllAsRead,
    [actions.markAsRead]: workers.markAsRead,
    [actions.getUserInformation]: workers.getUserInformation,
  }),

  workers: {
    *getUserInformation() {
      const { finishUserInfo } = this.actions;
      const { t } = this.props;
      try {
        const response = yield call(axios.get, USERINFOURL);
        if (response) {
          toast.success(t("user_information.success"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (err) {
        console.log({ err });
        if (
          err != null && 
          err.response != null &&
          err.response.data != null &&
          err.response.data.message != null
        ) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(t("user_information.error"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } finally {
        yield put(finishUserInfo());
      }
    },

    *getNotifications() {
      const user = yield this.get("user");
      const pagination = yield this.get("pagination");

      const { setNotifications, setNotificationsTotal } = this.actions;
      if (user != null) {
        try {
          const response = yield call(axios.get, NOTIFICATIONSURL, {
            params: {
              page: pagination.page,
              page_size: pagination.limit,
              ordering: "-created_at",
              user__id: user.id
            },
          });

          if (response && response.data && response.data.results) {
            if (pagination.page > 1) {
              const notifications = yield this.get("notifications");
              yield put(
                setNotifications([...notifications, ...response.data.results])
              );
            } else {
              yield put(setNotifications(response.data.results));
            }
            yield put(setNotificationsTotal(response.data.results.length));
          } else {
            yield put(setNotifications([]));
            yield put(setNotificationsTotal(0));
          }
        } catch (err) {
          console.log({ err });
          yield put(setNotifications([]));
          yield put(setNotificationsTotal(0));
        }
      }
    },

    *markAllAsRead() {
      const notifications = yield this.get("notifications");
      const { setNotifications } = this.actions;
      try {
        const copy = JSON.parse(JSON.stringify(notifications));
        copy.map((el) => ({ ...el, read: true }));
        yield put(setNotifications(copy));
        yield call(axios.get, `${NOTIFICATIONSURL}mark-all-as-read/`);
      } catch (err) {
        console.log(err);
      }
    },

    *markAsRead(action) {
      const notifications = yield this.get("notifications");
      const { payload } = action;
      const { setNotifications } = this.actions;
      if (payload.id != null) {
        try {
          var foundIndex = notifications.findIndex(
            (el) => payload.id === el.id
          );

          const copy = JSON.parse(JSON.stringify(notifications));
          copy[foundIndex].read = true;
          yield put(setNotifications(copy));
          yield call(
            axios.get,
            `${NOTIFICATIONSURL}${payload.id}/mark-as-read/`
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
});
