import * as React from "react"
import { Theme } from "globalStyles";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.682 10.75C20.067 10.75 22 8.79 22 6.375S20.067 2 17.682 2s-4.318 1.959-4.318 4.375 1.933 4.375 4.318 4.375z"
        fill={props.fill || Theme.secondaryColor}
      />
      <path
        d="M17.692 12.5h-.01c-3.333 0-6.045-2.748-6.045-6.125 0-.928.21-1.806.577-2.595a6.034 6.034 0 00-.577-.03c-3.339 0-6.046 2.742-6.046 6.125v2.44a5.899 5.899 0 01-2.062 4.49 1.543 1.543 0 00-.475 1.572c.183.686.852 1.123 1.553 1.123h14.055c.736 0 1.43-.483 1.579-1.214a1.545 1.545 0 00-.516-1.498c-1.256-1.066-1.982-2.633-2.033-4.289zM11.637 23c1.564 0 2.872-1.13 3.173-2.625H8.464C8.764 21.87 10.073 23 11.637 23z"
        fill={props.alertFill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
