/**
 * ClientDepositsTemplate Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { Theme } from "globalStyles";
import { useTranslation } from "react-i18next";

import moment from "moment";

export default ({ children, ...props }) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <Wrapper
      error={!data.complete ? 1 : 0}
      active={props.active ? 1 : 0}
      onClick={props.onClick}
    >
      <DateTime>{moment(data.datetime).format("DD/MM/YYYY HH:mm")}</DateTime>
      <Container>
        {data.container && data.container.name ? data.container.name : "-"}
      </Container>
      <Status>{`${t("table.earned_points")}: ${data.earnedPoints ? data.earnedPoints : 0}`}</Status>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 8px;
  column-gap: 8px;

  border-bottom: 1px solid ${Theme.inactiveColor};

  ${(props) =>
    props.error &&
    css`
      border-left: 3px solid ${Theme.errorColor};
      background-color: ${Theme.lightColor};
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.activeRowColor};
      background-color: ${Theme.activeRowColor};
    `}
`;

const DateTime = styled.div`
  font-weight: 300;
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: right;
  font-size: 12px;
  color: ${Theme.iconDarkColor};
`;

const Container = styled.div`
  font-size: 12px;
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 700;
  color: ${Theme.iconDarkColor};
`;
const Status = styled.div`
  color: ${Theme.iconDarkColor};
  grid-column: 1/2;
  grid-row: 2/3;
  text-align: left;
  font-size: 12px;
  margin: auto 0;
`;
