/**
 * DashboardWrapper Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from "kea";

//import { put } from 'redux-saga/effects'
//import { delay } from 'redux-saga'
//import { } from 'config'
//import { } from 'utils'
//import { } from './utils'

//import PropTypes from 'prop-types'
//import * as Check from 'validations'

import AppLogic from "containers/App/logic";

export default kea({
  path: () => ["scenes", "DashboardWrapper"],

  connect: {
    props: [AppLogic, ["user", "loadingUser"]],
    actions: [AppLogic, ["logout"]]
  },

  actions: () => ({}),

  reducers: ({ actions }) => ({}),

  start: function*() {},

  takeLatest: ({ actions, workers }) => ({}),

  workers: {}
});
