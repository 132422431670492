import React from "react";
import styled from "styled-components";
import Datetime from "react-datetime";
import { Theme } from "globalStyles";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { API_DATE_FORMAT } from "config";
import moment from "moment";

export default ({ children, ...props }) => {
  const { i18n } = useTranslation();
  if (isMobile) {
    return (
      <MobileWrapper>
        <MobileInputCalendarView>
          {moment(props.value).format("DD/MM/YYYY")}
        </MobileInputCalendarView>
        <MobileCalendarPick
          value={moment(props.value).format(API_DATE_FORMAT)}
          onChange={(event) =>
            event.target.value != null &&
            event.target.value !== "" &&
            props.onChange(moment(event.target.value))
          }
          type={"date"}
        ></MobileCalendarPick>
      </MobileWrapper>
    );
  }
  return (
    <Wrapper {...props} locale={i18n.language === "pt" ? "pt" : "en-gb"} />
  );
};

const Wrapper = styled(Datetime)`
  width: 250px;
  margin: 10px;
  height: 100%;
  input {
    width: 100%;
    padding: 10px;
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.borderColor};

    &:active,
    &:focus {
      border: 1px solid ${(props) => props.theme.borderColor};
    }

    &:disabled {
      background: transparent;
      color: ${(props) => props.theme.textonSecondary};
    }
  }

  .rdtPicker {
    /* background-color: ${(props) => props.theme.primaryColor}; */
    width: 100%;
    margin-top: 10px;
    th {
      height: 33px;
      border: 0px;
    }
  }

  .rdtMonth {
    transition: 0s all;
    :hover {
      background-color: ${(props) => props.theme.secondaryColor} !important;
      border: 2px solid ${(props) => props.theme.secondaryColor} !important;
      color: ${(props) => props.theme.white};
    }
    &.rdtActive {
      background-color: ${(props) => props.theme.secondaryColor} !important;
    }
  }
  .rdtYear {
    transition: 0s all;
    :hover {
      background-color: ${(props) => props.theme.secondaryColor} !important;
      border: 2px solid ${(props) => props.theme.secondaryColor} !important;
      color: ${(props) => props.theme.white};
    }
    &.rdtActive {
      background-color: ${(props) => props.theme.secondaryColor} !important;
    }
  }
  .rdtDay {
    transition: 0s all;
    :hover {
      background-color: ${(props) => props.theme.secondaryColor} !important;
      border: 2px solid ${(props) => props.theme.secondaryColor} !important;
      color: ${(props) => props.theme.white};
    }
    &.rdtActive {
      background-color: ${(props) => props.theme.secondaryColor} !important;
    }
  }
  &.rdtOpen .rdtPicker {
    position: relative;
  }
`;

const MobileWrapper = styled.div`
  width: 100%;
  position: relative;
  background: transparent;
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const MobileCalendarPick = styled.input`
  opacity: 0;
  width: 100%;
`;

const MobileInputCalendarView = styled.span`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  -webkit-appearance: none;

  padding: 0 8px;
  color: ${Theme.textonSecondary};
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 1em;

  height: 2rem;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};

  &:focus,
  &:active {
    outline: none;
    border: 2px solid ${(props) => props.theme.borderColor} !important;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;
