/**
 * CollectsTemplate Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { Theme } from "globalStyles";
import { useTranslation } from "react-i18next";

import moment from "moment";

export default ({ children, ...props }) => {
  const { data, mode } = props;
  const { t } = useTranslation();

  if (mode === "costumer") {
    return (
      <Wrapper onClick={props.onClick} status={data.status}>
        <DateTime>
          {moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        </DateTime>
        {/* <User>
            {data.costumer && data.costumer && data.costumer.fullName
              ? `${data.costumer.fullName}`
              : "-"}
          </User> */}
        <Container>
          {data.trader && data.trader && data.trader.fullName
            ? `${data.trader.fullName}`
            : "-"}
        </Container>
        <Points>
          {data.points != null
            ? `${data.points} ${t("table.earned_points")}`
            : "-"}
        </Points>
        <Status>{data.status != null ? t(`table.${data.status}`) : "-"}</Status>
      </Wrapper>
    );
  }

  if (mode === "trader") {
    return (
      <Wrapper onClick={props.onClick} status={data.status}>
        <DateTime>
          {moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        </DateTime>
        <User>
          {data.costumer && data.costumer && data.costumer.fullName
            ? `${data.costumer.fullName}`
            : "-"}
        </User>
        {/* <Container>
            {data.trader && data.trader && data.trader.fullName
              ? `${data.trader.fullName}`
              : "-"}
          </Container> */}
        <Points>
          {data.points != null
            ? `${data.points} ${t("table.earned_points")}`
            : "-"}
        </Points>
        <Status>{data.status != null ? t(`table.${data.status}`) : "-"}</Status>
      </Wrapper>
    );
  }

  if (mode === "admin") {
    return (
      <Wrapper onClick={props.onClick} status={data.status}>
        <DateTime>
          {moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        </DateTime>
        <User>
          {data.costumer && data.costumer && data.costumer.fullName
            ? `${data.costumer.fullName}`
            : "-"}
        </User>
        <Container>
          {data.trader && data.trader && data.trader.fullName
            ? `${data.trader.fullName}`
            : "-"}
        </Container>
        <Points>{}</Points>
        <Status>{data.status != null ? t(`table.${data.status}`) : "-"}</Status>
      </Wrapper>
    );
  }
  return null
};

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  row-gap: 8px;
  column-gap: 8px;

  border-bottom: 1px solid ${Theme.inactiveColor};

  ${(props) =>
    props.status === "cancelled" &&
    css`
      border-left: 3px solid ${Theme.errorColor};
      background-color: ${Theme.lightColor};
    `}

  ${(props) =>
    props.status === "approved" &&
    css`
      border-left: 3px solid ${Theme.successColor};
      background-color: ${Theme.lightSuccessColor};
    `}

  ${(props) =>
    props.status === "expired" &&
    css`
      border-left: 3px solid ${Theme.alertColor};
      background-color: ${Theme.lightAlertColor};
    `}
`;

const DateTime = styled.div`
  font-weight: 300;
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: right;
  font-size: 12px;
  color: ${Theme.iconDarkColor};
`;
const User = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 16px;
`;

const Points = styled.p`
  margin: 0;
  font-size: 16px;
`;
const Container = styled.div`
  font-size: 12px;
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 700;
  color: ${Theme.iconDarkColor};
`;
const Status = styled.div`
  color: ${Theme.textonSecondary};
  grid-column: 2/3;
  grid-row: 2/3;
  text-align: right;
  font-size: 12px;
  margin: auto 0;
  color: ${(props) => props.color};
`;
