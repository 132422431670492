/**
 * NotificationsWrapper Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Theme } from "globalStyles";

// title === uuid
// type === type
// user === user

export default ({ children, ...props }) => {
  const { t } = useTranslation();
  const buildNotificationBody = (type, description, title) => {
    if (description != null && description !== "") {
      switch (type) {
        case "alert":
          return `${title != null && title !== "" ? ": " : ""}${t(
            `${description}`
          )}`;
        case "error":
          return `${title != null && title !== "" ? ": " : ""}${t(
            `${description}`
          )}`;
        case "drop":
          return ``;
        case "pick":
          return ``;
        case "maintenance":
          return ``;
        default:
          return description ? description : "";
      }
    }
  };

  return (
    <Wrapper>
      <NotificationTitle>
        {props.type ? t(`notification.${props.type}`) : t("notification.title")}
      </NotificationTitle>
      <NotificationBody>
        <span>{props.title != null ? `${props.title}` : ""}</span>
        <span>
          {buildNotificationBody(props.type, props.description, props.title)}
        </span>
      </NotificationBody>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const NotificationTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 1rem;
  font-family: ${Theme.fontFamily};
  color: ${Theme.textonSecondary};
`;

const NotificationBody = styled.div`
  font-size: 14px;
  line-height: 1em;
  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};

  & > p {
    margin-left: auto;
    margin-top: 0.5rem;
    font-size: 10px;
    color: ${Theme.darkInactiveColor};
  }
`;
