/**
 * Index where the application begins
 * Where we configure the Hot Reloading
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { SENTRY_URL, VERSION } from 'config'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Store } from './store'
import { GlobalStyles } from './globalStyles'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'translations/i18n'
import { i18n } from 'element-react'
import localePT from 'element-react/src/locale/lang/pt'

// Stylings
import 'element-theme-default'

// App Root
import App from './containers/App'
import LogRocket from 'logrocket'

// Where the app is rendered
const app = document.getElementById('root')
i18n.use(localePT)

const localVersion = window.localStorage.getItem('version')
const localIsTheLast = VERSION === localVersion

if (!localIsTheLast) {
  window.localStorage.setItem('version', VERSION)
}

// Sentry
/* istanbul ignore if */
if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('0sjxyd/smartpayt-cantanhede')
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <Provider store={Store}>
    <React.Fragment>
      <GlobalStyles />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>,
  app
)
