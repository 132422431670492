import React from "react";
import styled from "styled-components";
import { Theme } from "globalStyles";

export const AnimatedArrow = (props) => (
  <AnimatedArrowWrapper>
    <svg
      viewBox="0 0 24 24"
      width={20}
      height={20}
      fill={Theme.black}
      transform={props.transform}
    >
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  </AnimatedArrowWrapper>
);

export const AnimatedArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  transform: ${({ transform }) => transform || ""};
  transition: 0.3s all;

  & svg, & path {
  transition: 0.3s all;

  }
`;
