import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.694 15.47a.832.832 0 00.59.244.836.836 0 00.593-1.425l-1.009-1.008v-2.16a.836.836 0 00-1.673 0v2.506c0 .222.088.433.245.59l1.254 1.253z"
        fill={props.fill || "#000"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.288 5.349c.023-.008.046-.015.07-.015.35 0 .642.282.642.625v15.416a.64.64 0 01-.643.625h-15C4.054 22 3 20.975 3 19.707V4.291a.56.56 0 01.042-.207C3.248 2.9 4.302 2 5.572 2h12.857c.471 0 .857.375.856.834v2.5h.857c.022 0 .044.006.067.013a.557.557 0 00.045.012l.034-.01zM6.429 13.668c0 2.982 2.502 5.416 5.571 5.416s5.571-2.434 5.571-5.416c0-2.983-2.503-5.417-5.571-5.417-3.068 0-5.571 2.433-5.571 5.417zm-.858-8.334h12V3.666h-12a.848.848 0 00-.857.834c0 .459.386.834.857.834z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
