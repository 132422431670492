/**
 * Brand Component
 *
 * @author Gonçalo Santos <gsantos@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'


const Arrow = props => ({
  render() {
    return (
      <Wrapper margin={this.props.margin} marginLeft={this.props.marginleft} {...this.props}>
        <svg viewBox="0 0 129 129" height={this.props.height ? this.props.height : "20"} width={this.props.height ? this.props.height : "20"} transform={`rotate(${this.props.rotate}, 0, 0)`} fill={this.props.fill ? this.props.fill : "#FFFFFF"}>
          < path d="M121.3 34.6c-1.6-1.6-4.2-1.6-5.8 0l-51 51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l53.9 53.9c.8.8 1.8 1.2 2.9 1.2 1 0 2.1-.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2.1-5.8z" />
        </svg >
      </Wrapper >

    )
  }
})


export default Arrow

const Wrapper = styled.div`
  margin:0px auto 0px auto;
  margin:${props => props.margin};
  margin-left:${props => props.marginleft};
`

