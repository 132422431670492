/**
 * Login Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled from "styled-components";
import { Input } from "element-react";
// import { lighten } from "polished";
import { Theme } from "globalStyles";
import { withTranslation } from "react-i18next";
import StyledButton from "components/StyledButton";

import { Link } from "react-router-dom";

class Login extends Component {
  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { submit } = this.actions;
      submit();
    }
  };

  componentDidUpdate() {
    if (this.props.response === "success") {
      const { history } = this.props;
      const { reset } = this.actions;

      history.push("/");
      reset();
    }
  }

  render() {
    const { username, password } = this.props.form;
    const { t, submiting, error } = this.props;
    const { change, submit } = this.actions;

    return (
      <Wrapper>
        <FlexWrapper>
          <LoginText>{t("login")}</LoginText>

          <StyledInput
            label={"Username"}
            type="email"
            // disabled={submiting}
            placeholder={t("username_placeholder")}
            value={username.value}
            onChange={(e) => {
              change({ username: e });
            }}
            onKeyDown={this.handleEnter}
            //haserror needs to be passed as strings and not booleans, to avoid console errors
            haserror={(username.valid === false && username.message != null).toString() || 'false'}
          />
          {username.valid === false && username.message != null && (
            <InputError>{t(username.message)}</InputError>
          )}

          <StyledInput
            id="standard-password-input"
            label="Password"
            type="password"
            // disabled={submiting}
            placeholder={t("password_placeholder")}
            autoComplete="current-password"
            value={password.value}
            onChange={(e) => {
              change({ password: e });
            }}
            onKeyDown={this.handleEnter}
            haserror={(username.valid === false && username.message != null).toString() || 'false'}
          />
           {password.valid === false && password.message != null && (
            <InputError>{t(password.message)}</InputError>
          )}

          <AlignCenter>
            <StyledButton
              disabled={submiting}
              onClick={submit}
              type={"primary"}
              margin={"0"}
            >
              {t("login")}
            </StyledButton>
          </AlignCenter>

          {error && <Error> {error.map((error) => t(error))}</Error>}

          <RecoverPassword to={"/password/reset/"}>
            {t("recover_password")}
          </RecoverPassword>
        </FlexWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation()(Logic(Login));

const Wrapper = styled.div`
  height: calc(100vh - 4rem - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8;
`;

const FlexWrapper = styled.div`
  min-height: 330px;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1);
  padding: 32px;
  margin: 32px;
  width: min(412px, 100%)
`;

const Error = styled.div`
  width: 100%;
  color: red;
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
  font-size: 12px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
`;

// const StyledButton = styled(Button)`
//   width: 100%;
//   color: #ffffff !important;
//   height: 40px !important;
//   background-color: ${Theme.primaryColor} !important;
//   box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1) !important;
//   transition: background-color 0.6s !important;
//   transition: color 0.3s;

//   :focus {
//     color: #8391a5 !important;
//     border-color: #8391a5 !important;
//     color: #ffffff !important;
//   }

//   :hover {
//     color: #ffffff !important;

//     background-color: ${lighten(0.1, Theme.primaryColor)} !important;
//   }
// `;

const StyledInput = styled(Input)`
  margin-bottom: 15px;

  .el-input__inner {
    color: #242a3e;
    font-family: ${Theme.fontFamily};
    font-size: 12px;
    line-height: 16px;
    padding: 0px 16px;
    :focus {
      border-color: #8391a5;
    }
  }

  input {
    text-align: left !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-text-fill-color: #1f2d3d !important;
    background-color: #ffffff !important;
    color: #1f2d3d !important;
  }

  margin-bottom: ${props => props.haserror === 'true' && 0};
`;

const LoginText = styled.div`
  height: 36px;
  color: #242a3e;
  font-family: ${Theme.fontFamily};
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 32px;
`;

const RecoverPassword = styled(Link)`
  margin: 0.5rem 0 0 auto;
  display: block;
  width: fit-content;

  color: ${Theme.primaryColor};
  font-family: ${Theme.fontFamily};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;

  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

const InputError = styled(Error)`
  text-align: left;
  justify-content: flex-start;

  margin: 0.5rem 0
`