import * as Sentry from '@sentry/react'
import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { AUTH_TOKEN_TYPE } from 'config'

let languageInterceptor

const decamelizeKeysTransformer = function (data) {
  return data && JSON.stringify(decamelizeKeys(data))
}

export const setAuthorizationInterceptor = (authToken) => {
  axios.interceptors.request.use(
    (config) => {
      let token = window.localStorage.getItem('access_token')

      if (token) {
        config.headers.Authorization = `${AUTH_TOKEN_TYPE} ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export const setLanguageInterceptor = (language) => {
  axios.interceptors.request.eject(languageInterceptor)
  languageInterceptor = axios.interceptors.request.use(
    (config) => {
      if (language) {
        config.headers['Accept-Language'] = `${language};q=0.8`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

function timeout (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const setResponseInterceptor = (history, checkUserAuthentication) => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (
        error.response.status === 401 &&
        history.location.match !== '/login/'
      ) {
        await checkUserAuthentication(error.config)
        await timeout(1000)
        const token = window.localStorage.getItem('access_token')
        error.config.headers['Authorization'] = `${AUTH_TOKEN_TYPE} ${token}`
        error.config.baseURL = undefined
        return axios.request(error.config)
      }
      return Promise.reject(error)
    }
  )
}

export const removeAuthorizationInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      delete config.headers.Authorization
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

// Converts all responses for CamelCase
axios.interceptors.response.use(
  (response) => {
    if (
      response.config.responseType == null ||
      response.config.responseType !== 'blob'
    ) {
      response.data = camelizeKeys(response.data)
    }
    return response
  },
  (error) => {
    // Log errors to sentry, if it's in production.
    const isProduction = process.env.NODE_ENV === 'production'
    if (isProduction) {
      // This order of lines of code should be kept to add 'payload' and 'response' to the exception that is sent to sentry
      Sentry.setExtra('payload', error.config.data)
      Sentry.setExtra('response', error.response.data)
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  }
)

// Converts all requests to under-cased
axios.interceptors.request.use(
  (config) => {
    const currentContentType = config.headers['Content-Type']

    // Converts URL get params to underscored
    if (config.params) {
      config.params = decamelizeKeys(config.params)
    }

    if (!currentContentType) {
      config.headers['Content-Type'] = 'application/json;charset=utf-8'
      config.transformRequest = [decamelizeKeysTransformer]
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
export default axios
