import { Theme } from "globalStyles";

export default (code) => {
  switch (code) {
    case "y":
      return Theme.warningColor;
    case "b":
      return Theme.infoColor;
    case "g":
      return Theme.successColor;
    case "u":
      return Theme.darkPrimaryColor;
    case "bw":
      return Theme.darkPrimaryColor;
    default:
      return Theme.darkPrimaryColor;
  }
};
