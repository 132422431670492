import React from "react";
import moment from "moment";
import StyledProgressBar from "components/StyledProgressBar";
import styled from "styled-components";

export const columns = [
  {
    label: "map.address",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "address",
    render: function (data) {
      return (
        <EllipsisDiv title={data.address ? data.address : "-"}>
          {data.address ? data.address : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: "400px",
  },
  {
    label: "table.event",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "type",
    render: function (data, { t }) {
      return (
        <EllipsisDiv>{t(`notification.${data.type}`)}</EllipsisDiv>
      );
    },
    width: 180,
  },
  {
    label: "table.fill_level",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "fillLevel",
    render: function (data) {
      return (
        <div>
          <StyledProgressBar
            percentage={
              data.fillLevel != null ? data.fillLevel : 0
            }
          />
        </div>
      );
    },
    width: 220,
  },
  {
    label: "table.date",

    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "date",
    render: function (data) {
      return (
        <EllipsisDiv title={moment(data.date).format("DD/MM/YY HH:mm")}>
          {moment(data.date).format("DD/MM/YY HH:mm")}
        </EllipsisDiv>
      );
    },
    width: 200,
  },
  {
    label: "table.user",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return <EllipsisDiv title={data.user}>{data.user}</EllipsisDiv>;
    },
    width: 180
  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;