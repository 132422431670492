/**
 * Transactions Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import React from 'react';

import { kea } from 'kea';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { BASE_API_URL, API_DATE_FORMAT } from 'config';
import axios from 'axios';

import NotificationsWrapper from 'components/NotificationsWrapper';
import AppLogic from 'containers/App/logic';

const TRANSACTIONSURL = BASE_API_URL + 'transactions/';
const EVENTSBYDAY = BASE_API_URL + 'transactions/by-day/';
const METRICSURL = BASE_API_URL + 'metrics/transactions/';
const SEARCHBASEURL = BASE_API_URL + 'transactions/';
const DOWNLOADURL = BASE_API_URL + 'transactions/export/';

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'Transactions'],

  connect: {
    props: [AppLogic, ['user']],
    actions: [
      AppLogic,
      ['setWebSocketHanlder', 'changeWatchingEvents', 'watchEvents'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,

    switchCostumerTrader: () => true,

    fetchDrops: (data) => ({ data }),
    setDropsLabels: (labels) => ({ labels }),
    setDrops: (data) => ({ data }),
    setLoadingDrops: (loading) => loading,

    search: (query, cb, type) => ({ query, cb, type }),

    fetchMaintenancesList: () => true,
    setMaintenancesList: (list) => ({ list }),
    setMaintenancesNumber: (number) => ({ number }),

    fetchMetrics: () => true,
    setMetrics: (metrics) => ({ metrics }),

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,

    setPagination: (pag) => ({ pag }),

    triggerFetch: () => true,

    download: (type) => ({ type }),
    finishDownload: () => true,
    setTraderSearchResults: (results) => ({ results }),
    setCostumerSearchResults: (results) => ({ results }),

    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.switchCostumerTrader]: () => ({ ...INTERVAL_FORM_DEFAULTS }),
        [actions.reset]: () => ({ ...INTERVAL_FORM_DEFAULTS }),
        [actions.resetFilter]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    pagination: [
      { page: 1, page_size: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFiltersForm]: (state) => ({ ...state, page: 1 }),
        [actions.switchCostumerTrader]: () => ({ page: 1, page_size: 10 }),
        [actions.resetFilter]: () => ({ page: 1, page_size: 10 }),
        [actions.reset]: () => ({ page: 1, page_size: 10 }),
      },
    ],

    maintenancesList: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenancesList]: (state, { list }) => list,
        [actions.switchCostumerTrader]: () => [],
        [actions.reset]: () => [],
      },
    ],

    metrics: [
      null,
      PropTypes.object,
      {
        [actions.setMetrics]: (state, { metrics }) => metrics,
        [actions.reset]: () => null,
        [actions.switchCostumerTrader]: () => null,
        [actions.resetFilter]: () => null,
      },
    ],

    filter: [
      {
        search_customer: '',
        search_trader: '',
        transaction_status: '',
      },
      PropTypes.object,
      {
        [actions.setFilter]: (state, { filter }) => filter,
        [actions.changeFilter]: (state, payload) => {
          return Object.assign({}, state, { [payload.key]: payload.value });
        },
        [actions.switchCostumerTrader]: () => ({
          search_customer: '',
          search_trader: '',
          transaction_status: '',
        }),
        [actions.resetFilter]: () => ({
          search_customer: '',
          search_trader: '',
          transaction_status: '',
        }),
        [actions.reset]: () => ({
          search_customer: '',
          search_trader: '',
          transaction_status: '',
        }),
      },
    ],

    loadingDrops: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingDrops]: (state, loading) => loading,
        [actions.setFiltersForm]: () => true,
        [actions.switchCostumerTrader]: () => true,
      },
    ],

    inTraderMode: [
      false,
      PropTypes.boolean,
      {
        [actions.switchCostumerTrader]: (state) => !state,
        [actions.reset]: () => false,
      },
    ],

    drops: [
      [],
      PropTypes.array,
      {
        [actions.setDrops]: (state, { data }) => data,
        [actions.switchCostumerTrader]: () => [],
        [actions.reset]: () => [],
      },
    ],

    dropsLabels: [
      [],
      PropTypes.array,
      {
        [actions.setDropsLabels]: (state, { labels }) => labels,
        [actions.switchCostumerTrader]: () => [],
        [actions.reset]: () => [],
      },
    ],

    loadingDownload: [
      false,
      PropTypes.boolean,
      {
        [actions.download]: () => true,
        [actions.finishDownload]: () => false,
        [actions.reset]: () => false,
      },
    ],

    searchCostumerResults: [
      [],
      PropTypes.array,
      {
        [actions.setCostumerSearchResults]: (state, { results }) => results,
      },
    ],
    searchCostumerLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'costumer',
        [actions.setCostumerSearchResults]: () => false,
      },
    ],

    searchTraderResults: [
      [],
      PropTypes.array,
      {
        [actions.setTraderSearchResults]: (state, { results }) => results,
      },
    ],
    searchTraderLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'trader',
        [actions.setTraderSearchResults]: () => false,
      },
    ],
  }),

  start: function* () {
    const {
      reset,
      resetFilter,
      switchCostumerTrader,
      setWebSocketHanlder,
      watchEvents,
      search,
    } = this.actions;
    yield put(reset());
    const user = yield this.get('user');
    if (user != null && user.isTrader) {
      yield put(switchCostumerTrader());
    }
    yield put(resetFilter());

    yield put(setWebSocketHanlder(this.workers.handle));
    yield put(watchEvents());

    yield put(search('', null, 'costumer'));
    yield put(search('', null, 'trader'));
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchMaintenancesList]: workers.fetchMaintenancesList,
    [actions.switchCostumerTrader]: [
      workers.fetchMaintenancesList,
      workers.fetchDrops,
      workers.fetchMetrics,
    ],
    [actions.resetFilter]: [
      workers.fetchMaintenancesList,
      workers.fetchDrops,
      workers.fetchMetrics,
    ],
    [actions.setPagination]: workers.fetchMaintenancesList,
    [actions.triggerFetch]: [
      workers.fetchMaintenancesList,
      workers.fetchDrops,
      workers.fetchMetrics,
    ],
    [actions.setFiltersForm]: [
      workers.fetchMaintenancesList,
      workers.fetchDrops,
      workers.fetchMetrics,
    ],
    [actions.fetchDrops]: workers.fetchDrops,
    [actions.download]: workers.download,
  }),

  takeEvery: ({ actions, workers }) => ({
    [actions.search]: workers.search,
  }),

  workers: {
    *search(action) {
      const { query, cb, type } = action.payload;
      const { setTraderSearchResults, setCostumerSearchResults } = this.actions;
      let url = null;
      if (query != null) {
        switch (type) {
          case 'trader':
            url = SEARCHBASEURL + 'search-trader/';
            break;

          case 'costumer':
            url = SEARCHBASEURL + 'search-customer/';
            break;
          default:
            break;
        }

        try {
          const request = yield call(axios.get, url, {
            params: {
              name: query,
            },
          });

          if (request.data) {
            const { results } = request.data;
            switch (type) {
              case 'trader':
                yield put(
                  setTraderSearchResults(
                    results.map((el) => ({ value: el, label: el }))
                  )
                );
                return;

              case 'costumer':
                yield put(
                  setCostumerSearchResults(
                    results.map((el) => ({ value: el, label: el }))
                  )
                );
                return;
              default:
                cb(results.map((el) => ({ value: el })));
                return;
            }
          }
        } catch (error) {
          console.log({ error });
          switch (type) {
            case 'trader':
              yield put(setTraderSearchResults([]));
              return;

            case 'costumer':
              yield put(setCostumerSearchResults([]));
              return;
            default:
              cb([]);
              return;
          }
        }
      }
    },

    *download(action) {
      const { payload } = action;
      const { t } = this.props;
      const { finishDownload } = this.actions;
      const inTraderMode = yield this.get('inTraderMode');

      const user = yield this.get('user');
      let filter = yield this.get('filter');
      const filtersForm = yield this.get('filtersForm');

      if (payload.type != null) {
        try {
          if (user != null && !user.isAdmin) {
            if (user.isTrader && inTraderMode) {
              filter = { ...filter, trading_as: 'trader' };
            } else {
              filter = { ...filter, trading_as: 'costumer' };
            }
          }

          const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
          const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

          const response = yield call(axios.get, DOWNLOADURL, {
            params: {
              file_type: payload.type,
              ...filter,
              date_after: moment(date_after).toISOString(),
              date_before: moment(date_before).endOf('day').toISOString(),
              ordering: '-datetime',
            },
            responseType: 'blob',
          });
          fileDownload(
            new Blob([response.data]),
            `report-transactions.${payload.type}`
          );
        } catch (err) {
          console.log({ err });
          toast.error(
            <NotificationsWrapper
              type={'download_error'}
              description={t('error.download_description')}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'downloadError',
            }
          );
        } finally {
          yield put(finishDownload());
        }
      }
    },

    *fetchMetrics(action) {
      const { setMetrics } = this.actions;

      const inTraderMode = yield this.get('inTraderMode');
      const filtersForm = yield this.get('filtersForm');
      const user = yield this.get('user');
      let filter = yield this.get('filter');

      if (user != null && !user.isAdmin) {
        if (user.isTrader && inTraderMode) {
          filter = { ...filter, trading_as: 'trader' };
        } else {
          filter = { ...filter, trading_as: 'costumer' };
        }
      }

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        const params = {
          ...filter,
          datetime_events_after: moment(date_after).toISOString(),
          datetime_events_before: moment(date_before)
            .endOf('day')
            .toISOString(),
        };

        const response = yield call(axios.get, METRICSURL, { params });

        if (response.data) {
          yield put(setMetrics(response.data));
        }
      } catch (err) {
        console.log({ err });
        yield put(setMetrics(null));
      }
    },

    *fetchMaintenancesList(action) {
      yield delay(500);

      const inTraderMode = yield this.get('inTraderMode');
      const filtersForm = yield this.get('filtersForm');
      const pagination = yield this.get('pagination');
      const user = yield this.get('user');
      let filter = yield this.get('filter');

      if (user != null && !user.isAdmin) {
        if (user.isTrader && inTraderMode) {
          filter = { ...filter, trading_as: 'trader' };
        } else {
          filter = { ...filter, trading_as: 'customer' };
        }
      }

      const { setMaintenancesList, setMaintenancesNumber } = this.actions;

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let response = yield call(axios.get, TRANSACTIONSURL, {
          params: {
            ...pagination,
            ...filter,
            date_after: moment(date_after).toISOString(),
            date_before: moment(date_before).endOf('day').toISOString(),
            ordering: '-datetime',
          },
        });

        if (response.data != null && response.data.results) {
          yield put(setMaintenancesList(response.data.results));
          yield put(setMaintenancesNumber(response.data.results.length));
        } else {
          yield put(setMaintenancesList([]));
          yield put(setMaintenancesNumber(0));
        }
      } catch (error) {
        console.log({ error });
        yield put(setMaintenancesList([]));
        yield put(setMaintenancesNumber(0));
      }
    },

    *fetchDrops(action) {
      yield delay(1500);
      const { setDropsLabels, setDrops, setLoadingDrops } = this.actions;
      yield put(setLoadingDrops(true));

      const inTraderMode = yield this.get('inTraderMode');
      const user = yield this.get('user');
      const filtersForm = yield this.get('filtersForm');
      let filter = yield this.get('filter');

      if (user != null && !user.isAdmin) {
        if (user.isTrader && inTraderMode) {
          filter = { ...filter, trading_as: 'trader' };
        } else {
          filter = { ...filter, trading_as: 'customer' };
        }
      }

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        const { data } = yield call(axios.get, EVENTSBYDAY, {
          params: {
            ...filter,
            date_after: moment(date_after).toISOString(),
            date_before: moment(date_before).endOf('day').toISOString(),
          },
        });

        if (data != null && data.results != null) {
          let labels = [];
          let counter = [];

          for (const transaction of data.results) {
            labels.push(transaction.date);
            counter.push(transaction.count);
          }
          yield put(setDropsLabels(labels));
          yield put(setDrops(counter));
        }
      } catch (err) {
        console.log({ err });
        yield put(setDropsLabels([]));
        yield put(setDrops([]));
      } finally {
        yield put(setLoadingDrops(false));
      }
    },

    *handle(message) {
      const { triggerFetch } = this.actions;
      const user = yield this.get('user');

      if (message.event_type === 'status' && user.isAdmin) {
        let temp = { ...message };
        const message_id =
          message.content && message.content.id ? message.content.id : null;

        toast.info(temp.message, {
          position: toast.POSITION.TOP_RIGHT,
          delay: 200,
          id: message_id ? `uniqueStatusMessage${message_id}` : null,
        });
      } else if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          const message_id =
            message.content && message.content.id ? message.content.id : null;

          if (
            message.content &&
            (message.content.type === 'drop' ||
              message.content.type === 'pick' ||
              message.content.type === 'maintenance') &&
            message.type === 'new' &&
            user.isAdmin
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueDropMessage${message_id}` : null,
              }
            );
          } else if (message.sender && message.sender === 'transaction') {
            yield put(triggerFetch());
          } else if (
            message.content &&
            message.content.type === 'alert' &&
            user.isAdmin
          ) {
            toast.warn(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
                description={
                  message.content != null && message.content.alertType
                    ? message.content.alertType
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueAlertMessage${message_id}` : null,
              }
            );
          } else if (
            message.content &&
            message.content.type === 'error' &&
            user.isAdmin
          ) {
            if (message.content.status === 'closed') {
              toast.success(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content && message.content.errorType
                      ? `${message.content.errorType}`
                      : ''
                  }
                  status={
                    message.content && message.content.status
                      ? message.content.status
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            } else {
              toast.error(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content != null && message.content.errorType
                      ? message.content.errorType
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            }
          }
        }
      }
    },
  },
});
