/**
 * GraphIndicator Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import AnimatedNumber from "react-animated-number";
import { Theme } from "globalStyles";

const formatter = new Intl.NumberFormat("en-GB", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <div>
      <StyledAnimatedNumber
        value={props.value}
        style={{
          transition: "0.8s ease-out",
        }}
        frameStyle={(perc) =>
          perc === 100 ? {} : { backgroundColor: "#ffeb3b" }
        }
        duration={300}
        stepPrecision={0.01}
        formatValue={(n) => formatter.format(n)}
      />
    </div>
    <IndicatorNumber>{props.name}</IndicatorNumber>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto 0;
  /* ${(props) =>
    props.border &&
    css`
      position: relative;
      & + & {
        &::after {
          content: "";
          height: 1px;
          width: 90%;
          background-color: ${Theme.lightInactiveColor};
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
      }
    `} */
`;

const IndicatorNumber = styled.div`
  color: ${Theme.darkInactiveColor};
  font-family: ${Theme.fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  text-align: center;
  margin: ${(props) => props.margin};
`;

const StyledAnimatedNumber = styled(AnimatedNumber)`
  color: ${Theme.black};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background: transparent !important;
`;
