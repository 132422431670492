import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.402 6.949c.42 0 .712-.44.554-.842-.56-1.43-1.919-2.44-3.503-2.44h-.732C15.448 2.179 14.184 1 12.636 1h-1.272C9.817 1 8.552 2.178 8.279 3.667h-.732c-1.584 0-2.943 1.01-3.504 2.44-.157.402.134.841.555.841h14.804zM11.364 2.23h1.272c.87 0 1.61.604 1.859 1.436h-4.99c.249-.832.988-1.436 1.859-1.436zM5.743 21.22A1.93 1.93 0 007.666 23h8.574a1.93 1.93 0 001.924-1.78l.9-12.572H4.842l.9 12.572zm7.968-9.154a.641.641 0 01.674-.606.64.64 0 01.61.67l-.343 6.813a.641.641 0 01-.642.607.64.64 0 01-.642-.67l.343-6.814zm-4.19-.606a.642.642 0 01.675.606l.343 6.814a.64.64 0 01-.643.67.641.641 0 01-.641-.607l-.343-6.814a.64.64 0 01.61-.67z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
