import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.751 18.53l-7.847-7.895c.776-1.996.345-4.338-1.294-5.986C10.886 2.913 8.3 2.566 6.23 3.52l3.708 3.73L7.35 9.854l-3.794-3.73c-1.035 2.082-.604 4.684 1.12 6.42 1.64 1.648 3.968 2.082 5.95 1.3l7.848 7.896a.83.83 0 001.207 0l1.984-1.996c.431-.347.431-.954.086-1.214z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
