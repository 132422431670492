/**
 * Main APP Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import PropTypes from "prop-types";
import { kea } from "kea";
import { put, call, take, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";
import { toast } from "react-toastify";
import { camelizeKeys } from "humps";
import { eventChannel } from "redux-saga";
import LogRocket from "logrocket";

import {
  setLanguageInterceptor,
  setAuthorizationInterceptor,
  removeAuthorizationInterceptor,
} from "interceptors";
import {
  BASE_API_URL,
  WS_BASE_URL,
  WS_JOIN_GROUP,
  AVAILABLE_ROLES,
} from "config";

const RefreshUrl = BASE_API_URL + "auth/token/refresh/";
const USERPROFILEURL = BASE_API_URL + "users/me/";

export default kea({
  path: () => ["scenes", "app"],

  actions: () => ({
    setShouldLogin: (value) => ({ value }),
    setShouldBypassLogin: (value) => ({ value }),

    setCurrentUser: (user) => ({ user }),
    getCurrentUser: true,
    getAppTranslations: true,
    checkUserAuthentication: (config) => ({ config }),
    setCurrentLanguage: (language) => ({ language }),

    logout: true,
    finished: true,
    loading: true,

    watchEvents: (handle) => handle,
    changeWatchingEvents: (event) => event,
    setWebSocketsChannel: (channel) => ({ channel }),
    setWebSocketHanlder: (handler) => ({ handler }),
  }),

  reducers: ({ actions }) => ({
    user: [
      null,
      PropTypes.any,
      {
        [actions.setCurrentUser]: (state, payload) => payload.user,
      },
    ],
    shouldLogin: [
      false,
      PropTypes.bool,
      {
        [actions.setShouldLogin]: (state, { value }) => value,
      },
    ],
    shouldBypassLogin: [
      false,
      PropTypes.bool,
      {
        [actions.setShouldBypassLogin]: (state, { value }) => value,
      },
    ],

    loading: [
      true,
      PropTypes.bool,
      {
        [actions.loading]: () => true,
        [actions.finished]: () => false,
      },
    ],
    loadingUser: [
      true,
      PropTypes.bool,
      {
        [actions.getCurrentUser]: () => true,
        [actions.setCurrentUser]: () => false,
      },
    ],
    watchingEvents: [
      false,
      PropTypes.boolean,
      {
        [actions.changeWatchingEvents]: (state, payload) => payload,
      },
    ],
    channel: [
      null,
      PropTypes.any,
      {
        [actions.setWebSocketsChannel]: (state, { channel }) => channel,
      },
    ],
    handler: [
      null,
      PropTypes.any,
      {
        [actions.setWebSocketHanlder]: (state, { handler }) => handler,
      },
    ],
    currentLanguage: [
      "pt-PT",
      PropTypes.string,
      {
        [actions.setCurrentLanguage]: (state, { language }) => language,
      },
    ],
  }),

  start: function* () {
    const { checkUserAuthentication } = this.actions;
    yield put(checkUserAuthentication());
    const currentLanguage = yield this.get("currentLanguage");
    
    if (currentLanguage) {
      setLanguageInterceptor(currentLanguage);
    }
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.setCurrentLanguage]: [workers.setCurrentLanguage],
    [actions.checkUserAuthentication]: workers.checkUserAuthentication,
    [actions.getCurrentUser]: workers.getCurrentUser,
    [actions.logout]: workers.logout,
    [actions.watchEvents]: workers.watchEvents,
  }),

  workers: {
    *setCurrentLanguage() {
      const currentLanguage = yield this.get("currentLanguage");
      
      if (currentLanguage) {
        setLanguageInterceptor(currentLanguage);
      }
    },

    /**
     * Checks for the user authentication token
     * @param {*} action
     */
    *checkUserAuthentication(action) {
      const { payload } = action;
      try {
        let refresh_token = window.localStorage.getItem("refresh_token");
        if (refresh_token) {
          const params = {
            refresh: refresh_token,
          };
          const request = yield call(axios.post, RefreshUrl, params);
          if (request.status === 200) {
            window.localStorage.setItem("access_token", request.data.access);
            setAuthorizationInterceptor();
            const { getCurrentUser, setShouldBypassLogin } = this.actions;
            yield put(getCurrentUser(true));
            yield put(setShouldBypassLogin(true));
            if (payload != null && payload.config != null) {
              const { finished } = this.actions;
              yield put(finished());
              return;
            }
          } else {
            window.localStorage.removeItem("access_token");
            const { setShouldLogin } = this.actions;
            yield put(setShouldLogin(true));
          }
        } else {
          const { setShouldLogin } = this.actions;
          yield put(setShouldLogin(true));
        }
      } catch (err) {
        console.log({ err });
        const { setShouldLogin, logout } = this.actions;
        yield put(logout());
        yield put(setShouldLogin(true));
      } finally {
        const { finished } = this.actions;
        yield delay(200);
        yield put(finished());
      }
    },

    /**
     * Gets the current user logged in
     * @param {*} action
     */
    *getCurrentUser() {
      const { setCurrentUser } = this.actions;
      const { t } = this.props;
      try {
        const request = yield call(axios.get, USERPROFILEURL);
        if (request.status === 200) {
          const isAdmin = request.data.group.name === AVAILABLE_ROLES.administration
          const isTrader =
            request.data.group.name === AVAILABLE_ROLES.trader;
          const isMaintenance =
            request.data.group.name === AVAILABLE_ROLES.maintenance;
            
          yield put(
            setCurrentUser({
              ...request.data,
              isAdmin,
              isTrader,
              isMaintenance,
            })
          );
        }

        LogRocket.identify(request.data.id, {
          name: request.data.fullName,
          // Add your own custom user variables here, ie:
          username: request.data.username,
        });
      } catch (err) {
        const { logout } = this.actions;
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("refresh_token");
        toast.error(t("server_error"), {
          position: toast.POSITION.TOP_RIGHT,
        });

        yield put(logout());
      }
    },

    /**
     * Logout's user
     */
    *logout() {
      const { setCurrentUser } = this.actions;
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("access_token");
      removeAuthorizationInterceptor();
      yield put(setCurrentUser(null));
    },

    *watchEvents(action) {
      const { changeWatchingEvents, setWebSocketsChannel } = this.actions;

      const channel = yield this.get("channel");

      yield put(changeWatchingEvents(true));
      console.info("%c[AppLogic] Started watching events", "color:orange");
      if (channel == null) {
        const new_channel = yield call(watchEvents);
        yield put(setWebSocketsChannel(new_channel));
      }

      while (true) {
        const channel = yield this.get("channel");
        const handler = yield this.get("handler");
        const watchingEvents = yield this.get("watchingEvents");
        const message = yield take(channel);
        if (handler && channel && watchingEvents) {
          yield fork(handler, message);
        }
      }
    },
  },
});

function watchEvents() {
  return eventChannel((emiter) => {
    let actualToken = window.localStorage.getItem("access_token");
    if (actualToken) {
      let wsInstance = new window.WebSocket(
        `${WS_BASE_URL}?token=${actualToken}`
      );

      wsInstance.blocked = false;
      wsInstance.count = 0;

      wsInstance.onopen = () => {
        console.log("websockets open");
        wsInstance.send(
          JSON.stringify({ command: "join", group: WS_JOIN_GROUP })
        );
        console.info(`%c🔌 WebSocket: joined #${WS_JOIN_GROUP}`, "color:green");
        // let data = {
        //   event_type: "status",
        //   message: "Notifications Activated",
        //   content: {
        //     id: 0,
        //   },
        // };
        // setTimeout(() => {
        //   emiter(data);
        // }, 3000);
      };

      wsInstance.onerror = (error) => {
        console.info(`%c🔌 WebSocket: error received`, "color:green");
        console.error(error);
      };

      wsInstance.onmessage = (e) => {
        // Parse the json string
        if (!wsInstance.blocked) {
          console.log("WS: Not blocked, allowing.");
          if (wsInstance.count > 5) {
            wsInstance.blocked = true;
          }

          setTimeout(() => {
            wsInstance.blocked = false;
            wsInstance.count = 0;
          }, 5000);

          const data = camelizeKeys(JSON.parse(e.data));
          wsInstance.count += 1;

          // Emitt to worker
          emiter({ ...data, event_type: "event" });
        } else {
          console.log("WS: Blocked.");
        }
      };
    }
    // unsubscribe function
    return () => {
      console.log("%c🔌 WebSocket: socket has been disconnected", "color:red");
    };
  });
}
