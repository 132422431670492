import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={166} height={27} viewBox="0 0 166 27" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M6.896 20.512c4.496 0 7.12-2.064 7.12-5.264 0-2.624-1.872-4.128-5.232-4.64L7.408 10.4C6 10.192 4.88 9.968 4.88 9.072c0-.848.8-1.408 2.384-1.408 1.744.016 3.712.656 4.944 1.264l1.408-3.248c-2.064-1.008-4-1.472-6.48-1.472-4.08 0-6.544 1.888-6.544 5.072 0 2.64 1.776 4.208 5.36 4.704l1.264.176c1.792.256 2.576.672 2.576 1.424 0 .912-.928 1.456-2.864 1.456-2.336 0-3.952-.64-5.28-1.52L0 18.688c2.016 1.424 4.784 1.824 6.896 1.824zm14.592-.4v-8.496c0-2.336 1.488-3.504 3.28-3.504 1.904 0 3.024 1.232 3.024 3.472v8.528h4.176v-8.496c0-2.336 1.488-3.504 3.28-3.504 1.888 0 3.024 1.232 3.024 3.472v8.528h4.16V10.24c0-3.6-2.464-6.048-6.112-6.048-2.032 0-4.064.688-5.264 2.656-1.04-1.648-2.88-2.656-5.12-2.656-1.76 0-3.456.592-4.56 2.24V4.608h-4.064v15.504h4.176zm31.744.4c2.128 0 3.632-.864 4.64-2.112v1.712h4.08V4.608h-4.08V6.32c-1.024-1.264-2.512-2.128-4.64-2.128-4.272 0-7.504 3.504-7.504 8.16 0 4.656 3.232 8.16 7.504 8.16zM54 16.624c-2.448 0-4-1.904-4-4.272 0-2.368 1.552-4.272 4-4.272 2.384 0 4 1.824 4 4.272 0 2.448-1.616 4.272-4 4.272zm16.512 3.488v-8.448c0-2.304 1.328-3.488 3.264-3.488.848 0 1.84.192 2.528.496l.624-4.144c-.576-.208-1.28-.336-2.176-.336-1.904 0-3.392.656-4.368 2.112V4.608h-4.048v15.504h4.176zm16.848.4c2.208 0 3.776-.704 5.136-1.632l-1.536-3.264c-.848.512-1.92 1.008-2.912 1.008-1.296 0-2.32-.704-2.32-2.64V8.32h5.616V4.608h-5.616V0h-4.176v4.608H78.56V8.32h2.992v6c0 4.016 1.968 6.192 5.808 6.192z"
          fill="#000"
        />
        <path
          d="M99.184 26.512v-8.016c1.008 1.2 2.48 2.016 4.56 2.016 4.272 0 7.504-3.504 7.504-8.16 0-4.656-3.232-8.16-7.52-8.16-2.128 0-3.616.864-4.624 2.128V4.608h-4.08v21.904h4.16zm3.776-9.888c-2.368 0-3.984-1.824-3.984-4.272 0-2.448 1.616-4.272 3.984-4.272 2.464 0 4 1.904 4 4.272 0 2.368-1.536 4.272-4 4.272zm18.24 3.888c2.128 0 3.632-.864 4.64-2.112v1.712h4.08V4.608h-4.08V6.32c-1.024-1.264-2.512-2.128-4.64-2.128-4.272 0-7.504 3.504-7.504 8.16 0 4.656 3.232 8.16 7.504 8.16zm.768-3.888c-2.448 0-4-1.904-4-4.272 0-2.368 1.552-4.272 4-4.272 2.384 0 4 1.824 4 4.272 0 2.448-1.616 4.272-4 4.272zm16.192 9.952l11.856-21.968h-4.576l-4.128 7.968-4.256-7.968h-4.736l6.688 12.208-5.424 9.76h4.576zm21.824-6.064c2.208 0 3.776-.704 5.136-1.632l-1.536-3.264c-.848.512-1.92 1.008-2.912 1.008-1.296 0-2.32-.704-2.32-2.64V8.32h5.616V4.608h-5.616V0h-4.176v4.608h-2.992V8.32h2.992v6c0 4.016 1.968 6.192 5.808 6.192z"
          fill="#4DA989"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
