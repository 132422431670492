/**
 * StyledProgressBar Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Progress } from "element-react";
import { Theme } from "globalStyles";

export default ({ children, ...props }) => (
  <Wrapper>
    <ProgressText {...props}>{`${Number(props.percentage).toFixed(
      2
    )}%`}</ProgressText>
    <StyledProgress
      percentage={props.percentage}
      strokeWidth={10}
      showText={false}
      {...props}
    />
  </Wrapper>
);

const StyledProgress = styled(Progress)`
  width: calc(100% - 5rem);
  & .el-progress-bar__outer {
    background-color: ${Theme.darkInactiveColor};
  }
  & .el-progress-bar__inner {
    background-color: ${Theme.secondaryColor};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProgressText = styled.div`
  color: ${Theme.textonSecondary};

  min-width: 4rem;
  margin-right: 0.25rem;
`;
