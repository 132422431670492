import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path fill={props.fill} d="M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z" />
    </svg>
  )
}

export default SvgComponent
