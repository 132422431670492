/**
 * Navbar Container
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled, { css } from "styled-components";
import { Theme } from "globalStyles";
import { withTranslation } from "react-i18next";
import posed from "react-pose";
import SECTIONS from "sections";
import { SIMPLE_FEATURES, ADDITIONAL_FEATURES } from "config";

import { Link, withRouter } from "react-router-dom";

import { Loading } from "element-react";

import ResponsiveHandler from "components/ResponsiveHandler";
import LanguageSwitch from "components/LanguageSwitch";
import Dialog from "components/Dialog";
import StyledButton from "components/StyledButton";
import NotificationsDialog from "components/NotificationsDialog";
import { AnimatedArrow } from "components/AnimatedArrow";

import PortugalIcon from "icons/PortugalIcon";
import UKIcon from "icons/UKIcon";
import NotificationsIcon from "icons/Notifications";
import NotificationsNewIcon from "icons/NotificationsNew";
import Logo from "icons/Logo";
import LogoRayt from "icons/LogoRayt";

import moment from "moment";

class Navbar extends Component {
  state = {
    open: false,
    notificationsDialog: false,
    openSwitch: false,
    burgerOpen: false,
    userInformationOpen: false,
  };

  onPtFlagClick = () => {
    const { i18n } = this.props;
    i18n.changeLanguage("pt");
    localStorage.setItem("language", "pt");
    this.setState({ burgerOpen: false });
  };
  onEnFlagClick = () => {
    const { i18n } = this.props;
    i18n.changeLanguage("en");
    localStorage.setItem("language", "en");
    this.setState({ burgerOpen: false });
  };

  sectionsGenerator = () => {
    const { t } = this.props;

    return SECTIONS.filter((sec) => {
      if (
        !ADDITIONAL_FEATURES.includes("trader_switch") &&
        this.props.user.isTrader &&
        sec.key !== "transactions"
      ) {
        return null;
      }
      if (!ADDITIONAL_FEATURES.includes(sec.key)) {
        return null;
      }

      if (
        ADDITIONAL_FEATURES.includes(sec.key) &&
        sec.admin_only &&
        this.props.user &&
        !this.props.user.isAdmin
      ) {
        return null;
      }

      return sec;
    }).map((section, index) => {
      const Icon = section.icon;
      return (
        <FlexDiv
          key={`section${index}`}
          onClick={() => {
            this.setState({ burgerOpen: false });
            this.props.history.push(section.path);
          }}
        >
          <Icon height={24} fill={Theme.primaryColor}></Icon>
          <ProfileName>{t(section.name)}</ProfileName>
        </FlexDiv>
      );
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname === "/login/" && this.state.burgerOpen) {
      this.setState({ burgerOpen: false });
    }
    if (this.props.location !== prevProps.location && this.props.user != null) {
      const { getNotifications } = this.actions;
      getNotifications();
    }
    if (
      this.props.gettingUserInformation !== prevProps.gettingUserInformation &&
      this.props.gettingUserInformation === false
    ) {
      this.setState({
        userInformationOpen: false,
      });
    }
  }

  render() {
    const {
      t,
      i18n,
      user,
      logout,
      responsiveHandlers,
      unreadNotifications,
      notifications,
      pagination,
      notificationsTotal,
      gettingUserInformation,
    } = this.props;
    const { markAsRead, markAllAsRead, setPagination, getUserInformation } =
      this.actions;
    const { burgerOpen, open, notificationsDialog, userInformationOpen } =
      this.state;
    return (
      <React.Fragment>
        <WrapperPosed
          pose={
            (responsiveHandlers.isMobile || responsiveHandlers.isTablet) &&
            burgerOpen
              ? "open"
              : "closed"
          }
        >
          <FlexWrapper>
            <LogoLink to={"/"}>
              {SIMPLE_FEATURES ? (
                <Logo
                  height={18}
                  width={
                    (responsiveHandlers.isMobile ||
                      responsiveHandlers.isTablet) ?
                    100 : '100%'
                  }
                ></Logo>
              ) : (
                <LogoRayt
                  height={18}
                  width={
                    (responsiveHandlers.isMobile ||
                      responsiveHandlers.isTablet) &&
                    100
                  }
                ></LogoRayt>
              )}
            </LogoLink>
            <RightWrapper>
              {/* https://github.com/jonsuh/hamburgers */}
              {responsiveHandlers.isMobile || responsiveHandlers.isTablet ? (
                user != null ? (
                  <React.Fragment>
                    <NotificationIconWrapper
                      onClick={() =>
                        this.setState({ notificationsDialog: true })
                      }
                    >
                      {unreadNotifications.length > 0 ? (
                        <NotificationsNewIcon height={24} />
                      ) : (
                        <NotificationsIcon height={24} />
                      )}
                    </NotificationIconWrapper>
                    <BurgerMenu
                      onClick={() => this.setState({ burgerOpen: !burgerOpen })}
                    >
                      <div
                        className={`hamburger hamburger--squeeze ${
                          burgerOpen ? "is-active" : ""
                        }`}
                      >
                        <div className="hamburger-box">
                          <div className="hamburger-inner"></div>
                        </div>
                      </div>
                    </BurgerMenu>
                  </React.Fragment>
                ) : (
                  <LanguageSwitch
                    isVisible={this.state.openSwitch}
                  ></LanguageSwitch>
                )
              ) : (
                <React.Fragment>
                  {user != null && (
                    <ProfileWapper
                      onMouseEnter={() => this.setState({ open: true })}
                      onMouseLeave={() => this.setState({ open: false })}
                    >
                      <ProfileName>
                        {user && user.fullName
                          ? `${user.fullName}`
                          : user && user.username}
                      </ProfileName>
                      <AnimatedArrow
                        transform={open ? "rotate(0)" : "rotate(-180)"}
                      />
                      <ProfileMenu>
                        {!user.isAdmin && !user.isTrader && (
                          <MenuItem
                            onClick={() => {
                              this.setState({ userInformationOpen: true });
                            }}
                          >
                            <ProfileName>
                              {t("user_information.button")}
                            </ProfileName>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            logout();
                          }}
                        >
                          <ProfileName>{t("logout")}</ProfileName>
                        </MenuItem>
                      </ProfileMenu>
                    </ProfileWapper>
                  )}
                  {user != null && (
                    <NotificationIconWrapper
                      onClick={() =>
                        this.setState({ notificationsDialog: true })
                      }
                    >
                      {unreadNotifications.length > 0 ? (
                        <NotificationsNewIcon height={24} />
                      ) : (
                        <NotificationsIcon height={24} />
                      )}
                    </NotificationIconWrapper>
                  )}
                  <LanguageSwitch
                    isVisible={this.state.openSwitch}
                  ></LanguageSwitch>
                </React.Fragment>
              )}
            </RightWrapper>
          </FlexWrapper>
          {(responsiveHandlers.isMobile || responsiveHandlers.isTablet) &&
            burgerOpen &&
            user != null && (
              <MobileWrapper>
                <FlexDiv>
                  <ProfileName>
                    {user && user.fullName
                      ? `${user.fullName}`
                      : user && user.username}
                  </ProfileName>
                </FlexDiv>
                {user != null && this.sectionsGenerator()}
                <FlexDiv onClick={logout}>
                  <ProfileName>{t("logout")}</ProfileName>
                </FlexDiv>
                <LanguageMobileWrapper>
                  <LanguageIconWrapper
                    active={i18n.language === "pt" ? 1 : 0}
                    onClick={this.onPtFlagClick}
                  >
                    <PortugalIcon height={24} width={24} />
                  </LanguageIconWrapper>

                  <LanguageIconWrapper
                    active={i18n.language === "en" ? 1 : 0}
                    onClick={this.onEnFlagClick}
                  >
                    <UKIcon height={24} width={24} />
                  </LanguageIconWrapper>
                </LanguageMobileWrapper>
              </MobileWrapper>
            )}
          {user != null && notifications != null && notificationsDialog && (
            <NotificationsDialog
              visible={notificationsDialog}
              onCancel={() => {
                this.setState({
                  notificationsDialog: false,
                });
                markAllAsRead();
              }}
              title={
                <NotificationsTitleWrapper>
                  <NotificationsTitle>
                    {t("notifications.title")}
                  </NotificationsTitle>
                  {unreadNotifications.length > 0 && (
                    <NotificationsMarkAll onClick={markAllAsRead}>
                      {t("notifications.mark_all")}
                    </NotificationsMarkAll>
                  )}
                </NotificationsTitleWrapper>
              }
            >
              {notifications.map((el) => (
                <NotificationWrapper
                  key={el.id}
                  read={el.read}
                  onClick={() => el.read === false && markAsRead(el.id)}
                >
                  <NotificationTitle>
                    {el.title ? el.title : t("notification.title")}
                  </NotificationTitle>
                  <NotificationDate>
                    {el.createdAt != null && moment(el.createdAt).isValid()
                      ? moment(el.createdAt).format("DD/MM/YY HH:mm")
                      : ""}
                  </NotificationDate>
                  <NotificationBody>
                    <span>{el.message != null ? el.message : ""}</span>
                  </NotificationBody>
                </NotificationWrapper>
              ))}
              {notifications.length === 0 && (
                <NoNotificationsWrapper>
                  <NoNotifications>
                    {t("notifications.no_notifications")}
                  </NoNotifications>
                </NoNotificationsWrapper>
              )}
              {notificationsTotal > notifications.length && (
                <LoadMoreWrapper>
                  <LoadMoreLink
                    onClick={() =>
                      setPagination({
                        ...pagination,
                        page: pagination.page + 1,
                      })
                    }
                  >
                    {t("notifications.load_more")}
                  </LoadMoreLink>
                </LoadMoreWrapper>
              )}
            </NotificationsDialog>
          )}
        </WrapperPosed>
        {userInformationOpen && (
          <Dialog
            visible={userInformationOpen}
            onCancel={() => {
              this.setState({
                userInformationOpen: false,
              });
            }}
            size={"medium"}
            title={t("user_information.title")}
          >
            {!gettingUserInformation && (
              <React.Fragment>
                <span>{t("user_information.description")}</span>
                <DialogActions>
                  <StyledButton
                    onClick={() =>
                      this.setState({
                        userInformationOpen: false,
                      })
                    }
                    type="seconday"
                    disabled={gettingUserInformation}
                  >
                    {t("cancel")}
                  </StyledButton>
                  <StyledButton
                    type="primary"
                    onClick={getUserInformation}
                    disabled={gettingUserInformation}
                  >
                    {t("user_information.confirm")}
                  </StyledButton>
                </DialogActions>
              </React.Fragment>
            )}
            {gettingUserInformation && <LoadingWrapper><CustomLoading></CustomLoading></LoadingWrapper>}
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ResponsiveHandler(withRouter(Logic(Navbar))));

const LanguageIconWrapper = styled.div`
  position: relative;
  ${(props) =>
    props.active &&
    css`
      &::after {
        content: "";
        width: 80%;
        height: 2px;
        position: absolute;
        bottom: -8px;
        right: 0;
        left: 0;
        margin: 0 auto;
        background-color: ${Theme.primaryColor};
      }
    `}
`;

const ProfileName = styled.p`
  color: inherit;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;

  ${({ theme }) => theme.smallDesktop`
    margin-left: 0;
    margin-right: 1rem;
  `}
`;

const MobileWrapper = styled.div`
  margin: auto;
  height: calc(100vh - 5rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${Theme.lightInactiveColor};
  width: 100%;
  cursor: pointer;
`;

const LanguageMobileWrapper = styled(FlexDiv)`
  margin-top: auto;
  border-bottom: none;
  & > * {
    margin: 0.5rem;
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  color: ${Theme.textonSecondary};
  background-color: ${Theme.white};

  & path {
    fill: ${Theme.textonSecondary};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${Theme.secondaryColor};
    color: ${Theme.white};

    & path {
      fill: ${Theme.white};
    }
  }
`;

const ProfileMenu = styled.div`
  background-color: ${Theme.white};
  padding: 0.5rem 0;
  min-width: 200px;

  top: 2.5rem;
  right: -0.75rem;

  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const ProfileWapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${Theme.textonSecondary};

  &:hover ${ProfileMenu} {
    display: block;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 15px;
  min-height: 64px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 64px;
  background-color: ${Theme.white};
  box-shadow: rgba(36, 42, 62, 0.2) 0px -1px 0px 0px inset,
    rgba(64, 82, 132, 0.2) 0px 0px 2px 0px;
  position: fixed;
  z-index: 10000;

  .v-modal {
    margin-top: 4rem;
    ${({ theme }) => theme.smallDesktop`
    margin-left: calc(4rem - 1px);
  `}
  }
`;

const WrapperPosed = posed(Wrapper)({
  open: {
    height: "100vh", //atrofia com max-content,
    transition: { duration: 225 },
  },
  closed: {
    height: "64px",
    transition: { duration: 225 },
  },
});

const LogoLink = styled(Link)`
  display:flex;
  align-items: center
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BurgerMenu = styled.div`
  margin-left: 1rem;
  height: 24px;
  .hamburger {
    height: 24px;

    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    ${({ theme }) => theme.smallDesktop`
    padding: 15px 15px;

    `}
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: ${Theme.primaryColor};
  }

  .hamburger-box {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 24px;
    height: 3px;
    background-color: ${Theme.primaryColor};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -6px;
  }
  .hamburger-inner::after {
    bottom: -6px;
  }

  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const NotificationIconWrapper = styled.div`
  margin-left: 1rem;
  height: 24px;
  cursor: pointer;
`;

const NotificationWrapper = styled.div`
  margin: 0;
  padding: 1rem 0.75rem;
  background: ${Theme.white};
  color: ${Theme.black};

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 20px auto;
  row-gap: 4px;

  opacity: ${(props) => (props.read === true ? 0.5 : 1)};
  position: relative;
  cursor: ${(props) => (props.read === true ? "auto" : "pointer")};

  & + & {
    border-top: 1px solid #eeeeee;
  }

  .Toastify__toast--success {
    background: ${Theme.white};

    border-left: 5px solid ${Theme.successColor};
  }
  .Toastify__toast--info {
    background: ${Theme.white};

    border-left: 5px solid ${Theme.primaryColor};
  }
  .Toastify__toast--warning {
    background: ${Theme.white};

    border-left: 5px solid ${Theme.alertColor};
  }
  .Toastify__toast--error {
    background: ${Theme.white};

    border-left: 5px solid ${Theme.errorColor};
  }
`;

const NotificationsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
`;

const NotificationsMarkAll = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;

  font-family: ${Theme.fontFamily};
  color: ${Theme.primaryColor};

  cursor: pointer;

  transition: text-decoration 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
`;

const NotificationsTitle = styled.h4`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
  font-family: ${Theme.fontFamily};
  color: ${Theme.textonSecondary};
`;

const NotificationTitle = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  margin: 0;
  font-family: ${Theme.fontFamily};
  color: ${Theme.textonSecondary};
  grid-column: 1/2;
  grid-row: 1/2;
`;

const NotificationDate = styled.p`
  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};
  font-size: 10px;
  line-height: 14px;

  text-align: right;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
`;

const NotificationBody = styled.div`
  grid-column: 1/3;
  grid-row: 2/3;
  font-size: 14px;
  line-height: 1em;
  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const LoadMoreLink = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  font-family: ${Theme.fontFamily};
  color: ${Theme.primaryColor};

  cursor: pointer;

  transition: text-decoration 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

const NoNotifications = styled.p`
  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};
  font-size: 14px;
  line-height: 16px;
`;
const NoNotificationsWrapper = styled.div`
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogActions = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 16px;
`;

const CustomLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 24px 32px;
  width: 100%;
  height: 100%;
`;
