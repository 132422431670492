import React from "react";
import moment from "moment";
import StyledProgressBar from "components/StyledProgressBar";
import styled from "styled-components";
import decodeType from "utils/decodeType";

export const columns = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    width: 100,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return <div> {moment(data.datetime).format("DD/MM/YYYY HH:mm")}</div>;
    },
    width: 170,
  },
  {
    label: "table.user",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return <EllipsisDiv>{data.maintainer && data.maintainer.fullName ? data.maintainer.fullName : "-"}</EllipsisDiv>;
    },
    width: 140
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv>
          {data.container && data.container.uuid ? data.container.uuid : "-"}
        </EllipsisDiv>
      );
    },
    width: 140
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv>
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
    width: 150
  },
  {
    label: "table.fill_level",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "container.fillLevel",
    render: function (data) {
      return (
        <div>
          <StyledProgressBar
            percentage={
              data.container.fillLevel != null ? data.container.fillLevel : 0
            }
            alert={
              data.container.fillLevel > 75
                ? "error"
                : data.container.fillLevel > 50
                ? "alert"
                : "success"
            }
          />
        </div>
      );
    },
    width: 220
  },
  {
    label: "table.description",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function ({ t, addDescription, user, ...data }) {
      return (
        <EllipsisDivFlex>
          {data.description
            ? data.description
            : (user.isMaintenance || user.isAdmin) && (
                <Link onClick={(e) => addDescription(e, data)}>
                  {t("table.add_description")}
                </Link>
              )}
        </EllipsisDivFlex>
      );
    },
    minWidth: 220
  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsisDivFlex = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;

const Link = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;
`;
