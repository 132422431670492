/**
 * CloseButton Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Theme } from "globalStyles";
import CrossIcon from "icons/CrossIcon";

export default ({ children, ...props }) => (
  <Wrapper>
    <CrossIcon
      height={"18px"}
      width={"18px"}
      fill={Theme.black}
      onClick={props.closeToast}
    ></CrossIcon>
  </Wrapper>
);

const Wrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
