import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 12 12" {...props}>
      <path
        d="M12 2.4c0-.1 0-.2-.1-.2L11.1.5c-.2-.3-.5-.5-.9-.5H1.8c-.4 0-.7.2-.9.5L.1 2.2c-.1.1-.1.1-.1.2v8.1c0 .8.7 1.5 1.5 1.5h9c.8 0 1.5-.7 1.5-1.5V2.4zM10.5 11H8.2V8.9c0-.8-.6-1.4-1.4-1.4H5.1c-.8 0-1.4.6-1.4 1.4V11H1.5c-.3 0-.5-.2-.5-.5V6.3c.3.1.5.2.8.2.5 0 1-.2 1.4-.6.4.4.9.6 1.4.6.6 0 1.1-.2 1.5-.6.4.4.9.6 1.4.6.5 0 1-.2 1.3-.6l.1.1c.3.3.8.5 1.3.5.3 0 .6-.1.8-.2v4.2c0 .3-.2.5-.5.5z"
        fill={props.fill || "#fff"}
      />
    </svg>
  )
}

export default SvgComponent
