/**
 * Login Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from 'kea'

import { put, call } from 'redux-saga/effects'

import axios from 'axios'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import * as Check from 'validations'
import { BASE_API_URL } from 'config'
import { setAuthorizationInterceptor } from 'interceptors';
import AppLogic from 'containers/App/logic'

const LoginUrl = BASE_API_URL + "auth/token/"

// Constants

const DEFAULT_VALUES = {
  username: {
    value: ''
  },
  password: {
    value: ''
  }
}

const VALIDATIONS = {
  username: [
    Check.isRequired
  ],
  password: [
    Check.isRequired
  ]
}


export default kea({
  path: () => ['scenes', 'Login'],

  connect: {
    actions: [
      AppLogic, [
        'checkUserAuthentication',
        'getCurrentUser'
      ],
    ]
  },

  actions: () => ({

    change: (field) => ({ field }),
    submit: () => ({}),
    response: (response) => ({ response }),
    error: (error) => ({ error }),
    setForm: (form) => ({ form }),
    reset: () => true,

  }),

  reducers: ({ actions }) => ({

    form: [DEFAULT_VALUES, PropTypes.object, {
      [actions.change]: (state, payload) => Check.setAndCheckValidation(state, payload, VALIDATIONS),
      [actions.setForm]: (state, payload) => Check.checkValidation(payload.form, VALIDATIONS).form,
      [actions.reset]: () => DEFAULT_VALUES
    }],

    dirty: [false, PropTypes.bool, {
      [actions.change]: () => true,
      [actions.response]: () => false,
      [actions.error]: () => true,
      [actions.reset]: () => false
    }],

    submiting: [false, PropTypes.bool, {
      [actions.submit]: () => true,
      [actions.error]: () => false,
      [actions.response]: () => false,
      [actions.reset]: () => false
    }],

    response: [null, PropTypes.any, {
      [actions.response]: (state, payload) => payload.response,
      [actions.reset]: () => null
    }],

    error: [null, PropTypes.any, {
      [actions.error]: (state, payload) => payload.error,
      [actions.reset]: () => null
    }],


  }),

  start: function* () {
    const { checkUserAuthentication } = this.actions
    yield put(checkUserAuthentication())
  },

  takeLatest: ({ actions, workers }) => ({

    [actions.submit]: workers.submit,

  }),


  workers: {

    /**
     * Write something about your form submitons
     * @param {*} action
     */
    * submit(action) {
      const {
        error,
        setForm
      } = this.actions

      const form = yield this.get('form')
      const dirty = yield this.get('dirty')

      // Check validations
      const validation = Check.checkValidation(form, VALIDATIONS)

      if (dirty && validation.invalid) {
        // try to scroll to first form field error
        yield put(error([]))
        return false
      }

      if (!dirty && validation.invalid) {
        // try to scroll to first form field error
        yield put(setForm(validation.form))
        yield put(error([]))
        return false
      }

      //remove token if it exists (https://gitlab.ubiwhere.com/payt/frontend/-/issues/79)
      window.localStorage.removeItem("access_token")

      // Transform object and remove uneeded state values
      let params = mapValues(form, ({ value }) => value)
      try {
        const request = yield call(axios.post, LoginUrl, params)

        if (request.status === 200) {
          window.localStorage.setItem("access_token", request.data.access)
          window.localStorage.setItem("refresh_token", request.data.refresh)


          setAuthorizationInterceptor(request.data.access)


          const { response, getCurrentUser } = this.actions
          yield put(getCurrentUser(true))
          yield put(response("success"))
        }
        else {
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
          yield put(error(["login_error"]))
        }

      } catch (er) {
        console.log(er)
        if (er.response) {
          // map WS return errors to form format
          // put the errors on each field and changed them to invalid
          // try to scroll to first form field error
        }

        yield put(error(["login_error"]))
      }
    },

  }

})

