import React from "react";
import moment from "moment";
// import StyledProgressBar from "components/StyledProgressBar";
import styled from "styled-components";
import decodeType from "utils/decodeType";
import Bin from "icons/Bin";
import OpenInNew from "icons/OpenInNew";

export const columns = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 70,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return <span title={t(column.label)}>{t(column.label)}</span>;
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.container && a.container.name && b.container && b.container.name) {
        return a.container.name >= b.container.name;
      }
      return false;
    },
    minWidth: 115,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.name ? data.container.name : "-"
          }
        >
          {data.container && data.container.name ? data.container.name : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : "-"
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.actions",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function ({ t, seeMore, deleteOccurence, user, ...data }) {
      return (
        <CenterDiv>
          <Link onClick={(e) => seeMore(e, data)}>
            <OpenInNew height={20}></OpenInNew>
          </Link>
          {user != null &&
            data != null &&
            data.tag != null &&
            data.tag.user != null &&
            user.id === data.tag.user.id && (
              <Link onClick={(e) => deleteOccurence(e, data)}>
                <Bin height={20}></Bin>
              </Link>
            )}
        </CenterDiv>
      );
    },
  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Link = styled.span`
  path {
    fill: ${({ theme }) => theme.primaryColor};
  }
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    path {
      fill: ${({ theme }) => theme.darkPrimaryColor};
    }
  }

  & + & {
    margin-left: 16px;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`;
