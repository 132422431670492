import * as React from 'react'
import {Theme} from 'globalStyles'

function SvgComponent (props) {
  return (
    <svg
      height={24}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <path
          d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm1 15H9v-2h2v2zm0-4H9V5h2v6z'
          id='prefix__a__error'
        />
      </defs>
      <use
        fill={props.fill || Theme.errorColor}
        fillRule='nonzero'
        xlinkHref='#prefix__a__error'
        transform='translate(2 2)'
      />
    </svg>
  )
}

export default SvgComponent
