/**
 * Login Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from "kea";

import { put, call } from "redux-saga/effects";
import { findGetParameter } from "utils";

import axios from "axios";
import PropTypes from "prop-types";
import mapValues from "lodash/mapValues";
import * as Check from "validations";
import { BASE_API_URL } from "config";
// import { setAuthorizationInterceptor } from "interceptors";
// import AppLogic from "containers/App/logic";

const CONFIRM_PASSWORD_URL = BASE_API_URL + "auth/password_reset/confirm/";

// Constants

const DEFAULT_VALUES = {
  password: {
    value: "",
  },
  password_confirm: {
    value: "",
  },
};

const VALIDATIONS = {
  password: [Check.isRequired],
  password_confirm: [Check.isRequired],
};

export default kea({
  path: () => ["scenes", "PasswordSet"],

  connect: {
    actions: [],
  },

  actions: () => ({
    change: (field) => ({ field }),
    submit: () => ({}),
    setResponse: (response) => ({ response }),
    setError: (error) => ({ error }),
    setForm: (form) => ({ form }),
    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES,
      },
    ],

    submiting: [
      false,
      PropTypes.bool,
      {
        [actions.submit]: () => true,
        [actions.setError]: () => false,
        [actions.setResponse]: () => false,
        [actions.reset]: () => false,
      },
    ],

    response: [
      null,
      PropTypes.any,
      {
        [actions.setResponse]: (state, payload) => payload.response,
        [actions.reset]: () => null,
      },
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload.error,
        [actions.reset]: () => null,
      },
    ],
  }),

  start: function* () {
    const { reset } = this.actions;
    yield put(reset());
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
  }),

  workers: {
    /**
     * Write something about your form submitons
     * @param {*} action
     */
    *submit(action) {
      const { setError, setForm, setResponse } = this.actions;

      const form = yield this.get("form");
      const validation = Check.checkValidation(form, VALIDATIONS);

      const passwordValidation = Check.passwordsMatch(
        form.password.value,
        form.password_confirm.value
      );
      if (validation.invalid || !passwordValidation.valid) {
        yield put(
          setForm({
            ...validation.form,
            password_confirm: {
              ...validation.form.password_confirm,
              ...passwordValidation,
            },
          })
        );
        yield put(setError([]));
        return false;
      }

      try {
        let params = mapValues(form, ({ value }) => value);
        const token = findGetParameter("token");

        const response = yield call(axios.post, CONFIRM_PASSWORD_URL, {
          ...params,
          token,
        });
        if (response && response.status >= 200 && response.status < 400) {
          yield put(setResponse("success"));
        } else {
          yield put(setResponse("error"));
        }
      } catch (err) {
        console.log({ err });
        yield put(setResponse("error"));
      }
    },
  },
});
