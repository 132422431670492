/**
 * Login Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled from "styled-components";
import { Input, Loading } from "element-react";
// import { lighten } from "polished";
import { Theme } from "globalStyles";
import { withTranslation } from "react-i18next";
import StyledButton from "components/StyledButton";
import { Link, withRouter } from "react-router-dom";
import { findGetParameter } from "utils";
import ErrorIcon from "icons/ServerErrorIcon";
import BackArrow from "icons/BackArrow";
import { isMobile } from "react-device-detect";
import { MOBILE_DEEPLINK } from "config";

class PasswordSet extends Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();

    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    const urlToken = findGetParameter("token");
    const currentURL = window.location.href;

    if (urlToken && isMobile && MOBILE_DEEPLINK !== 'pocityf') {
      window.location = currentURL.replace(/^https?/g, MOBILE_DEEPLINK);
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  }

  render() {
    const { password, password_confirm } = this.props.form;
    const { t, submiting, error, response } = this.props;
    const { change, submit } = this.actions;
    const urlToken = findGetParameter("token");

    if (this.state.loading) {
      return (
        <Wrapper>
          <CustomLoading></CustomLoading>
        </Wrapper>
      );
    }

    if (response === "success") {
      return (
        <Wrapper>
          <FlexWrapper>
            <TitleRow>
              <Link to={"/login/"}>
                <BackArrow height={16} width={16}></BackArrow>
              </Link>
              <LoginText>{t("set_password.title")}</LoginText>
            </TitleRow>

            <SuccessMessage>{t("set_password.success")}</SuccessMessage>
            <GoBackLink to="/login/">{t("back_main_page")}</GoBackLink>
          </FlexWrapper>
        </Wrapper>
      );
    }

    if (response === "error") {
      return (
        <Wrapper>
          <SuccessWrapper>
            <ErrorIcon height={80} style={{ margin: "0 auto" }} />
            <SuccessMessage>{t("set_password.error_text")}</SuccessMessage>
            <GoBackLink to="/password/reset">
              {t("set_password.try_again")}
            </GoBackLink>
          </SuccessWrapper>
        </Wrapper>
      );
    }

    if (urlToken) {
      return (
        <Wrapper>
          <FlexWrapper>
            <LoginText>{t("set_password.title")}</LoginText>

            <StyledInput
              id="standard-password-input"
              type="password"
              // disabled={submiting}
              placeholder={t("password_placeholder")}
              autoComplete="new-password"
              value={password.value}
              onChange={(e) => {
                change({ password: e });
              }}
              onKeyDown={this.handleEnter}
              error={password.valid === false && password.message != null}
            />
            {password.valid === false && password.message != null && (
              <InputError>{password.message}</InputError>
            )}

            <StyledInput
              id="standard-confirm-password-input"
              type="password"
              // disabled={submiting}
              placeholder={t("confirm_password_placeholder")}
              autoComplete="new-password"
              value={password_confirm.value}
              onChange={(e) => {
                change({ password_confirm: e });
              }}
              onKeyDown={this.handleEnter}
              error={
                password_confirm.valid === false &&
                password_confirm.message != null
              }
            />
            {password_confirm.valid === false &&
              password_confirm.message != null && (
                <InputError>{password_confirm.message}</InputError>
              )}

            <AlignCenter>
              <StyledButton
                disabled={submiting}
                onClick={submit}
                type={"primary"}
                margin={"0"}
              >
                {t("set_password.button")}
              </StyledButton>
            </AlignCenter>

            <Error>{error && error.map((error) => t(error))}</Error>
          </FlexWrapper>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <SuccessWrapper>
            <ErrorIcon height={80} style={{ margin: "0 auto" }} />
            <SuccessMessage>{t("set_password.error_text")}</SuccessMessage>
            <GoBackLink to="/password/reset">
              {t("set_password.try_again")}
            </GoBackLink>
          </SuccessWrapper>
        </Wrapper>
      );
    }
  }
}

export default withTranslation()(withRouter(Logic(PasswordSet)));

const Wrapper = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8;
  margin: -20px;
`;

const FlexWrapper = styled.div`
  max-height: 330px;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1);
  padding: 32px;
  margin: 2rem;
  ${({ theme }) => theme.smallDesktop`
     width: 410px;
   `}
`;

const Error = styled.div`
  width: 100%;
  color: red;
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
  font-size: 12px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;

  .el-input__inner {
    color: #242a3e;
    font-family: ${Theme.fontFamily};
    font-size: 12px;
    line-height: 16px;
    padding: 0px 16px;
    :focus {
      border-color: #8391a5;
    }
  }

  input {
    text-align: left !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-text-fill-color: #1f2d3d !important;
    background-color: #ffffff !important;
    color: #1f2d3d !important;
  }

  margin-bottom: ${(props) => props.error === true && 0};
`;

const LoginText = styled.div`
  height: 36px;
  color: #242a3e;
  font-family: ${Theme.fontFamily};
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
`;

const GoBackLink = styled(Link)`
  width: fit-content;

  margin: 1rem auto;
  font-size: 0.875rem;
  text-decoration: underline;
  color: ${Theme.primaryColor};
  transition: opacity 0.3s ease-in-out;

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
  }

  display: flex;
`;

const SuccessWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1);
  padding: 32px;
  display: flex;
  flex-direction: column;

  margin: auto 0;
`;

const SuccessMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 1.5rem auto;
  line-height: 1.25rem;
`;

const InputError = styled(Error)`
  text-align: left;
  justify-content: flex-start;

  margin: 0.5rem 0;
`;

const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  column-gap: 16px;
  margin-bottom: 32px;

  align-items: center;
`;

const CustomLoading = styled(Loading)`
  display: flex !important;
  width: -webkit-fill-available !important;
  position: relative;
  width: 100%;

  .el-loading-spinner {
    left: 0px !important;
    top: ${(props) => (props.top ? props.top : "40px")};
    display: flex !important;
    justify-content: center;

    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;
