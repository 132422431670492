import React from "react";
import moment from "moment";
import CrossIcon from "icons/CrossIcon";
import CheckIcon from "icons/CheckIcon";
import { Theme } from "globalStyles";
import styled from "styled-components";
import decodeType from "utils/decodeType";

const columns = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    width: 100,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    prop: "startDate",
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv title={moment(data.startDate).format("DD/MM/YYYY HH:mm")}>
          {moment(data.startDate).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    width: 180,
  },
  {
    label: "table.user",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.tag != null && b.tag != null) {
        if (
          a.tag.user != null &&
          a.tag.user.fullName != null &&
          a.tag.user.fullName !== "" &&
          b.tag.user != null &&
          b.tag.user.fullName != null &&
          b.tag.user.fullName !== ""
        ) {
          return a.tag.user.fullName >= b.tag.user.fullName;
        }
        return false;
      }
    },
    render: function (data) {
      if (data.tag) {
        if (
          data.tag.user != null &&
          data.tag.user.fullName != null &&
          data.tag.user.fullName !== ""
        ) {
          return (
            <EllipsisDiv title={data.tag.user.fullName}>
              {data.tag.user.fullName}
            </EllipsisDiv>
          );
        }
      }
      return <EllipsisDiv>{"-"}</EllipsisDiv>;
    },
    width: 200
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a.container && a.container.name && b.container && b.container.name) {
        return a.container.name >= b.container.name;
      }
      return false;
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.name ? data.container.name : "-"
          }
        >
          {data.container && data.container.name ? data.container.name : "-"}
        </EllipsisDiv>
      );
    },
    width: 250
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : "-"
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
    width: 200
  },
  {
    label: "table.description",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv
          title={data.type && data.type.name ? t(data.type.name) : "-"}
        >
          {data.type && data.type.name ? t(data.type.name) : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 250
  },
  {
    label: "dashboard.resolved",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a && b) {
        const first = a.userNotified ? moment(a.endDate) : moment(null);

        const second = b.userNotified ? moment(b.endDate) : moment(null);
        return first >= second;
      }
      return false;
    },
    prop: "status",
    sortable: true,
    render: function (data) {
      return data.status === "closed" ? (
        data.endDate ? (
          <EllipsisDiv title={moment(data.endDate).format("DD/MM/YYYY HH:mm")}>
            <CheckIcon height={"24px"} width={"24px"} fill={Theme.successColor}/>
          </EllipsisDiv>
        ) : (
          "-"
        )
      ) : (
        <EllipsisDiv title={moment(data.startDate).format("DD/MM/YYYY HH:mm")}>
          <CenterIcon>
            <CrossIcon height={"24px"} width={"24px"} fill={Theme.errorColor} />
          </CenterIcon>
        </EllipsisDiv>
      );
    },
    width: 150
  },
];

export default columns;

const CenterIcon = styled.div`
  display: flex;
`;

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
