/**
 * Login Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from "kea";

import { put, call } from "redux-saga/effects";

import axios from "axios";
import PropTypes from "prop-types";
import mapValues from "lodash/mapValues";
import * as Check from "validations";
import { BASE_API_URL } from "config";

const RECOVER_PASSWORD_URL = BASE_API_URL + "auth/password-reset/";

// Constants

const DEFAULT_VALUES = {
  email: {
    value: "",
  },
};

const VALIDATIONS = {
  email: [Check.isRequired, Check.Email],
};

export default kea({
  path: () => ["scenes", "RecoverPassword"],

  connect: {
    actions: [],
  },

  actions: () => ({
    change: (field) => ({ field }),
    submit: () => ({}),
    setResponse: (response) => ({ response }),
    setError: (error) => ({ error }),
    setForm: (form) => ({ form }),
    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    form: [
      DEFAULT_VALUES,
      PropTypes.object,
      {
        [actions.change]: (state, payload) =>
          Check.setAndCheckValidation(state, payload, VALIDATIONS),
        [actions.setForm]: (state, payload) =>
          Check.checkValidation(payload.form, VALIDATIONS).form,
        [actions.reset]: () => DEFAULT_VALUES,
      },
    ],

    submiting: [
      false,
      PropTypes.bool,
      {
        [actions.submit]: () => true,
        [actions.setError]: () => false,
        [actions.setResponse]: () => false,
        [actions.reset]: () => false,
      },
    ],

    response: [
      null,
      PropTypes.any,
      {
        [actions.setResponse]: (state, payload) => payload.response,
        [actions.reset]: () => null,
      },
    ],

    error: [
      null,
      PropTypes.any,
      {
        [actions.setError]: (state, payload) => payload.error,
        [actions.reset]: () => null,
      },
    ],
  }),

  start: function* () {
    const { reset } = this.actions;
    yield put(reset());
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.submit]: workers.submit,
  }),

  workers: {
    /**
     * Write something about your form submitons
     * @param {*} action
     */
    *submit(action) {
      const { setError, setForm, setResponse } = this.actions;

      const form = yield this.get("form");
      const validation = Check.checkValidation(form, VALIDATIONS);

      if (validation.invalid) {
        // try to scroll to first form field error
        yield put(setForm(validation.form));
        yield put(setError([]));
        return false;
      }
      let params = mapValues(form, ({ value }) => value);
      try {
        yield call(axios.post, RECOVER_PASSWORD_URL, params);
        yield put(setResponse("success"));
      } catch (err) {
        console.log({ err });
        yield put(setResponse("success"));
      }
    },
  },
});
