import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill={props.fill}
        d="M14.94 19.5L12 17.77 9.06 19.5l.78-3.34-2.59-2.24 3.41-.29L12 10.5l1.34 3.13 3.41.29-2.59 2.24M20 2H4v2l4.86 3.64a8 8 0 106.28 0L20 4m-2 11a6 6 0 11-7.18-5.88 5.86 5.86 0 012.36 0A6 6 0 0118 15m-5.37-8h-1.26l-4-3h9.34z"
      />
    </svg>
  );
}

export default SvgComponent;
