/**
 * ErrorsTemplate Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Theme } from "globalStyles";
import moment from "moment";
import MarkAsResolved from "icons/MarkAsResolved";

export default ({ children, ...props }) => {
  const { data } = props;
  const { t } = useTranslation();
  const error = data.status && data.status === "open" ? 1 : 0;
  return (
    <Wrapper error={error} active={props.active} onClick={props.onClick}>
      <DateTime>{moment(data.startDate).format("DD/MM/YYYY HH:mm:ss")}</DateTime>
      <Container>
        {data.container && data.container.name ? data.container.name : "-"}
      </Container>
      <Description error={error}>
        {data.type && data.type.name ? t(data.type.name) : "-"}
      </Description>
      {!!error && (
        <Resolve
          onClick={(e) => {
            e.stopPropagation();
            props.markAsResolved(data);
          }}
        >
          <MarkAsResolved
            height={"24px"}
            fill={Theme.primaryColor}
          ></MarkAsResolved>
        </Resolve>
      )}
      {!error && <Notified>{t("table.status")}</Notified>}
      {!error && (
        <DescriptionDateTime>
          {moment(data.endDate).format("DD/MM/YYYY HH:mm:ss")}
        </DescriptionDateTime>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  row-gap: 8px;
  column-gap: 8px;

  border-bottom: 1px solid ${Theme.inactiveColor};

  ${(props) =>
    props.error &&
    css`
      border-left: 3px solid ${Theme.errorColor};
      background-color: ${Theme.lightColor};
    `}
    
    ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.activeRowColor};
      background-color: ${Theme.activeRowColor};
    `}
`;

const DateTime = styled.div`
  font-weight: 300;
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: right;
  font-size: 12px;
  color: ${Theme.iconDarkColor};
`;

const Container = styled.div`
  font-size: 12px;
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 700;
  color: ${Theme.iconDarkColor};
`;

const Description = styled.div`
  font-size: 14px;
  grid-column: 1/2;
  grid-row: 2/4;
  color: ${Theme.successColor};

  ${(props) =>
    props.error &&
    css`
      color: ${Theme.errorColor};
    `}
`;
const DescriptionDateTime = styled.div`
  font-size: 12px;
  grid-column: 2/3;
  grid-row: 3/4;
  color: ${Theme.successColor};
  text-align: right;
`;
const Notified = styled.div`
  font-size: 12px;
  grid-column: 2/3;
  grid-row: 2/3;
  color: ${Theme.successColor};
  text-align: right;
`;

const Resolve = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-row: 2/3;
  grid-column: 2/3;
`;
