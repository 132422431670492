import React, { Component } from 'react';
import Logic from './logic';
import styled, { css } from 'styled-components';
import { MAP_BOX_KEY, AVAILABLE_CONTAINERS } from 'config';
//import MapSideBarRoutes from 'containers/MapSideBarRoutes'
//import MapRouteInformationSideBar from 'containers/MapRouteInformationSideBar'
import ReactMapboxGl, { GeoJSONLayer, Cluster, Marker } from 'react-mapbox-gl';
// import RouteFilterMaps from 'containers/RouteFilterMaps'
import MapboxGL from 'mapbox-gl';
import PaperMarker from 'icons/Markers/Paper';
import GlassMarker from 'icons/Markers/Glass';
import PlasticMarker from 'icons/Markers/Plastic';
import BinMarker from 'icons/Markers/Bin';
import decodeType from 'utils/decodeType';

import moment from 'moment';
import Fullscreen from 'icons/Fullscreen';
import BackArrow from 'icons/BackArrow';
import { Loading } from 'element-react';
import { decodeColor } from 'utils';
import {
  MAPCENTER,
  MAPINITIALBOUNDS,
  ZOOMLEVEL,
  MAXZOOMLEVEL,
  ADDITIONAL_FEATURES,
} from 'config';
import { withTranslation } from 'react-i18next';
import ResponsiveHandler from 'components/ResponsiveHandler';

import { Theme } from 'globalStyles';

const COLORS = {
  plastic: Theme.warningColor,
  paper: Theme.infoColor,
  glass: Theme.successColor,
  bio_waste: Theme.darkPrimaryColor,
  undifferentiated: Theme.darkPrimaryColor,
  polygonFill: Theme.lightPrimaryColor,
  polygonLine: Theme.primaryColor,
};

let polygonPaint = (MapboxGL.FillPaint = {
  'fill-color': COLORS['polygonFill'],
  'fill-opacity': 0.3,
});

let linePaint = (MapboxGL.FillPaint = {
  'line-color': COLORS['polygonLine'],
  'line-opacity': 0.6,
  'line-width': 2,
});

const getMarker = (
  marker,
  equipmentInDetail,
  dimensions = { height: '20px', width: '20px' }
) => {
  switch (marker.type) {
    case 'y':
      return <PlasticMarker {...dimensions}></PlasticMarker>;
    case 'plastic':
      return <PlasticMarker {...dimensions}></PlasticMarker>;
    case 'b':
      return <PaperMarker {...dimensions}></PaperMarker>;
    case 'paper':
      return <PaperMarker {...dimensions}></PaperMarker>;
    case 'g' || 'glass':
      return <GlassMarker {...dimensions}></GlassMarker>;
    case 'glass':
      return <GlassMarker {...dimensions}></GlassMarker>;
    case 'u':
      return <BinMarker {...dimensions}></BinMarker>;
    case 'undifferentiated':
      return <BinMarker {...dimensions}></BinMarker>;

    default:
      return <BinMarker {...dimensions}></BinMarker>;
  }
};

const FirstStageLegend = ({ props }) => {
  const { t } = props;
  return (
    <FlexWrapperLegend>
      {!props.equipmentInDetail && (
        <NumberOfContainersWrapper>
          {`${t('map.containers')}: ${
            props.globalNumberOfContainers ? props.globalNumberOfContainers : 0
          }`}
        </NumberOfContainersWrapper>
      )}
      <MapCaption>
        <FlexLegend marginTop='0px'>
          <AreaLegendIcon />

          <LegentTextWrapper>{t('map.area')}</LegentTextWrapper>
        </FlexLegend>
      </MapCaption>
    </FlexWrapperLegend>
  );
};

const SecondStageLegend = ({ props }) => {
  const { t, responsiveHandlers } = props;
  return (
    <FlexWrapperLegend>
      {!props.equipmentInDetail && (
        <NumberOfContainersWrapper>
          {`${t('map.containers')}: ${
            props.globalNumberOfContainers ? props.globalNumberOfContainers : 0
          }`}
        </NumberOfContainersWrapper>
      )}
      <MapCaption>
        <FlexLegend marginTop='0px'>
          <AreaLegendIcon />
          <LegentTextWrapper>{t('map.area')}</LegentTextWrapper>
        </FlexLegend>

        <FlexLegend marginTop='0px'>
          <ClusterMarkerLegend>
            <ClusterTextLegend>{4}</ClusterTextLegend>
          </ClusterMarkerLegend>
          <LegentTextWrapper>{t('map.cluster')}</LegentTextWrapper>
        </FlexLegend>
        {AVAILABLE_CONTAINERS.map((key, index) => (
          <FlexLegend key={`legend${index}`}>
            {getMarker({ type: key }, null, {
              height: responsiveHandlers.height > 750 ? '26px' : '20px',
              width: responsiveHandlers.height > 750 ? '26px' : '20px',
            })}
            <LegentTextWrapper>{t(`map.${key}`)}</LegentTextWrapper>
          </FlexLegend>
        ))}
      </MapCaption>
    </FlexWrapperLegend>
  );
};

/*eslint-disable*/

const Map = ReactMapboxGl({
  accessToken: MAP_BOX_KEY,
  maxZoom: MAXZOOMLEVEL,
});

class ContainerMapAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerAddress: 'NO_ADDRESS',
      activeCollapse: ['1', '2'],
      zoom: ZOOMLEVEL,
      center: MAPCENTER,
      popupLocation: null,
      user: null,
      activeTab: '1',
      addresses: {},
      mapBounds: MAPINITIALBOUNDS,
      linkedArea: null,
    };
  }

  findNewBounds = (nextProps) => {
    let delay = 0;
    if (nextProps.equipmentInDetail) {
      var bounds = new MapboxGL.LngLatBounds();
      bounds.extend(nextProps.equipmentInDetail.location?.coordinates);
      if (nextProps.equipmentInDetail.location?.coordinates) {
        setTimeout(
          () =>
            this.setState({
              mapBounds: [
                [bounds._sw.lng, bounds._sw.lat],
                [bounds._ne.lng, bounds._ne.lat],
              ],
            }),
          delay
        );
      }
    } else if (nextProps.linkedArea) {
      var bounds = new MapboxGL.LngLatBounds();
      nextProps.linkedArea.polygon.coordinates[0][0].forEach(function (
        feature
      ) {
        bounds.extend(feature);
      });
      setTimeout(
        () =>
          this.setState({
            mapBounds: [
              [bounds._sw.lng, bounds._sw.lat],
              [bounds._ne.lng, bounds._ne.lat],
            ],
          }),
        delay
      );
    } else if (nextProps.areas.length) {
      var bounds = new MapboxGL.LngLatBounds();
      nextProps.areas.forEach(function (feature) {
        feature.polygon.coordinates[0][0].forEach(function (feat) {
          bounds.extend(feat);
        });
      });
      setTimeout(
        () =>
          this.setState({
            mapBounds: [
              [bounds._sw.lng, bounds._sw.lat],
              [bounds._ne.lng, bounds._ne.lat],
            ],
          }),
        delay
      );
    }
  };

  showPopup(marker) {
    this.setState({
      center: [marker.location.coordinates[0], marker.location.coordinates[1]],
      zoom: [20],
      popupLocation: [
        marker.location.coordinates[0],
        marker.location.coordinates[1],
      ],
      popedArea: { ...marker, color: decodeColor(marker.type) },
    });
  }

  closePopup = () => {
    this.setState({
      popedArea: null,
    });
  };

  resetZoom() {
    this.resetMapBounds();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.polygons !== this.props.polygons &&
      this.state.popedArea &&
      nextProps.polygons.filter(
        (marker) => marker.id === this.state.popedArea.id
      ).length === 0
    ) {
      this.closePopup();
    }
    if (this.props.linkedArea !== nextProps.linkedArea) {
      this.findNewBounds(nextProps);
    } else if (this.props.areas !== nextProps.areas && nextProps.areas) {
      this.findNewBounds(nextProps);
    } else if (nextProps.equipmentInDetail !== this.props.equipmentInDetail) {
      this.findNewBounds(nextProps);
    }
  }

  componentDidMount() {
    if (this.props.linkedArea) {
      var bounds = new MapboxGL.LngLatBounds();
      this.props.linkedArea.polygon.coordinates[0].forEach(function (feature) {
        bounds.extend(feature);
      });
      setTimeout(
        () =>
          this.setState({
            mapBounds: [
              [bounds._sw.lng, bounds._sw.lat],
              [bounds._ne.lng, bounds._ne.lat],
            ],
          }),
        200
      );
    }
  }

  showCluster(coordinates, userColection) {
    const { setEquipmentInDetail } = this.props;
    // setEquipmentInDetail(null);
    let zoom = this.map.getZoom();
    if (this.state.zoom < 20) {
      zoom = [parseInt(zoom) + 3];
    } else {
      zoom = [parseInt(zoom)];
    }
    this.setState({ center: coordinates, zoom: zoom });
  }

  clusterMarker = (coordinates, pointCount, getLeaves) => {
    let leaves = getLeaves();
    let userColection = [];
    for (let i = 0; i < leaves.length; i++) {
      userColection.push(leaves[i].props.markerInfo);
    }

    const aux = leaves[0].props.markerInfo;

    const findArea = this.props.areas.filter(
      (area) => area.id === aux.area.id
    )[0];

    return (
      <Marker
        coordinates={coordinates}
        key={coordinates}
        style={{ outline: 'none', cursor: 'pointer', zIndex: 100 }}
        anchor='center'
        tabIndex={-1}
        onClick={() => {
          if (!this.props.linkedArea) this.props.setLinkedArea(findArea);
          else if (this.props.linkedArea.id !== findArea.id)
            this.props.setLinkedArea(findArea);
        }}
      >
        <ClusterMarkerLegend>
          <ClusterTextLegend>{pointCount}</ClusterTextLegend>
        </ClusterMarkerLegend>
      </Marker>
    );
  };

  render() {
    const {
      areas,
      linkedArea,
      loading,
      setLinkedArea,
      equipmentInDetail,
      setEquipmentInDetail,
      areaEquipments,
      t,
    } = this.props;
    const { center, zoom } = this.state;

    return (
      <Wrapper>
        <CustomMap
          style='mapbox://styles/mapbox/streets-v9'
          className='markercluster-map'
          fitBoundsOptions={{
            padding: { top: 100, bottom: 100, left: 100, right: 100 },
            linear: false,
          }}
          onClick={this.closePopup}
          fitBounds={this.state.mapBounds}
          onDragStart={() => this.closePopup}
          onStyleLoad={(el) => (this.map = el)}
          zoom={zoom}
          center={center}
        >
          {loading && <MapLoading />}
          {!equipmentInDetail &&
            areas &&
            areas.map((area, index) => (
              <React.Fragment key={`fragment${index}`}>
                {!linkedArea || linkedArea.id === area.id ? (
                  <React.Fragment>
                    <GeoJSONLayer
                      key={index}
                      data={area.polygon}
                      fillPaint={polygonPaint}
                      fillOnMouseEnter={(e) => {
                        if (!linkedArea) {
                          this.map.getCanvas().style.cursor = 'pointer';
                        }
                      }}
                      fillOnMouseLeave={(e) => {
                        this.map.getCanvas().style.cursor = '';
                      }}
                      fillOnClick={() => {
                        if (linkedArea && linkedArea.id !== area.id) {
                          setLinkedArea(area);
                        }
                        if (!linkedArea) {
                          setLinkedArea(area);
                        }
                      }}
                    />

                    <GeoJSONLayer
                      key={`line${index}`}
                      data={area.lines}
                      linePaint={linePaint}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
            ))}
          {areaEquipments &&
            areaEquipments.length > 0 &&
            areas.map((area) => (
              <Cluster
                key={`${area.name}-${area.id}-cluster`}
                ClusterMarkerFactory={this.clusterMarker}
                radius={80}
                minZoom={5}
                maxZoom={16}
                zoomOnClick={linkedArea}
                zoomOnClickPadding={100}
              >
                {areaEquipments
                  .filter((equipment) =>
                    equipment.area ? equipment.area.id === area.id : false
                  )
                  .map((marker, index) => (
                    <Marker
                      key={`marker${marker.id}${index}`}
                      coordinates={[
                        marker.location?.coordinates[0],
                        marker.location?.coordinates[1],
                      ]}
                      markerInfo={marker}
                      anchor='center'
                      style={{ outline: 'none', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEquipmentInDetail({ ...marker });
                      }}
                    >
                      {getMarker(marker, equipmentInDetail, {
                        height:
                          equipmentInDetail != null &&
                          equipmentInDetail.id === marker.id
                            ? '40px'
                            : '30px',
                        width:
                          equipmentInDetail != null &&
                          equipmentInDetail.id === marker.id
                            ? '40px'
                            : '30px',
                      })}
                    </Marker>
                  ))}
              </Cluster>
            ))}

          <HelpsWrapper>
            {!linkedArea ? (
              <MapHelp cursor={''}>
                <Fullscreen />
                {t('map.click_area')}
              </MapHelp>
            ) : !equipmentInDetail ? (
              <React.Fragment>
                {areas && areas.length > 1 && (
                  <MapHelp
                    showAlways
                    cursor={'pointer'}
                    onClick={() => {
                      setEquipmentInDetail(null);
                      setLinkedArea(null);
                    }}
                  >
                    <BackArrow />
                    {t('map.see_all')}
                  </MapHelp>
                )}
                <MapHelp cursor={''}>
                  <Fullscreen />
                  {t('map.click_device')}
                </MapHelp>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {areas && areas.length > 1 && (
                  <MapHelp
                    showAlways
                    cursor={'pointer'}
                    onClick={() => {
                      setEquipmentInDetail(null);
                      setLinkedArea(null);
                    }}
                  >
                    <BackArrow />
                    {t('map.see_all')}
                  </MapHelp>
                )}
                <MapHelp
                  showAlways
                  cursor={'pointer'}
                  onClick={() => setEquipmentInDetail(null)}
                >
                  <BackArrow />
                  {t('map.view_all')}
                </MapHelp>
              </React.Fragment>
            )}
          </HelpsWrapper>

          {!linkedArea ? (
            <FirstStageLegend props={this.props} />
          ) : (
            !equipmentInDetail && <SecondStageLegend props={this.props} />
          )}

          {equipmentInDetail && (
            <Details>
              <PopupComponent>
                <PopupBody>
                  <PopupBodyText>
                    <PopupBodyFirst>{t('map.container')}</PopupBodyFirst>
                    <PopupBodySecond>{equipmentInDetail.name}</PopupBodySecond>
                  </PopupBodyText>
                  {equipmentInDetail.type != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('table.type')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {t(`table.${decodeType(equipmentInDetail.type)}`)}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.address != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('map.address')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.address}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.tags != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('map.number_users')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.tags != null
                          ? equipmentInDetail.tags
                          : '-'}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.fillLevel != null &&
                    !isNaN(Number(equipmentInDetail.fillLevel)) && (
                      <PopupBodyText>
                        <PopupBodyFirst>{t('map.fill_level')}</PopupBodyFirst>
                        <PopupBodySecond>
                          {Number(equipmentInDetail.fillLevel).toFixed(2)}
                        </PopupBodySecond>
                      </PopupBodyText>
                    )}
                  {equipmentInDetail.capacity != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('map.capacity')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.capacity}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {ADDITIONAL_FEATURES.includes('deposits') && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('map.last_drop')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.lastDrop
                          ? moment(equipmentInDetail.lastDrop).format(
                              'DD/MM/YY HH:mm:ss'
                            )
                          : '-'}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}

                  {ADDITIONAL_FEATURES.includes('collects') && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t('map.last_pickup')}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.lastPickup
                          ? moment(equipmentInDetail.lastPickup).format(
                              'DD/MM/YY HH:mm:ss'
                            )
                          : '-'}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}

                  {ADDITIONAL_FEATURES.includes('maintenances') && (
                    <PopupBodyText>
                      <PopupBodyFirst>
                        {t('map.last_maintenance')}
                      </PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.lastMaintenance
                          ? moment(equipmentInDetail.lastMaintenance).format(
                              'DD/MM/YY HH:mm:ss'
                            )
                          : '-'}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  <PopupBodyText>
                    <PopupBodyFirst>{t('map.battery')}</PopupBodyFirst>
                    <PopupBodySecond>
                      {equipmentInDetail.hardwareDevice &&
                      equipmentInDetail.hardwareDevice.battery
                        ? (
                            equipmentInDetail.hardwareDevice.battery / 100
                          ).toFixed(3)
                        : '-'}
                    </PopupBodySecond>
                  </PopupBodyText>
                  <PopupBodyText>
                    <PopupBodyFirst>
                      {t('map.last_communication')}
                    </PopupBodyFirst>
                    <PopupBodySecond>
                      {equipmentInDetail.hardwareDevice &&
                      equipmentInDetail.hardwareDevice.lastCommunication
                        ? moment(
                            equipmentInDetail.hardwareDevice.lastCommunication
                          ).format('DD/MM/YY HH:mm:ss')
                        : '-'}
                    </PopupBodySecond>
                  </PopupBodyText>
                </PopupBody>
              </PopupComponent>
            </Details>
          )}
        </CustomMap>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(ContainerMapAreas)));

const Wrapper = styled.div`
  height: 41vh;
  min-height: 440px;
  width: 100%;

  ${({ theme }) => theme.smallDesktop`
  min-height: auto;
`}

  @media (max-height: 768px) {
    height: 320px;
  }
`;

const PopupComponent = styled.div`
  max-width: 300px;

  ${({ theme }) => theme.smallDesktop`
    min-width: 200px;
  `}
`;

const Details = styled.div`
  position: absolute;
  z-index: 100;
  background: ${Theme.white};
  bottom: 8px;
  top: 8px;
  right: 8px;
  overflow-y: auto;
`;

const PopupBody = styled.div`
  padding: 5px;
`;

const PopupBodyText = styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const PopupBodyFirst = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.smallDesktop`
  font-size: 14px;
  line-height: 14px;
  `}
  @media screen and (min-height: 750px) {
    font-size: 12px;
    line-height: 12px;
  }
`;
const PopupBodySecond = styled.div`
  display: flex;
  font-size: 10px;
  line-height: 12px;
  flex-direction: row;
  justify-content: center;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;

  ${({ theme }) => theme.smallDesktop`
    font-size: 12px;
  `}

  @media screen and (min-height: 950px) {
    margin-top: 5px;
    margin-left: 0;
  }
`;

const CustomMap = styled(Map)`
  .mapboxgl-canvas {
    width: 100% !important;
    outline: none;
  }

  &.mapboxgl-map {
    font-family: ${Theme.fontFamily};
    font-size: 16px;
  }
`;

const MapHelp = styled.div`
  background-color: white;
  padding: 8px 11px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 5px;
  cursor: ${(props) => props.cursor};
  width: fit-content;
  z-index: 100;

  ${(props) =>
    props.showAlways &&
    css`
      display: block;
    `}

  @media screen and (min-height: 750px) {
    display: block;
  }
`;

const HelpsWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  /* z-index:100; */
`;

const NumberOfContainersWrapper = styled.div`
  padding: 8px 11px 6px 11px;
  font-weight: 400;
  font-size: 8px;

  line-height: 12px;
  background-color: ${Theme.white};
  margin-bottom: 10px;

  @media screen and (min-height: 750px) {
    display: block;
    font-size: 14px;
  }
`;

const MapCaption = styled.div`
  background-color: ${Theme.white};
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  z-index: 100;
`;

const FlexLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) => props.marginLeft};

  & + & {
    margin-top: 5px;
  }
`;

const AreaLegendIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${COLORS['polygonFill']};
  border: 2px solid ${COLORS['polygonLine']};
  border-radius: 50%;
  margin: 0 0 0 1px;

  @media screen and (min-height: 750px) {
    height: 26px;
    width: 26px;
  }
`;

const LegentTextWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-left: 5px;
  margin-left: ${(props) => props.marginLeft};
`;

const ClusterMarkerLegend = styled.div`
  width: 20px;
  height: 20px;
  background: ${COLORS['polygonFill']};
  outline: none;
  position: relative;
  cursor: pointer;
  border: 2px solid ${COLORS['polygonLine']};
  border-radius: 50%;
  margin: 0 0 0 1px;

  @media screen and (min-height: 750px) {
    height: 26px;
    width: 26px;
  }
`;

const ClusterTextLegend = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  color: ${Theme.textOnPrimary};
  width: fit-content;
  font-family: ${Theme.fontFamily};
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  transform: translate(-50%, -50%);
  outline: none;
  cursor: pointer;
`;

const MapLoading = styled(Loading)`
  right: 60px !important;
  top: 37px;
  display: flex !important;
  position: absolute !important;

  .el-loading-spinner {
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const FlexWrapperLegend = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
`;

const Bold = styled.span`
  font-weight: bold;
`;
