/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * FundingFooter Container Utils
 *
 * @author Ricardo Leite <rleite@ubiwhere.com>
 */

// SC: used to dynamic import of LOGOS from assets
export const getLogos = async (logos, common_logos) => {
    let images = [];
    return new Promise((resolve, reject) => {
      try {
        logos.forEach(async (element) => {
          let response = await import(`../../images/${element}`);
          images.push(response);
          if (images.length === logos.length + common_logos.length) resolve(images);
        });
        common_logos.forEach(async (element) => {
          let response = await import(`../../images/common_logos/${element}`);
          images.push(response);
          if (images.length === logos.length + common_logos.length) resolve(images);
        });
      } catch (err) {
        reject(new Error('Error fetching logos'));
      }
    });
  }
  