/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Layout, Breadcrumb, Loading } from "element-react";
import ContainerMapMaintenances from "containers/ContainerMapMaintenances";
import IntervalPicker from "containers/IntervalPicker";
import StyledMobileTable from "containers/StyledMobileTable";
import DownloadDropDownContainer from "containers/DownloadDropDownContainer";
import { Theme } from "globalStyles";

import columns from "./columns";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import StyledTable from "components/StyledTable";
import StyledButton from "components/StyledButton";
import ToggleFilter from "components/ToggleFilter";
import StyledSelect from "components/StyledSelect";
import ResponsiveHandler from "components/ResponsiveHandler";
import ErrorsTemplate from "components/ErrorsTemplate";
import Dialog from "components/Dialog";
import StyledInput from "components/StyledInput";
import NotificationsWrapper from "components/NotificationsWrapper";

import { getIntervalText } from "utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

const errorStatusOptions = [
  {
    label: "yes",
    value: "closed",
  },
  {
    label: "no",
    value: "open",
  },
];

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      filtersWidth: 20,
      inputWidth: "140px",
      triggerSet: false,
      dialogContainer: null,
      dialogOpen: false,
      changedForm: null,
      statusOptions: errorStatusOptions,
    };
    this.tableRef = React.createRef();
    // https://stackoverflow.com/a/61346857/15423901
    this.setState = this.setState.bind(this);
    this._autocompleteRef = null;
    this._areaAutocompleteRef = null;
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  openDialog = (row) => {
    this.setState({ dialogOpen: true, dialogContainer: row });
  };

  componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    if (
      prevProps.descriptionResponse !== this.props.descriptionResponse &&
      this.props.descriptionResponse != null
    ) {
      if (this.props.descriptionResponse === "success") {
        this.setState({ dialogOpen: false, dialogContainer: null });
        const { resetDescriptionForm } = this.actions;
        resetDescriptionForm();
        toast.success(
          <NotificationsWrapper
            type={"add_description"}
            title={t("table.description_success_description")}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "table-add-description-success",
          }
        );
      }
      if (this.props.descriptionResponse === "error") {
        this.setState({ dialogOpen: false, dialogContainer: null });
        const { resetDescriptionForm } = this.actions;
        resetDescriptionForm();

        toast.error(
          <NotificationsWrapper
            type={"add_description"}
            title={t("table.description_error_description")}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "table-add-description-error",
          }
        );
      }
    }
  }

  autoCompleteFetch = (queryString) => {
    if (this._autocompleteRef) {
      clearTimeout(this._autocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._autocompleteRef = setTimeout(function () {
      search(queryString, null, "container");
    }, 500);
  };

  areaAutoCompleteFetch = (queryString) => {
    if (this._areaAutocompleteRef) {
      clearTimeout(this._areaAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._areaAutocompleteRef = setTimeout(function () {
      search(queryString, null, "area");
    }, 500);
  };

  render() {
    const {
      setLinkedArea,
      setEquipmentInDetail,
      resetFilter,
      changeFilter,
      setFiltersForm,
      resetFiltersForm,
      changeDescription,
      resetDescriptionForm,
      markAsResolved,
      download,
    } = this.actions;
    const {
      areasList,
      linkedArea,
      equipmentInDetail,
      maintenancesList,
      filter,
      filteredEquipments,
      t,
      filtersForm,
      responsiveHandlers,
      descriptionForm,
      loadingDownload,
      filteredEquipmentsNumber,
      searchResults,
      searchLoading,

      searchAreaResults,
      searchAreaLoading,
    } = this.props;
    const {
      filtersWidth,
      inputWidth,
      triggerSet,
      dialogOpen,
      dialogContainer,
      changedForm,
    } = this.state;

    const areaEquipments = filteredEquipments
      ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow height="36px" display="flex" gutter="20">
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: "0 0px auto" }} separator="/">
                <Breadcrumb.Item>
                  <span onClick={() => {
                    setLinkedArea(null);
                    setEquipmentInDetail(null);
                    }}>
                    {t("errors.title")}
                  </span>
                </Breadcrumb.Item>
                {linkedArea && (
                  <Breadcrumb.Item>
                    <span
                      onClick={() => setEquipmentInDetail(null)}
                    >
                      {linkedArea.name}
                    </span>
                  </Breadcrumb.Item>
                )}
                {equipmentInDetail &&(
                  <Breadcrumb.Item>{equipmentInDetail.name}</Breadcrumb.Item>
                )}
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float="left"
              justifyContent={"flex-end"}
              paddingLeft={"14px"}
              paddingRight={0}
            >
              <React.Fragment>
                <StyledSelect
                  width={inputWidth}
                  placeholder={t("area_input_placeholder")}
                  value={filter.container__area__name}
                  onChange={(event) =>
                    changeFilter("container__area__name", event)
                  }
                  remoteMethod={this.areaAutoCompleteFetch}
                  options={searchAreaResults}
                  loading={searchAreaLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea != null}
                ></StyledSelect>
                <StyledSelect
                  placeholder={t("container_input_placeholder")}
                  onChange={(event) => changeFilter("container__uuid", event)}
                  value={filter.container__uuid}
                  remoteMethod={this.autoCompleteFetch}
                  options={searchResults}
                  loading={searchLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea == null}
                ></StyledSelect>
                <StyledSelect
                  value={filter.status}
                  onChange={(value) => changeFilter("status", value)}
                  placeholder={t("table.status")}
                  clearable={true}
                  filterMethod={(val) => {
                    if (val != null && val !== "") {
                      this.setState({
                        statusOptions: errorStatusOptions.filter(
                          (d) => t(d.label).toLowerCase() === val.toLowerCase()
                        ),
                      });
                    } else {
                      this.setState({
                        statusOptions: errorStatusOptions,
                      });
                    }
                  }}
                  options={
                    this.state.statusOptions != null
                      ? this.state.statusOptions.map((el) => ({
                          ...el,
                          label: t(el.label),
                        }))
                      : []
                  }
                ></StyledSelect>
              </React.Fragment>

              {changedForm != null && (
                <ToggleFilter
                  text={getIntervalText(changedForm, t)}
                  variableToWatch={filtersForm}
                  zIndex={1001}
                >
                  <IntervalPicker
                    form={filtersForm}
                    intervalOptions={intervalOptions}
                    replaceForm={setFiltersForm}
                    resetForm={resetFiltersForm}
                    defaultForm={INTERVAL_FORM_DEFAULTS}
                    triggerSet={triggerSet}
                    resetTriggerSet={() => this.setState({ triggerSet: false })}
                    state={{ changedForm }}
                    setState={this.setState}
                  />
                </ToggleFilter>
              )}

              <ButtonWrapper>
                <StyledButton
                  margin={"0"}
                  onClick={() => {
                    resetFilter();
                    setLinkedArea(null);
                  }}
                >
                  {t("clear_filters")}
                </StyledButton>

                <StyledButton
                  type={"primary"}
                  margin={"0"}
                  onClick={() => this.setState({ triggerSet: true })}
                >
                  {t("apply")}
                </StyledButton>
                <ToggleFilter
                  order={10}
                  right={0}
                  text={
                    <div>
                      {!loadingDownload ? "Download" : <DownloadLoading />}
                    </div>
                  }
                  type={"button"}
                  loadingDownload={loadingDownload}
                >
                  <DownloadDropDownContainer
                    download={download}
                  ></DownloadDropDownContainer>
                </ToggleFilter>
              </ButtonWrapper>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          style={{ marginBottom: "24px", marginTop: "24px" }}
          gutter="15"
        >
          <SelectivePaddingColNoFixedHeightWithBoxShadow
            paddingLeft="0px"
            paddingRight="0px"
            span="24"
          >
            <ContainerMapMaintenances
              areas={areasList}
              linkedArea={linkedArea}
              setLinkedArea={(area) => setLinkedArea(area)}
              areaEquipments={areaEquipments}
              equipmentInDetail={equipmentInDetail}
              globalNumberOfContainers={filteredEquipmentsNumber}
              setEquipmentInDetail={(equipment) =>
                setEquipmentInDetail(equipment)
              }
            />
          </SelectivePaddingColNoFixedHeightWithBoxShadow>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          gutter="20"
          height={"calc(55vh - 64px - 36px - 48px - 4rem)"}
        >
          <div ref={this.tableRef}>
            <NoPaddingCol span="24">
              <TableName>{t("errors.title")}</TableName>
              {responsiveHandlers.isMobile || responsiveHandlers.isTablet ? (
                <StyledMobileTable
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({ ...equipment }))
                      : []
                  }
                  template={ErrorsTemplate}
                  active={(row) =>
                    equipmentInDetail &&
                    row.container &&
                    equipmentInDetail.id === row.container.id
                      ? 1
                      : 0
                  }
                  markAsResolved={(data) => this.openDialog(data)}
                  onRowClick={(entrance) => {
                    window.scrollTo({
                      left: 0,
                      top: 0,
                      behavior: "smooth",
                    });
                    this.setState({
                      transitionEnded: false,
                    });
                    setEquipmentInDetail(entrance.container);
                  }}
                />
              ) : (
                <StyledTable
                  style={{ marginTop: ".5rem", minHeight: "100%" }}
                  height={
                    responsiveHandlers.height > 768
                      ? "calc(55vh - 64px - 36px - 48px - 4rem - 42px - 1px )"
                      : ""
                  }
                  defaultSort={{ prop: "startDate", order: "descending" }}
                  columns={columns.map((column) => ({
                    t,
                    ...column,
                    markAsResolved: (data) => this.openDialog(data),
                  }))}
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({
                          t,
                          ...equipment,
                        }))
                      : []
                  }
                  rowClassName={(row) => {
                    let toReturn = "";
                    if (
                      equipmentInDetail &&
                      row.container &&
                      equipmentInDetail.id === row.container.id
                    ) {
                      toReturn += " active";
                    }
                    if (row.status === "open") {
                      toReturn += " error";
                    }
                    return toReturn;
                  }}
                  onRowClick={(entrance) => {
                    setEquipmentInDetail(entrance.container);
                  }}
                />
              )}
            </NoPaddingCol>
          </div>
        </CustomLayoutSecondaryRow>
        {dialogOpen && dialogContainer != null && (
          <Dialog
            visible={dialogOpen}
            onCancel={() => {
              this.setState({
                dialogOpen: false,
                dialogContainer: null,
              });
              resetDescriptionForm();
            }}
            title={t("table.add_description")}
          >
            <DialogBodyWrapper>
              <div>
                <StyledInput
                  type="textarea"
                  width={"100%"}
                  label={t("table.description")}
                  placeholder={t("table.description")}
                  value={descriptionForm.description.value}
                  onChange={(e) => {
                    changeDescription({ description: e });
                  }}
                />
                {descriptionForm.description != null && (
                  <ErrorText>{descriptionForm.description.message}</ErrorText>
                )}
              </div>
              <DialogButtonWrapper>
                <StyledButton
                  type={"primary"}
                  onClick={() => markAsResolved(dialogContainer)}
                >
                  {t("table.submit")}
                </StyledButton>
              </DialogButtonWrapper>
            </DialogBodyWrapper>
          </Dialog>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Tickets)));

const Wrapper = styled.div`
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
  overflow: visible;
`;

const SelectivePaddingColNoFixedHeightWithBoxShadow = styled(
  SelectivePaddingColNoFixedHeight
)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;


const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;
    }
  `}
`;

const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.desktop`
    &.el-col-20{
      display: flex;
      justify-content: flex-end;
      width: 83.33333%;
      padding-left: 14px !important;
    }
  `}
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: span 2;

  ${({ theme }) => theme.desktop`
    display flex;
  `}
`;

const DialogBodyWrapper = styled.div``;

const DialogButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 150px;
  margin-left: auto;
`;

const ErrorText = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-heihgt: 1em;
  color: ${({ theme }) => theme.errorColor};
`;

const DownloadLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;