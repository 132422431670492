/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Layout, Card, Loading, Breadcrumb } from "element-react";
import { withTranslation } from "react-i18next";

import ContainerMapClientDashboard from "containers/ContainerMapClientDashboard";

import { Theme } from "globalStyles";
import AnimatedNumber from "react-animated-number";

import { Bar } from "react-chartjs-2";

import columns from "./columns";

import ContainerIcon from "icons/ContainerIcon";
import Alert from "icons/Alert";
import Deposit from "icons/Deposit";
import PointsIcon from "icons/PointsIcon";

import IntervalPicker from "containers/IntervalPicker";

import ResponsiveHandler from "components/ResponsiveHandler";
import ToggleFilter from "components/ToggleFilter";
import StyledButton from "components/StyledButton";

import StyledMobileTable from "containers/StyledMobileTable";

import StyledTable from "components/StyledTable";
import ClientDepositsTemplate from "components/ClientDepositsTemplate";

import { GRAPH_COLORS } from "./utils";
import { ADDITIONAL_FEATURES } from "config";
import { getIntervalText } from "utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

class ClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconHeight: 32,
      triggerSet: false,
      changedForm: null,
    };
    this.tableRef = React.createRef();
    this.setState = this.setState.bind(this);
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  mapTooltips = (text, data) => {
    return text;
  };

  timeIntervalSelect = () => {
    const { filtersForm, t } = this.props;
    const {
      setFiltersForm,
      resetFiltersForm,
      changeFiltersForm,
      setEquipmentInDetail,
    } = this.actions;

    const { triggerSet, changedForm } = this.state;

    return (
      <SelectPositioner>
        {changedForm != null && (
          <DateFilterWrapper>
            <ToggleFilter
              right={0}
              text={getIntervalText(changedForm, t)}
              variableToWatch={filtersForm}
            >
              <IntervalPicker
                form={filtersForm}
                intervalOptions={intervalOptions}
                changeForm={changeFiltersForm}
                replaceForm={setFiltersForm}
                resetForm={resetFiltersForm}
                triggerSet={triggerSet}
                resetTriggerSet={() => this.setState({ triggerSet: false })}
                defaultForm={INTERVAL_FORM_DEFAULTS}
                state={{ changedForm }}
                setState={this.setState}
              />
            </ToggleFilter>
          </DateFilterWrapper>
        )}
        <ButtonWrapper>
          <StyledButton
            margin={"0"}
            onClick={() => {
              resetFiltersForm();
              setEquipmentInDetail(null);
            }}
          >
            {t("clear_filters")}
          </StyledButton>
          <StyledButton
            type={"primary"}
            margin={"0"}
            onClick={() => this.setState({ triggerSet: true })}
          >
            {t("apply")}
          </StyledButton>
        </ButtonWrapper>
      </SelectPositioner>
    );
  };

  createDropTooltip = (tooltipItem) => {
    const { t } = this.props;
    return `${tooltipItem.value} ${t("graph.drops")}`;
  };

  createTooltipTile = (tooltipItem, data) => {
    return tooltipItem[0].label;
  };

  render() {
    const { setEquipmentInDetail } =
      this.actions;
    const {
      filteredEquipments,
      equipmentInDetail,
      dropsLabels,
      drops,
      loadingDrops,
      globalNumberOfAlerts,
      dropsCount,
      t,
      dropsLabelsKeys,
      responsiveHandlers,
      dropsList,
      user,
      filteredEquipmentsNumber,
    } = this.props;

    const { iconHeight } = this.state;

    const areaEquipments = filteredEquipments
      ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow marginTop="0px" gutter="30">
          <BreakcrumbWrapper>
            <CustomBreakcrumb style={{ margin: "0px 0px auto" }} separator="/">
              <Breadcrumb.Item>
                <span
                  onClick={() => setEquipmentInDetail(null)}
                >
                  {t("dashboard.title")}
                </span>
              </Breadcrumb.Item>
              {equipmentInDetail && (
                <Breadcrumb.Item>
                  <span>{equipmentInDetail.name}</span>
                </Breadcrumb.Item>
              )}
            </CustomBreakcrumb>
            <RightNavWrapper>{this.timeIntervalSelect()}</RightNavWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <MapCardsWrapper marginTop="15px" gutter="20"  style={{ marginBottom: "24px", marginTop: "24px" }}>
          <CardsWrapper paddingSides={"5px"} span="4">
            <StaticCardsWrapper>
              {ADDITIONAL_FEATURES.includes("transactions") && user != null && (
                <CardInsideWrapper flex="0.5">
                  <StaticCardIconContainer>
                    <FlexInsideCard>
                      <PointsIcon
                        height={`${iconHeight}px`}
                        fill={Theme.secondaryColor}
                        style={{ margin: "auto" }}
                      />
                      <SectionInfo
                        value={user.points}
                        style={{
                          transition: "0.8s ease-out",
                        }}
                        duration={300}
                        stepPrecision={0.1}
                      />
                    </FlexInsideCard>
                  </StaticCardIconContainer>
                  <StaticCardTextContainer>
                    <SmallCardTitle margin="20px auto 0px auto">
                      {t("dashboard.points")}
                    </SmallCardTitle>
                  </StaticCardTextContainer>
                </CardInsideWrapper>
              )}

              <CardInsideWrapper>
                <StaticCardIconContainer>
                  <FlexInsideCard>
                    <ContainerIcon
                      height={`${iconHeight}px`}
                      fill={Theme.secondaryColor}
                      style={{ margin: "auto" }}
                    />
                    <SectionInfo
                      value={filteredEquipmentsNumber}
                      style={{
                        transition: "0.8s ease-out",
                      }}
                      duration={300}
                      stepPrecision={0.1}
                    />
                  </FlexInsideCard>
                </StaticCardIconContainer>
                <StaticCardTextContainer>
                  <SmallCardTitle margin="20px auto 0px auto">
                    {t("dashboard.containers")}
                  </SmallCardTitle>
                </StaticCardTextContainer>
              </CardInsideWrapper>

              <CardInsideWrapper>
                <StaticCardIconContainer>
                  <FlexInsideCard>
                    <Deposit
                      height={`${iconHeight}px`}
                      fill={Theme.secondaryColor}
                      style={{ margin: "auto" }}
                    />
                    <SectionInfo
                      value={dropsCount}
                      style={{
                        transition: "0.8s ease-out",
                      }}
                      duration={300}
                      stepPrecision={0.1}
                    />
                  </FlexInsideCard>
                </StaticCardIconContainer>
                <StaticCardTextContainer>
                  <SmallCardTitle margin="20px auto 0px auto">
                    {t("dashboard.drops_metrics_title")}
                  </SmallCardTitle>
                </StaticCardTextContainer>
              </CardInsideWrapper>

              <CardInsideWrapper flex="0.5">
                <StaticCardIconContainer>
                  <FlexInsideCard>
                    <Alert
                      height={`${iconHeight}px`}
                      fill={Theme.secondaryColor}
                      style={{ margin: "auto" }}
                    />
                    <SectionInfo
                      value={globalNumberOfAlerts}
                      style={{
                        transition: "0.8s ease-out",
                      }}
                      duration={300}
                      stepPrecision={0.1}
                    />
                  </FlexInsideCard>
                </StaticCardIconContainer>
                <StaticCardTextContainer>
                  <SmallCardTitle margin="20px auto 0px auto">
                    {t("dashboard.alerts_metrics_title")}
                  </SmallCardTitle>
                </StaticCardTextContainer>
              </CardInsideWrapper>
            </StaticCardsWrapper>
          </CardsWrapper>

          <MapWrapper paddingSides={"5px"} span="20">
            <ContainerMapClientDashboard
              equipments={areaEquipments}
              equipmentInDetail={equipmentInDetail}
              setEquipmentInDetail={(equipment) =>
                setEquipmentInDetail(equipment)
              }
            />
          </MapWrapper>
        </MapCardsWrapper>

        <CustomLayoutSecondaryRow marginTop="16px" gutter="30">
          <FlexRowNormal>
            <CustomCard
              height={"calc(55vh - 164px)"}
              width={
                !ADDITIONAL_FEATURES.includes("transactions") ||
                responsiveHandlers.isMobile ||
                responsiveHandlers.isTablet
                  ? "100%"
                  : "60%"
              }
              graph
            >
              <GraphTitle margin={"18px 0 0 0"}>
                {t("dashboard.drops_graph_title")}
              </GraphTitle>
              <GraphFlex>
                <CustomLoading top="120px" loading={loadingDrops}>
                  <GraphWrapper width={"100%"}>
                    {!loadingDrops && (
                      <Bar
                        data={{
                          labels: dropsLabels.map((label) =>
                            this.mapTooltips(label)
                          ),
                          datasets: drops.map((el, index) => ({
                            label: dropsLabelsKeys[index],
                            backgroundColor:
                              GRAPH_COLORS[index] != null
                                ? GRAPH_COLORS[index]
                                : Theme.secondaryColor,
                            borderColor: Theme.secondaryColor,
                            fill: false,
                            data: [...el],
                          })),
                        }}
                        style={{ height: "100%" }}
                        options={{
                          legend: {
                            display: false,
                            position: "top",
                          },
                          maintainAspectRatio: false,
                          responsive: true,
                          scales: {
                            yAxes: [
                              {
                                stacked: true,
                                ticks: {
                                  suggestedMin: 0,
                                  suggestedMax: 10,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                stacked: true,
                                ticks: {
                                  autoSkip: true,
                                  maxRotation:
                                    responsiveHandlers.isMobile ||
                                    responsiveHandlers.isTablet
                                      ? 0
                                      : 70,
                                  minRotation: 0,
                                  maxTicksLimit:
                                    responsiveHandlers.isMobile ||
                                    responsiveHandlers.isTablet
                                      ? 12
                                      : 31,
                                },
                              },
                            ],
                          },
                          tooltips: {
                            // Disable the on-canvas tooltip
                            backgroundColor: "#FFFFFF",
                            bodyFontColor: "#000000",
                            titleFontColor: "#000000",
                            borderColor: "rgba(0,0,0,1)",
                            borderWidth: "0.5",
                            callbacks: {
                              title: (tooltipItem, data) => {
                                return this.createTooltipTile(
                                  tooltipItem,
                                  data
                                );
                              },
                              label: (tooltipItem, data) => {
                                return this.createDropTooltip(tooltipItem);
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </GraphWrapper>
                </CustomLoading>
              </GraphFlex>
            </CustomCard>
            {ADDITIONAL_FEATURES.includes("transactions") && (
              <CustomCardTransparent
                height="calc(55vh  - 112px)"
                boxShadow={dropsList == null || dropsList.length === 0}
                width={
                  responsiveHandlers.isMobile || responsiveHandlers.isTablet
                    ? "100%"
                    : "40%"
                }
              >
                <div ref={this.tableRef}>
                  <NoPaddingCol span={24}>
                    <CustomCard>
                      {responsiveHandlers.isMobile ||
                      responsiveHandlers.smallDesktop ? (
                        <StyledMobileTable
                          data={
                            dropsList
                              ? dropsList.map((equipment) => ({
                                  ...equipment,
                                }))
                              : []
                          }
                          template={ClientDepositsTemplate}
                          active={(row) =>
                            equipmentInDetail &&
                            row.container &&
                            equipmentInDetail.id === row.container.id
                          }
                          onRowClick={(entrance) => {
                            window.scrollTo({
                              left: 0,
                              top: 0,
                              behavior: "smooth",
                            });
                            this.setState({
                              transitionEnded: false,
                            });
                            
                            setEquipmentInDetail(entrance.container);
                          }}
                          margin={0}
                        />
                      ) : (
                        <StyledTable
                          withoutShadow
                          columns={columns.map((column) => ({ t, ...column }))}
                          data={
                            dropsList != null
                              ? dropsList.map((equipment) => ({
                                  ...equipment,
                                }))
                              : []
                          }
                          height="calc(55vh - 112px)"
                          onRowClick={(entrance) => {
                            this.setState({
                              transitionEnded: false,
                            });
                            
                            setEquipmentInDetail(entrance.container);
                          }}
                        />
                      )}
                    </CustomCard>
                  </NoPaddingCol>
                </div>
              </CustomCardTransparent>
            )}
          </FlexRowNormal>
        </CustomLayoutSecondaryRow>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(ClientDashboard)));

const Wrapper = styled.div``;

const SectionInfo = styled(AnimatedNumber)`
  margin: 0 0 0 8px;
  color: ${Theme.black};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background: transparent !important;
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const CustomCard = styled(Card)`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border + " !important"};
  flex: 0 auto;

  & + & {
    margin-top: 8px;
  }

  ${({ theme }) => theme.smallDesktop`
    height: ${(props) => props.height};

    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  `}

  .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 auto;

    & > div {
      height: 100%;
    }
  }

  @media (max-height: 768px) {
    min-height: 280px;
  }
`;

const CustomCardTransparent = styled(CustomCard)`
  box-shadow: ${(props) => !props.boxShadow && "none !important;"};
  &.el-card {
    background-color: transparent;
  }
  @media (max-height: 768px) {
    &.el-card {
      min-height: auto;
      height: 320px;
    }
  }
`;

const CardInsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5px;
  align-items: center;
  justify-content: center;

  position: relative;
  & + & {
    &::after {
      content: "";
      height: 1px;
      width: 90%;
      background-color: ${Theme.lightInactiveColor};
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
  width: 100%;
`;

const MapCardsWrapper = styled(CustomLayoutSecondaryRow)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.smallDesktop`
    display: block;
  `}
`;

const FlexRowNormal = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  flex: 100%;

  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const GraphFlex = styled(FlexRowNormal)`
  .el-card__body && {
    ${({ theme }) => theme.smallDesktop`
      height: calc(100% - 40px);
    `}
  }
`;

const GraphWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  width: ${(props) => props.width};
  padding: 4px 10px 12px 25px;
`;

const FlexInsideCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SmallCardTitle = styled.div`
  color: ${Theme.darkInactiveColor};
  font-family: ${Theme.fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  text-align: center;
  margin: ${(props) => props.margin};

  ${({ theme }) => theme.smallDesktop`
  font-size: 12px;
  margin: 0;
    `}
`;

const CustomLoading = styled(Loading)`
  display: flex !important;
  width: -webkit-fill-available !important;
  position: relative;
  width: 100%;

  .el-loading-spinner {
    left: 0px !important;
    top: ${(props) => (props.top ? props.top : "40px")};
    display: flex !important;
    justify-content: center;

    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const SelectPositioner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 8px;
  column-gap: 8px;

  height: auto;
  padding: 0px 0px 0px 0px;
  width: 100%;
  grid-column: span 2;

  ${({ theme }) => theme.desktop`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 8px;
    width: auto;
    grid-column: auto;
  `}
  ${({ theme }) => theme.smallDesktop`
    display: flex;
    column-gap: 8px;
  `}
`;

const LayoutColLessPadding = styled(Layout.Col)`
  padding-left: ${(props) => props.paddingSides + " !important"};
  padding-right: ${(props) => props.paddingSides + " !important"};
  &:first-child {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
  }
`;

const MapWrapper = styled(LayoutColLessPadding)`
  &.el-col {
    order: -1;
    width: 100%;
    padding: 0 !important;

    ${({ theme }) => theme.smallDesktop`
    width: 83.33333%;
    padding-left: 5px !important;
  `}
  }
`;
const CardsWrapper = styled(LayoutColLessPadding)`
  &.el-col {
    order: 0;
    width: 100%;
    padding: 0 !important;
    margin-top: 16px;

    ${({ theme }) => theme.smallDesktop`
    width: 16.66667%;
    padding-right: 5px !important;
    margin-top: 0;

  `}
  }
`;

const StaticCardTextContainer = styled.div`
  grid-column: 2;
  color: ${Theme.darkPrimaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StaticCardIconContainer = styled.div`
  grid-column: 1;
  color: ${Theme.darkPrimaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StaticCardsWrapper = styled.div`
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);

  min-height: 440px;

  height: calc(40vh + 15px);

  width: 100%;

  ${({ theme }) => theme.smallDesktop`
    min-height: auto;
  `}

  @media (max-height: 768px) {
    height: 320px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomBreakcrumb = styled(Breadcrumb)`
   .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;

const RightNavWrapper = styled(FlexInsideCard)`
  margin-top: 1rem;
  justify-content: flex-start;
  ${({ theme }) => theme.smallDesktop`
    margin-top: 0;
    align-items: center;
    margin-left: auto;

  `}
`;

const GraphTitle = styled.h2`
  color: ${Theme.black};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  line-height: 22px;
  padding: 17px 15px 0px 15px;
  text-align: center;
  margin: 0;
  padding: ${(props) => props.margin};
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;

  grid-column: span 2;
  column-gap: 8px;

  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-left: 0;

  ${({ theme }) => theme.smallDesktop`
  display flex;
  margin-left: 0;
  column-gap: 8px;
  grid-template-columns: auto auto;

`}
  ${({ theme }) => theme.desktop`
  display flex;
  margin-left: 0;
  column-gap: 8px;
  
`}
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding: ${(props) => props.padding} !important;
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DateFilterWrapper = styled.div`
  grid-column: span 2;
  ${({ theme }) => theme.smallDesktop`
  grid-column: span 1;

  `}
`;
