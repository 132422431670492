import React from "react";
import moment from "moment";
import MarkAsResolved from "icons/MarkAsResolved";
import CrossIcon from "icons/CrossIcon";
import styled from "styled-components";
import { Theme } from "globalStyles";
import decodeType from "utils/decodeType";

const columns = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    width: 80,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    prop: "startDate",
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv title={moment(data.startDate).format("DD/MM/YYYY HH:mm")}>
          {moment(data.startDate).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    width: 180,
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.uuid ? data.container.uuid : "-"
          }
        >
          {data.container && data.container.uuid ? data.container.uuid : "-"}
        </EllipsisDiv>
      );
    },
    width: 250,
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : "-"
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
    width: 170
  },
  {
    label: "table.description",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv
          title={data.type && data.type.name ? t(data.type.name) : "-"}
        >
          {data.type && data.type.name ? t(data.type.name) : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 120
  },
  {
    label: "table.status",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.status && b.status) {
        return a.status >= b.status;
      }
      return false;
    },
    render: function (data) {
      return data.status && data.status === "open" ? (
        <CrossIcon height={"24px"} width={"24px"} fill={Theme.errorColor} />
      ) : data.endDate ? (
        <EllipsisDiv title={moment(data.endDate).format("DD/MM/YY HH:mm")}>
          {moment(data.endDate).format("DD/MM/YY HH:mm")}
        </EllipsisDiv>
      ) : (
        "-"
      );
    },
    width: 170,
  },
  {
    label: "table.resolve",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function (data, options) {
      return data.status && data.status === "open" ? (
        <ClickIcon
          height={"24px"}
          onClick={(e) => {
            e.stopPropagation();
            options.markAsResolved(data);
          }}
          fill={Theme.primaryColor}
        ></ClickIcon>
      ) : data.additionalInformation ? (
        <EllipsisDiv title={data.additionalInformation}>
          {data.additionalInformation}
        </EllipsisDiv>
      ) : (
        ""
      );
    },
    minWidth: 100
  },
];

export default columns;

const ClickIcon = styled(MarkAsResolved)`
  cursor: pointer;
  z-index: 100;

  :hover path {
    fill: ${Theme.darkPrimaryColor};
  }
`;

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
