/**
 * DashboardWrapper Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import Occurrences from "../Occurrences";
import ClientOccurrences from "../ClientOccurrences";
import { Redirect } from "react-router";
import { Loading } from "element-react";

class DashboardWrapper extends Component {
  render() {
    const { user, loadingUser } = this.props;
    if (loadingUser) {
      return <Loading fullscreen={true}></Loading>;
    }
    if (user != null) {
      if (user.isAdmin) {
        return <Occurrences />;
      } else {
        return <ClientOccurrences />;
      }
    }
    return <Redirect to={"/login/"} />;
  }
}

export default Logic(DashboardWrapper);
