/**
 * Main APP Container
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import Logic from "./logic";
import {
  setAuthorizationInterceptor,
  setResponseInterceptor,
} from "interceptors";

import Routes from "routes";
import { withTranslation } from "react-i18next";
import { Theme, media } from "globalStyles";
import { Switch, Route, matchPath, withRouter } from "react-router";
import "moment/locale/en-gb";
import "moment/locale/pt";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Container from "components/Container";
import CloseButton from "components/CloseButton";
import Sidebar from "containers/Sidebar";
import Navbar from "containers/Navbar";
import FundingFooter from "containers/FundingFooter";
import ResponsiveHandler from "components/ResponsiveHandler";

import { SIMPLE_FEATURES, ADDITIONAL_FEATURES } from "config";
import moment from "moment";

class App extends Component {
  constructor() {
    super();
    setAuthorizationInterceptor();
  }

  componentWillUnmount() {
    const { i18n } = this.props;
    i18n.off("languageChanged", this.refreshContent);
  }

  refreshContent = (event) => {
    const { setCurrentLanguage } = this.actions;
    setCurrentLanguage(event);
    moment.locale(event);
  };

  componentDidMount() {
    const { t } = this.props;
    Chart.defaults.scale.gridLines.display = false;
    Chart.plugins.register({
      afterDraw: function (chart) {
        if (chart.data.datasets.length === 0) {
          // No data is present
          let ctx = chart.chart.ctx;
          let width = chart.chart.width;
          let height = chart.chart.height;
          chart.clear();
          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fontColor = "#4A5B6D";
          ctx.fillStyle = "#4A5B6D";
          ctx.font = "12px 'Nunito Sans', 'Roboto', sans-serif";
          ctx.fillText(t("no_data"), width / 2, height / 2);
          ctx.restore();
          return;
        } else {
          for (
            let datasetNumber = 0;
            datasetNumber < chart.data.datasets.length;
            datasetNumber++
          ) {
            for (
              let i = 0;
              i < chart.data.datasets[datasetNumber].data.length;
              i++
            ) {
              if (
                chart.data.datasets[datasetNumber].data[i] &&
                chart.data.datasets[datasetNumber].data[i] !== "0"
              ) {
                return;
              }
            }
          }
          // No data is present
          let ctx = chart.chart.ctx;
          let width = chart.chart.width;
          let height = chart.chart.height;
          chart.clear();
          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fontColor = "#4A5B6D";
          ctx.fillStyle = "#4A5B6D";
          ctx.font = "12px 'Nunito Sans', 'Roboto', sans-serif";
          ctx.fillText(t("no_data"), width / 2, height / 2);
          ctx.restore();
        }
      },
    });
    const { i18n } = this.props;
    i18n.on("languageChanged", this.refreshContent);
  }

  isOutsideApp = (currentPath) => {
    const outsideRoutes = ["/login/", "/password/reset/", "/password/set/"];
    return (
      outsideRoutes.filter((el) => {
        const match = matchPath(currentPath, el);
        return match != null && match.isExact === true;
      }).length > 0
    );
  };

  componentDidUpdate(prevProps) {
    const { checkUserAuthentication } = this.actions;

    const { shouldLogin, shouldBypassLogin } = this.props;

    if (shouldLogin) {
      const { setShouldLogin, logout } = this.actions;
      logout();
      setShouldLogin(false);
      if (!this.isOutsideApp(this.props.history.location.pathname)) {
        this.props.history.push("/login/");
      }
    } else if (shouldBypassLogin) {
      const { setShouldBypassLogin } = this.actions;
      setResponseInterceptor(this.props.history, checkUserAuthentication);
      setShouldBypassLogin(false);

      if (this.props.history.location.pathname === "/login/") {
        this.props.history.push("/");
      }
    }
  }

  render() {
    const { loading, user, responsiveHandlers } = this.props;
    const { logout, setShouldLogin } = this.actions;

    let routes = [];

    const allowedRoutes = [
      "login",
      "notfound",
      "dashboard",
      "recoverpassword",
      "passwordset",
    ];

    if (!loading) {
      routes = Routes.map((sec) => {
        if (
          !ADDITIONAL_FEATURES.includes(sec.key) &&
          !allowedRoutes.includes(sec.key)
        ) {
          return null;
        }

        if (
          ADDITIONAL_FEATURES.includes(sec.key) &&
          sec.admin_only &&
          user != null &&
          !user.isAdmin
        ) {
          return null;
        }

        return (
          <Route
            exact={sec.exact}
            key={sec.key}
            path={sec.path}
            component={sec.component}
          />
        );
      });
    }

    let showNavbar = false;
    let showSidebar = false;
    if (this.props.location.pathname !== "/unavailable") {
      showNavbar = true;
      if (
        !(responsiveHandlers.isMobile || responsiveHandlers.isTablet) &&
        user != null
      ) {
        showSidebar = true;
      }
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>{SIMPLE_FEATURES ? "smartPayt" : "smartRayt"}</title>

          <link
            rel="shortcut icon"
            href={`${SIMPLE_FEATURES ? "payt" : "rayt"}/favicon.ico`}
          ></link>
          <link
            rel="apple-touch-icon"
            href={`${SIMPLE_FEATURES ? "payt" : "rayt"}/apple-touch-icon.png`}
          ></link>
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href={`${
              SIMPLE_FEATURES ? "payt" : "rayt"
            }/android-chrome-192x192.png`}
          ></link>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${SIMPLE_FEATURES ? "payt" : "rayt"}/favicon-32x32.png`}
          ></link>
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${SIMPLE_FEATURES ? "payt" : "rayt"}/favicon-16x16.png`}
          ></link>
          <meta
            name="msapplication-TileImage"
            content={`${SIMPLE_FEATURES ? "payt" : "rayt"}/mstile-150x150.png`}
          ></meta>
          <link
            rel="manifest"
            href={`${SIMPLE_FEATURES ? "payt" : "rayt"}/site.webmanifest`}
          ></link>
        </Helmet>
        <ThemeProvider theme={{ ...Theme, ...responsiveHandlers, ...media }}>
          <Main>
            {!loading && (
              <React.Fragment>
                {showNavbar && (
                  <Navbar
                    logout={() => {
                      logout();
                      setShouldLogin(false);
                      if (
                        !this.isOutsideApp(this.props.history.location.pathname)
                      ) {
                        this.props.history.push("/login/");
                      }
                    }}
                  />
                )}
                {showSidebar && <Sidebar user={user} />}
                <SceneWrapper
                  width={!showSidebar ? "100vw" : "calc(100vw - 4rem)"}
                  height={"calc(100vh - 4rem)"}
                  margin={showSidebar ? "calc(3rem + 15px)" : "0"}
                >
                  <Container>
                    <Switch>{routes}</Switch>
                  </Container>
                  {ADDITIONAL_FEATURES.includes("funding_footer") && (
                    <FooterWrapper>
                      <FundingFooter />
                    </FooterWrapper>
                  )}
                </SceneWrapper>

                <ToastContainer
                  pauseOnFocusLoss={false}
                  autoClose={5000}
                  hideProgressBar={true}
                  closeButton={<CloseButton></CloseButton>}
                />
              </React.Fragment>
            )}
          </Main>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  ResponsiveHandler(withRouter(Logic(App)))
);

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const SceneWrapper = styled.div`
  width: ${(props) => props.width};
  margin-left: ${(props) => props.margin};
  min-height: calc(100vh - 4rem);
  min-height: ${(props) => props.height};
  margin-top: 4rem;
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 700;
`