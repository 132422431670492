import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.761 6.334A9.957 9.957 0 002.33 9.45h4.549L3.76 6.334zM9.2 2.4a9.964 9.964 0 00-3.16 1.57L9.2 7.128V2.4zM6.276 20.197A9.949 9.949 0 009.198 21.6v-4.325l-2.923 2.923zM2.444 14.953A9.962 9.962 0 003.929 17.9l2.948-2.948H2.444z"
        fill="#29337A"
      />
      <path
        d="M3.926 6.102c-.056.076-.11.154-.165.232L6.878 9.45H2.329c-.074.281-.134.567-.183.857h5.986L3.926 6.102zM6.044 20.032c.076.057.154.11.231.165L9.2 17.274v4.325c.281.082.567.153.857.21V16.02l-4.012 4.012zM2.222 14.096c.062.29.135.576.222.857h4.433L3.93 17.9c.32.438.676.85 1.062 1.23l5.035-5.035H2.222zM9.2 2.4v4.728L6.04 3.97c-.435.324-.842.683-1.22 1.072l5.236 5.235V2.19c-.29.058-.576.129-.857.21z"
        fill="#fff"
      />
      <path
        d="M9.2 14.922l-.03.03h.03v-.03zM10.026 14.096h.03v-.03l-.03.03zM13.874 10.307h-.029v.029l.03-.03zM9.229 9.45l-.03-.03v.03h.03zM10.056 10.277v.03h.03l-.03-.03z"
        fill="#D32030"
      />
      <path
        d="M14.702 19.386v2.241a9.943 9.943 0 002.964-1.389l-2.429-2.428c-.194.681-.265 1.034-.535 1.576zM15.25 6.58l2.651-2.652a9.962 9.962 0 00-3.2-1.556v2.303c.312.625.339 1.099.549 1.905zM20.03 17.96a9.972 9.972 0 001.526-3.007h-4.533l3.007 3.006zM21.67 9.45a9.952 9.952 0 00-1.473-3.174L17.023 9.45h4.649z"
        fill="#252F6C"
      />
      <path
        d="M15.834 14.266l.687.687 3.29 3.29c.074-.094.148-.188.22-.283l-3.007-3.007h4.533c.086-.28.16-.566.221-.857h-5.88c-.01.122-.053.049-.064.17zM15.146 7.081L18.127 4.1a10.37 10.37 0 00-.226-.172L15.25 6.58c.027.105-.13.394-.104.501zM14.702 4.49V2.373a9.985 9.985 0 00-.857-.2v1.034c.319.345.604.78.857 1.285zM15.766 10.307h6.089c-.05-.29-.11-.576-.184-.857h-4.648l3.175-3.174a10.042 10.042 0 00-1.038-1.254l-3.577 3.576c.097.648.14 1.025.183 1.709zM15.093 17.665l2.573 2.573c.428-.294.831-.622 1.208-.979l-3.386-3.385c-.108.626-.236 1.23-.395 1.79zM13.845 20.675v1.152c.29-.054.576-.121.857-.2v-2.241c-.252.505-.538.944-.857 1.29z"
        fill="#E7E7E7"
      />
      <path
        d="M15.583 8.598l3.576-3.576a9.99 9.99 0 00-1.032-.922l-2.81 2.81c.125.522.182 1.12.266 1.688z"
        fill="#D71F28"
      />
      <path
        d="M11.5 2.012zM12 22zM12 22c.63 0 1.247-.061 1.845-.173v-1.152C13.32 21.245 12.705 22 12 22z"
        fill="#D32030"
      />
      <path
        d="M21.854 10.307h-6.009c.08 1.254.07 2.544-.029 3.79h5.962a10.033 10.033 0 00.076-3.79zM15.834 14.266c-.056.59-.25 1.05-.346 1.608l3.385 3.385c.335-.317.648-.656.937-1.017l-3.29-3.29-.686-.686z"
        fill="#D71F28"
      />
      <path
        d="M13.845 3.28V2.173A10.047 10.047 0 0012 2c.706 0 1.32.711 1.845 1.28z"
        fill="#D32030"
      />
      <path
        d="M14.702 19.818c.27-.542.502-1.166.696-1.847l-.696-.697v2.544zM15.485 6.345c-.21-.806-.471-1.539-.783-2.163v2.946l.783-.783z"
        fill="#29337A"
      />
      <path
        d="M16.028 14.46l.031-.364h-.395l.364.364zM14.702 7.128V4.182a5.532 5.532 0 00-.857-1.285v5.485l1.72-1.72-.08-.317-.783.783zM13.874 10.307h2.214a24.165 24.165 0 00-.209-2.005l-2.005 2.005zM13.845 21.103c.319-.346.604-.78.857-1.285v-2.544l.696.697c.16-.56.294-1.159.403-1.785l-1.956-1.955v6.872z"
        fill="#fff"
      />
      <path
        d="M13.845 21.103V14.23l1.955 1.955c.097-.557.172-1.136.228-1.726l-.364-.364h.395c.099-1.245.108-2.535.029-3.79h-2.214l2.005-2.004a19.233 19.233 0 00-.314-1.64l-1.72 1.72V2.897C13.32 2.327 12.705 2 12 2a10.066 10.066 0 00-1.944.19v8.087L4.82 5.042c-.322.332-.621.686-.895 1.06L7.275 9.45l.857.857H2.146C2.052 10.857 2 11.423 2 12c0 .72.078 1.42.222 2.096h7.804L4.99 19.131c.33.324.681.625 1.053.901l4.012-4.012v5.79A10.074 10.074 0 0012 22c.706 0 1.32-.328 1.845-.897z"
        fill="#E51D35"
      />
    </svg>
  )
}

export default SvgComponent
