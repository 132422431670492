/**
 * Occurrences Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Logic, { INTERVAL_FORM_DEFAULTS } from './logic';
import styled from 'styled-components';
import { Theme } from 'globalStyles';
import { Layout, Breadcrumb, Loading } from 'element-react';
import { withTranslation } from 'react-i18next';

import { columns } from './columns';

import ContainerMapMaintenances from 'containers/ContainerMapMaintenances';
import IntervalPicker from 'containers/IntervalPicker';
import StyledMobileTable from 'containers/StyledMobileTable';
import DownloadDropDownContainer from 'containers/DownloadDropDownContainer';

import StyledTable from 'components/StyledTable';
import StyledButton from 'components/StyledButton';
import ToggleFilter from 'components/ToggleFilter';
import ResponsiveHandler from 'components/ResponsiveHandler';
import OccurrencesTemplate from 'components/OccurrencesTemplate';
import StyledSelect from 'components/StyledSelect';
import Dialog from 'components/Dialog';
import moment from 'moment';

import { getIntervalText } from 'utils';

const getUserName = (data) => {
  if (data) {
    return data.user;
  }
  return '-';
};

const intervalOptions = [
  {
    value: 'day',
    label: 'day',
  },
  {
    value: 'month',
    label: 'month',
  },
  {
    value: 'year',
    label: 'year',
  },
];

const depositCompleteOptions = [
  {
    label: 'closed',
    value: 'True',
  },
  {
    label: 'open',
    value: 'False',
  },
];

class Occurrences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      transitionEnded: false,
      filtersWidth: 20,
      inputWidth: '140px',
      triggerSet: false,
      changedForm: null,
      completeOptions: depositCompleteOptions,
      dialogOpen: false,
      dialogContainer: null,
      deleteOpen: false,
      deleteContainer: null,
    };
    this.tableRef = React.createRef();
    this.setState = this.setState.bind(this);
    this._autocompleteRef = null;
    this._userAutocompleteRef = null;
    this._areaAutocompleteRef = null;
  }

  _mapContainer = React.createRef();

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this._mapContainer).addEventListener(
      'transitionend',
      () => {
        this.setState({ transitionEnded: true });
      }
    );
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  autoCompleteFetch = (queryString) => {
    if (this._autocompleteRef) {
      clearTimeout(this._autocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._autocompleteRef = setTimeout(function () {
      search(queryString, null, 'container');
    }, 500);
  };

  userAutoCompleteFetch = (queryString) => {
    if (this._userAutocompleteRef) {
      clearTimeout(this._userAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._userAutocompleteRef = setTimeout(function () {
      search(queryString, null, 'username');
    }, 500);
  };

  areaAutoCompleteFetch = (queryString) => {
    if (this._areaAutocompleteRef) {
      clearTimeout(this._areaAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._areaAutocompleteRef = setTimeout(function () {
      search(queryString, null, 'area');
    }, 500);
  };

  seeMore = (event, row) => {
    event.stopPropagation();
    this.setState({ dialogOpen: true, dialogContainer: row });
  };

  setDeleteOccurence = (event, row) => {
    event.stopPropagation();
    this.setState({ deleteOpen: true, deleteContainer: row });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.deleteResponse !== prevProps.deleteResponse &&
      this.props.deleteResponse === 'success' &&
      this.state.deleteOpen === true
    ) {
      this.setState({
        deleteOpen: false,
        deleteContainer: null,
      });
      const { setDeleteResponse } = this.actions;
      setDeleteResponse(null);
    }
  }

  shouldRevealImage(container) {
    const hasArticles = container.articles.length > 0;
    const hasAttachment =
      hasArticles && container.articles.at(-1).attachments.length > 0;
    const imgRegex =
      /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*\.(jpg|jpeg|png|gif|bmp|svg|webp)(\?[^\s]*)?$/;
    const isAttachmentImage =
      hasAttachment &&
      new RegExp(imgRegex).test(
        container.articles.at(-1).attachments.at(-1).file
      );

    return isAttachmentImage;
  }

  render() {
    const {
      setLinkedArea,
      setEquipmentInDetail,
      resetFilter,
      changeFilter,
      setFiltersForm,
      resetFiltersForm,
      download,
      deleteOccurence,
    } = this.actions;

    const {
      areasList,
      linkedArea,
      filteredEquipments,
      equipmentInDetail,
      maintenancesList,
      filter,
      t,
      filtersForm,
      responsiveHandlers,
      loadingDownload,
      filteredEquipmentsNumber,
      searchUserResults,
      searchUserLoading,
      searchAreaResults,
      searchAreaLoading,
      user,
      isDeleting,
    } = this.props;

    const {
      transitionEnded,
      filtersWidth,
      inputWidth,
      triggerSet,
      changedForm,
      dialogOpen,
      dialogContainer,
      deleteOpen,
      deleteContainer,
    } = this.state;
    const columnsToMap = columns;

    const areaEquipments = filteredEquipments
      ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow gutter='20'>
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: '0 0px auto' }} separator='/'>
                <Breadcrumb.Item>
                  <span
                    onClick={() => {
                      setLinkedArea(null);
                    }}
                  >
                    {t('occurrences.title')}
                  </span>
                </Breadcrumb.Item>
                {linkedArea && (
                  <Breadcrumb.Item>
                    <span onClick={() => setEquipmentInDetail(null)}>
                      {linkedArea.name}
                    </span>
                  </Breadcrumb.Item>
                )}
                {equipmentInDetail && (
                  <Breadcrumb.Item>{equipmentInDetail.name}</Breadcrumb.Item>
                )}
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float='left'
              justifyContent={'flex-end'}
              paddingLeft={'14px'}
              paddingRight={0}
            >
              {user != null && user.isAdmin && (
                <React.Fragment>
                  <StyledSelect
                    width={inputWidth}
                    placeholder={t('area_input_placeholder')}
                    value={filter.container__area__name}
                    onChange={(event) =>
                      changeFilter('container__area__name', event)
                    }
                    remoteMethod={this.areaAutoCompleteFetch}
                    options={searchAreaResults}
                    loading={searchAreaLoading}
                    remote={true}
                    clearable={true}
                    disabled={linkedArea != null}
                  ></StyledSelect>

                  <StyledSelect
                    width={inputWidth}
                    placeholder={t('user_input_placeholder')}
                    onChange={(event) => changeFilter('search_user', event)}
                    remoteMethod={this.userAutoCompleteFetch}
                    value={filter.search_user}
                    options={searchUserResults}
                    loading={searchUserLoading}
                    filterable={true}
                    remote={true}
                    clearable={true}
                  ></StyledSelect>
                </React.Fragment>
              )}
              {changedForm != null && (
                <ToggleFilter
                  right={0}
                  text={getIntervalText(changedForm, t)}
                  variableToWatch={filtersForm}
                  zIndex={1001}
                >
                  <IntervalPicker
                    form={filtersForm}
                    intervalOptions={intervalOptions}
                    replaceForm={setFiltersForm}
                    resetForm={resetFiltersForm}
                    defaultForm={INTERVAL_FORM_DEFAULTS}
                    triggerSet={triggerSet}
                    resetTriggerSet={() => this.setState({ triggerSet: false })}
                    state={{ changedForm }}
                    setState={this.setState}
                  />
                </ToggleFilter>
              )}

              <ButtonWrapper>
                <StyledButton
                  margin={'0'}
                  onClick={() => {
                    resetFilter();
                    setLinkedArea(null);
                  }}
                >
                  {t('clear_filters')}
                </StyledButton>
                <StyledButton
                  type={'primary'}
                  margin={'0'}
                  onClick={() => this.setState({ triggerSet: true })}
                >
                  {t('apply')}
                </StyledButton>
                {user != null && user.isAdmin && (
                  <ToggleFilter
                    order={10}
                    right={0}
                    text={
                      <div>
                        {!loadingDownload ? 'Download' : <DownloadLoading />}
                      </div>
                    }
                    type={'button'}
                    loadingDownload={loadingDownload}
                  >
                    <DownloadDropDownContainer
                      download={download}
                    ></DownloadDropDownContainer>
                  </ToggleFilter>
                )}
              </ButtonWrapper>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <FirstRow
          style={{ marginBottom: '24px', marginTop: '24px' }}
          gutter='15'
        >
          <MapWrapper
            paddingLeft='0px'
            paddingRight='0px'
            ref={(elem) => (this._mapContainer = elem)}
            span={'24'}
          >
            <ContainerMapMaintenances
              areas={areasList}
              linkedArea={linkedArea}
              setLinkedArea={(area) => setLinkedArea(area)}
              areaEquipments={areaEquipments}
              globalNumberOfContainers={filteredEquipmentsNumber}
              equipmentInDetail={equipmentInDetail}
              setEquipmentInDetail={(equipment) => {
                this.setState({
                  transitionEnded: false,
                });
                setEquipmentInDetail(equipment);
              }}
              transitionEnded={transitionEnded}
            />
          </MapWrapper>
        </FirstRow>

        <CustomLayoutSecondaryRow
          gutter='20'
          height={'calc(55vh - 64px - 36px - 48px - 4rem)'}
          minHeight={'320px'}
        >
          <div ref={this.tableRef}>
            <NoPaddingCol span={'24'}>
              <TableName>{t('occurrences.title')}</TableName>
              {responsiveHandlers.isMobile || responsiveHandlers.isTablet ? (
                <StyledMobileTable
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({ ...equipment }))
                      : []
                  }
                  template={OccurrencesTemplate}
                  user={user}
                  active={(row) =>
                    equipmentInDetail &&
                    row.container &&
                    equipmentInDetail.id === row.container.id
                  }
                  onRowClick={(entrance) => {
                    window.scrollTo({
                      left: 0,
                      top: 0,
                      behavior: 'smooth',
                    });
                    this.setState({
                      transitionEnded: false,
                    });

                    setEquipmentInDetail(entrance.container);
                  }}
                />
              ) : (
                <StyledTable
                  style={{ marginTop: '.5rem', minHeight: '100%' }}
                  height={
                    responsiveHandlers.height > 768
                      ? 'calc(55vh - 64px - 36px - 48px - 4rem - 42px - 1px )'
                      : ''
                  }
                  defaultSort={{ prop: 'startDate', order: 'descending' }}
                  columns={columnsToMap.map((column) => ({ t, ...column }))}
                  data={
                    maintenancesList
                      ? maintenancesList.map((equipment) => ({
                          t,
                          user,
                          seeMore: this.seeMore,
                          deleteOccurence: this.setDeleteOccurence,
                          ...equipment,
                        }))
                      : []
                  }
                  rowClassName={(row) => {
                    let toReturn = '';
                    if (
                      equipmentInDetail &&
                      row.container &&
                      equipmentInDetail.id === row.container.id
                    ) {
                      toReturn += ' active';
                    }
                    if (row.status === 'open') {
                      toReturn += ' error';
                    }
                    return toReturn;
                  }}
                  onRowClick={(entrance) => {
                    this.setState({
                      transitionEnded: false,
                    });

                    setEquipmentInDetail(entrance.container);
                  }}
                />
              )}
            </NoPaddingCol>
          </div>
        </CustomLayoutSecondaryRow>

        {deleteOpen && deleteContainer != null && (
          <Dialog
            visible={deleteOpen}
            onCancel={() => {
              this.setState({
                deleteOpen: false,
                deleteContainer: null,
              });
            }}
            size={'medium'}
            title={t('occurrences.delete_title')}
          >
            {t('occurrences.delete_description')}
            <DialogActions>
              <StyledButton
                onClick={() =>
                  this.setState({
                    deleteOpen: false,
                    deleteContainer: null,
                  })
                }
                disabled={isDeleting}
                type='seconday'
              >
                {t('cancel')}
              </StyledButton>
              <StyledButton
                type='primary'
                disabled={isDeleting}
                onClick={() => deleteOccurence(deleteContainer.id)}
              >
                {t('delete')}
              </StyledButton>
            </DialogActions>
          </Dialog>
        )}

        {dialogOpen && dialogContainer != null && (
          <Dialog
            visible={dialogOpen}
            onCancel={() => {
              this.setState({
                dialogOpen: false,
                dialogContainer: null,
              });
            }}
            title={``}
            size={'large'}
          >
            <DialogBodyWrapper>
              <DialogDetails>
                <DialogTitle>{`${t('table.occurrence')} #${
                  dialogContainer.id
                }`}</DialogTitle>
                <DialogItem hasDate>
                  <DialogLabel>
                    {t('created_by')}{' '}
                    <b>{dialogContainer.email ? dialogContainer.email : '-'}</b>
                  </DialogLabel>
                  <DialogDescription isDate>
                    {moment(dialogContainer.createdAt).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </DialogDescription>
                </DialogItem>
                <DialogItem>
                  <DialogLabel>{t('table.container')}</DialogLabel>
                  <DialogDescription>
                    {dialogContainer.container
                      ? dialogContainer.container
                      : '-'}
                  </DialogDescription>
                </DialogItem>
                <DialogItem>
                  <DialogLabel>{t('table.title')}</DialogLabel>
                  <DialogDescription>{dialogContainer.title}</DialogDescription>
                </DialogItem>
                {dialogContainer.articles.length > 0 && (
                  <DialogItem>
                    <DialogLabel>{t('table.description')}</DialogLabel>
                    <DialogDescription>
                      {dialogContainer.articles.at(-1).message}
                    </DialogDescription>
                  </DialogItem>
                )}
              </DialogDetails>
              {this.shouldRevealImage(dialogContainer) && (
                <DialogImage
                  src={dialogContainer.articles.at(-1).attachments.at(-1).file}
                ></DialogImage>
              )}
            </DialogBodyWrapper>
          </Dialog>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Occurrences)));

const Wrapper = styled.div``;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  & > div {
    ${({ theme }) => theme.smallDesktop`
      height: 100%;
  `}
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
  transition: width 0.4s;
  overflow: visible;
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;
    }
  `}
`;
const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`

  `}

  ${({ theme }) => theme.desktop`
    &.el-col-20{
        display: flex;
        justify-content: flex-end;
        width: 83.33333%;
        padding-left: 14px !important;
      }
  `}
`;

const SelectivePaddingColNoFixedHeightWithBoxShadow = styled(
  SelectivePaddingColNoFixedHeight
)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding: ${(props) => props.padding} !important;
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;

  &.el-col-12 {
    width: 100%;
    padding: 0 !important;
    height: calc(100% - 3rem);
  }

  ${({ theme }) => theme.smallDesktop`
   &.el-col-12{
     width: 50%;
    padding: ${(props) => props.padding} !important;
    padding-left: ${(props) => props.paddingLeft} !important;
    padding-right: ${(props) => props.paddingRight} !important;
  }
  `}
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: span 2;
  justify-content: flex-end;

  ${({ theme }) => theme.desktop`
    display flex;
  `}
`;

const FirstRow = styled(CustomLayoutSecondaryRow)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const MapWrapper = styled(SelectivePaddingColNoFixedHeightWithBoxShadow)`
  &.el-col-22 {
    width: 100%;
  }
  ${({ theme }) => theme.smallDesktop`
  &.el-col-22{
    width: 83.33333%;
  }
  `}
  ${({ theme }) => theme.desktop`
  &.el-col-22{
    width: 91.66667%;
  }
  `}
`;

const DownloadLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;
const DialogBodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;
`;

const DialogDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 200px;
  row-gap: 24px;
`;

const DialogImage = styled.img`
  height: auto;
  width: min(100%, 400px);
  object-fit: contain;
  margin: 0 auto;
`;

const DialogTitle = styled.h4`
  margin: 0;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  grid-row: 1/2;
  grid-column: 1/2;

  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};
`;

const DialogItem = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 8px;
  grid-row: span 1;
  grid-column: 1/2;

  ${(props) =>
    props.hasDate &&
    `
    row-gap: 6px;
  `}
`;

const DialogLabel = styled.label`
  margin: 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};

  b {
    color: ${Theme.primaryColor};
  }
`;

const DialogDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${Theme.textonSecondary};
  font-family: ${Theme.fontFamily};

  ${(props) =>
    props.isDate &&
    `
      font-size: 14px;
      line-hegith: 20px;
      color: ${Theme.darkPrimaryColorOpacity}
    `};
`;

const DialogActions = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 16px;
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;
