import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.22 4.216A.72.72 0 012.74 4h11.852c.201 0 .375.072.521.216.147.144.22.314.22.511V6.91h1.852c.209 0 .434.05.677.148.243.098.438.22.585.363l2.291 2.25c.1.099.188.212.26.341.074.129.128.245.163.347.035.102.06.242.075.42s.025.31.03.393c.003.083.003.227 0 .431-.005.205-.007.338-.007.398v3.636c.2 0 .374.072.521.216.147.144.22.315.22.512a.898.898 0 01-.046.3.43.43 0 01-.157.211 3.764 3.764 0 01-.19.13.648.648 0 01-.273.069l-.26.023a3.855 3.855 0 01-.295 0 18.42 18.42 0 00-.26-.006h-.741c0 .803-.29 1.488-.868 2.057a2.881 2.881 0 01-2.095.852 2.881 2.881 0 01-2.095-.852 2.777 2.777 0 01-.868-2.057H9.407c0 .803-.29 1.488-.868 2.057A2.881 2.881 0 016.444 20a2.881 2.881 0 01-2.094-.852 2.777 2.777 0 01-.868-2.057l-.26.006a3.86 3.86 0 01-.296 0 54.706 54.706 0 01-.26-.023.646.646 0 01-.272-.068 3.606 3.606 0 01-.191-.131.43.43 0 01-.157-.21.89.89 0 01-.046-.301V4.727c0-.197.073-.367.22-.511zm13.553 13.898c.293.287.64.432 1.042.432.401 0 .748-.144 1.042-.432.293-.288.44-.629.44-1.023 0-.394-.147-.735-.44-1.023a1.437 1.437 0 00-1.042-.432c-.401 0-.749.144-1.042.432-.293.288-.44.63-.44 1.023 0 .394.147.735.44 1.023zm-.44-6.841h4.445v-.341a.337.337 0 00-.104-.25l-2.257-2.216a.35.35 0 00-.255-.102h-1.829v2.909zm-9.93 6.84c.293.288.64.433 1.041.433.402 0 .75-.144 1.042-.432.293-.288.44-.629.44-1.023 0-.394-.147-.735-.44-1.023a1.438 1.438 0 00-1.042-.432c-.4 0-.748.144-1.041.432-.293.288-.44.63-.44 1.023 0 .394.146.735.44 1.023z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
