import moment from "moment";


export const mapLabelsAndData = (data, start, end, type, i18n) => {
  let labels = [];
  let dataValues = [];
  let labelsKeys = [""];
  let currentYearData = {};
  let format = "DD/MM/YY";
  let total = 0;
  switch (type) {
    case "day":
      format = "DD/MM/YY";
      break;
    case "month":
      format = "MM/YYYY";
      break;
    case "year":
      format = "YYYY";
      break;
    default:
      format = "DD/MM/YY";
      break;
  }

  let startTime = start;
  let endTime = end;
  while (startTime.isSameOrBefore(endTime, type)) {
    const toJoin = [moment(startTime).format(format)];
    toJoin.forEach((el) => (currentYearData[el] = 0));
    labels = [...labels, ...toJoin];
    startTime.add(1, type);
  }
  data.forEach((value) => {
    currentYearData[moment(value.dateTime).format(format)] += Number(
      value.count
    );
    total += value.count;
  });

  dataValues = [Object.values(currentYearData)];

  return { labels, data: dataValues, labelsKeys, total };
};
