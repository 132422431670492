import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path fill={props.fill} d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42z" />
    </svg>
  )
}

export default SvgComponent
