/**
 * Sidebar Container
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled from "styled-components";
import SidebarSection from "containers/SidebarSection";

import SECTIONS from "sections";
import { withRouter } from "react-router-dom";
import posed from "react-pose";
import { Theme } from "globalStyles";

import { isMobile } from "react-device-detect";

import {  ADDITIONAL_FEATURES } from "config";

class Sidebar extends Component {
  state = {
    activeSection: "",
    open: false,
  };

  myRef = React.createRef();

  procClick = (name) => {
    this.setState({ activeSection: name });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeSection !== this.state.activeSection) {
      this.setState({ open: false });
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  render() {
    const { open } = this.state;

    
    return (
      <WrapperPosed
        onMouseEnter={() => !isMobile && this.setState({ open: true })}
        onMouseLeave={() => !isMobile && this.setState({ open: false })}
        onClick={() => isMobile && this.handleClick()}
        pose={open ? "open" : "closed"}
        ref={this.setWrapperRef}
      >
        <FlexColumns>
          <SectionsWrapper>
            {SECTIONS.filter((sec) => {
              if (
                !ADDITIONAL_FEATURES.includes("trader_switch") &&
                this.props.user.isTrader && sec.key !== "transactions"
              ) {
                return null;
              }
              if(!ADDITIONAL_FEATURES.includes(sec.key)){
                return null
              }

              if (
                ADDITIONAL_FEATURES.includes(sec.key) && 
                sec.admin_only &&
                this.props.user &&
                !this.props.user.isAdmin
              ) {
                return null;
              }

              return sec;
            }).map((section, index) => (
              <SidebarSection
                {...section}
                {...this.props}
                active={this.props.location.pathname === section.path}
                key={`section${index}`}
                procClick={this.procClick}
              ></SidebarSection>
            ))}
          </SectionsWrapper>
        </FlexColumns>
      </WrapperPosed>
    );
  }
}

export default withRouter(Logic(Sidebar));

const Wrapper = styled.div`
  width: calc(3rem + 15px);
  height: calc(100% - 4rem);
  position: fixed;
  bottom: 0;
  background: ${Theme.primaryColor};
  z-index: 10000001;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 0.5rem;
`;

const WrapperPosed = posed(Wrapper)({
  open: {
    width: "256px", //atrofia com max-content,
    transition: { duration: 225 },
  },
  closed: {
    width: "calc(3rem + 15px)",
    transition: { duration: 225 },
  },
});

const SectionsWrapper = styled.div`
  transition: all 0.5s;
`;
const FlexColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
