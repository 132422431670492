import React from "react";
import styled from "styled-components";
import StyledDatePicker from "components/StyledDatePicker";
import moment from "moment";
import { withTranslation } from "react-i18next";

const getModifiedEndDate = (interval, startDate) => {
  let endDate = moment(startDate);

  switch (interval) {
    case "year":
      endDate = moment(endDate).endOf("year").isSameOrBefore(moment())
        ? moment(endDate).endOf("year")
        : moment();
      break;
    case "month":
      endDate = moment(endDate).endOf("month").isSameOrBefore(moment())
        ? moment(endDate).endOf("month")
        : moment();
      break;
    case "day":
      break;
    default:
      endDate = moment(endDate).endOf("month").isSameOrBefore(moment())
        ? moment(endDate).endOf("month")
        : moment();
      break;
  }
  return endDate;
};
const getModifiedStartDate = (interval, startDate) => {
  let modifiedStartDate = moment(startDate);

  switch (interval) {
    case "year":
      modifiedStartDate.startOf("year");
      break;
    case "month":
      modifiedStartDate.startOf("month");
      break;
    case "day":
      break;
    default:
      modifiedStartDate.startOf("month");
      break;
  }
  return modifiedStartDate;
};

const datePicker = (start, end, interval, onChangeFunction, changeBoth) => {
  let tempFormat = "YYYY";
  let timeFormat = false;
  let viewMode = "years";

  switch (interval) {
    case "year":
      tempFormat = "YYYY";
      viewMode = "years";
      break;
    case "month":
      tempFormat = "MM/YYYY";
      viewMode = "months";
      break;
    case "day":
      tempFormat = "DD/MM/YYYY";
      viewMode = "days";
      break;
    default:
      tempFormat = "YYYY";
      viewMode = "years";
      break;
  }

  return (
    <React.Fragment>
      <StyledDatePicker
        inputProps={{ disabled: true }}
        isValidDate={(currentDate, selectedDate) =>
          moment(moment(currentDate)).isSameOrBefore(
            moment(moment(end.value))
          ) && currentDate.isSameOrBefore(moment())
        }
        dateFormat={tempFormat}
        timeFormat={timeFormat}
        value={start.value}
        viewMode={viewMode}
        open={true}
        onChange={(value) => {
          if (end.value.isBefore(value)) {
            changeBoth(interval, value);
          } else {
            onChangeFunction("start", value);
          }
        }}
      />
      <StyledDatePicker
        inputProps={{ disabled: true }}
        isValidDate={(currentDate, selectedDate) =>
          currentDate.isSameOrAfter(start.value) &&
          currentDate.isSameOrBefore(moment())
        }
        dateFormat={tempFormat}
        timeFormat={timeFormat}
        viewMode={viewMode}
        open={true}
        value={moment(end.value).startOf(interval)}
        onChange={(value) => {
          onChangeFunction("end", value);
        }}
      />
    </React.Fragment>
  );
};

class TimeFramePicker extends React.Component {

  modifyFormValue = (key, value) => {
    this.props.setState({
      changedForm: {
        ...this.props.state.changedForm,
        [key]: { value: value },
        end: {
          value:
            key === "end"
              ? value
              : moment(this.props.state.changedForm.end.value).startOf(
                  this.props.state.changedForm.interval.value
                ),
        },
      },
    });
  };

  modifyBoth = (interval, value) => {
    this.props.setState({
      changedForm: {
        ...this.props.state.changedForm,
        start: { value: value },
        end: { value: moment(value).startOf(interval) },
      },
    });
  };

  modifyIntervalValue = (value) => {
    this.props.setState({
      changedForm: {
        ...this.props.state.changedForm,
        interval: { value: value },
        end: {
          value: moment(this.props.state.changedForm.start.value).startOf(value),
        },
        start: {
          value: getModifiedStartDate(
            value,
            this.props.state.changedForm.start.value
          ),
        },
      },
    });
  };

  componentDidMount() {
    this.props.setState({ changedForm: { ...this.props.form } });
  }
  

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.form !== this.props.form) {
      this.props.setState({ changedForm: { ...this.props.form } });
    }
    
    if (
      oldProps.triggerSet !== this.props.triggerSet &&
      this.props.triggerSet
      ) {
      const { resetTriggerSet, replaceForm } = this.props;
      const { changedForm } = this.props.state;
      replaceForm({
        ...changedForm,
        end: {
          value: getModifiedEndDate(
            changedForm.interval.value,
            changedForm.end.value
          ),
        },
      });
      resetTriggerSet();
    }
  }
  

  render() {
    const { intervalOptions, t } = this.props;

    const { changedForm } = this.props.state;

    return (
      <Wrapper>
        <ColumnFlexer>
          <TimeOptionsWrapper>
            <InfoText>{t("choose_interval")}:</InfoText>
            {intervalOptions.map((el) => (
              <OptionSpan
                key={"span" + el.label}
                selected={changedForm.interval.value === el.value}
                onClick={() => this.modifyIntervalValue(el.value)}
              >
                {t(el.label)}
              </OptionSpan>
            ))}
          </TimeOptionsWrapper>
        </ColumnFlexer>

        {datePicker(
          changedForm.start,
          changedForm.end,
          changedForm.interval.value,
          this.modifyFormValue,
          this.modifyBoth
        )}
      </Wrapper>
    );
  }
}

export default withTranslation()(TimeFramePicker);

const ColumnFlexer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  padding: 20px;

  ${({ theme }) => theme.smallDesktop`
  flex-direction: row;

  `}
`;

const TimeOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  padding: 1rem;
  width: 200px;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    margin: 15px 30px;
  `}
`;

const InfoText = styled.span`
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 10px;

  grid-column: span 3;
`;

const OptionSpan = styled.span`
  color: ${(props) => (props.selected ? props.theme.white : props.theme.black)};
  background-color: ${(props) => props.selected && props.theme.secondaryColor};
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  padding: 5px;
  cursor: ${(props) => (props.selected ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.secondaryColorHover};
  }
`;
