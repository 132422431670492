import React from 'react';
import { kea } from 'kea';
import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { BASE_API_URL, API_DATE_FORMAT } from 'config';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { decodeColor } from 'utils';
import { toast } from 'react-toastify';
import NotificationsWrapper from 'components/NotificationsWrapper';
import AppLogic from 'containers/App/logic';

const EVENTSURL = BASE_API_URL + 'events/';
const MAINTENANCESURL = BASE_API_URL + 'containers/maintenances/';
const AREASURL = BASE_API_URL + 'areas/';
const EQUIPMENTSSURL = BASE_API_URL + 'containers/';

const getContainerByUUID = (uuid, array) => {
  if (uuid != null && array != null) {
    return array.find((el) => {
      return el.uuid === uuid;
    });
  }
  return null;
};

const getAreaByName = (name, array) => {
  if (name != null && array != null) {
    return array.find((el) => {
      return el.name === name;
    });
  }
  return null;
};

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'Containers'],

  connect: {
    props: [],
    actions: [
      AppLogic,
      ['watchEvents', 'changeWatchingEvents', 'setWebSocketHanlder'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    fetchAreasEquipments: () => true,

    setHistoryList: (list) => ({ list }),
    setHistoryNumber: (number) => ({ number }),

    fetchEvents: () => true,
    setEventsList: (list) => ({ list }),
    setEventsNumber: (number) => ({ number }),

    fetchMaintenances: () => true,
    setMaintenancesList: (list) => ({ list }),
    setMaintenancesNumber: (number) => ({ number }),

    fetchOccurrences: () => true,
    setOccurrencesList: (list) => ({ list }),
    setOccurrencesNumber: (number) => ({ number }),

    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),

    fetchAreasList: () => true,
    setAreasList: (list) => ({ list }),
    setAreasNumber: (number) => ({ number }),
    setLoadingAreas: (loading) => loading,

    setLinkedArea: (area) => ({ area, type: 'container', query: '' }),
    setFilteredEquipments: (equipments) => ({ equipments }),
    updateFilteredEquipments: (equipments) => ({ equipments }),
    setFilteredEquipmentsNumber: (number) => ({ number }),

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,
    setPagination: (pag) => ({ pag }),

    setEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetail: (equipment) => ({
      equipment,
      type: 'container',
      query: '',
    }),

    setUsingMonthCalendar: (value) => ({ value }),
    setShowCalendarFilter: (value) => ({ value }),

    setSearchResults: (results) => ({ results }),
    setAreaSearchResults: (results) => ({ results }),

    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payload) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.resetFilter]: (state, payload) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    historyList: [
      [],
      PropTypes.array,
      {
        [actions.setHistoryList]: (state, { list }) => list,
        [actions.resetFilter]: () => null,
      },
    ],

    historyCount: [
      0,
      PropTypes.number,
      {
        [actions.setHitoryNumber]: (state, { number }) => number,
      },
    ],

    eventsList: [
      [],
      PropTypes.array,
      {
        [actions.setEventsList]: (state, { list }) => list,
      },
    ],

    eventsCount: [
      0,
      PropTypes.number,
      {
        [actions.setDepositsNumber]: (state, { number }) => number,
      },
    ],

    maintenancesList: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenancesList]: (state, { list }) => list,
      },
    ],

    occurrencesList: [
      [],
      PropTypes.array,
      {
        [actions.setOccurrencesList]: (state, { list }) => list,
      },
    ],

    occurrencesCount: [
      0,
      PropTypes.number,
      {
        [actions.setOccurrencesNumber]: (state, { number }) => number,
      },
    ],

    pagination: [
      { page: 1, page_size: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFetchedEquipmentInDetail]: () => ({
          page: 1,
          page_size: 10,
        }),
        [actions.setFiltersForm]: () => ({ page: 1, page_size: 10 }),
      },
    ],

    linkedArea: [
      null,
      PropTypes.object,
      {
        [actions.setLinkedArea]: (state, { area }) => area,
      },
    ],

    filter: [
      {
        container__area__name: '',
        container__uuid: '',
        fill_level: '',
        complete: '',
      },
      PropTypes.object,
      {
        [actions.setFilter]: (state, { filter }) => filter,
        [actions.changeFilter]: (state, payload) => {
          return Object.assign({}, state, { [payload.key]: payload.value });
        },
        [actions.setLinkedArea]: (state, { area }) => ({
          ...state,
          container__area__name: area != null && area.name ? area.name : '',
        }),
        [actions.setEquipmentInDetail]: (state, { equipment }) => ({
          ...state,
          container__uuid:
            equipment != null && equipment.uuid ? equipment.uuid : '',
        }),
        [actions.resetFilter]: () => ({
          container__area__name: '',
          container__uuid: '',
          fill_level: '',
          complete: '',
        }),
      },
    ],

    fillLevelFilterOptions: [
      [
        {
          value: 'less',
          label: 'x < 25%',
        },
        {
          value: 'between',
          label: '25% < x < 50%',
        },
        {
          value: 'between_2',
          label: '50% < x < 75%',
        },
        {
          value: 'greater',
          label: '75% < x',
        },
      ],
      PropTypes.array,
      {},
    ],

    areasList: [
      [],
      PropTypes.array,
      {
        [actions.setAreasList]: (state, { list }) => list,
      },
    ],

    loadingAreas: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingAreas]: (state, loading) => loading,
      },
    ],

    filteredEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setFilteredEquipments]: (state, { equipments }) => equipments,
        [actions.updateFilteredEquipments]: (state, { equipments }) => [
          ...state,
          ...equipments,
        ],
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
      },
    ],

    filteredEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setFilteredEquipmentsNumber]: (state, { number }) => number,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setFetchedEquipmentInDetail]: (state, { equipment }) =>
          equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
        [actions.resetFilter]: () => null,
      },
    ],

    usingMonthCalendar: [
      true,
      PropTypes.bool,
      {
        [actions.setUsingMonthCalendar]: (state, { value }) => value,
      },
    ],

    showCalendarFilter: [
      false,
      PropTypes.bool,
      {
        [actions.setShowCalendarFilter]: (state, { value }) => value,
      },
    ],

    searchResults: [
      [],
      PropTypes.array,
      {
        [actions.setSearchResults]: (state, { results }) => results,
      },
    ],

    searchLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'container',
        [actions.setSearchResults]: () => false,
      },
    ],

    searchAreaResults: [
      [],
      PropTypes.array,
      {
        [actions.setAreaSearchResults]: (state, { results }) => results,
      },
    ],

    searchAreaLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'area',
        [actions.setAreaSearchResults]: () => false,
      },
    ],
  }),

  start: function* () {
    const {
      fetchAreasList,
      fetchAreasEquipments,
      setWebSocketHanlder,
      watchEvents,
    } = this.actions;

    yield put(fetchAreasList());
    yield put(fetchAreasEquipments());

    yield put(setWebSocketHanlder(this.workers.handle));

    yield put(watchEvents());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,

    [actions.fetchAreasList]: workers.fetchAreasList,

    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,

    [actions.setLinkedArea]: [
      workers.showOnlyOneArea,
      workers.fetchAreasEquipments,
    ],

    [actions.setEquipmentInDetail]: [
      workers.fetchEquipmentInDetail,
      workers.fetchEvents,
      workers.fetchMaintenances,
      workers.fetchOccurrences,
    ],
    [actions.setFetchedEquipmentInDetail]: [
      workers.fetchEvents,
      workers.fetchMaintenances,
    ],

    [actions.setEventsList]: workers.fetchHistory,

    [actions.setMaintenancesList]: workers.fetchHistory,

    [actions.filterEntrances]: [workers.fetchEvents, workers.fetchMaintenances],

    [actions.resetFilter]: [workers.fetchEvents, workers.fetchMaintenances],

    [actions.setFiltersForm]: [
      workers.fetchEvents,
      workers.fetchMaintenances,
      workers.fetchAreasEquipments,
    ],
  }),

  workers: {
    *showOnlyOneArea(action) {
      const { setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');
      const linkedArea = yield this.get('linkedArea');
      if (areasList.length === 1 && linkedArea !== areasList[0]) {
        yield delay(300);
        yield put(setLinkedArea(areasList[0]));
      }
    },

    *fetchAreasList(action) {
      const {
        setAreasList,
        setLinkedArea,
        setAreaSearchResults,
        setAreasNumber,
      } = this.actions;

      try {
        const response = yield call(axios.get, AREASURL);
        const areasRes = response.data.results;

        if (areasRes.length) {
          // add data to area filter
          const searchAreaArr = areasRes.map((area) => ({
            value: area.name,
            label: area.name,
          }));
          yield put(setAreaSearchResults(searchAreaArr));

          // set up areas and prepare polygons
          let results = areasRes.map((area) => {
            area.lines = {
              type: 'LineString',
              coordinates: area.polygon.coordinates[0],
            };
            return area;
          });
          yield put(setAreasList(results));
          yield put(setAreasNumber(areasRes.length));

          // if there is only one area, focus it
          if (results.length === 1) {
            yield delay(300);
            yield put(setLinkedArea(results[0]));
          }
        } else {
          yield put(setAreasList([]));
          yield put(setAreasNumber(0));
        }
      } catch (error) {
        yield put(setAreasList([]));
        yield put(setAreasNumber(0));
        yield put(setAreaSearchResults([]));
      }
    },

    *fetchAreasEquipments() {
      yield delay(600);
      const linkedArea = yield this.get('linkedArea');
      const filter = yield this.get('filter');

      const {
        setFilteredEquipments,
        setSearchResults,
        setFilteredEquipmentsNumber,
      } = this.actions;

      const installationStatus = ['assembled', 'installed'];

      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            fill_level__gte: 25,
            fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            fill_level__gte: 50,
            fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      try {
        let next = 1;
        const commonParams = {
          ...fillLevel,
          page_size: 500,
        };

        let results = [];
        while (next) {
          let params = {
            ...commonParams,
            page: next,
          };

          if (linkedArea) params = { ...params, area__id: linkedArea.id };

          let containers = [];

          const responses = yield installationStatus.map((status) => {
            params = { ...params, installation_status: status };
            return call(axios.get, EQUIPMENTSSURL, { params });
          });

          responses.forEach((el) =>
            containers.push.apply(containers, el.data.results)
          );

          containers.map((container) => {
            container.color = decodeColor(container.type);
            results.push(container);
            return container;
          });

          const equipmentsList = results.map((el) => ({
            value: el.uuid,
            label: el.name,
          }));

          next = 0;

          yield put(setFilteredEquipments(results));
          yield put(setFilteredEquipmentsNumber(results.length));
          yield put(setSearchResults(equipmentsList));
        }
      } catch (error) {
        yield put(setFilteredEquipments([]));
        yield put(setFilteredEquipmentsNumber(0));
        yield put(setSearchResults([]));
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const { setFetchedEquipmentInDetailNoFetchs } = this.actions;

      try {
        const { data } = yield call(
          axios.get,
          EQUIPMENTSSURL + `${action.payload.equipment.id}/`
        );
        data.color = decodeColor(data.type);
        yield put(setFetchedEquipmentInDetailNoFetchs(data));
      } catch (error) {
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
    },

    *fetchEquipmentInDetail(action) {
      yield delay(300);

      const { setFetchedEquipmentInDetail, setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');

      if (
        action.payload &&
        action.payload.equipment &&
        action.payload.equipment.id
      ) {
        try {
          const { data } = yield call(
            axios.get,
            EQUIPMENTSSURL + `${action.payload.equipment.id}/`
          );
          data.color = decodeColor(data.type);
          const equipmentArea = areasList.find((el) => el.id === data.area.id);

          if (equipmentArea != null) {
            yield put(setLinkedArea(equipmentArea));
          }
          yield put(setFetchedEquipmentInDetail(data));
        } catch (error) {
          yield put(setFetchedEquipmentInDetail(null));
        }
      } else {
        yield put(setFetchedEquipmentInDetail(null));
      }
    },

    *fetchHistory() {
      yield delay(500);
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const events = yield this.get('eventsList');
      const maintenances = yield this.get('maintenancesList');

      const { setHistoryList, setHistoryNumber } = this.actions;

      if (events && maintenances) {
        let historyList = [];

        for (let event of events) {
          let aux = {
            address: equipmentInDetail.address,
            type: event.type,
            fillLevel:
              event.type === 'pick'
                ? event.previousFillLevel
                : event.containerFillLevel,
            date: event.datetime,
            user: event.tag.user
              ? event.tag.user.fullName
                ? event.tag.user.fullName
                : '-'
              : '-',
          };

          historyList.push(aux);
        }

        for (let maintenance of maintenances) {
          let aux = {
            address: equipmentInDetail.address,
            type: 'maintenance',
            fillLevel: maintenance.container.fillLevel,
            date: maintenance.datetime,
            user: maintenance.maintainer
              ? maintenance.maintainer.fullName
                ? maintenance.maintainer.fullName
                : '-'
              : '-',
          };

          historyList.push(aux);
        }

        let sortedArr = historyList.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        yield put(setHistoryList(sortedArr));
        yield put(setHistoryNumber(historyList.length));
      } else {
        yield put(setHistoryList(null));
        yield put(setHistoryNumber(0));
      }
    },

    *fetchEvents() {
      yield delay(500);
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const linkedArea = yield this.get('linkedArea');
      const areasList = yield this.get('areasList');

      let filter = yield this.get('filter');
      const filtersForm = yield this.get('filtersForm');
      const filteredEquipments = yield this.get('filteredEquipments');

      const {
        setEventsList,
        setEventsNumber,
        setEquipmentInDetail,
        setLinkedArea,
      } = this.actions;

      if (
        filter.container__area__name != null &&
        filter.container__area__name !== ''
      ) {
        if (
          getAreaByName(filter.container__area__name, areasList) != null &&
          (linkedArea == null ||
            (linkedArea != null &&
              linkedArea.name !== filter.container__area__name))
        ) {
          yield put(
            setLinkedArea(
              getAreaByName(filter.container__area__name, areasList)
            )
          );
        }
      }

      if (filter.container__uuid != null && filter.container__uuid !== '') {
        if (
          getContainerByUUID(filter.container__uuid, filteredEquipments) !=
            null &&
          (equipmentInDetail == null ||
            (equipmentInDetail != null &&
              equipmentInDetail.uuid !== filter.container__uuid))
        ) {
          yield put(
            setEquipmentInDetail(
              getContainerByUUID(filter.container__uuid, filteredEquipments)
            )
          );
        } else if (
          getContainerByUUID(filter.container__uuid, filteredEquipments) ==
            null &&
          equipmentInDetail != null
        ) {
          yield put(setEquipmentInDetail(null));
        }
      } else if (
        (filter.container__uuid == null || filter.container__uuid === '') &&
        equipmentInDetail != null
      ) {
        yield put(setEquipmentInDetail(null));
      }

      if (equipmentInDetail) {
        try {
          const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
          const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

          let fillLevel;

          switch (filter.fill_level) {
            case 'less':
              fillLevel = {
                container__fill_level__lte: 25,
              };
              break;
            case 'between':
              fillLevel = {
                container__fill_level__gte: 25,
                container__fill_level__lte: 50,
              };
              break;
            case 'between_2':
              fillLevel = {
                container__fill_level__gte: 50,
                container__fill_level__lte: 75,
              };
              break;
            case 'greater':
              fillLevel = {
                container__fill_level__gte: 75,
              };
              break;
            default:
              fillLevel = {};
          }

          const commonParams = {
            ...filter,
            ...fillLevel,
            container: equipmentInDetail.id,
            date_after: moment(date_after).toISOString(),
            date_before: moment(date_before).endOf('day').toISOString(),
            ordering: '-datetime',
          };

          let eventsRes = yield call(axios.get, EVENTSURL, {
            params: commonParams,
          });
          const events = eventsRes.data.results;

          yield put(setEventsList(events));
          yield put(setEventsNumber(events.length));
        } catch (error) {
          console.log({ error });
          yield put(setEventsList([]));
          yield put(setEventsNumber(0));
          yield put(setEquipmentInDetail(null));
        }
      } else {
        yield put(setEventsList(null));
        yield put(setEventsNumber(0));
      }
    },

    *fetchMaintenances() {
      yield delay(500);
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');

      let filter = yield this.get('filter');
      const filtersForm = yield this.get('filtersForm');
      const areasList = yield this.get('areasList');
      const filteredEquipments = yield this.get('filteredEquipments');

      const {
        setMaintenancesList,
        setMaintenancesNumber,
        setEquipmentInDetail,
        setLinkedArea,
      } = this.actions;

      if (
        filter.container__area__name != null &&
        filter.container__area__name !== ''
      ) {
        if (
          getAreaByName(filter.container__area__name, areasList) != null &&
          (linkedArea == null ||
            (linkedArea != null &&
              linkedArea.name !== filter.container__area__name))
        ) {
          yield put(
            setLinkedArea(
              getAreaByName(filter.container__area__name, areasList)
            )
          );
        }
      }

      if (filter.container__uuid != null && filter.container__uuid !== '') {
        if (
          getContainerByUUID(filter.container__uuid, filteredEquipments) !=
            null &&
          (equipmentInDetail == null ||
            (equipmentInDetail != null &&
              equipmentInDetail.uuid !== filter.container__uuid))
        ) {
          yield put(
            setEquipmentInDetail(
              getContainerByUUID(filter.container__uuid, filteredEquipments)
            )
          );
        } else if (
          getContainerByUUID(filter.container__uuid, filteredEquipments) ==
            null &&
          equipmentInDetail != null
        ) {
          yield put(setEquipmentInDetail(null));
        }
      } else if (
        (filter.container__uuid == null || filter.container__uuid === '') &&
        equipmentInDetail != null
      ) {
        yield put(setEquipmentInDetail(null));
      }

      if (equipmentInDetail) {
        try {
          const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
          const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

          const commonParams = {
            ...filter,
            container__id: equipmentInDetail.id,
            date_after: moment(date_after).toISOString(),
            date_before: moment(date_before).endOf('day').toISOString(),
            ordering: '-datetime',
          };

          let maintenancesRes = yield call(axios.get, MAINTENANCESURL, {
            params: commonParams,
          });
          const maintenances = maintenancesRes.data.results;

          yield put(setMaintenancesList(maintenances));
          yield put(setMaintenancesNumber(maintenances.length));
        } catch (error) {
          console.log(error);
          yield put(setMaintenancesList([]));
          yield put(setMaintenancesNumber(0));
          yield put(setEquipmentInDetail(null));
        }
      } else {
        yield put(setMaintenancesList(null));
        yield put(setMaintenancesNumber(0));
      }
    },

    *fetchOccurrences() {},

    *handle(message) {
      const { fetchMaintenancesList, fetchEquipmentInDetailNoFetchs } =
        this.actions;
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (message.event_type === 'status') {
        let temp = { ...message };
        const message_id =
          message.content && message.content.id ? message.content.id : null;

        toast.info(temp.message, {
          position: toast.POSITION.TOP_RIGHT,
          delay: 200,
          id: message_id ? `uniqueStatusMessage${message_id}` : null,
        });
      } else if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          const message_id =
            message.content && message.content.id ? message.content.id : null;

          if (
            message.content != null &&
            (message.content.type === 'drop' ||
              message.content.type === 'maintenance') &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueDropMessage${message_id}` : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.content &&
            message.content.type === 'pick' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniquePickMessage${message_id}` : null,
              }
            );

            yield put(fetchMaintenancesList());
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (message.content && message.content.type === 'alert') {
            toast.warn(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
                description={
                  message.content != null && message.content.alertType
                    ? message.content.alertType
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueAlertMessage${message_id}` : null,
              }
            );
          } else if (message.content && message.content.type === 'error') {
            if (message.content.status === 'closed') {
              toast.success(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content && message.content.errorType
                      ? `${message.content.errorType}`
                      : ''
                  }
                  status={
                    message.content && message.content.status
                      ? message.content.status
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            } else {
              toast.error(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content != null && message.content.errorType
                      ? message.content.errorType
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            }
          }
        }
      }
    },
  },
});
