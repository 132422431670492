/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Layout, Breadcrumb, Loading } from "element-react";
import ContainerMapMaintenances from "containers/ContainerMapMaintenances";
import { columns } from "./columns";
import { Theme } from "globalStyles";

import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import StyledTable from "components/StyledTable";
import StyledButton from "components/StyledButton";
import StyledInput from "components/StyledInput";
import Dialog from "components/Dialog";
import ResponsiveHandler from "components/ResponsiveHandler";
import NotificationsWrapper from "components/NotificationsWrapper";
import MaintenancesTemplate from "components/MaintenancesTemplate";
import ToggleFilter from "components/ToggleFilter";
import StyledSelect from "components/StyledSelect";

import IntervalPicker from "containers/IntervalPicker";
import StyledMobileTable from "containers/StyledMobileTable";
import DownloadDropDownContainer from "containers/DownloadDropDownContainer";
import { getIntervalText } from "utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];
class Maintenances extends Component {
  constructor(props) {
    super(props);
    // https://stackoverflow.com/a/61346857/15423901
    this.setState = this.setState.bind(this);
    this._autocompleteRef = null;
    this._areaAutocompleteRef = null;
  }

  state = {
    focusedInput: null,
    filtersWidth: 20,
    triggerSet: false,
    inputWidth: "140px",
    dialogOpen: false,
    dialogContainer: null,
    changedForm: null,
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  autoCompleteFetch = (queryString) => {
    if (this._autocompleteRef) {
      clearTimeout(this._autocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._autocompleteRef = setTimeout(function () {
      search(queryString, null, "container");
    }, 500);
  };

  areaAutoCompleteFetch = (queryString) => {
    if (this._areaAutocompleteRef) {
      clearTimeout(this._areaAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._areaAutocompleteRef = setTimeout(function () {
      search(queryString, null, "area");
    }, 500);
  };

  componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    if (
      prevProps.descriptionResponse !== this.props.descriptionResponse &&
      this.props.descriptionResponse != null
    ) {
      if (this.props.descriptionResponse === "success") {
        this.setState({ dialogOpen: false, dialogContainer: null });
        const { resetDescriptionForm } = this.actions;
        resetDescriptionForm();
        toast.success(
          <NotificationsWrapper
            type={"add_description"}
            title={t("table.description_success_description")}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "table-add-description-success",
          }
        );
      }
      if (this.props.descriptionResponse === "error") {
        this.setState({ dialogOpen: false, dialogContainer: null });
        const { resetDescriptionForm } = this.actions;
        resetDescriptionForm();

        toast.error(
          <NotificationsWrapper
            type={"add_description"}
            title={t("table.description_error_description")}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "table-add-description-error",
          }
        );
      }
    }
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  addDescription = (event, row) => {
    event.stopPropagation();
    this.setState({ dialogOpen: true, dialogContainer: row });
  };

  render() {
    const {
      setLinkedArea,
      setEquipmentInDetail,
      resetFilter,
      changeFilter,
      setFiltersForm,
      resetFiltersForm,
      submitDescription,
      changeDescription,
      resetDescriptionForm,
      download,
    } = this.actions;
    const {
      areasList,
      linkedArea,
      filteredEquipments,
      equipmentInDetail,
      maintenancesList,
      filter,
      t,
      filtersForm,
      descriptionForm,
      responsiveHandlers,
      loadingDownload,
      user,
      filteredEquipmentsNumber,
      searchResults,
      searchLoading,
      searchAreaResults,
      searchAreaLoading,
    } = this.props;

    const {
      filtersWidth,
      inputWidth,
      triggerSet,
      dialogOpen,
      dialogContainer,
      changedForm,
    } = this.state;

    const columnsToMap = columns;

    const areaEquipments = filteredEquipments
      ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow height="36px" display="flex" gutter="20">
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: "0px 0 auto" }} separator="/">
                <Breadcrumb.Item>
                  <span onClick={() => setLinkedArea(null)}>
                    {t("maintenances.title")}
                  </span>
                </Breadcrumb.Item>
                {linkedArea && (
                  <Breadcrumb.Item>
                    <span
                      onClick={() => setEquipmentInDetail(null)}
                    >
                      {linkedArea.name}
                    </span>
                  </Breadcrumb.Item>
                )}
                {equipmentInDetail && (
                    <Breadcrumb.Item>{equipmentInDetail.name}</Breadcrumb.Item>
                  )}
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float="left"
              justifyContent={"flex-end"}
              paddingLeft={"14px"}
              paddingRight={0}
            >
              <React.Fragment>
                <StyledSelect
                  width={inputWidth}
                  placeholder={t("area_input_placeholder")}
                  value={filter.container__area__name}
                  onChange={(event) =>
                    changeFilter("container__area__name", event)
                  }
                  remoteMethod={this.areaAutoCompleteFetch}
                  options={searchAreaResults}
                  loading={searchAreaLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea != null}
                ></StyledSelect>
                <StyledSelect
                  placeholder={t("container_input_placeholder")}
                  onChange={(event) => changeFilter("container__uuid", event)}
                  value={filter.container__uuid}
                  remoteMethod={this.autoCompleteFetch}
                  triggerOnFocus={false}
                  options={searchResults}
                  loading={searchLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea == null}
                ></StyledSelect>
              </React.Fragment>
              {changedForm != null && (
                <ToggleFilter
                  text={getIntervalText(changedForm, t)}
                  variableToWatch={filtersForm}
                  zIndex={1001}
                >
                  <IntervalPicker
                    form={filtersForm}
                    intervalOptions={intervalOptions}
                    replaceForm={setFiltersForm}
                    resetForm={resetFiltersForm}
                    defaultForm={INTERVAL_FORM_DEFAULTS}
                    triggerSet={triggerSet}
                    resetTriggerSet={() => this.setState({ triggerSet: false })}
                    state={{ changedForm }}
                    setState={this.setState}
                  />
                </ToggleFilter>
              )}
              <ButtonWrapper>
                <StyledButton
                  margin={"0"}
                  onClick={() => {
                    resetFilter();
                    setLinkedArea(null);
                  }}
                >
                  {t("clear_filters")}
                </StyledButton>
                <StyledButton
                  type={"primary"}
                  margin={"0"}
                  onClick={() => this.setState({ triggerSet: true })}
                >
                  {t("apply")}
                </StyledButton>
                <ToggleFilter
                  order={10}
                  right={0}
                  text={
                    <div>
                      {!loadingDownload ? "Download" : <DownloadLoading />}
                    </div>
                  }
                  type={"button"}
                  loadingDownload={loadingDownload}
                >
                  <DownloadDropDownContainer
                    download={download}
                  ></DownloadDropDownContainer>
                </ToggleFilter>
              </ButtonWrapper>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          style={{ marginBottom: "24px", marginTop: "24px" }}
          gutter="15"
        >
          <SelectivePaddingColNoFixedHeightWithBoxShadow
            paddingLeft="0px"
            paddingRight="0px"
            span="24"
          >
            <ContainerMapMaintenances
              areas={areasList}
              linkedArea={linkedArea}
              setLinkedArea={(area) => setLinkedArea(area)}
              areaEquipments={areaEquipments}
              equipmentInDetail={equipmentInDetail}
              globalNumberOfContainers={filteredEquipmentsNumber}
              setEquipmentInDetail={(equipment) =>
                setEquipmentInDetail(equipment)
              }
            />
          </SelectivePaddingColNoFixedHeightWithBoxShadow>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          gutter="20"
          height={"calc(55vh - 64px - 36px - 48px - 4rem)"}
          minHeight={"320px"}
        >
          <NoPaddingCol span="24">
            <TableName>{t("maintenances.title")}</TableName>
            {responsiveHandlers.isMobile || responsiveHandlers.isTablet ? (
              <StyledMobileTable
                data={
                  maintenancesList
                    ? maintenancesList.map((equipment) => ({
                        addDescription: this.addDescription,
                        ...equipment,
                      }))
                    : []
                }
                template={MaintenancesTemplate}
                user={user}
                active={(row) =>
                  equipmentInDetail &&
                  row.container &&
                  equipmentInDetail.id === row.container.id
                }
                onRowClick={(entrance) => {
                  window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: "smooth",
                  });
                  this.setState({
                    transitionEnded: false,
                  });
                  
                  setEquipmentInDetail(entrance.container);
                }}
              />
            ) : (
              <StyledTable
                style={{ marginTop: ".5rem", minHeight: "100%" }}
                height={
                  responsiveHandlers.height > 768
                    ? "calc(55vh - 64px - 36px - 48px - 4rem - 42px - 1px )"
                    : ""
                }
                columns={columnsToMap.map((column) => ({ t, ...column }))}
                data={
                  maintenancesList
                    ? maintenancesList.map((equipment) => ({
                        t,
                        user,
                        addDescription: this.addDescription,
                        ...equipment,
                      }))
                    : []
                }
                rowClassName={(row) =>
                  equipmentInDetail &&
                  row.container &&
                  equipmentInDetail.id === row.container.id &&
                  "active"
                }
                onRowClick={(entrance) => setEquipmentInDetail(entrance.container)}
              />
            )}
          </NoPaddingCol>
        </CustomLayoutSecondaryRow>
        {dialogOpen && dialogContainer != null && (
          <Dialog
            visible={dialogOpen}
            onCancel={() => {
              this.setState({
                dialogOpen: false,
                dialogContainer: null,
              });
              resetDescriptionForm();
            }}
            title={t("table.add_description")}
          >
            <DialogBodyWrapper>
              <div>
                <StyledInput
                  type="textarea"
                  width={"100%"}
                  label={t("table.description")}
                  placeholder={t("table.description")}
                  value={descriptionForm.description.value}
                  onChange={(e) => {
                    changeDescription({ description: e });
                  }}
                />
                {descriptionForm.description && (
                  <ErrorText>{descriptionForm.description.message && t(descriptionForm.description.message)}</ErrorText>
                )}
              </div>
              <DialogButtonWrapper>
                <StyledButton
                  type={"primary"}
                  onClick={() => submitDescription(dialogContainer.id)}
                >
                  {t("table.submit")}
                </StyledButton>
              </DialogButtonWrapper>
            </DialogBodyWrapper>
          </Dialog>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Maintenances)));

const DialogBodyWrapper = styled.div``;

const Wrapper = styled.div`
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
`;

const ErrorText = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-heihgt: 1em;
  color: ${({ theme }) => theme.errorColor};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DialogButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 150px;
  margin-left: auto;
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
`;

const SelectivePaddingColNoFixedHeightWithBoxShadow = styled(
  SelectivePaddingColNoFixedHeight
)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;


const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: span 2;

  ${({ theme }) => theme.desktop`
    display flex;
  `}
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;
    }
  `}
`;

const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-20{
      display: grid;
    }
  `}

  ${({ theme }) => theme.desktop`
    &.el-col-20{
      display: flex;
      justify-content: flex-end;
      width: 83.33333%;
      padding-left: 14px !important;
    }
  `}
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const DownloadLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;