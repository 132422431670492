import React from 'react';
import { kea } from 'kea';
import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { BASE_API_URL, API_DATE_FORMAT } from 'config';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { decodeColor } from 'utils';
import AppLogic from 'containers/App/logic';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import NotificationsWrapper from 'components/NotificationsWrapper';

const EVENTSURL = BASE_API_URL + 'events/';
const AREASURL = BASE_API_URL + 'areas/';
const EQUIPMENTSSURL = BASE_API_URL + 'containers/';
const DOWNLOADURL = BASE_API_URL + 'events/export/';
const EVENTSMETRICURL = BASE_API_URL + 'metrics/events/';
const FILLLEVELURL = BASE_API_URL + 'metrics/fill-level/';

const getContainerByUUID = (uuid, array) => {
  if (uuid != null && array != null) {
    return array.find((el) => {
      return el.uuid === uuid;
    });
  }
  return null;
};

const getAreaByName = (name, array) => {
  if (name != null && array != null) {
    return array.find((el) => {
      return el.name === name;
    });
  }
  return null;
};

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'Deposits'],

  connect: {
    props: [],
    actions: [
      AppLogic,
      ['setWebSocketHanlder', 'changeWatchingEvents', 'watchEvents'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    setLoadingPlot: () => true,

    fetchAreasEquipments: () => true,
    fetchMaintenancesList: () => true,
    setMaintenancesList: (list) => ({ list }),
    setMaintenancesNumber: (number) => ({ number }),
    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),

    search: (query, cb, type) => ({ query, cb, type }),

    setMaxDrops: (number) => ({ number }),
    setAverageDayDrops: (number) => ({ number }),
    setNumberOfDrops: (number) => ({ number }),

    fetchAreasList: () => true,
    setAreasList: (list) => ({ list }),
    setAreasNumber: (number) => ({ number }),
    setLoadingAreas: (loading) => loading,

    setLinkedArea: (area) => ({ area, type: 'container', query: '' }),
    setFilteredEquipments: (equipments) => ({ equipments }),
    updateFilteredEquipments: (equipments) => ({ equipments }),
    setFilteredEquipmentsNumber: (number) => ({ number }),

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,
    setPagination: (pag) => ({ pag }),

    setEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetail: (equipment) => ({
      equipment,
      type: 'container',
      query: '',
    }),

    setUsingMonthCalendar: (value) => ({ value }),
    setShowCalendarFilter: (value) => ({ value }),

    setDropsListToPlot: (list) => ({ list }),
    fetchDepositsToPlot: () => true,

    download: (type) => ({ type }),
    finishDownload: () => true,

    setSearchResults: (results) => ({ results }),
    setUserSearchResults: (results) => ({ results }),
    setAreaSearchResults: (results) => ({ results }),
  }),

  reducers: ({ actions }) => ({
    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.resetFilter]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    maintenancesList: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenancesList]: (state, { list }) => list,
      },
    ],

    pagination: [
      { page: 1, page_size: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFetchedEquipmentInDetail]: () => ({
          page: 1,
          page_size: 10,
        }),

        [actions.setFiltersForm]: () => ({ page: 1, page_size: 10 }),
      },
    ],

    linkedArea: [
      null,
      PropTypes.object,
      {
        [actions.setLinkedArea]: (state, { area }) => area,
      },
    ],

    dropsListToPlot: [
      null,
      PropTypes.array,
      {
        [actions.setDropsListToPlot]: (state, { list }) => list,
      },
    ],

    filter: [
      {
        container__area__name: '',
        container__uuid: '',
        fill_level: '',
        complete: '',
      },
      PropTypes.object,
      {
        [actions.setFilter]: (state, { filter }) => filter,
        [actions.setLinkedArea]: (state, { area }) => ({
          ...state,
          container__area__name: area != null && area.name ? area.name : '',
        }),
        [actions.setEquipmentInDetail]: (state, { equipment }) => ({
          ...state,
          container__uuid:
            equipment != null && equipment.uuid ? equipment.uuid : '',
        }),
        [actions.changeFilter]: (state, payload) => {
          return Object.assign({}, state, { [payload.key]: payload.value });
        },
        [actions.resetFilter]: () => ({
          container__area__name: '',
          container__uuid: '',
          fill_level: '',
          complete: '',
        }),
      },
    ],

    areasList: [
      [],
      PropTypes.array,
      {
        [actions.setAreasList]: (state, { list }) => list,
      },
    ],

    loadingAreas: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingAreas]: (state, loading) => loading,
      },
    ],

    loadingDownload: [
      false,
      PropTypes.boolean,
      {
        [actions.download]: () => true,
        [actions.finishDownload]: () => false,
      },
    ],

    loadingPlot: [
      true,
      PropTypes.boolean,
      {
        [actions.setLoadingPlot]: (state, loading) => true,
        [actions.fetchDepositsToPlot]: (state, loading) => true,
        [actions.setDropsListToPlot]: (state, loading) => false,
      },
    ],

    filteredEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setFilteredEquipments]: (state, { equipments }) => equipments,
        [actions.updateFilteredEquipments]: (state, { equipments }) => [
          ...state,
          ...equipments,
        ],
        [actions.setLinkedArea]: (state, { area }) => {
          return area == null ? null : state;
        },
      },
    ],

    filteredEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setFilteredEquipmentsNumber]: (state, { number }) => number,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setFetchedEquipmentInDetail]: (state, { equipment }) =>
          equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,

        [actions.setLinkedArea]: (state, { area }) => {
          return area == null ? null : state;
        },
        [actions.resetFilter]: () => null,
      },
    ],

    usingMonthCalendar: [
      true,
      PropTypes.bool,
      {
        [actions.setUsingMonthCalendar]: (state, { value }) => value,
      },
    ],

    showCalendarFilter: [
      false,
      PropTypes.bool,
      {
        [actions.setShowCalendarFilter]: (state, { value }) => value,
      },
    ],

    numberOfDrops: [
      0,
      PropTypes.number,
      {
        [actions.setNumberOfDrops]: (state, { number }) => number,
      },
    ],

    averageDayDrops: [
      0,
      PropTypes.number,
      {
        [actions.setAverageDayDrops]: (state, { number }) => number,
      },
    ],

    maxDrops: [
      0,
      PropTypes.number,
      {
        [actions.setMaxDrops]: (state, { number }) => number,
      },
    ],

    searchResults: [
      [],
      PropTypes.array,
      {
        [actions.setSearchResults]: (state, { results }) => results,
      },
    ],

    searchLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'container',
        [actions.setSearchResults]: () => false,
      },
    ],

    fillLevelFilterOptions: [
      [
        {
          value: 'less',
          label: 'x < 25%',
        },
        {
          value: 'between',
          label: '25% < x < 50%',
        },
        {
          value: 'between_2',
          label: '50% < x < 75%',
        },
        {
          value: 'greater',
          label: '75% < x',
        },
      ],
      PropTypes.array,
      {},
    ],

    searchAreaResults: [
      [],
      PropTypes.array,
      {
        [actions.setAreaSearchResults]: (state, { results }) => results,
      },
    ],

    searchAreaLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'area',
        [actions.setAreaSearchResults]: () => false,
      },
    ],
  }),

  start: function* () {
    const {
      fetchMaintenancesList,
      fetchAreasList,
      setWebSocketHanlder,
      watchEvents,
      fetchAreasEquipments,
    } = this.actions;

    yield put(fetchAreasList());
    yield put(fetchMaintenancesList());
    yield put(fetchAreasEquipments());

    yield put(setWebSocketHanlder(this.workers.handle));

    yield put(watchEvents());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchMaintenancesList]: [
      workers.fetchMaintenancesList,
      workers.fetchDepositsToPlot,
    ],
    [actions.fetchAreasList]: [
      workers.fetchAreasList,
      workers.fetchDepositsToPlot,
    ],
    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,
    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,
    [actions.setLinkedArea]: [
      workers.showOnlyOneArea,
      workers.fetchMaintenancesList,
      workers.fetchAreasEquipments,
      workers.fetchDepositsToPlot,
    ],
    [actions.setEquipmentInDetail]: [
      workers.fetchEquipmentInDetail,
      workers.fetchDepositsToPlot,
    ],
    [actions.setFetchedEquipmentInDetail]: [workers.fetchMaintenancesList],
    [actions.filterEntrances]: [workers.fetchMaintenancesList],
    [actions.resetFilter]: [
      workers.fetchMaintenancesList,
      workers.fetchDepositsToPlot,
    ],
    [actions.setPagination]: workers.fetchMaintenancesList,
    [actions.setFiltersForm]: [
      workers.fetchAreasEquipments,
      workers.fetchMaintenancesList,
      workers.fetchDepositsToPlot,
    ],
    [actions.download]: workers.download,
  }),

  workers: {
    *download(action) {
      const { payload } = action;
      const { t } = this.props;
      const { finishDownload } = this.actions;
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      let filter = yield this.get('filter');

      if (payload.type != null) {
        try {
          let params;
          if (equipmentInDetail) {
            params = { ...filter, container__id: equipmentInDetail.id };
          } else if (linkedArea) {
            params = { ...filter, container__area__id: linkedArea.id };
          } else {
            params = { ...filter };
          }

          const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
          const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

          const response = yield call(axios.get, DOWNLOADURL, {
            params: {
              type: 'drop',
              file_type: payload.type,
              ...params,
              date_after: moment(date_after).toISOString(),
              date_before: moment(date_before).endOf('day').toISOString(),
              ordering: '-datetime',
            },
            responseType: 'blob',
          });

          fileDownload(
            new Blob([response.data]),
            `${t('deposits.download')}.${payload.type}`
          );
        } catch (err) {
          toast.error(
            <NotificationsWrapper
              type={'download_error'}
              description={t('error.download_description')}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'downloadError',
            }
          );
        } finally {
          yield put(finishDownload());
        }
      }
    },

    *showOnlyOneArea(action) {
      const { setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');
      const linkedArea = yield this.get('linkedArea');

      if (areasList.length === 1 && linkedArea !== areasList[0]) {
        yield delay(300);
        yield put(setLinkedArea(areasList[0]));
      }
    },

    *fetchDepositsToPlot(action) {
      const { setDropsListToPlot, setLoadingPlot } = this.actions;
      yield put(setLoadingPlot());
      const filteredEquipments = yield this.get('filteredEquipments');
      const filtersForm = yield this.get('filtersForm');
      const filter = yield this.get('filter');
      const areasList = yield this.get('areasList');

      const area = getAreaByName(filter.container__area__name, areasList);

      let container;

      if (
        filter.container__uuid != null &&
        filter.container__uuid !== '' &&
        getContainerByUUID(filter.container__uuid, filteredEquipments) != null
      ) {
        container = getContainerByUUID(
          filter.container__uuid,
          filteredEquipments
        );
      } else {
        container = null;
      }

      const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
      const date_before = filtersForm.end.value.format(API_DATE_FORMAT);
      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            container__fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            container__fill_level__gte: 25,
            container__fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            container__fill_level__gte: 50,
            container__fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            container__fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      const params = {
        area__id: area ? area.id : null,
        container__id: container ? container.id : null,
        ...fillLevel,
        datetime_after: moment(date_after).toISOString(),
        datetime_before: moment(date_before).endOf('day').toISOString(),
      };

      try {
        let request = yield call(axios.get, FILLLEVELURL, { params });

        if (request.data != null && request.data.timeline != null) {
          const final = request.data.timeline.map((r) => [
            moment(r.timestamp).toISOString(),
            r.averageFillLevel,
          ]);

          yield put(setDropsListToPlot(final));
        }
      } catch (err) {
        console.log({ err });
        yield put(setDropsListToPlot([]));
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const { setFetchedEquipmentInDetailNoFetchs } = this.actions;

      try {
        const { data } = yield call(
          axios.get,
          EQUIPMENTSSURL + `${action.payload.equipment.id}/`
        );
        data.color = decodeColor(data.type);
        yield put(setFetchedEquipmentInDetailNoFetchs(data));
      } catch (error) {
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
    },

    *fetchEquipmentInDetail(action) {
      yield delay(300);

      const { setFetchedEquipmentInDetail, setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');

      if (
        action.payload &&
        action.payload.equipment &&
        action.payload.equipment.id
      ) {
        try {
          const { data } = yield call(
            axios.get,
            EQUIPMENTSSURL + `${action.payload.equipment.id}/`
          );
          data.color = decodeColor(data.type);
          const equipmentArea = areasList.find((el) => el.id === data.area.id);

          if (equipmentArea != null) {
            yield put(setLinkedArea(equipmentArea));
          }
          yield put(setFetchedEquipmentInDetail(data));
        } catch (error) {
          yield put(setFetchedEquipmentInDetail(null));
        }
      } else {
        yield put(setFetchedEquipmentInDetail(null));
      }
    },

    *fetchMaintenancesList() {
      yield delay(500);
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');

      let filter = yield this.get('filter');
      const filtersForm = yield this.get('filtersForm');
      const filteredEquipments = yield this.get('filteredEquipments');
      const areasList = yield this.get('areasList');

      const {
        setMaintenancesList,
        setMaintenancesNumber,
        setMaxDrops,
        setAverageDayDrops,
        setNumberOfDrops,
        setEquipmentInDetail,
        setLinkedArea,
      } = this.actions;

      const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
      const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            container__fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            container__fill_level__gte: 25,
            container__fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            container__fill_level__gte: 50,
            container__fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            container__fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      const commonParams = {
        ...filter,
        ...fillLevel,
        date_after: moment(date_after).toISOString(),
        date_before: moment(date_before).endOf('day').toISOString(),
        event_type: 'drop',
        ordering: '-datetime',
      };

      let params = { ...commonParams };

      if (equipmentInDetail) {
        params = { ...commonParams, container: equipmentInDetail.id };
      } else if (linkedArea) {
        params = { ...commonParams, area: linkedArea.id };
      }

      try {
        const depositsRes = yield call(axios.get, EVENTSURL, { params });
        const deposits = depositsRes.data.results;

        const containerAreaNameExists = !!filter.container__area__name;
        const area = getAreaByName(filter.container__area__name, areasList);

        if (deposits) {
          if (containerAreaNameExists) {
            if (
              area != null &&
              (linkedArea == null ||
                (linkedArea != null &&
                  linkedArea.name !== filter.container__area__name))
            ) {
              yield put(setLinkedArea(area));
            }
          }
          if (filter.container__uuid != null && filter.container__uuid !== '') {
            const container = getContainerByUUID(
              filter.container__uuid,
              filteredEquipments
            );
            if (
              container != null &&
              (equipmentInDetail == null ||
                (equipmentInDetail != null &&
                  equipmentInDetail.uuid !== filter.container__uuid))
            ) {
              yield put(setEquipmentInDetail(container));
            } else if (container == null && equipmentInDetail != null) {
              yield put(setEquipmentInDetail(null));
            }
          } else if (
            (filter.container__uuid == null || filter.container__uuid === '') &&
            equipmentInDetail != null
          ) {
            yield put(setEquipmentInDetail(null));
          }

          yield put(setMaintenancesList(deposits));
          yield put(setMaintenancesNumber(deposits.length));
        } else {
          yield put(setMaintenancesList([]));
          yield put(setMaintenancesNumber(0));
          yield put(setEquipmentInDetail(null));
        }
      } catch (error) {
        yield put(setMaintenancesList([]));
        yield put(setMaintenancesNumber(0));
        yield put(setEquipmentInDetail(null));
      }

      let container;

      if (
        filter.container__uuid != null &&
        filter.container__uuid !== '' &&
        getContainerByUUID(filter.container__uuid, filteredEquipments) != null
      ) {
        container = getContainerByUUID(
          filter.container__uuid,
          filteredEquipments
        );
      } else {
        container = null;
      }

      const metricsEventsParams = {
        container__id: container ? container.id : null,
        area__id:
          (filter.container__uuid == null || filter.container__uuid === '') &&
          linkedArea
            ? linkedArea.id
            : null,
        datetime_after: moment(date_after).toISOString(),
        datetime_before: moment(date_before).endOf('day').toISOString(),
        type: 'drop',
      };

      try {
        const response = yield call(axios.get, EVENTSMETRICURL, {
          params: metricsEventsParams,
        });
        if (response.data) {
          const { data } = response;
          yield put(setMaxDrops(data.maxEvents));
          yield put(setAverageDayDrops(data.averageEvents));
          yield put(setNumberOfDrops(data.totalEvents));
        }
      } catch (err) {
        yield put(setMaxDrops(0));
        yield put(setAverageDayDrops(0));
        yield put(setNumberOfDrops(0));
      }
    },

    *fetchAreasList() {
      const {
        setAreasList,
        setLinkedArea,
        setAreaSearchResults,
        setAreasNumber,
      } = this.actions;

      try {
        const response = yield call(axios.get, AREASURL);
        const areasRes = response.data.results;

        if (areasRes.length) {
          // add data to area filter
          const searchAreaArr = areasRes.map((area) => ({
            value: area.name,
            label: area.name,
          }));
          yield put(setAreaSearchResults(searchAreaArr));

          // set up areas and prepare polygons
          let results = areasRes.map((area) => {
            area.lines = {
              type: 'LineString',
              coordinates: area.polygon.coordinates[0],
            };
            return area;
          });
          yield put(setAreasList(results));
          yield put(setAreasNumber(areasRes.length));

          // if there is only one area, focus it
          if (results.length === 1) {
            yield delay(300);
            yield put(setLinkedArea(results[0]));
          }
        } else {
          yield put(setAreasList([]));
          yield put(setAreasNumber(0));
        }
      } catch (error) {
        yield put(setAreasList([]));
        yield put(setAreasNumber(0));
        yield put(setAreaSearchResults([]));
      }
    },

    *fetchAreasEquipments(action) {
      yield delay(600);
      const linkedArea = yield this.get('linkedArea');
      const filter = yield this.get('filter');

      const {
        setFilteredEquipments,
        setSearchResults,
        setFilteredEquipmentsNumber,
      } = this.actions;

      const installationStatus = ['assembled', 'installed'];

      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            fill_level__gte: 25,
            fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            fill_level__gte: 50,
            fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      try {
        let next = 1;
        const commonParams = {
          page_size: 500,
          ...fillLevel,
        };

        let results = [];
        while (next) {
          let params = {
            ...commonParams,
            page: next,
          };

          if (linkedArea) params = { ...params, area__id: linkedArea.id };

          let containers = [];

          const responses = yield installationStatus.map((status) => {
            params = { ...params, installation_status: status };
            return call(axios.get, EQUIPMENTSSURL, { params });
          });

          responses.forEach((el) =>
            containers.push.apply(containers, el.data.results)
          );

          containers.map((container) => {
            container.color = decodeColor(container.type);
            results.push(container);
            return container;
          });

          const equipmentsList = results.map((el) => ({
            value: el.uuid,
            label: el.name,
          }));

          next = 0;

          yield put(setFilteredEquipments(results));
          yield put(setFilteredEquipmentsNumber(results.length));
          yield put(setSearchResults(equipmentsList));
        }
      } catch (error) {
        yield put(setFilteredEquipments([]));
        yield put(setFilteredEquipmentsNumber(0));
        yield put(setSearchResults([]));
      }
    },

    *handle(message) {
      const { fetchMaintenancesList, fetchEquipmentInDetailNoFetchs } =
        this.actions;
      const equipmentInDetail = yield this.get('equipmentInDetail');
      if (message.event_type === 'status') {
        let temp = { ...message };
        const message_id =
          message.content && message.content.id ? message.content.id : null;

        toast.info(temp.message, {
          position: toast.POSITION.TOP_RIGHT,
          delay: 200,
          id: message_id ? `uniqueStatusMessage${message_id}` : null,
        });
      } else if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          const message_id =
            message.content && message.content.id ? message.content.id : null;

          if (
            message.content != null &&
            message.content.type === 'drop' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueDropMessage${message_id}` : null,
              }
            );

            yield put(fetchMaintenancesList());
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.content != null &&
            (message.content.type === 'pick' ||
              message.content.type === 'maintenance') &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniquePickMessage${message_id}` : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.content != null &&
            message.content.type === 'alert'
          ) {
            toast.warn(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
                description={
                  message.content != null && message.content.alertType
                    ? message.content.alertType
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueAlertMessage${message_id}` : null,
              }
            );
          } else if (message.content && message.content.type === 'error') {
            if (message.content.status === 'closed') {
              toast.success(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content && message.content.errorType
                      ? `${message.content.errorType}`
                      : ''
                  }
                  status={
                    message.content && message.content.status
                      ? message.content.status
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            } else {
              toast.error(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content != null && message.content.errorType
                      ? message.content.errorType
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            }
          }
        }
      }
    },
  },
});
