import { createGlobalStyle, css } from 'styled-components'
import { lighten } from 'polished'
import {
  primaryColor,
  lightPrimaryColor,
  darkPrimaryColor,
  secondaryColor,
  darkSecondaryColor,
  textOnPrimary,
  textonSecondary,
  bodyBackground,
  inactiveColor,
  darkInactiveColor,
  successColor,
  errorColor,
  darkErrorColor,
  warningColor,
  alertColor,
  infoColor,
  white,
  black,
  borderColor,
  lightInactiveColor,
  iconDarkColor,
  lightColor,
  activeRowColor,
  primaryColorOpacity,
  darkPrimaryColorOpacity,
  secondaryColorHover,
  lightSuccessColor,
  lightAlertColor
} from 'config'

const mediaQuery = (...query) => (literals, ...placeholders) => css`
  @media ${css`
      ${query}
    `} {
    ${css(literals, ...placeholders)}
  }
`

export const breakpoints = {
  tablet: {
    min: 768
  },
  smallDesktop: {
    min: 1024
  },
  desktop: {
    min: 1440
  },
  largeDesktop: {
    min: 1800
  }
}

// Theme variables
export const Theme = {
  bodyBackground: bodyBackground,
  lightPrimaryColor: lightPrimaryColor,
  primaryColor: primaryColor,
  darkPrimaryColor: darkPrimaryColor,
  secondaryColor: secondaryColor,
  darkSecondaryColor: darkSecondaryColor,
  textOnPrimary: textOnPrimary,
  textonSecondary: textonSecondary,
  inactiveColor: inactiveColor,
  darkInactiveColor: darkInactiveColor,
  successColor: successColor,
  errorColor: errorColor,
  darkErrorColor: darkErrorColor,
  warningColor: warningColor,
  alertColor: alertColor,
  infoColor: infoColor,
  white: white,
  black: black,
  borderColor: borderColor,
  lightInactiveColor: lightInactiveColor,
  iconDarkColor: iconDarkColor,
  lightColor: lightColor,
  activeRowColor: activeRowColor,
  primaryColorOpacity: primaryColorOpacity,
  darkPrimaryColorOpacity,
  secondaryColorHover,
  lightSuccessColor,
  lightAlertColor,
  fontFamily: "'Nunito Sans', 'Roboto', sans-serif",
  breakpoints: breakpoints
}

export const media = {
  tablet: mediaQuery(`(min-width: ${Theme.breakpoints.tablet.min / 16}em)`),

  smallDesktop: mediaQuery(
    `(min-width: ${Theme.breakpoints.smallDesktop.min / 16}em)`
  ),
  desktop: mediaQuery(`(min-width: ${Theme.breakpoints.desktop.min / 16}em)`),
  largeDesktop: mediaQuery(
    `(min-width: ${Theme.breakpoints.largeDesktop.min / 16}em)`
  )
}

// Global styles
export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0 !important;
    padding: 0;
    font-family: ${Theme.fontFamily};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-x: hidden;
    background: ${Theme.bodyBackground};
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
  }

  .content {
    overflow: hidden;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.8);
  }



  .markercluster-map2{
    height: 100%;
    width:100%;
    box-shadow:0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
  }

  .markercluster-map{
    height: 100%;
    width:100%;
    box-shadow:0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
  }

  .mapboxgl-popup-tip{
    display:none;
  }

  .mapboxgl-popup-content{
    padding:0px !important;
  }

  .el-input__inner, .el-button{
    font-family: ${Theme.fontFamily};
    border-radius: 0 !important;
  }

  .el-card{
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04) !important;
  }

  .mapboxgl-ctrl{
    display:none !important;
  }

  .el-card__header{
    padding:0px !important;
  }

  .el-card__body{
    padding:0px !important;
  }

  .el-row{
    margin-left:-10px !important;
    margin-right:-10px !important;
  }

  .el-carousel__indicator{
    button{
      background-color:${Theme.primaryColor} !important;
    }
  }

  .el-carousel__indicators{
    margin-bottom: 5px !important;
  }


  .el-carousel__button{
    width:8px !important;
    height:8px !important;
  }

  .el-breadcrumb__item__inner{
    cursor:pointer !important;
    color:${Theme.darkPrimaryColor} !important;

    :hover{
      color:${Theme.primaryColor} !important;

    }
  }

  .el-breadcrumb__item{
    >span{
    font-size:16px !important;
    line-height:24px !important;
    font-weight:500 !important;
    }
  }

  .el-breadcrumb__separator{
    font-size:16px !important;
    line-height:24px !important;
    font-weight:500 !important;
  }

  .DateRangePicker{
    border: 1px solid ${lighten(0.4, Theme.borderColor)};
    color: ${inactiveColor};
    transition: border-color  0.5s;
    z-index:100;
    margin-left: 14px;
    height: 36px;
    :hover{
      border: 1px solid ${Theme.borderColor} !important;
    }
    .DateRangePickerInput{
      border:0px !important;
      background-color:${white} !important;
      ${media.tablet`
        min-width: 145px;
      `}
      .DateInput {
        width:95px !important;
        background-color:transparent !important;
        .DateInput_input__focused{
          border-bottom: 0px;
          text-decoration: underline;
          padding-top:3px;
        }
        input{
          color: ${Theme.textonSecondary} !important;
          font-size: 14px;
          padding:5px 11px 3px;
          background-color:transparent !important;
        }
        ${media.tablet`
          width: 65px !important;
          
          input{
            font-size: 10px;
            padding:5px 5px 3px;
          }
        `}
      }
      svg{
        margin-bottom: 2px;
        height: 18px !important;
        ${media.tablet`
          width: 14px;
        `}
      }
    }
    .CalendarDay{
      background-color: ${white} !important;
      border:1px solid ${white} !important;

      &.CalendarDay__selected_span,
      &.CalendarDay__selected_end, 
      &.CalendarDay__selected_start{
        background-color: ${Theme.darkSecondaryColor} !important;
        color: ${Theme.textOnPrimary} !important;
      }
    }
    td{
      color: ${Theme.textonSecondary} !important;
    }

  }

  .number .active{
    background-color: ${Theme.darkSecondaryColor} !important;

  }

  .customTableChart{
    background-color:white;
    box-shadow:0 0 10px 0 rgba(0,0,0,0.3);
    padding:5px;
    margin:0px;

    color: ${Theme.textonSecondary};
    font-family: ${Theme.fontFamily}
    font-size: 14px;
    line-height: 14px;
    height:22px;
    thead{
      height:0px;
    }

  }


  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Theme.lightPrimaryColor};
    border: 0px none ${Theme.white};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${Theme.lightPrimaryColor};
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: ${Theme.lightPrimaryColor};
  }

  ::-webkit-scrollbar-track {
    background-color: ${Theme.white};
    border: 0px none ${Theme.white};
  }

  ::-webkit-scrollbar-track:hover {
    background: rgba(255,255,255,0.15);
  }

  ::-webkit-scrollbar-track:active {
    background: rgba(255,255,255,0.15);
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }


  .cell {
      text-overflow:ellipsis;
      overflow:hidden;
    .div{
      text-overflow:ellipsis;
      overflow:hidden;
    }
  }

  a[download="file.pdf"]{
    height: 35px;
    display: flex;
    align-items: center;
  }

  /*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.Toastify__toast--success{
  background: ${Theme.white};

  border-left: 5px solid ${Theme.successColor};
}
.Toastify__toast--info{
  background: ${Theme.white};

  border-left: 5px solid ${Theme.primaryColor};
}
.Toastify__toast--warning{
  background: ${Theme.white};

  border-left: 5px solid ${Theme.alertColor};
}
.Toastify__toast--error{
  background: ${Theme.white};
  
  border-left: 5px solid ${Theme.errorColor};
}

.Toastify__toast{
  margin: 0;
  margin-right: 1.2rem;
  padding: 1rem 0.75rem !important;
  background: ${Theme.white} !important;
  color: ${Theme.black};
}
.Toastify{
  z-index: 10001;
}


.el-dialog__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
`
