/**
 * Transactions Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Theme } from "globalStyles";
import { columns_admin, columns_trader, columns_costumer } from "./columns";
import { ADDITIONAL_FEATURES } from "config";

import moment from "moment";
import { Layout, Card, Loading, Breadcrumb } from "element-react";
import { withTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";

import IntervalPicker from "containers/IntervalPicker";
import DownloadDropDownContainer from "containers/DownloadDropDownContainer";

import StyledMobileTable from "containers/StyledMobileTable";

import ResponsiveHandler from "components/ResponsiveHandler";
import ToggleFilter from "components/ToggleFilter";
import ToggleFilterTitle from "components/ToggleFilterTitle";
import StyledButton from "components/StyledButton";
import GraphIndicator from "components/GraphIndicator";
import StyledTable from "components/StyledTable";
import StyledSelect from "components/StyledSelect";
import StyledOption from "components/StyledOption";
import { getIntervalText } from "utils";
import TransactionsTemplate from "components/TransactionsTemplate";

import TraderIcon from "icons/TraderIcon";
import CostumerIcon from "icons/CostumerIcon";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

const statusOptions = [
  {
    label: "pending",
    value: "pending",
  },
  {
    label: "approved",
    value: "approved",
  },
  {
    label: "cancelled",
    value: "cancelled",
  },
  {
    label: "expired",
    value: "expired",
  },
];

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerSet: false,
      filtersWidth: 20,
      inputWidth: "140px",
      changedForm: null,
    };
    this.tableRef = React.createRef();
    this.setState = this.setState.bind(this);
    this._costumerAutocompleteRef = null;
    this._traderAutocompleteRef = null;

  }

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  createDropTooltip = (tooltipItem, type) => {
    const { t } = this.props;
    return `${tooltipItem.value} ${t(`graph.${type}`)}`;
  };

  createTooltipTile = (tooltipItem, data) => {
    return tooltipItem[0].label;
  };

  mapTooltips = (text, data) => {
    return text;
  };

  timeIntervalSelect = () => {
    const { filtersForm, t } = this.props;
    const { setFiltersForm, resetFiltersForm, resetFilter, changeFiltersForm } =
      this.actions;

    const { triggerSet, changedForm } = this.state;

    return (
      <React.Fragment>
        {changedForm != null && (
          <ToggleFilter
            right={0}
            text={getIntervalText(changedForm, t)}
            variableToWatch={filtersForm}
            zIndex={1001}
          >
            <IntervalPicker
              form={filtersForm}
              intervalOptions={intervalOptions}
              changeForm={changeFiltersForm}
              replaceForm={setFiltersForm}
              resetForm={resetFiltersForm}
              triggerSet={triggerSet}
              resetTriggerSet={() => this.setState({ triggerSet: false })}
              defaultForm={INTERVAL_FORM_DEFAULTS}
              state={{ changedForm }}
              setState={this.setState}
            />
          </ToggleFilter>
        )}
        <SelectPositioner>
          <ButtonWrapper>
            <StyledButton margin={"0"} onClick={() => resetFilter()}>
              {t("clear_filters")}
            </StyledButton>
            <StyledButton
              type={"primary"}
              margin={"0 0"}
              onClick={() => this.setState({ triggerSet: true })}
            >
              {t("apply")}
            </StyledButton>
          </ButtonWrapper>
        </SelectPositioner>
      </React.Fragment>
    );
  };

  costumerAutoCompleteFetch = (queryString) => {
    if (this._costumerAutocompleteRef) {
      clearTimeout(this._costumerAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._costumerAutocompleteRef = setTimeout(function () {
      search(queryString, null, "costumer");
    }, 500);
  };

  traderAutoCompleteFetch = (queryString) => {
    if (this._traderAutocompleteRef) {
      clearTimeout(this._traderAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._traderAutocompleteRef = setTimeout(function () {
      search(queryString, null, "trader");
    }, 500);
  };

  render() {
    const {
      i18n,
      t,
      responsiveHandlers,
      maintenancesList,
      user,
      dropsLabels,
      drops,
      loadingDrops,
      metrics,
      filter,
      inTraderMode,
      loadingDownload,
      searchCostumerLoading,
      searchCostumerResults,
      searchTraderLoading,
      searchTraderResults
    } = this.props;
    const {
      changeFilter,
      switchCostumerTrader,
      download,
    } = this.actions;
    const { filtersWidth, inputWidth } = this.state;

    let columnsToMap = null;
    let mode = null;
    if (user != null) {
      if (user.isAdmin) {
        columnsToMap = columns_admin;
        mode = "admin";
      } else if (user.isTrader && inTraderMode) {
        columnsToMap = columns_trader;
        mode = "trader";
      } else {
        columnsToMap = columns_costumer;
        mode = "costumer";
      }
    } else {
      columnsToMap = [];
    }

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow marginTop="0px" gutter="30">
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: "0px 0 auto" }} separator="/">
                <Breadcrumb.Item>
                  {user != null &&
                  user.isTrader &&
                  ADDITIONAL_FEATURES.includes("trader_switch") ? (
                    <ToggleFilterTitle
                      icon={inTraderMode ? TraderIcon : CostumerIcon}
                      text={
                        inTraderMode
                          ? t("transactions.title_trader")
                          : t("transactions.title_costumer")
                      }
                      variableToWatch={inTraderMode}
                    >
                      <SwitchWrapper>
                        <StyledOption
                          onClick={() =>
                            !inTraderMode && switchCostumerTrader()
                          }
                        >
                          {t("transactions.title_trader")}
                        </StyledOption>
                        <StyledOption
                          onClick={() => inTraderMode && switchCostumerTrader()}
                        >
                          {t("transactions.title_costumer")}
                        </StyledOption>
                      </SwitchWrapper>
                    </ToggleFilterTitle>
                  ) : (
                    t("transactions.title")
                  )}
                </Breadcrumb.Item>
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float="left"
              justifyContent={"flex-end"}
              paddingLeft={"14px"}
              paddingRight={0}
            >
              <React.Fragment>
                {/* {user != null && user.isTrader && (
                  <SwitchButton>
                    <StyledButton onClick={switchCostumerTrader} type="primary">
                      {}
                    </StyledButton>
                  </SwitchButton>
                )} */}
                {user != null &&
                  ((inTraderMode && user.isTrader) || user.isAdmin) && (
                    <StyledSelect
                      width={inputWidth}
                      placeholder={t("transactions.costumer")}
                      onChange={(event) =>
                        changeFilter("search_customer", event)
                      }
                      value={filter.search_costumer}
                      remoteMethod={this.costumerAutoCompleteFetch}
                      options={searchCostumerResults}
                      loading={searchCostumerLoading}
                      filterable={true}
                      remote={true}
                      clearable={true}
                    ></StyledSelect>
                  )}
                {!inTraderMode && user != null && (
                  <StyledSelect
                    width={inputWidth}
                    placeholder={t("transactions.trader")}
                    onChange={(event) => changeFilter("search_trader", event)}
                    value={filter.search_trader}
                    remoteMethod={this.traderAutoCompleteFetch}
                    options={searchTraderResults}
                    loading={searchTraderLoading}
                    filterable={true}
                    remote={true}
                    clearable={true}
                  ></StyledSelect>
                )}
                <StyledSelect
                  value={filter.status}
                  onChange={(value) => changeFilter("transaction_status", value)}
                  placeholder={t("table.transaction_status")}
                  clearable={true}
                  options={
                    statusOptions != null
                      ? statusOptions.map((el) => ({
                          ...el,
                          label: t(`table.${el.label}`),
                        }))
                      : []
                  }
                ></StyledSelect>
                {this.timeIntervalSelect()}
                {user != null && user.isAdmin && (
                  <ToggleFilter
                    order={10}
                    right={0}
                    text={
                      <div>
                        {!loadingDownload ? "Download" : <DownloadLoading />}
                      </div>
                    }
                    zIndex={1}
                    type={"button"}
                    loadingDownload={loadingDownload}
                  >
                    <DownloadDropDownContainer
                      download={download}
                    ></DownloadDropDownContainer>
                  </ToggleFilter>
                )}
              </React.Fragment>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>
        <MapCardsWrapper style={{ marginBottom: "24px", marginTop: "24px" }} gutter="20">
          <CardsWrapper paddingSides={"5px"} span="4">
            <CustomCard
              height={"calc(55vh - 1rem - 160px)"}
              minHeight={"320px"}
            >
              <GraphIndicatorsWrapper>
              {metrics != null && metrics != null && (
                  <React.Fragment>
                  <GraphIndicator
                    name={t("transactions.indicators_total")}
                    value={metrics.totalTransactions}
                    border
                  />
                  {user != null && user.isAdmin && (
                      <React.Fragment>
                        <Divider></Divider>
                        <GraphIndicator
                          name={t("transactions.indicators_max")}
                          value={metrics.maxTransactions}
                          border
                        />
                        <Divider></Divider>
                        <GraphIndicator
                          name={t("transactions.indicators_average")}
                          value={metrics.averageTransactions}
                          border
                        />
                      </React.Fragment>
                  )}
                  </React.Fragment>
                )}

                {user != null &&
                  !user.isAdmin &&
                  metrics != null &&
                  metrics.lastTransaction != null && (
                    <React.Fragment>
                      <Divider></Divider>
                      <GraphIndicatorWrapper>
                        <div>
                          {user.isTrader ? (
                            <GraphIndicatorText>
                              {metrics.lastTransaction.costumer != null
                                ? metrics.lastTransaction.costumer.fullName
                                : "-"}
                            </GraphIndicatorText>
                          ) : (
                            <GraphIndicatorText>
                              {metrics.lastTransaction.trader != null
                                ? metrics.lastTransaction.trader.fullName
                                : "-"}
                            </GraphIndicatorText>
                          )}
                        </div>
                        <GraphIndicatorLabel>
                          {t(`table.${metrics.lastTransaction.status}`)}
                        </GraphIndicatorLabel>
                        <GraphIndicatorLabel>
                          {moment(metrics.lastTransaction.datetime)
                            .locale(i18n.language)
                            .format("DD MMM HH:mm")}
                        </GraphIndicatorLabel>
                        <GraphIndicatorLabel>
                          {t("transactions.last_transaction")}
                        </GraphIndicatorLabel>
                      </GraphIndicatorWrapper>
                    </React.Fragment>
                  )}
              </GraphIndicatorsWrapper>
            </CustomCard>
          </CardsWrapper>

          {/* BAR GRAPH */}
          <CardsWrapper paddingSides={"5px"} span="20">
            <CustomCard
              height={"calc(55vh - 1rem - 160px)"}
              width={"100%"}
              minHeight={"320px"}
            >
              <GraphTitle margin={"18px 0 0 0"}>
                {t("transactions.graph_title")}
              </GraphTitle>
              <GraphFlex>
                <CustomLoading top="120px" loading={loadingDrops}>
                  <GraphWrapper width={"100%"}>
                    {!loadingDrops && (
                      <Bar
                        data={{
                          labels: dropsLabels,
                          datasets: [
                            {
                              label: dropsLabels,
                              backgroundColor: Theme.secondaryColor,
                              borderColor: Theme.secondaryColor,
                              fill: false,
                              data: drops,
                            },
                          ],
                        }}
                        style={{ height: "100%" }}
                        options={{
                          legend: {
                            display: false,
                            position: "top",
                          },
                          maintainAspectRatio: false,
                          responsive: true,
                          scales: {
                            yAxes: [
                              {
                                stacked: true,
                                ticks: {
                                  suggestedMin: 0,
                                  suggestedMax: 10,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                stacked: true,
                                ticks: {
                                  autoSkip: true,
                                  maxRotation:
                                    responsiveHandlers.isMobile ||
                                    responsiveHandlers.isTablet
                                      ? 0
                                      : 70,
                                  minRotation: 0,
                                  maxTicksLimit:
                                    responsiveHandlers.isMobile ||
                                    responsiveHandlers.isTablet
                                      ? 12
                                      : 31,
                                },
                              },
                            ],
                          },
                          tooltips: {
                            // Disable the on-canvas tooltip
                            backgroundColor: "#FFFFFF",
                            bodyFontColor: "#000000",
                            titleFontColor: "#000000",
                            borderColor: "rgba(0,0,0,1)",
                            borderWidth: "0.5",
                            callbacks: {
                              title: (tooltipItem, data) => {
                                return this.createTooltipTile(
                                  tooltipItem,
                                  data
                                );
                              },
                              label: (tooltipItem, data) => {
                                return this.createDropTooltip(
                                  tooltipItem,
                                  "transactions"
                                );
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </GraphWrapper>
                </CustomLoading>
              </GraphFlex>
            </CustomCard>
          </CardsWrapper>
        </MapCardsWrapper>

        <CustomLayoutSecondaryRow gutter="30">
          <CustomCardTransparent
            height={"calc(55vh - 170px)"}
            width={"100%"}
            minHeight={"320px"}
          >
            <div ref={this.tableRef}>
              {columnsToMap != null && (
                <NoPaddingCol span={24}>
                  <TableName>{t("transactions.title")}</TableName>
                  <CustomCard minHeight={"100%"}>
                    {responsiveHandlers.isMobile ||
                    responsiveHandlers.smallDesktop ? (
                      <StyledMobileTable
                        data={
                          maintenancesList
                            ? maintenancesList.map((equipment) => ({
                                t,
                                ...equipment,
                              }))
                            : []
                        }
                        template={TransactionsTemplate}
                        active={() => false}
                        onRowClick={(entrance) => {
                          window.scrollTo({
                            left: 0,
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        mode={mode}
                        margin={0}
                      />
                    ) : (
                      <StyledTable
                        style={{ marginTop: ".5rem", minHeight: "100%" }}
                        withoutShadow
                        columns={columnsToMap.map((column) => ({
                          t,
                          ...column,
                        }))}
                        data={
                          maintenancesList
                            ? maintenancesList.map((equipment) => ({
                                t,
                                ...equipment,
                              }))
                            : []
                        }
                        height={
                          responsiveHandlers.height > 768
                            ? "calc(55vh - 4rem - 112px - 40px)"
                            : ""
                        }
                      />
                    )}
                  </CustomCard>
                </NoPaddingCol>
              )}
            </div>
          </CustomCardTransparent>
        </CustomLayoutSecondaryRow>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Transactions)));

const Wrapper = styled.div`
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
  width: 100%;
`;

const MapCardsWrapper = styled(CustomLayoutSecondaryRow)`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => theme.smallDesktop`

    display: block;

    and @media (max-height: 768px) {
      height: 320px;
    }
  `}
`;

const LayoutColLessPadding = styled(Layout.Col)`
  padding-left: ${(props) => props.paddingSides + " !important"};
  padding-right: ${(props) => props.paddingSides + " !important"};
  &:first-child {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
  }
`;
const CardsWrapper = styled(LayoutColLessPadding)`
  &.el-col-4 {
    order: 0;
    width: 100%;
    padding: 0 !important;
    margin-top: 16px;

    ${({ theme }) => theme.smallDesktop`
    width: 16.66667%;
    padding-right: 5px !important;
    margin-top: 0;

  `}
  }
  &.el-col-20 {
    order: 0;
    width: 100%;
    padding: 0 !important;
    margin-top: 16px;

    ${({ theme }) => theme.smallDesktop`
    width: 83.33333%;
    padding-right: 5px !important;
    margin-top: 0;

  `}
  }

  @media (max-height: 768px) {
    height: 100%;
  }
`;

const CustomBreakcrumb = styled(Breadcrumb)`
.el-breadcrumb__item > span {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

.el-breadcrumb__separator {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}
`;

const SelectPositioner = styled.div`
  height: auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  grid-column: span 2;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  grid-column: span 2;
  width: 100%;

  ${({ theme }) => theme.smallDesktop`
    display flex;
  `}
`;

const CustomCard = styled(Card)`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border + " !important"};
  height: ${(props) => props.minHeight};

  flex: 0 auto;

  & + & {
    margin-top: 8px;
  }

  ${({ theme }) => theme.smallDesktop`
    height: ${(props) => props.height};

    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  `}

  .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 auto;

    & > div {
      height: 100%;
      @media (max-height: 768px) {
        height: 320px;
        overflow: auto;
      }
    }
  }

  @media (max-height: 768px) {
    height: 100%;
  }
`;

const CustomCardTransparent = styled(CustomCard)`
  box-shadow: none !important;
  height: auto;

  @media (max-height: 768px) {
    min-height: 320px;
  }
  &.el-card {
    background-color: transparent;
  }

  ${({ theme }) => theme.smallDesktop`
    height: ${(props) => props.height};

    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  `}
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding: ${(props) => props.padding} !important;
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`;

const FlexRowNormal = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  flex: 100%;

  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const GraphFlex = styled(FlexRowNormal)`
  .el-card__body && {
    @media (max-height: 768px) {
      height: 280px !important;
    }
    ${({ theme }) => theme.smallDesktop`
      height: calc(100% - 40px);
    `}
  }
`;

const GraphWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  width: ${(props) => props.width};
  padding: 4px 10px 12px 25px;
`;

const GraphTitle = styled.h2`
  color: ${Theme.black};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  line-height: 22px;
  padding: 17px 15px 0px 15px;
  text-align: center;
  margin: 0;
  padding: ${(props) => props.margin};
`;

const CustomLoading = styled(Loading)`
  display: flex !important;
  width: 100%;
  height: 100%;
  width: -webkit-fill-available !important;
  position: relative;

  .el-loading-spinner {
    left: 0px !important;
    top: ${(props) => (props.top ? props.top : "40px")};
    display: flex !important;
    justify-content: center;

    height: 100%;
    top: 0;
    padding: 0;
    align-items: center;

    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const GraphIndicatorsWrapper = styled.div`
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-height: 768px) {
    height: 320px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${Theme.lightInactiveColor};
  margin: 0 auto;
`;

const GraphIndicatorWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto 0;
`;

const GraphIndicatorLabel = styled.div`
  color: ${Theme.darkInactiveColor};
  font-family: ${Theme.fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  text-align: center;
  margin: ${(props) => props.margin};
`;

const GraphIndicatorText = styled.p`
  color: ${Theme.black};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: fit-content;
  background: transparent !important;
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
  transition: width 0.4s;
  overflow: visible;
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;
    }
  `}

  ${({ theme }) => theme.desktop`
    &.el-col-4{
      width: 16.66667%;
      margin-bottom: 0;
    }
  `}
`;

const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-20{
      display: grid;
    }
  `}

  ${({ theme }) => theme.desktop`
    &.el-col-20{
      display: flex;
      justify-content: flex-end;
      width: 83.33333%;
      padding-left: 14px !important;
    }
  `}
`;

const SwitchWrapper = styled.div`
  padding: 6px 0;
  background-color: ${Theme.textOnPrimary};
  z-index: 100;
  min-width: 150px;
`;

const DownloadLoading = styled(Loading)`
  height: 24px;
  width: 24px;
  overflow: hidden;

  .el-loading-spinner {
    margin-top: 0;
    top: 0;
    svg {
      height: 24px;
      width: 24px;
    }
    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;