import React from 'react';
import moment from 'moment';
import StyledProgressBar from 'components/StyledProgressBar';
import styled from 'styled-components';
import decodeType from 'utils/decodeType';
import { Table, Tag } from 'element-react';
import { GarbageBin } from 'icons/ClientIcons';
import { checkDay } from './utils';

export const columns = [
  {
    label: 'table.id',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: 'id',
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : '-'}>
          {data.id ? data.id : '-'}
        </EllipsisDiv>
      );
    },
    width: 80,
  },
  {
    label: 'table.date',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: 'datetime',
    render: function (data) {
      return (
        <EllipsisDiv title={moment(data.datetime).format('DD/MM/YYYY HH:mm')}>
          {moment(data.datetime).format('DD/MM/YYYY HH:mm')}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: 'table.user',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.tag != null && b.tag != null) {
        if (
          a.tag.user != null &&
          a.tag.user.fullName != null &&
          a.tag.user.fullName !== '' &&
          b.tag.user != null &&
          b.tag.user.fullName != null &&
          b.tag.user.fullName !== ''
        ) {
          return a.tag.user.fullName >= b.tag.user.fullName;
        }
        return false;
      }
    },
    render: function (data) {
      if (data.tag) {
        if (
          data.tag.user != null &&
          data.tag.user.fullName != null &&
          data.tag.user.fullName !== ''
        ) {
          return (
            <EllipsisDiv title={data.tag.user.fullName}>
              {data.tag.user.fullName}
            </EllipsisDiv>
          );
        }
      }
      return <EllipsisDiv>{'-'}</EllipsisDiv>;
    },
    minWidth: 130,
  },
  {
    label: 'table.container',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.uuid ? data.container.uuid : '-'
          }
        >
          {data.container && data.container.uuid ? data.container.uuid : '-'}
        </EllipsisDiv>
      );
    },
    minWidth: 130,
  },
  {
    label: 'table.type',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : '-'
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : '-'}
        </EllipsisDiv>
      );
    },
    minWidth: 160,
  },
  {
    label: 'table.fill_level',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: 'previousFillLevel',
    render: function (data) {
      return (
        <div>
          <StyledProgressBar
            percentage={
              data.previousFillLevel != null ? data.previousFillLevel : 0
            }
          />
        </div>
      );
    },
    minWidth: 230,
  },
];

export const collectScheduleColumn = [
  {
    label: 'table.route_name',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return <EllipsisDiv title={data.name}>{data.name}</EllipsisDiv>;
    },
    width: 290,
  },
  {
    label: 'table.license_plate',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function (data) {
      return (
        <EllipsisDiv title={data.licensePlate}>{data.licensePlate}</EllipsisDiv>
      );
    },
    width: 125,
  },
  {
    label: 'table.last_collect_status',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv>
          <StatusText status={data.lastCollect}>
            {data.lastCollect === '-'
              ? '-'
              : !data.lastCollect
              ? t('table.success')
              : t('table.failed')}
          </StatusText>
        </EllipsisDiv>
      );
    },
    width: 200,
  },
  {
    label: 'table.next_collect',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: 'datetime',
    render: function (data) {
      return (
        <EllipsisDiv title={checkDay(data.day)}>
          {checkDay(data.day)}
        </EllipsisDiv>
      );
    },
    width: 240,
  },
  {
    type: 'expand',
    expandPannel: function ({ t, ...data }) {
      return (
        <div>
          <CustomTable
            columns={expandedColumns.map((column) => ({ t, ...column }))}
            data={
              data.containers
                ? data.containers.map((container) => ({ t, ...container }))
                : []
            }
            emptyText='No Results'
          />
        </div>
      );
    },
  },
];

const expandedColumns = [
  {
    label: 'dashboard.containers',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    render: function (data) {
      return (
        <EllipsisDiv>
          <ContainerDescriptionWrapper>
            <ContainerName>
              <GarbageBin height='20px' width='20px' fill='#4DA989' />
              {data.name}
            </ContainerName>
            <ContainerAddress>{data.address}</ContainerAddress>
          </ContainerDescriptionWrapper>
        </EllipsisDiv>
      );
    },
    fit: true,
  },
  {
    label: 'table.all_containers',
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    filters: [
      { text: 'Recolhido', value: false },
      { text: 'Falhou', value: true },
    ],
    filterMethod: (value, row) =>
      value === 'Recolhido' && !row.missed ? true : false,
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv>
          {data.missed === '-' ? (
            <CustomTag type='primary'>{t('table.for_collect')}</CustomTag>
          ) : data.missed ? (
            <CustomTag type='danger'>{t('table.failed')}</CustomTag>
          ) : (
            <CustomTag type='success'>{t('table.container_status')}</CustomTag>
          )}
        </EllipsisDiv>
      );
    },
    width: 200,
    headerAlign: 'center',
    align: 'center',
  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusText = styled.span`
  color: ${(props) =>
    props.status === '-' ? 'black' : !props.status ? 'green' : 'red'};
`;

const ContainerDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
`;

const ContainerName = styled.span`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: 700;
`;

const ContainerAddress = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomTable = styled(Table)`
  &.el-table th {
    background-color: transparent !important;

    div {
      color: #9d9d9d;
      font-weight: 600;
    }
  }
`;

const CustomTag = styled(Tag)`
  &.el-tag {
    border-radius: 50px;
  }
`;
