/**
 * Areas Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import { kea } from "kea";

import { put, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import { BASE_API_URL, API_DATE_FORMAT } from "config";
//import { } from 'utils'
//import { } from './utils'

import PropTypes from "prop-types";
//import * as Check from 'validations'
import axios from "axios";
import { decodeColor } from "utils";
import moment from "moment";
import { mapLabelsAndData } from "./dataFetching";

import AppLogic from "containers/App/logic";

const EVENTSURL = BASE_API_URL + "events/";
const EQUIPMENTSSURL = BASE_API_URL + "min-containers/";

const EVENTSBYDAYURLV2 = BASE_API_URL + "v2/events-by-day/";
const ALERTSURL = BASE_API_URL + "alerts/";

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf("month"),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: "day",
  },
};

export default kea({
  path: () => ["scenes", "ClientDashboard"],

  connect: {
    props: [AppLogic, ["user"]],
    actions: [
      AppLogic,
      [
        "logout",
        "watchEvents",
        "changeWatchingEvents",
        "setWebSocketHanlder",
        "getCurrentUser",
      ],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    fetchAreasEquipments: () => true,
    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    fetchDropsList: () => true,
    setDropsNumber: (number) => ({ number }),
    setGlobalNumberOfAlerts: (number) => ({ number }),

    setPagination: (pag) => ({ pag }),

    setDropsList: (list) => ({ list }),

    setFilteredEquipments: (equipments) => ({ equipments }),
    updateFilteredEquipments: (equipments) => ({ equipments }),
    setFilteredEquipmentsNumber: (number) => ({ number }),

    setEquipmentInDetail: (equipment) => ({ equipment }),

    triggerFetch: () => true,

    fetchDrops: (data) => ({ data }),
    setDropsLabels: (labels) => ({ labels }),
    setDropsLabelsKeys: (labels) => ({ labels }),
    setDrops: (data) => ({ data }),
    setLoadingDrops: (loading) => loading,

    setSelectOptions: (options) => ({ options }),
    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    dropsList: [
      null,
      PropTypes.array,
      {
        [actions.setDropsList]: (state, { list }) => list,
        [actions.reset]: () => null,
      },
    ],
    dropsCount: [
      0,
      PropTypes.number,
      {
        [actions.setDropsNumber]: (state, { number }) => number,
        [actions.reset]: () => 0,
      },
    ],

    pagination: [
      { offset: 1, limit: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFiltersForm]: (state) => ({ ...state, offset: 1 }),
        [actions.setEquipmentInDetail]: (state) => ({ ...state, offset: 1 }),
        [actions.reset]: () => ({ offset: 1, limit: 10 }),
      },
    ],

    filteredEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setFilteredEquipments]: (state, { equipments }) => equipments,
        [actions.updateFilteredEquipments]: (state, { equipments }) => [
          ...state,
          ...equipments,
        ],
        [actions.reset]: () => null,
      },
    ],

    filteredEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setFilteredEquipmentsNumber]: (state, { number }) => number,
        [actions.reset]: () => 0,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setEquipmentInDetail]: (state, { equipment }) => equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
        [actions.resetFiltersForm]: () => null,
        [actions.reset]: () => null,
      },
    ],

    drops: [
      [],
      PropTypes.array,
      {
        [actions.setDrops]: (state, { data }) => data,
        [actions.reset]: () => [],
      },
    ],

    dropsLabels: [
      [],
      PropTypes.array,
      {
        [actions.setDropsLabels]: (state, { labels }) => labels,
        [actions.reset]: () => [],
      },
    ],
    dropsLabelsKeys: [
      [],
      PropTypes.array,
      {
        [actions.setDropsLabelsKeys]: (state, { labels }) => labels,
        [actions.reset]: () => [],
      },
    ],

    loadingDrops: [
      false,
      PropTypes.boolean,
      {
        [actions.fetchDrops]: (state, loading) => true,
        [actions.setDrops]: (state, loading) => false,

        [actions.reset]: () => false,
      },
    ],

    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.reset]: () => ({ ...INTERVAL_FORM_DEFAULTS }),
      },
    ],

    selectOptions: [
      [],
      PropTypes.array,
      {
        [actions.setSelectOptions]: (state, { options }) => options,
        [actions.reset]: () => [],
      },
    ],
    globalNumberOfAlerts: [
      0,
      PropTypes.number,
      {
        [actions.setGlobalNumberOfAlerts]: (state, { number }) => number,
        [actions.reset]: () => 0,
      },
    ],
  }),

  selectors: ({ selectors }) => ({}),

  start: function* () {
    const {
      triggerFetch,
      fetchAreasEquipments,
      reset,
      watchEvents,
      setWebSocketHanlder,
    } = this.actions;

    yield put(reset());

    yield put(triggerFetch());
    yield put(fetchAreasEquipments());
    yield put(setWebSocketHanlder(this.workers.handle));
    yield put(watchEvents());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.triggerFetch]: [
      workers.fetchDropsList,
      workers.fetchDrops,
      workers.fetchGlobalNumberOfAlerts,
    ],
    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,
    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,
    [actions.fetchDropsList]: workers.fetchDropsList,
    [actions.setPagination]: workers.fetchDropsList,
    [actions.fetchDepositsToPlot]: workers.fetchDrops,
    [actions.setEquipmentInDetail]: [
      workers.fetchEquipmentInDetailNoFetchs,
      workers.fetchDropsList,
      workers.fetchDrops,
      workers.fetchGlobalNumberOfAlerts,
    ],
    [actions.setFiltersForm]: [
      workers.fetchDropsList,
      workers.fetchDrops,
      workers.fetchGlobalNumberOfAlerts,
    ],
    [actions.resetFiltersForm]: [
      workers.fetchDropsList,
      workers.fetchDrops,
      workers.fetchGlobalNumberOfAlerts,
    ],
  }),

  workers: {
    *fetchDropsList() {
      yield delay(500);

      const user = yield this.get("user");
      const filtersForm = yield this.get("filtersForm");
      const equipmentInDetail = yield this.get("equipmentInDetail");

      const { setDropsList, setDropsNumber } = this.actions;

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          type: "drop",
          user: user.id,
          date_after: moment(date_after).toISOString(),
          date_before: moment(date_before).endOf("day").toISOString(),
          ordering: "-datetime",
        };

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }
        const response = yield call(axios.get, EVENTSURL, {
          params,
        });
        if (response.data.results) {
          yield put(setDropsList(response.data.results));
          yield put(setDropsNumber(response.data.count));
        } else {
          yield put(setDropsList([]));
          yield put(setDropsNumber(0));
        }
      } catch (err) {
        console.log({ err });
        yield put(setDropsList([]));
        yield put(setDropsNumber(0));
      }
    },

    *fetchDrops(action) {
      yield delay(100);
      const { setDropsLabels, setDrops, setDropsLabelsKeys } = this.actions;
      const user = yield this.get("user");
      const filtersForm = yield this.get("filtersForm");
      const equipmentInDetail = yield this.get("equipmentInDetail");

      const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
      const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

      let params = {
        type: "drop",
        user: user.id,
        date_after: moment(date_after).toISOString(),
        date_before: moment(date_before).endOf("day").toISOString(),
      };

      if (equipmentInDetail) {
        params = { ...params, container: equipmentInDetail.id };
      }

      try {
        const { data } = yield call(axios.get, EVENTSBYDAYURLV2, {
          params,
        });
        if (data) {
          const response = mapLabelsAndData(
            data,
            filtersForm.start.value.clone(),
            filtersForm.end.value.clone(),
            filtersForm.interval.value
          );
          yield put(setDropsLabels(response.labels));
          yield put(setDrops(response.data));
          yield put(setDropsLabelsKeys(response.labelsKeys));
        }
      } catch (err) {
        console.log({ err });

        yield put(setDropsLabels([]));
        yield put(setDrops([]));
      }
    },

    *fetchGlobalNumberOfAlerts(action) {
      const { setGlobalNumberOfAlerts } = this.actions;
      const user = yield this.get("user");
      const filtersForm = yield this.get("filtersForm");
      const equipmentInDetail = yield this.get("equipmentInDetail");

      try {
        let params = {};

        if (equipmentInDetail) {
          params = { container__id: equipmentInDetail.id };
        }

        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        const { data } = yield call(axios.get, ALERTSURL, {
          params: {
            ...params,
            limit: 1,
            user: user.id,
            start_date_after: moment(date_after).toISOString(),
            start_date_before: moment(date_before).endOf("day").toISOString(),
          },
        });
        yield put(setGlobalNumberOfAlerts(data.count));
      } catch (err) {
        console.log({ err });
        yield put(setGlobalNumberOfAlerts(0));
      }
    },

    *handle(message) {
      const { triggerFetch, fetchEquipmentInDetailNoFetchs } = this.actions;
      const equipmentInDetail = yield this.get("equipmentInDetail");

      if (message.event_type === "event" && message.content) {
        if (message.event_type === "event") {
          if (
            (message.content.type === "alert" ||
              message.content.type === "pick" ||
              message.content.type === "drop") &&
            message.type === "new"
          ) {
            yield put(triggerFetch());
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.sender &&
            message.sender === "transaction" &&
            message.type === "new"
          ) {
            yield put(triggerFetch());
          }
        }
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const { setFetchedEquipmentInDetailNoFetchs } = this.actions;

      try {
        if (
          action != null &&
          action.payload != null &&
          action.payload.equipment != null &&
          action.payload.equipment.id != null
        ) {
          const { data } = yield call(
            axios.get,
            EQUIPMENTSSURL + `${action.payload.equipment.id}/`
          );
          data.color = decodeColor(data.type);
          yield put(setFetchedEquipmentInDetailNoFetchs(data));
        }
      } catch (error) {
        console.log({ error });
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
    },

    *fetchAreasEquipments(action) {
      yield delay(600);
      const { setFilteredEquipments, setFilteredEquipmentsNumber } =
        this.actions;

      try {
        let next = 1;
        let number = 0;

        while (next) {
          let params = {
            limit: 500,
            offset: (next - 1) * 500,
          };

          let results = [];
          const { data } = yield call(axios.get, EQUIPMENTSSURL, {
            params,
          });

          if (data.results && data.results.length) {
            number = number + data.results.length;

            data.results.map((container) => {
              container.color = decodeColor(container.type);
              results.push(container);
              return container;
            });
          }
          next = data.next ? next + 1 : 0;

          yield put(setFilteredEquipments(results));
          yield put(setFilteredEquipmentsNumber(number));
        }
      } catch (error) {
        console.log({ error });
        yield put(setFilteredEquipments([]));
        yield put(setFilteredEquipmentsNumber(0));
      }
    },
  },
});
