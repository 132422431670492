import React, { Component } from "react";
import Logic from "./logic";
import styled, { css } from "styled-components";
import { MAP_BOX_KEY, AVAILABLE_CONTAINERS } from "config";
import ReactMapboxGl, { Cluster, Marker } from "react-mapbox-gl";
// import RouteFilterMaps from 'containers/RouteFilterMaps'
import MapboxGL from "mapbox-gl";
import PaperMarker from "icons/Markers/Paper";
import GlassMarker from "icons/Markers/Glass";
import PlasticMarker from "icons/Markers/Plastic";
import BinMarker from "icons/Markers/Bin";
import decodeType from "utils/decodeType";

import Fullscreen from "icons/Fullscreen";

import {
  MAPCENTER,
  MAPINITIALBOUNDS,
  ZOOMLEVEL,
  MAXZOOMLEVEL,
} from "config";
import { withTranslation } from "react-i18next";

import { Theme } from "globalStyles";
/*eslint-disable*/
import BackArrow from "icons/BackArrow";
import ResponsiveHandler from "components/ResponsiveHandler";

const Map = ReactMapboxGl({
  accessToken: MAP_BOX_KEY,
  maxZoom: MAXZOOMLEVEL,
});

const COLORS = {
  plastic: Theme.warningColor,
  paper: Theme.infoColor,
  glass: Theme.successColor,
  bio_waste: Theme.darkPrimaryColor,
  undifferentiated: Theme.darkPrimaryColor,
  polygonFill: Theme.lightPrimaryColor,
  polygonLine: Theme.primaryColor,
};

const getMarker = (
  marker,
  equipmentInDetail,
  dimensions = { height: "20px", width: "20px" }
) => {
  switch (marker.type) {
    case "y":
      return <PlasticMarker {...dimensions}></PlasticMarker>;
    case "plastic":
      return <PlasticMarker {...dimensions}></PlasticMarker>;
    case "b":
      return <PaperMarker {...dimensions}></PaperMarker>;
    case "paper":
      return <PaperMarker {...dimensions}></PaperMarker>;
    case "g" || "glass":
      return <GlassMarker {...dimensions}></GlassMarker>;
    case "glass":
      return <GlassMarker {...dimensions}></GlassMarker>;
    case "u":
      return <BinMarker {...dimensions}></BinMarker>;
    case "undifferentiated":
      return <BinMarker {...dimensions}></BinMarker>;

    default:
      return <BinMarker {...dimensions}></BinMarker>;
  }
};

const FirstStageLegend = ({ props }) => {
  const { t, responsiveHandlers } = props;
  return (
    <FlexWrapperLegend>
      <MapCaption>
        <FlexLegend marginTop="0px">
          <ClusterMarkerLegend>
            <ClusterTextLegend>{4}</ClusterTextLegend>
          </ClusterMarkerLegend>
          <LegentTextWrapper>{t("map.cluster")}</LegentTextWrapper>
        </FlexLegend>
        {AVAILABLE_CONTAINERS.map((key, index) => (
          <FlexLegend key={`legend${index}`}>
            {getMarker({ type: key }, null, {
              height: responsiveHandlers.height > 750 ? "26px" : "20px",
              width: responsiveHandlers.height > 750 ? "26px" : "20px",
            })}
            <LegentTextWrapper>{t(`map.${key}`)}</LegentTextWrapper>
          </FlexLegend>
        ))}
      </MapCaption>
    </FlexWrapperLegend>
  );
};

class ContainerMapClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerAddress: "NO_ADDRESS",
      activeCollapse: ["1", "2"],
      zoom: ZOOMLEVEL,
      center: MAPCENTER,
      popupLocation: null,
      user: null,
      activeTab: "1",
      addresses: {},
      mapBounds: MAPINITIALBOUNDS,
      linkedArea: null,
      dialogVisible: false,
    };
  }

  findNewBounds = (nextProps) => {
    let delay = 0;

    if (nextProps.equipmentInDetail) {
      var bounds = new MapboxGL.LngLatBounds();
      bounds.extend(nextProps.equipmentInDetail.location.coordinates);
      setTimeout(
        () =>
          this.setState({
            mapBounds: [
              [bounds._sw.lng, bounds._sw.lat],
              [bounds._ne.lng, bounds._ne.lat],
            ],
          }),
        delay
      );
    } else if (nextProps.equipments && nextProps.equipments.length) {
      var bounds = new MapboxGL.LngLatBounds();
      nextProps.equipments.forEach(function (feature) {
        bounds.extend(feature.location.coordinates);
      });

      setTimeout(
        () =>
          this.setState({
            mapBounds: [
              [bounds._sw.lng, bounds._sw.lat],
              [bounds._ne.lng, bounds._ne.lat],
            ],
          }),
        0
      );
    }
  };

  closePopup = (e) => {
    this.setState({
      popedArea: null,
    });
  };

  resetZoom() {
    this.resetMapBounds();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.equipments !== nextProps.equipments) {
      this.findNewBounds(nextProps);
    }
    if (this.props.linkedArea !== nextProps.linkedArea) {
      this.findNewBounds(nextProps);
    } else if (this.props.areas !== nextProps.areas && nextProps.areas) {
      this.findNewBounds(nextProps);
    } else if (nextProps.equipmentInDetail !== this.props.equipmentInDetail) {
      this.findNewBounds(nextProps);
    }
  }

  showCluster(coordinates, userColection) {
    const { setEquipmentInDetail } = this.props;
    // setEquipmentInDetail(null);
    let zoom = this.map.getZoom();
    if (this.state.zoom < 20) {
      zoom = [parseInt(zoom) + 3];
    } else {
      zoom = [parseInt(zoom)];
    }
    this.setState({ center: coordinates, zoom: zoom });
  }

  clusterMarker = (coordinates, pointCount, getLeaves) => {
    let leaves = getLeaves();
    let userColection = [];
    for (let i = 0; i < leaves.length; i++) {
      userColection.push(leaves[i].props.markerInfo);
    }

    return (
      <Marker
        coordinates={coordinates}
        key={coordinates}
        style={{ outline: "none", cursor: "pointer" }}
        anchor="center"
        onClick={this.showCluster.bind(this, coordinates, userColection)}
        tabIndex={-1}
      >
        <ClusterMarkerLegend>
          <ClusterTextLegend>{pointCount}</ClusterTextLegend>
        </ClusterMarkerLegend>
      </Marker>
    );
  };

  render() {
    const {
      equipments,
      equipmentInDetail,
      setEquipmentInDetail,
      t,
    } = this.props;
    const { center, zoom } = this.state;

    return (
      <Wrapper>
        <CustomMap
          style="mapbox://styles/mapbox/streets-v9"
          className="markercluster-map"
          fitBoundsOptions={{
            padding: { top: 100, bottom: 100, left: 100, right: 100 },
            linear: false,
          }}
          onClick={this.closePopup}
          fitBounds={this.state.mapBounds}
          onDragStart={() => this.closePopup}
          onStyleLoad={(el) => (this.map = el)}
          zoom={zoom}
          center={center}
        >
          {equipments && equipments.length > 0 && (
            <Cluster
              ClusterMarkerFactory={this.clusterMarker}
              radius={80}
              minZoom={5}
              maxZoom={16}
              zoomOnClickPadding={100}
            >
              {equipments.map((marker, index) => (
                <Marker
                  key={`marker${marker.id}${index}`}
                  coordinates={[
                    marker.location.coordinates[0],
                    marker.location.coordinates[1],
                  ]}
                  markerInfo={marker}
                  anchor="center"
                  style={{ outline: "none", cursor: "pointer" }}
                  onClick={() => setEquipmentInDetail({ ...marker })}
                >
                  {getMarker(marker, equipmentInDetail, {
                    height:
                      equipmentInDetail != null &&
                      equipmentInDetail.id === marker.id
                        ? "40px"
                        : "30px",
                    width:
                      equipmentInDetail != null &&
                      equipmentInDetail.id === marker.id
                        ? "40px"
                        : "30px",
                  })}
                </Marker>
              ))}
            </Cluster>
          )}

          <HelpsWrapper>
            {!equipmentInDetail ? (
              <MapHelp cursor={""}>
                <Fullscreen />
                {t("map.click_device")}
              </MapHelp>
            ) : (
              <MapHelp
                cursor={"pointer"}
                onClick={() => {
                  setEquipmentInDetail(null);
                }}
                showAlways
              >
                <BackArrow />
                {t("map.view_all")}
              </MapHelp>
            )}
          </HelpsWrapper>

          {!equipmentInDetail && <FirstStageLegend props={this.props} />}

          {equipmentInDetail && (
            <Details>
              <PopupComponent>
                <PopupBody>
                  <PopupBodyText>
                    <PopupBodyFirst>
                      {t("map.container")}
                    </PopupBodyFirst>
                    <PopupBodySecond>{equipmentInDetail.name}</PopupBodySecond>
                  </PopupBodyText>
                  {equipmentInDetail.type != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t("table.type")}</PopupBodyFirst>
                      <PopupBodySecond>
                        {t(`table.${decodeType(equipmentInDetail.type)}`)}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.address != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t("map.address")}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.address}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.fillLevel != null &&
                  !isNaN(Number(equipmentInDetail.fillLevel)) && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t("map.fill_level")}</PopupBodyFirst>
                      <PopupBodySecond>
                        {Number(equipmentInDetail.fillLevel).toFixed(2)}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                  {equipmentInDetail.capacity != null && (
                    <PopupBodyText>
                      <PopupBodyFirst>{t("map.capacity")}</PopupBodyFirst>
                      <PopupBodySecond>
                        {equipmentInDetail.capacity}
                      </PopupBodySecond>
                    </PopupBodyText>
                  )}
                </PopupBody>
              </PopupComponent>
            </Details>
          )}
        </CustomMap>
      </Wrapper>
    );
  }
}

export default withTranslation()(
  ResponsiveHandler(Logic(ContainerMapClientDashboard))
);

const Wrapper = styled.div`
  min-height: 440px;

  height: calc(40vh + 15px);

  width: 100%;

  ${({ theme }) => theme.smallDesktop`
  min-height: auto;
`}

  @media (max-height: 768px) {
    height: 320px;
  }
`;

const CustomMap = styled(Map)`
  .mapboxgl-canvas {
    width: 100% !important;
    outline: none;
  }
  &.mapboxgl-map {
    font-family: ${Theme.fontFamily};
    font-size: 16px;
  }
`;

const ClusterMarkerLegend = styled.div`
  width: 20px;
  height: 20px;
  background: ${COLORS["polygonFill"]};
  outline: none;
  position: relative;
  cursor: pointer;
  border: 2px solid ${COLORS["polygonLine"]};
  border-radius: 50%;
  margin: 0 0 0 1px;

  @media screen and (min-height: 750px) {
    height: 26px;
    width: 26px;
  }
`;

const ClusterTextLegend = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  color: #ffffff;
  width: fit-content;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  transform: translate(-50%, -50%);
  outline: none;
  cursor: pointer;
`;

const PopupComponent = styled.div`
  height: auto;
  width: auto;

  ${({ theme }) => theme.smallDesktop`
  min-width: 200px;
  `}
`;

const Details = styled.div`
  position: absolute;
  z-index: 100;
  background: ${Theme.white};
  bottom: 8px;
  right: 8px;
  margin: auto 0 0;
  height: fit-content;

  ${({ theme }) => theme.smallDesktop`
    top: 0;
    bottom: 0;
    right: 8px;
    margin: auto 0;
  `}
`;

const PopupBody = styled.div`
  padding: 12px 8px;
`;

const PopupBodyText = styled.div`
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  margin-top: ${(props) => props.marginTop};
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-height: 750px) {
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const PopupBodyFirst = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ theme }) => theme.smallDesktop`
  font-size: 14px;
  line-height: 14px;
  `}
  @media screen and (min-height: 750px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const PopupBodySecond = styled.div`
  display: flex;
  font-size: 10px;
  line-height: 8px;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;

  ${({ theme }) => theme.smallDesktop`
    font-size: 12px;
    line-height: 12px;
  `}

  @media screen and (min-height: 950px) {
    margin-top: 5px;
    margin-left: 0;
  }
`;
const MapHelp = styled.div`
  background-color: white;
  padding: 8px 11px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 5px;
  cursor: ${(props) => props.cursor};
  width: fit-content;
  z-index: 100;

  ${(props) =>
    props.showAlways &&
    css`
      display: block;
    `}

  @media screen and (min-height: 750px) {
    display: block;
  }
`;

const HelpsWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  /* z-index: 100; */
`;

const Bold = styled.span`
  font-weight: bold;
`;

const FlexLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) => props.marginLeft};

  & + & {
    margin-top: 5px;
  }
`;

const LegentTextWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-left: 5px;
  margin-left: ${(props) => props.marginLeft};
  white-space: nowrap;
`;

const MapCaption = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: ${Theme.white};
  padding: 5px;
  z-index: 100;
`;

const FlexWrapperLegend = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
`;
