import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle fill={props.fill || "#2987CF"} cx={20} cy={20} r={20} />
        <g fill={props.iconFill || "#FFF"} fillRule="nonzero">
          <path d="M25.514 15.974a.475.475 0 00.45-.65 3.074 3.074 0 00-2.846-1.883h-.595a2.559 2.559 0 00-2.506-2.058h-1.034a2.56 2.56 0 00-2.506 2.058h-.595c-1.287 0-2.391.78-2.847 1.884-.128.31.11.65.451.65h12.028zm-6.53-3.641h1.033c.707 0 1.308.467 1.51 1.108h-4.054a1.586 1.586 0 011.51-1.108zM14.416 26.989c.058.77.745 1.374 1.563 1.374h6.966c.818 0 1.505-.604 1.563-1.374l.732-9.703H13.684l.732 9.703zm6.474-7.065c.014-.272.26-.482.548-.468.288.014.51.245.495.517l-.278 5.26a.51.51 0 01-.522.467c-.3 0-.536-.237-.522-.517l.279-5.26zm-3.404-.468c.288-.014.534.196.548.468l.279 5.259c.014.28-.222.517-.522.517a.51.51 0 01-.522-.468l-.278-5.259a.505.505 0 01.495-.517z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
