import React, { Component } from "react";
import styled from "styled-components";
import StyledButton from "components/StyledButton";
import { Link } from "react-router-dom";
import { Theme } from "globalStyles";

export default class NotFound extends Component {
  render() {
    return (
      <Wrapper>
        <Code>404</Code>
        <Message>Page Not Found</Message>

        <Link to="/">
          <StyledButton>Return Home </StyledButton>
        </Link>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const Message = styled.span`
  font-size: 26px;
  margin: 15px auto;
  color: ${Theme.darkPrimaryColor};
`;

const Code = styled.span`
  font-size: 150px;
  color: ${Theme.errorColor};
`;
