/**
 * Disclaimer Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Theme } from "globalStyles";
import { Dialog } from "element-react";

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <Dialog.Body>{children}</Dialog.Body>
  </Wrapper>
);

const Wrapper = styled(Dialog)`
  &.el-dialog--small {
    width: 100%;
    max-width: 20rem;
    margin: auto;
    top: 0 !important;
    left: 0;
    transform: none;
    position: initial;
  }
  .el-dialog__header {
    padding: 20px;
  }

  .el-dialog__body {
    padding: 0 20px 20px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: ${Theme.textonSecondary};
    margin-left: 8px;
  }

  .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close{
    color: ${Theme.secondaryColor};
  }

  &.el-dialog--large{
    max-width: calc(100vw - 8rem);
    padding: 40px 20px 20px;
    width: auto;

    .el-dialog__header{
      display: none;
    }
  }

  &.el-dialog{
    top: 15%;
    bottom: 15%;
    overflow: scroll;
    height: fit-content;
    max-height: calc(100vh - 35%);
  }
`;
