import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12c0 5.523-4.477 10-10 10a10.02 10.02 0 01-2.363-.281C5.255 20.657 2 16.709 2 12s3.255-8.657 7.637-9.719A10.022 10.022 0 0112 2c5.523 0 10 4.477 10 10z"
        fill="#E04955"
      />
      <path
        d="M9.637 2.281v19.438C5.255 20.657 2 16.709 2 12s3.255-8.657 7.637-9.719z"
        fill="#5DB33A"
      />
      <path
        d="M9.637 16.233a4.233 4.233 0 100-8.466 4.233 4.233 0 000 8.466z"
        fill="#FED22B"
      />
      <path
        d="M9.637 15.018a2.213 2.213 0 01-2.213-2.213V9.876h4.426v2.93c0 1.222-.99 2.212-2.213 2.212z"
        fill="#FFF5F4"
      />
      <path
        d="M8.87 11.99h-.453a.134.134 0 00-.134.133v.344a.36.36 0 00.721 0v-.344a.133.133 0 00-.133-.133zM9.864 11.99H9.41a.133.133 0 00-.133.133v.344a.36.36 0 00.72 0v-.344a.133.133 0 00-.133-.133zM9.864 10.841H9.41a.133.133 0 00-.133.134v.343a.36.36 0 00.72 0v-.343a.133.133 0 00-.133-.134zM9.864 13.138H9.41a.133.133 0 00-.133.133v.344a.36.36 0 00.72 0v-.344a.134.134 0 00-.133-.133zM10.857 11.99h-.453a.134.134 0 00-.134.133v.344a.36.36 0 00.72 0v-.344a.133.133 0 00-.133-.133z"
        fill="#4377C6"
      />
      <path
        d="M11.85 9.57H7.424a.307.307 0 00-.307.306v2.93c0 1.389 1.13 2.52 2.52 2.52 1.39 0 2.52-1.131 2.52-2.52v-2.93a.307.307 0 00-.307-.306zm-.307 3.235a1.908 1.908 0 01-1.906 1.907 1.908 1.908 0 01-1.906-1.907v-2.622h3.812v2.622z"
        fill="#E5646E"
      />
      <path
        d="M4.213 12a9.999 9.999 0 005.424 8.893v.826C5.255 20.657 2 16.709 2 12s3.255-8.657 7.637-9.719v.826A9.999 9.999 0 004.213 12z"
        fill="#5DB33A"
      />
      <path
        d="M4.213 12a9.999 9.999 0 005.424 8.893v.826C5.255 20.657 2 16.709 2 12s3.255-8.657 7.637-9.719v.826A9.999 9.999 0 004.213 12z"
        fill="#5CA33B"
      />
      <path
        d="M9.637 20.893v.826a10.02 10.02 0 003.47.22 9.953 9.953 0 01-3.47-1.046zM13.107 2.06a10.1 10.1 0 00-3.47.221v.826a9.906 9.906 0 013.47-1.046z"
        fill="#DA2F3C"
      />
    </svg>
  )
}

export default SvgComponent
