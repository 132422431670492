import React from "react";
import moment from "moment";
import styled from "styled-components";
import decodeType from "utils/decodeType";

const columns = [
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return <EllipsisDiv title={moment(data.datetime).format("DD/MM/YYYY HH:mm")}>{moment(data.datetime).format("DD/MM/YYYY HH:mm")}</EllipsisDiv>;
    },
    width: 200,
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.container && a.container.name && b.container && b.container.name) {
        return a.container.name >= b.container.name;
      }
      return false;
    },
    render: function (data) {
      return (
        <EllipsisDiv title={data.container && data.container.name ? data.container.name : "-"}>
          {data.container && data.container.name ? data.container.name : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 115,
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv title={data.container && data.container.type
          ? t(`table.${decodeType(data.container.type)}`)
          : "-"}>
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.earned_points",

    sortMethod: function (a, b) {
      if (a != null && b != null) {
        const first = a.earnedPoints != null ? a.earnedPoints : 0;
        const second = b.earnedPoints != null ? b.earnedPoints : 0;
        return first >= second;
      }
      return false;
    },
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "points",
    render: function (data) {
      return <EllipsisDiv title={data.earnedPoints != null ? data.earnedPoints : 0}>{data.earnedPoints != null ? data.earnedPoints : 0}</EllipsisDiv>;
    },
    minWidth: 90,

  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default columns;
