/**
 * StyledButton Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { Button } from "element-react";
import { Theme } from "globalStyles";

export default ({ children, ...props }) => (
  <CustomButton {...props}>{children}</CustomButton>
);

const CustomButton = styled(Button)`
  &.el-button {
    border: 1px solid  ${Theme.darkPrimaryColor};
    background-color:  ${Theme.darkPrimaryColor};
    color: ${Theme.textOnPrimary};
    height: 36px;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;

    font-family: ${Theme.fontFamily};

    width: 100%;
    margin: 0;

    & + & {
      margin-left: 0;
    }

    ${({ theme }) => theme.desktop`
      margin: ${({ margin }) => margin};


      & + & {
      }
  `}

    :hover,
    :active,
    :focus {
      border: 1px solid ${Theme.darkPrimaryColor} !important;
      color: ${Theme.textOnPrimary};
      background-color: ${Theme.darkPrimaryColor};
      opacity: 0.5;
    }

    ${(props) =>
      props.type === "primary" &&
      css`
        background-color: ${Theme.primaryColor};
        color: ${Theme.white};
        border: 1px solid ${Theme.primaryColor} !important;
        :hover,
        :focus {
          background-color: ${Theme.secondaryColor};
          border: 1px solid ${Theme.secondaryColor} !important;
          color: ${Theme.textOnPrimary};
          opacity: 1;
        }
        :active {
          border: 1px solid ${Theme.secondaryColor} !important;
        }
      `}

      ${(props) =>
      props.type === "secondary" &&
      css`
        background-color: ${Theme.secondaryColor};
        color: ${Theme.white};
        border: 1px solid ${Theme.secondaryColor} !important;
        :hover,
        :focus {
          background-color: ${Theme.darkSecondaryColor};
          border: 1px solid ${Theme.darkSecondaryColor} !important;
          color: ${Theme.textOnPrimary};
          opacity: 1;
        }
        :active {
          border: 1px solid ${Theme.secondaryColor} !important;
        }
      `}

      ${(props) =>
      props.type === "neutral" &&
      css`
        background-color: ${Theme.white};
        color: ${Theme.textonSecondary};
        border: 1px solid #dddfe4 !important;
        &:hover,
      &:focus,
      &:active {
        background-color: ${Theme.white};
        color: ${Theme.textonSecondary};
        border: 1px solid #6e7587;
        opacity: 1;

      }
       
      `}
  }
`;
