/**
 * Downloadbutton Container
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import { Theme } from "globalStyles";
import styled from "styled-components";
import StyledOption from "components/StyledOption";

export default ({ children, ...props }) => {
  const { download } = props;
  return (
    <OptionsWrapper>
      <StyledOption onClick={() => download("csv")}>CSV</StyledOption>
      <StyledOption onClick={() => download("xls")}>XLS</StyledOption>
      <StyledOption onClick={() => download("xlsx")}>XLSX</StyledOption>
    </OptionsWrapper>
  );
};

const OptionsWrapper = styled.div`
  padding: 6px 0;
  background-color: ${Theme.textOnPrimary};
  min-width: 150px;
`;
