/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * FundingFooter Container
 *
 * @author Ricardo Leite <rleite@ubiwhere.com>
 */
import React, { Component } from "react";
import styled from "styled-components";
import { FUNDING_FOOTER_LOGOS, COMMON_FUNDING_FOOTER_LOGOS } from "config";
import { getLogos } from "./utils";

class FundingFooter extends Component {
  state = {
    logos: [],
  };

  componentDidMount() {
    this.handleLogos();
  }

  async handleLogos() {
    let images = [];
    images = await getLogos(FUNDING_FOOTER_LOGOS, COMMON_FUNDING_FOOTER_LOGOS);
    this.setState({ ...this.state, logos: images });
  }

  render() {
    const { logos } = this.state;

    return (
      <FlexWrapper>
        {logos && (
          <LogoWrapper>
            {logos.map((val, index) => (
              <Logo key={index} src={val.default} />
            ))}
          </LogoWrapper>
        )}
      </FlexWrapper>
    );
  }
}

export default FundingFooter;

const FlexWrapper = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
`;
const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 1rem;
  padding: 0.5rem 0;
`;

const Logo = styled.img`
  height: 1.5rem;
  object-fit: contain;

  @media only screen and (min-width: 768px) {
    height: 2rem;
  }
`;
