/**
 * StyledOption Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Theme } from "globalStyles";

export default ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

const Wrapper = styled.div`
  font-family: ${Theme.fontFamily};
  color: ${Theme.textonSecondary};
  background-color: transparent;
  font-size: 14px;
  padding: 8px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  line-height: 1.5;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${Theme.secondaryColorHover};
  }
`;
