/**
 * DoughnutWithText Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import { Doughnut } from "react-chartjs-2";

import { Theme } from "globalStyles";

// some of this code is a variation on https://jsfiddle.net/cmyker/u6rr5moq/
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 150).toFixed(2);
    ctx.font = `bold normal ${fontSize}em ${Theme.fontFamily}`;
    ctx.textBaseline = "middle";

    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = Math.round(height / 2);

    ctx.fillText(text, textX, textY);
  },
});

export default ({ children, ...props }) => <Doughnut {...props} />;
