/**
 * NotificationsDialog Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
 import React from "react";
 import styled from "styled-components";
 import { Dialog } from "element-react";
 
 export default ({ children, ...props }) => (
   <Wrapper {...props} showClose={false}>
     <Dialog.Body>{children}</Dialog.Body>
   </Wrapper>
 );
 
 const Wrapper = styled(Dialog)`
   &.el-dialog--small {
       top: 80px !important;
    bottom: auto;
     
    margin-left: auto;
    width: 100%;
    max-width: calc(100vw - 4rem);

    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, 0);

    ${({ theme }) => theme.smallDesktop`
        width: 400px;

        margin: 0;
        left: auto;
        right: 16px;
        transform: none;
    `}
   }
   .el-dialog__header {
     padding: 20px;
   }
 
   .el-dialog__body {
       
        padding: 0 20px 20px;

        overflow: scroll;
        min-height: 200px;

        max-height: calc(100vh - 80px - 64px - 200px);


        ${({ theme }) => theme.smallDesktop`
            max-height: 464px;
        `}
   }
 `;
 