// import User from 'icons/User'
import ContainerIcon from './icons/ContainerIcon'
import Deposit from './icons/Deposit'
import Pick from './icons/Pick'
import Maintenance from './icons/Maintenance'
import Alert from './icons/Alert'
import ErrorIcon from './icons/ErrorIcon'
import Dashboard from './icons/Dashboard'
import HistoryIcon from './icons/HistoryIcon'
import Occurrences from './icons/Occurrences'

const SECTIONS = [
  {
    name: 'dashboard.title',
    key: 'dashboard',
    icon: Dashboard,
    path: '/',
    admin_only: false
  },
  {
    name: 'containers.title',
    key: 'containers',
    icon: ContainerIcon,
    path: '/containers/',
    admin_only: true
  },
  {
    name: 'deposits.title',
    key: 'deposits',
    icon: Deposit,
    path: '/deposits/',
    admin_only: true
  },
  {
    name: 'collects.title',
    key: 'collects',
    icon: Pick,
    path: '/collects/',
    admin_only: true
  },
  {
    name: 'maintenances.title',
    key: 'maintenances',
    icon: Maintenance,
    path: '/maintenances/',
    admin_only: true
  },
  {
    name: 'transactions.title',
    key: 'transactions',
    icon: HistoryIcon,
    path: '/transactions/',
    admin_only: false
  },
  {
    name: 'alerts.title',
    key: 'alerts',
    icon: Alert,
    path: '/alerts/',
    admin_only: true
  },
  {
    name: 'errors.title',
    key: 'errors',
    icon: ErrorIcon,
    path: '/errors/',
    admin_only: true
  },
  {
    name: 'occurrences.title',
    key: 'occurrences',
    icon: Occurrences,
    path: '/occurrences/',
    admin_only: false
  }
]

export default SECTIONS
