import React from "react";
import moment from "moment";
import CheckIcon from "icons/CheckIcon";
import CrossIcon from "icons/CrossIcon";
import styled from "styled-components";
import { Theme } from "globalStyles";
import decodeType from "utils/decodeType";
import StyledProgressBar from "components/StyledProgressBar";

export const columns = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 90,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: "table.fill_level",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (!!a && !!b) {
        const first = a.containerFillLevel != null ? a.containerFillLevel : 0;
        const second = b.containerFillLevel != null ? b.containerFillLevel : 0;
        return first >= second;
      }
      return false;
    },
    sortable: true,
    render: function (data) {
      return (
        <div>
          <StyledProgressBar
            percentage={
              data.containerFillLevel != null ? parseFloat(data.containerFillLevel).toFixed(2) : 0
            }
          />
        </div>
      );
    },
    width: 220,
  },
  {
    label: "table.user",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.tag != null && b.tag != null) {
        if (
          a.tag.user != null &&
          a.tag.user.fullName != null &&
          a.tag.user.fullName !== "" &&
          b.tag.user != null &&
          b.tag.user.fullName != null &&
          b.tag.user.fullName !== ""
        ) {
          return a.tag.user.fullName >= b.tag.user.fullName;
        }
        return false;
      }
    },
    render: function (data) {
      if (data.tag) {
        if (
          data.tag.user != null &&
          data.tag.user.fullName != null &&
          data.tag.user.fullName !== ""
        ) {
          return <EllipsisDiv>{data.tag.user.fullName}</EllipsisDiv>;
        }
      }
      return <EllipsisDiv>{"-"}</EllipsisDiv>;
    },
    minWidth: 150,
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.uuid ? data.container.uuid : "-"
          }
        >
          {data.container && data.container.uuid ? data.container.uuid : "-s"}
        </EllipsisDiv>
      );
    },
    minWidth: 140,
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : "-"
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
    width: 150
  },
  {
    label: "table.earned_points",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a != null && b != null) {
        const first = a.earnedPoints != null ? a.earnedPoints : 0;
        const second = b.earnedPoints != null ? b.earnedPoints : 0;
        return first >= second;
      }
      return false;
    },
    sortable: true,
    prop: "points",
    render: function (data) {
      return (
        <EllipsisDiv title={data.earnedPoints != null ? data.earnedPoints : 0}>
          {data.earnedPoints != null ? data.earnedPoints : 0}
        </EllipsisDiv>
      );
    },
    minWidth: 110,
  },
  {
    label: "table.completed",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.complete && b.complete) {
        return a.complete >= b.complete;
      }
      return false;
    },
    render: function (data) {
      return (
        <CenterIcon>
          {data.complete ? (
            <CheckIcon
              height={"24px"}
              width={"24px"}
              fill={Theme.successColor}
            />
          ) : (
            <CrossIcon height={"24px"} width={"24px"} fill={Theme.errorColor} />
          )}
        </CenterIcon>
      );
    },
    minWidth: 130,
  },
];

export const columns_simple = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 70,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}
        </EllipsisDiv>
      );
    },
    width: 180,
  },
  {
    label: "table.fill_level",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (!!a && !!b) {
        const first = a.containerFillLevel != null ? a.containerFillLevel : 0;
        const second = b.containerFillLevel != null ? b.containerFillLevel : 0;
        return first >= second;
      }
      return false;
    },
    sortable: true,
    render: function (data) {
      return (
        <div>
          <StyledProgressBar
            percentage={
              data.containerFillLevel != null ? parseFloat(data.containerFillLevel).toFixed(2) : 0
            }
          />
        </div>
      );
    },
    width: 220,
  },
  {
    label: "table.container",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.uuid ? data.container.uuid : "-"
          }
        >
          {data.container && data.container.uuid ? data.container.uuid : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 125,
  },
  {
    label: "table.type",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    render: function (data, { t }) {
      return (
        <EllipsisDiv
          title={
            data.container && data.container.type
              ? t(`table.${decodeType(data.container.type)}`)
              : "-"
          }
        >
          {data.container && data.container.type
            ? t(`table.${decodeType(data.container.type)}`)
            : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 90,
  },
  {
    label: "table.completed",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    align: "center",
    headerAlign: "center",
    sortable: true,
    minWidth: 115,
    sortMethod: function (a, b) {
      if (a.complete && b.complete) {
        return a.complete >= b.complete;
      }
      return false;
    },
    render: function (data) {
      return (
        <CenterIcon>
          {data.complete ? (
            <CheckIcon
              height={"24px"}
              width={"24px"}
              fill={Theme.successColor}
            />
          ) : (
            <CrossIcon height={"24px"} width={"24px"} fill={Theme.errorColor} />
          )}
        </CenterIcon>
      );
    },
  },
  {
    label: "table.user",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    sortMethod: function (a, b) {
      if (a.tag != null && b.tag != null) {
        if (
          a.tag.user != null &&
          a.tag.user.fullName != null &&
          a.tag.user.fullName !== "" &&
          b.tag.user != null &&
          b.tag.user.fullName != null &&
          b.tag.user.fullName !== ""
        ) {
          return a.tag.user.fullName >= b.tag.user.fullName;
        }
        return false;
      }
    },
    render: function (data) {
      if (data.tag) {
        if (
          data.tag.user != null &&
          data.tag.user.fullName != null &&
          data.tag.user.fullName !== ""
        ) {
          return (
            <EllipsisDiv title={data.tag.user.fullName}>
              {data.tag.user.fullName}
            </EllipsisDiv>
          );
        }
      }
      return <EllipsisDiv>{"-"}</EllipsisDiv>;
    },
    minWidth: 125,
  }
];

const CenterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
