import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.062 3h13.876C20.075 3 21 3.925 21 5.062v13.876A2.064 2.064 0 0118.937 21H5.062A2.064 2.064 0 013 18.937V5.062C3 3.925 3.925 3 5.062 3zm4.835 2.118h-3.44c-.74 0-1.34.596-1.34 1.331v1.903c0 .733.6 1.33 1.34 1.33h3.44c.738 0 1.339-.597 1.339-1.332V6.448a1.337 1.337 0 00-1.339-1.33zm0 6.085h-3.44c-.74 0-1.34.598-1.34 1.332v4.944c0 .735.6 1.332 1.34 1.332h3.44c.738 0 1.339-.597 1.339-1.332v-4.944a1.338 1.338 0 00-1.339-1.332zm4.206-6.085h3.44c.74 0 1.34.596 1.34 1.331v4.945c0 .735-.602 1.332-1.34 1.332h-3.44a1.337 1.337 0 01-1.339-1.332V6.449c0-.735.6-1.331 1.339-1.331zm3.44 9.129h-3.44c-.738 0-1.339.596-1.339 1.331v1.902c0 .734.6 1.332 1.339 1.332h3.44a1.338 1.338 0 001.34-1.333v-1.902c0-.734-.6-1.33-1.34-1.33z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
