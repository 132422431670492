import moment from "moment";
const intervalOptions = [
  {
    value: "day",
    format: "DD/MM/YYYY",
  },
  {
    value: "month",
    format: "MM/YYYY",
  },
  {
    value: "year",
    format: "YYYY",
  },
];

export default (filtersForm, t) => {
  const selectedInterval = intervalOptions.find(
    (el) => el.value === filtersForm.interval.value
  );
  if (
    selectedInterval != null &&
    filtersForm != null &&
    filtersForm.start != null &&
    filtersForm.end != null
  ) {
    return `${moment(filtersForm.start.value).format(
      selectedInterval.format
    )} ${t("to")} ${moment(filtersForm.end.value).format(
      selectedInterval.format
    )}`;
  }
};
