/**
 * StyledInput Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Theme } from "globalStyles";
import { Input } from "element-react";
import { lighten } from "polished";

export default ({ children, ...props }) => (
  <CustomInput {...props}></CustomInput>
);

const CustomInput = styled(Input)`
  &.el-input,
  &.el-textarea {
    width: ${(props) => (props.width ? props.width : "auto")};
    &:not(:first-child) {
      margin-left: 14px;
    }
  }
  & .el-input__inner,
  & .el-textarea__inner {
    resize: none;
    color: ${Theme.inactiveColor};
    border: none;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04); */
    font-family: ${Theme.fontFamily};
    border: 1px solid ${lighten(0.4, Theme.borderColor)};
    transition: border-color 0.5s, color 0.5s;
    :not(:placeholder-shown) {
      color: ${Theme.textonSecondary};
    }
    ::placeholder {
      transition: color 0.5s;
      color: ${Theme.borderColor};
    }
    :focus,
    :hover {
      border: 1px solid ${Theme.borderColor} !important;
      color: ${Theme.textonSecondary};
      ::placeholder {
        color: ${Theme.textonSecondary};
      }
    }
  }
`;
