/**
 * StyledSelect Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";

import { AnimatedArrow } from "components/AnimatedArrow";
import { Theme } from "globalStyles";

class ToggleFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (this.props.variableToWatch !== oldProps.variableToWatch) {
      this.setState({ open: false });
    }
    if (
      this.props.loadingDownload !== oldProps.loadingDownload &&
      this.props.loadingDownload
    ) {
      this.setState({ open: false });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.open
    ) {
      this.setState({ open: false });
    }
  };

  render() {
    const { open } = this.state;
    const { text, children, type, loadingDownload } = this.props;

    return (
      <Wrapper ref={this.wrapperRef} {...this.props}>
        <ToggleButtonWrapper
          type={type}
          open={open}
          onClick={() => {
            if (!loadingDownload) {
              this.setState({ open: !open });
            }
          }}
        >
          <ToggleText type={type}>{text}</ToggleText>

          <AnimatedArrow transform={open ? "rotate(0)" : "rotate(-180)"} />
        </ToggleButtonWrapper>
        <ReferenceWrapper right={this.props.right} isDashboard={this.props.isDashboard} isContainers={this.props.isContainers}>
          <DynamicWrapper style={{ maxHeight: open ? "1000px" : "0px" }}>
            {children}
          </DynamicWrapper>
        </ReferenceWrapper>
      </Wrapper>
    );
  }
}

export default ToggleFilter;

const Wrapper = styled.div`
  position: relative;
  user-select: none;
  order: -1;
  order: ${(props) => props.order};
  z-index: ${(props) => props.zIndex ? props.zIndex : '1000'};
`;

const ReferenceWrapper = styled.div`
  position: absolute;
  box-shadow: 0px 0px 22px 0px rgba(204, 204, 204, 1);
  top: 54px;
  left: 0;
  ${({ theme, isDashboard }) => isDashboard && theme.smallDesktop`
    left: calc(-100vw + 680px);
  `}
  ${({ theme, isDashboard, isContainers }) => (isDashboard || isContainers) && theme.desktop`
    right: 0;
    left: unset;
  `}
`;

const DynamicWrapper = styled.div`
  height: auto;
  overflow: hidden;
  position: relative;
  right: 0px;
`;

const ToggleButtonWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Theme.textonSecondary};
  height: 36px;
  transition: border-color 0.5s, color 0.5s;
  margin: ${({ margin }) => margin};

  font-family: ${Theme.fontFamily};
  background-color: ${Theme.white};
  border: 1px solid #dddfe4;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #6e7587;
  }
`;

const ToggleText = styled.span`
  color: ${(props) => props.theme.black};
  font-size: 14px;
  line-height: 1em;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
    text-overflow: ellipsis;

  &:focus,
  &:active {
    color: ${(props) => props.theme.darkPrimaryColor};
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;
