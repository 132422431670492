/**
 * StyledTable Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Table } from "element-react";
import { Theme } from "globalStyles";
import { useTranslation } from "react-i18next";

export default React.forwardRef(({ children, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <CustomTable
      ref={ref}
      style={{
        width: "100%",
        margin: "0 auto",
        border: "none",
        boxShadow: props.withoutShadow
          ? ""
          : "0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04)",
        minHeight: "100%",
      }}
      highlightCurrentRow
      emptyText={t("no_data")}
      {...props}
    >
      {children}
    </CustomTable>
  );
});

const CustomTable = styled(Table)`
  &&::before,
  && ::after {
    content: none;
  }
  &.el-table {
    th.gutter {
      background-color: ${Theme.primaryColorOpacity};
    }

    th.is-leaf {
      border: none;
      background-color: ${Theme.primaryColorOpacity};
    }

    td {
      border: none;
      border-bottom: 1px solid ${Theme.inactiveColor};
    }

    & .sort-caret.ascending {
      border-bottom-color: ${Theme.darkPrimaryColor};
    }

    & .ascending .sort-caret.ascending {
      border-bottom-color: ${Theme.secondaryColor};
    }

    & .sort-caret.descending {
      border-top-color: ${Theme.darkPrimaryColor};
    }

    & .descending .sort-caret.descending {
      border-top-color: ${Theme.secondaryColor};
    }

    tr {
      background-color: ${Theme.white};
    }
  }

  .el-table__header-wrapper thead div {
    background-color: transparent;
    color: ${Theme.black};
    font-weight: 700;
  }

  td {
    margin: 0px !important;
    height: 35px !important;
    padding: 12px 0;
    color: ${Theme.darkPrimaryColor};
    font-size: 16px;
    line-height: 24px;
  }

  tr.error {
    background-color: ${Theme.lightColor};
    td {
      background: ${Theme.lightColor} !important;
    }
  }

  .el-table--enable-row-hover .el-table__body tr&:hover > td {
    background-color: ${Theme.secondaryColorHover} !important;
  }
`;
