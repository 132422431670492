/**
 * Sidebarsection Container
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled from "styled-components";
import posed from "react-pose";
import Arrow from "icons/Arrow";
import { Theme } from "globalStyles";
import { withTranslation } from "react-i18next";

class SidebarSection extends Component {
  render() {
    const { active, path, name, subsections, procClick, icon, t } = this.props;
    const Icon = icon;
    return (
      <Wrapper>
        <ClickAreaPosed
          onClick={() => {
            if (subsections) {
              procClick(active ? "" : name);
            } else {
              procClick(name);
              this.props.history.push(path);
            }
          }}
          justifyContent={subsections ? "space-between" : "center"}
          active={active}
          pose={active ? "active" : "notActive"}
        >
          <React.Fragment>
            <MarginDiv>
              {icon && <Icon height={24} fill={Theme.white}></Icon>}
            </MarginDiv>
            <ClickAreaText>{t(name)}</ClickAreaText>
          </React.Fragment>
          {subsections && (
            <Arrow
              margin={"0px auto 0px auto"}
              fill={Theme.textOnPrimary}
              rotate={active ? "180" : "0"}
            />
          )}
        </ClickAreaPosed>
      </Wrapper>
    );
  }
}

export default withTranslation()(Logic(SidebarSection));

const Wrapper = styled.div``;

const ClickArea = styled.div`
  height: 3rem;

  overflow: hidden;
  justify-content: ${(props) => props.justifyContent};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  align-items: center;
  justify-content: flex-start;
`;

const ClickAreaText = styled.div`
  text-align: center;
  color: ${Theme.textOnPrimary};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
`;

const ClickAreaPosed = posed(ClickArea)({
  hoverable: true,
  hover: {
    background: Theme.darkPrimaryColor,
  },
  active: {
    background: Theme.darkPrimaryColor,
    transition: { duration: 300 },
  },
  notActive: {
    background: Theme.primaryColor,
    transition: { duration: 300 },
  },
});

const MarginDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 3rem;
  height: 3rem;
  justify-content: flex-start;
`;
