import React from "react";
import moment from "moment";
import styled from "styled-components";

export const columns_costumer = [
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: "table.trader",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a && b && a.trader && b.trader) {
        if (a.trader.fullName && b.trader.fullName) {
          return a.trader.fullName >= b.trader.fullName;
        }
      }
      return false;
    },
    sortable: true,
    prop: "trader",

    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.trader && data.trader && data.trader.fullName
              ? `${data.trader.fullName}`
              : "-"
          }
        >
          {data.trader && data.trader && data.trader.fullName
            ? `${data.trader.fullName}`
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.points",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "points",
    render: function ({ t, ...data }) {
      return <EllipsisDiv title={data.points}>{data.points}</EllipsisDiv>;
    },
  },
  {
    label: "table.transaction_status",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "status",
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv title={t(`table.${data.status}`)}>
          {t(`table.${data.status}`)}
        </EllipsisDiv>
      );
    },
  },
];

export const columns_trader = [
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: "table.costumer",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    prop: "customer",
    sortMethod: function (a, b) {
      if (a && b && a.customer && b.customer) {
        if (a.customer.fullName && b.customer.fullName) {
          return a.customer.fullName >= b.customer.fullName;
        }
      }
      return false;
    },
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.customer && data.customer && data.customer.fullName
              ? `${data.customer.fullName}`
              : "-"
          }
        >
          {data.customer && data.customer && data.customer.fullName
            ? `${data.customer.fullName}`
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.points",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "points",
    render: function ({ t, ...data }) {
      return <EllipsisDiv title={data.points}>{data.points}</EllipsisDiv>;
    },
  },
  {
    label: "table.transaction_status",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "status",
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv title={t(`table.${data.status}`)}>
          {t(`table.${data.status}`)}
        </EllipsisDiv>
      );
    },
  },
];

export const columns_admin = [
  {
    label: "table.id",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "id",
    render: function (data) {
      return (
        <EllipsisDiv title={data.id ? data.id : "-"}>
          {data.id ? data.id : "-"}
        </EllipsisDiv>
      );
    },
    minWidth: 70,
  },
  {
    label: "table.date",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "datetime",
    render: function (data) {
      return (
        <EllipsisDiv
          title={moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        >
          {moment(data.datetime).format("DD/MM/YYYY HH:mm")}
        </EllipsisDiv>
      );
    },
    minWidth: 180,
  },
  {
    label: "table.trader",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a && b && a.trader && b.trader) {
        if (a.trader.fullName && b.trader.fullName) {
          return a.trader.fullName >= b.trader.fullName;
        }
      }
      return false;
    },
    sortable: true,
    prop: "trader",
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.trader && data.trader && data.trader.fullName
              ? `${data.trader.fullName}`
              : "-"
          }
        >
          {data.trader && data.trader && data.trader.fullName
            ? `${data.trader.fullName}`
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.costumer",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortMethod: function (a, b) {
      if (a && b && a.customer && b.customer) {
        if (a.customer.fullName && b.customer.fullName) {
          return a.customer.fullName >= b.customer.fullName;
        }
      }
      return false;
    },
    sortable: true,
    prop: "customer",
    render: function (data) {
      return (
        <EllipsisDiv
          title={
            data.customer && data.customer && data.customer.fullName
              ? `${data.customer.fullName}`
              : "-"
          }
        >
          {data.customer && data.customer && data.customer.fullName
            ? `${data.customer.fullName}`
            : "-"}
        </EllipsisDiv>
      );
    },
  },
  {
    label: "table.points",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "points",
    render: function ({ t, ...data }) {
      return <EllipsisDiv title={data.points}>{data.points}</EllipsisDiv>;
    },
  },
  {
    label: "table.transaction_status",
    renderHeader: function ({ t, ...column }) {
      return t(column.label);
    },
    sortable: true,
    prop: "status",
    render: function ({ t, ...data }) {
      return (
        <EllipsisDiv title={t(`table.${data.status}`)}>
          {t(`table.${data.status}`)}
        </EllipsisDiv>
      );
    },
  },
];

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
