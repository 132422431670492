/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled from "styled-components";
import { Layout, Breadcrumb } from "element-react";
import ContainerMapMaintenances from "containers/ContainerMapMaintenances";
import { columns } from "./columns";
import { withTranslation } from "react-i18next";
import { Theme } from "globalStyles";

import StyledTable from "components/StyledTable";
import StyledButton from "components/StyledButton";
import ToggleFilter from "components/ToggleFilter";
import ResponsiveHandler from "components/ResponsiveHandler";
import StyledSelect from "components/StyledSelect";

import IntervalPicker from "containers/IntervalPicker";
import { getIntervalText } from "utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

class Containers extends Component {
  constructor(props) {
    super(props);
    // https://stackoverflow.com/a/61346857/15423901
    this.setState = this.setState.bind(this);
    this._autocompleteRef = null;
    this._areaAutocompleteRef = null;
  }

  state = {
    focusedInput: null,
    filtersWidth: 20,
    inputWidth: "140px",
    triggerSet: false,
    changedForm: null,
  };

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  autoCompleteFetch = (queryString) => {
    if (this._autocompleteRef) {
      clearTimeout(this._autocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._autocompleteRef = setTimeout(function () {
      search(queryString, null, "container");
    }, 500);
  };

  areaAutoCompleteFetch = (queryString) => {
    if (this._areaAutocompleteRef) {
      clearTimeout(this._areaAutocompleteRef);
    }

    const self = this;
    const { search } = self.actions;
    this._areaAutocompleteRef = setTimeout(function () {
      search(queryString, null, "area");
    }, 500);
  };

  render() {
    const {
      setLinkedArea,
      setEquipmentInDetail,
      resetFilter,
      changeFilter,
      setFiltersForm,
      resetFiltersForm,
    } = this.actions;

    const {
      areasList,
      linkedArea,
      filteredEquipments,
      equipmentInDetail,
      historyList,
      filter,
      filtersForm,
      t,
      responsiveHandlers,
      filteredEquipmentsNumber,
      searchResults,
      searchLoading,
      fillLevelFilterOptions,
      searchAreaResults,
      searchAreaLoading,
    } = this.props;

    const { filtersWidth, inputWidth, triggerSet, changedForm } = this.state;

    const areaEquipments = filteredEquipments ? filteredEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        ) : filteredEquipments;

    return (
      <Wrapper>
        <CustomLayoutSecondaryRow gutter="20">
          <BreakcrumbWrapper>
            <BreadcrumbWrapper span={24 - filtersWidth}>
              <CustomBreakcrumb style={{ margin: "0 0px auto" }} separator="/">
                <Breadcrumb.Item>
                  <span
                    onClick={() => {
                      setLinkedArea(null);
                      setEquipmentInDetail(null);
                    }}
                  >
                    {t("containers.title")}
                  </span>
                </Breadcrumb.Item>
                {linkedArea && (
                  <Breadcrumb.Item>
                    <span
                      onClick={() => {
                        setEquipmentInDetail(null);
                      }}
                    >
                      {linkedArea.name}
                    </span>
                  </Breadcrumb.Item>
                )}
                {equipmentInDetail && (
                  <Breadcrumb.Item>{equipmentInDetail.name}</Breadcrumb.Item>
                )}
              </CustomBreakcrumb>
            </BreadcrumbWrapper>
            <FiltersWrapper
              span={filtersWidth}
              float="left"
              justifyContent={"flex-end"}
              paddingLeft={"14px"}
              paddingRight={0}
            >
              <React.Fragment>
                <StyledSelect
                  width={inputWidth}
                  placeholder={t("area_input_placeholder")}
                  value={filter.container__area__name}
                  onChange={(event) =>
                    changeFilter("container__area__name", event)
                  }
                  remoteMethod={this.areaAutoCompleteFetch}
                  options={searchAreaResults}
                  loading={searchAreaLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea != null}
                ></StyledSelect>
                <StyledSelect
                  placeholder={t("container_input_placeholder")}
                  onChange={(event) => changeFilter("container__uuid", event)}
                  value={filter.container__uuid}
                  remoteMethod={this.autoCompleteFetch}
                  triggerOnFocus={false}
                  options={searchResults}
                  loading={searchLoading}
                  remote={true}
                  clearable={true}
                  disabled={linkedArea == null}
                ></StyledSelect>
                <StyledSelect
                  width={"180px"}
                  placeholder={t("fill_level_input_placeholder")}
                  onChange={(event) => changeFilter("fill_level", event)}
                  remoteMethod={this.userAutoCompleteFetch}
                  value={filter.fill_level}
                  options={fillLevelFilterOptions}
                  remote={true}
                  clearable={true}
                ></StyledSelect>
              </React.Fragment>
              {changedForm != null && (
                <ToggleFilter
                  isContainers
                  text={getIntervalText(changedForm, t)}
                  variableToWatch={filtersForm}
                  zIndex={1001}
                >
                  <IntervalPicker
                    form={filtersForm}
                    intervalOptions={intervalOptions}
                    replaceForm={setFiltersForm}
                    resetForm={resetFiltersForm}
                    defaultForm={INTERVAL_FORM_DEFAULTS}
                    triggerSet={triggerSet}
                    resetTriggerSet={() => this.setState({ triggerSet: false })}
                    state={{ changedForm }}
                    setState={this.setState}
                  />
                </ToggleFilter>
              )}

              <ButtonWrapper>
                <StyledButton
                  margin={"0"}
                  onClick={() => {
                    resetFilter();
                    setLinkedArea(null);
                  }}
                >
                  {t("clear_filters")}
                </StyledButton>
                <StyledButton
                  type={"primary"}
                  margin={"0"}
                  onClick={() => this.setState({ triggerSet: true })}
                >
                  {t("apply")}
                </StyledButton>
              </ButtonWrapper>
            </FiltersWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          style={{ marginBottom: "24px", marginTop: "24px" }}
          gutter="15"
        >
          <SelectivePaddingColNoFixedHeightWithBoxShadow
            paddingLeft="0px"
            paddingRight="0px"
            span="24"
          >
            <ContainerMapMaintenances
              areas={areasList}
              linkedArea={linkedArea}
              setLinkedArea={(area) => setLinkedArea(area)}
              areaEquipments={areaEquipments}
              globalNumberOfContainers={filteredEquipmentsNumber}
              equipmentInDetail={equipmentInDetail}
              setEquipmentInDetail={(equipment) => {
                setEquipmentInDetail(equipment);
              }}
            />
          </SelectivePaddingColNoFixedHeightWithBoxShadow>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          gutter="20"
          height={"calc(55vh - 64px - 36px - 48px - 4rem)"}
          minHeight={"320px"}
        >
          <SecondRowWrapper>
            <NoPaddingCol>
            <TableName>{t("containers.history")}</TableName>
              <StyledTable
                style={{ marginTop: ".5rem", minHeight: "100%" }}
                height={
                  responsiveHandlers.height > 768
                    ? "calc(55vh - 64px - 36px - 48px - 4rem - 42px - 1px )"
                    : ""
                }
                columns={columns.map((column) => ({ t, ...column }))}
                data={
                  historyList
                    ? historyList.map((equipment) => ({ ...equipment }))
                    : []
                }
                rowClassName={(row) =>
                  equipmentInDetail &&
                  row.container &&
                  equipmentInDetail.id === row.container.id &&
                  "active"
                }
                emptyText={
                  !equipmentInDetail ? t("select_container") : t("no_data")
                }
              />
            </NoPaddingCol>
          </SecondRowWrapper>
        </CustomLayoutSecondaryRow>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Containers)));

const Wrapper = styled.div``;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${(props) => props.marginTop};
  min-height: ${(props) => props.minHeight};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectivePaddingColNoFixedHeight = styled(Layout.Col)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  float: ${(props) => props.float} !important;
`;

const SelectivePaddingColNoFixedHeightWithBoxShadow = styled(
  SelectivePaddingColNoFixedHeight
)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const NoPaddingCol = styled(Layout.Col)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding-left: ${(props) => props.paddingLeft} !important;
  padding-right: ${(props) => props.paddingRight} !important;

  &.el-col-24 {
    padding: 0 !important;
    height: calc(100% - 3rem);
  }

  ${({ theme }) => theme.smallDesktop`
   &.el-col-24{
    padding: ${(props) => props.padding} !important;
    padding-left: ${(props) => props.paddingLeft} !important;
    padding-right: ${(props) => props.paddingRight} !important;
  }
  `}
`;

const SecondRowWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: span 2;

  ${({ theme }) => theme.desktop`
    display flex;
  `}
`;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.desktop`
    flex-direction: row;
  `}
`;

const BreadcrumbWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-4 {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
    &.el-col-4{
      margin-bottom: 8px;
      padding: 0 16px 0 0 !important;

    }
  `}
`;
const FiltersWrapper = styled(SelectivePaddingColNoFixedHeight)`
  &.el-col-20 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: left;
    row-gap: 8px;
    column-gap: 8px;
    padding: 0 !important;
  }
  ${({ theme }) => theme.smallDesktop`
    &.el-col-20{
      display: grid;
    }
  `}

  ${({ theme }) => theme.desktop`
    &.el-col-20{
      display: flex;
      justify-content: flex-end;
      width: 83.33333%;
      padding-left: 14px !important;
    }
  `}
`;

const TableName = styled.span`
  color: ${Theme.primaryColor};
  font-size: 1rem;
  font-weight: 800;
  line-height: 22px;
`;