import React from 'react'


export const DropOff = props => (
  <svg
    viewBox="0 0 294.976 294.976"

    fill={"#394055"}
    height={70}
    width={70}
    {...props}
  >
    <path d="M185.274 126.15c-1.604-5.116-6.51-8.479-11.871-8.479h-.031l-24.5.06-24.514-47.953c-2.465-4.822-7.591-6.766-12.711-6.699-.427.006-46.075.004-46.48-.004-5.355-.103-10.677 2.076-13.016 7.202L11.17 160.061c-2.867 6.28-.1 13.695 6.181 16.562 6.274 2.866 13.693.104 16.562-6.181l19.624-42.993c0 11.127-.286 141.401-.286 152.527 0 8.524 7.111 15.389 15.726 14.982 8.067-.38 14.274-7.329 14.274-15.406v-82.36a5.128 5.128 0 0 1 10.256 0v82.36c0 8.077 6.207 15.026 14.274 15.406 8.614.406 15.726-6.458 15.726-14.982 0-109.212-.288-157.506-.288-157.506l6.885 13.469a12.501 12.501 0 0 0 11.13 6.811h.03l32.17-.078c8.197-.02 14.533-7.928 11.84-16.522zM88.378 57.477c15.87 0 28.743-12.858 28.743-28.738C117.121 12.856 104.248 0 88.378 0c-15.875 0-28.75 12.856-28.75 28.738 0 15.88 12.875 28.739 28.75 28.739z" />
    <path d="M277.438 174.957H173.402a7.499 7.499 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h.943l11.366 90.351a14.198 14.198 0 0 0 14.086 12.425h51.246a14.196 14.196 0 0 0 14.085-12.425l11.366-90.351h.943a7.5 7.5 0 0 0 7.5-7.5 7.497 7.497 0 0 0-7.499-7.5zm-67.474 60.004a7.5 7.5 0 0 1-14.883 1.874l-5.896-46.877h15.118l5.661 45.003zM201.402 161.997a4.069 4.069 0 0 0 5.903 3.136l18.143-9.331a4.07 4.07 0 0 0 2.123-4.45l-3.62-17.355a4.072 4.072 0 0 0-5.513-2.942l-16.893 6.849a4.071 4.071 0 0 0-2.512 4.255l2.369 19.838z" />
  </svg>
)


export const Prize = props => (
  <svg height="70" viewBox="0 0 512 512" width="70"
  fill={"#394055"}
  {...props}>
    <path d="M497 36.953h-65.703c.238-7.277.371-14.598.371-21.953 0-8.285-6.719-15-15-15H95.336c-8.285 0-15 6.715-15 15 0 7.355.129 14.676.367 21.953H15c-8.285 0-15 6.715-15 15 0 67.211 17.566 130.621 49.46 178.555 31.528 47.387 73.524 74.566 118.888 77.21 10.285 11.192 21.16 20.317 32.484 27.278v66.668h-25.164c-30.422 0-55.168 24.75-55.168 55.168v25.164h-1.066c-8.286 0-15 6.719-15 15 0 8.285 6.714 15 15 15h273.132c8.286 0 15-6.715 15-15 0-8.281-6.714-15-15-15H391.5v-25.164c0-30.418-24.75-55.168-55.168-55.168h-25.164v-66.668c11.324-6.96 22.195-16.086 32.48-27.277 45.368-2.645 87.36-29.824 118.891-77.211C494.434 182.574 512 119.164 512 51.953c0-8.285-6.719-15-15-15zM74.437 213.891c-26.308-39.54-41.765-91.246-44.12-146.938h52.062c5.41 68.461 21.48 131.738 46.598 181.973 4 8 8.183 15.554 12.523 22.676-24.95-9.422-48.04-29.118-67.063-57.711zm363.125 0C418.54 242.484 395.45 262.18 370.5 271.6c4.344-7.12 8.523-14.675 12.523-22.675 25.118-50.235 41.184-113.512 46.598-181.973h52.063c-2.356 55.692-17.813 107.399-44.122 146.938zm0 0" />
  </svg>
)

export const GarbageBin = props=> (
  <svg viewBox="0 0 268.476 268.476" height="70" 
  fill={"#394055"}
  {...props}
  >
    <path
      d="M63.119 250.254s3.999 18.222 24.583 18.222h93.072c20.583 0 24.582-18.222 24.582-18.222l18.374-178.66H44.746l18.373 178.66zM170.035 98.442a8.948 8.948 0 0 1 8.949-8.949 8.95 8.95 0 0 1 8.95 8.949l-8.95 134.238a8.949 8.949 0 1 1-17.898 0l8.949-134.238zm-44.746 0a8.949 8.949 0 0 1 8.949-8.949 8.948 8.948 0 0 1 8.949 8.949V232.68a8.948 8.948 0 0 1-8.949 8.949 8.949 8.949 0 0 1-8.949-8.949V98.442zm-35.797-8.95a8.948 8.948 0 0 1 8.949 8.949l8.95 134.238a8.95 8.95 0 0 1-17.899 0L80.543 98.442a8.95 8.95 0 0 1 8.949-8.95zM218.36 35.811h-39.376V17.899C178.984 4.322 174.593 0 161.086 0H107.39C95.001 0 89.492 6.001 89.492 17.899v17.913H50.116c-7.914 0-14.319 6.007-14.319 13.43 0 7.424 6.405 13.431 14.319 13.431H218.36c7.914 0 14.319-6.007 14.319-13.431 0-7.423-6.405-13.431-14.319-13.431zm-57.274 0h-53.695l.001-17.913h53.695v17.913z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
)
