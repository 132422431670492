/**
 * Login Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import styled from "styled-components";
import { Input } from "element-react";
// import { lighten } from "polished";
import { Theme } from "globalStyles";
import { withTranslation } from "react-i18next";
import StyledButton from "components/StyledButton";
import BackArrow from "icons/BackArrow";
import { Link } from "react-router-dom";

class RecoverPassword extends Component {
  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { submit } = this.actions;
      submit();
    }
  };

  componentDidUpdate() {}

  render() {
    const { email } = this.props.form;
    const { t, submiting, error, response } = this.props;
    const { change, submit } = this.actions;

    if (response === "success") {
      return (
        <Wrapper>
          <FlexWrapper>
            <TitleRow>
              <Link to={"/login/"}>
                <BackArrow height={16} width={16}></BackArrow>
              </Link>
              <LoginText>{t("recover_password")}</LoginText>
            </TitleRow>

            <SuccessMessage>{t('recover_success')}</SuccessMessage>
            <GoBackLink to='/login/'>{t('back_main_page')}</GoBackLink>
          </FlexWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <FlexWrapper>
          <TitleRow>
            <Link to={"/login/"}>
              <BackArrow height={16} width={16}></BackArrow>
            </Link>
            <LoginText>{t("recover_password")}</LoginText>
          </TitleRow>

          <StyledInput
            label={"Email"}
            type="email"
            // disabled={submiting}
            placeholder={t("email_placeholder")}
            value={email.value}
            onChange={(e) => {
              change({ email: e });
            }}
            onKeyDown={this.handleEnter}
            error={email.valid === false && email.message != null}
          />
          {email.valid === false && email.message != null && (
            <InputError>{t(email.message)}</InputError>
          )}

          <AlignCenter>
            <StyledButton
              disabled={submiting}
              onClick={submit}
              type={"primary"}
              margin={"0"}
            >
              {t("recover_password")}
            </StyledButton>
          </AlignCenter>

          <Error>{error && error.map((error) => t(error))}</Error>
        </FlexWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation()(Logic(RecoverPassword));

const Wrapper = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8;
  margin: -20px;
`;

const FlexWrapper = styled.div`
  max-height: 330px;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1);
  padding: 32px;
  margin: 2rem;
  ${({ theme }) => theme.smallDesktop`
     width: 410px;
   `}
`;

const Error = styled.div`
  width: 100%;
  color: red;
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
  font-size: 12px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
`;

// const StyledButton = styled(Button)`
//   width: 100%;
//   color: #ffffff !important;
//   height: 40px !important;
//   background-color: ${Theme.primaryColor} !important;
//   box-shadow: 0 0 14px 0 rgba(64, 82, 132, 0.1) !important;
//   transition: background-color 0.6s !important;
//   transition: color 0.3s;

//   :focus {
//     color: #8391a5 !important;
//     border-color: #8391a5 !important;
//     color: #ffffff !important;
//   }

//   :hover {
//     color: #ffffff !important;

//     background-color: ${lighten(0.1, Theme.primaryColor)} !important;
//   }
// `;

const StyledInput = styled(Input)`
  margin-bottom: 15px;

  .el-input__inner {
    color: #242a3e;
    font-family: ${Theme.fontFamily};
    font-size: 12px;
    line-height: 16px;
    padding: 0px 16px;
    :focus {
      border-color: #8391a5;
    }
  }

  input {
    text-align: left !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-text-fill-color: #1f2d3d !important;
    background-color: #ffffff !important;
    color: #1f2d3d !important;
  }
  margin-bottom: ${(props) => props.error === true && 0};
`;

const LoginText = styled.div`
  color: #242a3e;
  font-family: ${Theme.fontFamily};
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
`;

const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  column-gap: 16px;
  margin-bottom: 32px;

  align-items: center;
`;

const InputError = styled(Error)`
  text-align: left;
  justify-content: flex-start;

  margin: 0.5rem 0;
`;


const SuccessMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 1.5rem auto;
  line-height: 1.25rem;
`

const GoBackLink = styled(Link)`
  width: fit-content;

  margin: 1rem auto;
  font-size: 0.875rem;
  text-decoration: underline;
  color: ${Theme.primaryColor};
  transition: opacity 0.3s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
  }
`