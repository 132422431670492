import React from 'react';
import { kea } from 'kea';
import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { BASE_API_URL, API_DATE_FORMAT } from 'config';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { decodeColor } from 'utils';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import NotificationsWrapper from 'components/NotificationsWrapper';
import AppLogic from 'containers/App/logic';

const EVENTSURL = BASE_API_URL + 'events/';
const AREASURL = BASE_API_URL + 'areas/';
const EQUIPMENTSSURL = BASE_API_URL + 'containers/';
const DOWNLOADURL = BASE_API_URL + 'events/export/';
const PERFORMEDROUTES = BASE_API_URL + 'trucks/routes/performed/';
const PLANNEDROUTES = BASE_API_URL + 'trucks/routes/planned/';

const getContainerByUUID = (uuid, array) => {
  if (uuid != null && array != null) {
    return array.find((el) => {
      return el.uuid === uuid;
    });
  }
  return null;
};

const getAreaByName = (name, array) => {
  if (name != null && array != null) {
    return array.find((el) => {
      return el.name === name;
    });
  }
  return null;
};

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'Collects'],

  connect: {
    props: [],
    actions: [
      AppLogic,
      ['watchEvents', 'changeWatchingEvents', 'setWebSocketHanlder'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    fetchAreasEquipments: () => true,

    fetchMaintenancesList: () => true,
    setMaintenancesList: (list) => ({ list }),
    setMaintenancesNumber: (number) => ({ number }),
    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),

    search: (query, cb, type) => ({ query, cb, type }),

    fetchAreasList: () => true,
    setAreasList: (list) => ({ list }),
    setAreasNumber: (number) => ({ number }),
    setLoadingAreas: (loading) => loading,

    setLinkedArea: (area) => ({ area, type: 'container', query: '' }),
    setFilteredEquipments: (equipments) => ({ equipments }),
    updateFilteredEquipments: (equipments) => ({ equipments }),
    setFilteredEquipmentsNumber: (number) => ({ number }),

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,
    setPagination: (pag) => ({ pag }),

    setEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetail: (equipment) => ({
      equipment,
      type: 'container',
      query: '',
    }),

    fetchRoutes: () => true,
    setRoutes: (routes) => ({ routes }),

    setUsingMonthCalendar: (value) => ({ value }),
    setShowCalendarFilter: (value) => ({ value }),

    download: (type) => ({ type }),
    finishDownload: () => true,

    setSearchResults: (results) => ({ results }),
    setAreaSearchResults: (results) => ({ results }),

    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payload) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.resetFilter]: (state, payload) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    maintenancesList: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenancesList]: (state, { list }) => list,
      },
    ],

    pagination: [
      { page: 1, page_size: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFetchedEquipmentInDetail]: () => ({
          page: 1,
          page_size: 10,
        }),
        [actions.setFiltersForm]: () => ({ page: 1, page_size: 10 }),
      },
    ],

    linkedArea: [
      null,
      PropTypes.object,
      {
        [actions.setLinkedArea]: (state, { area }) => area,
      },
    ],

    filter: [
      {
        container__area__name: '',
        container__uuid: '',
        fill_level: '',
        complete: '',
      },
      PropTypes.object,
      {
        [actions.setFilter]: (state, { filter }) => filter,
        [actions.changeFilter]: (state, payload) => {
          return Object.assign({}, state, { [payload.key]: payload.value });
        },
        [actions.setLinkedArea]: (state, { area }) => ({
          ...state,
          container__area__name: area != null && area.name ? area.name : '',
        }),
        [actions.setEquipmentInDetail]: (state, { equipment }) => ({
          ...state,
          container__uuid:
            equipment != null && equipment.uuid ? equipment.uuid : '',
        }),
        [actions.resetFilter]: () => ({
          container__area__name: '',
          container__uuid: '',
          fill_level: '',
          complete: '',
        }),
      },
    ],

    fillLevelFilterOptions: [
      [
        {
          value: 'less',
          label: 'x < 25%',
        },
        {
          value: 'between',
          label: '25% < x < 50%',
        },
        {
          value: 'between_2',
          label: '50% < x < 75%',
        },
        {
          value: 'greater',
          label: '75% < x',
        },
      ],
      PropTypes.array,
      {},
    ],

    areasList: [
      [],
      PropTypes.array,
      {
        [actions.setAreasList]: (state, { list }) => list,
      },
    ],

    loadingDownload: [
      false,
      PropTypes.boolean,
      {
        [actions.download]: () => true,
        [actions.finishDownload]: () => false,
      },
    ],

    loadingAreas: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingAreas]: (state, loading) => loading,
      },
    ],

    routesList: [
      [],
      PropTypes.array,
      {
        [actions.setRoutes]: (state, { routes }) => routes,
      },
    ],

    filteredEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setFilteredEquipments]: (state, { equipments }) => equipments,
        [actions.updateFilteredEquipments]: (state, { equipments }) => [
          ...state,
          ...equipments,
        ],
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
      },
    ],

    filteredEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setFilteredEquipmentsNumber]: (state, { number }) => number,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setFetchedEquipmentInDetail]: (state, { equipment }) =>
          equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
        [actions.resetFilter]: () => null,
      },
    ],

    usingMonthCalendar: [
      true,
      PropTypes.bool,
      {
        [actions.setUsingMonthCalendar]: (state, { value }) => value,
      },
    ],

    showCalendarFilter: [
      false,
      PropTypes.bool,
      {
        [actions.setShowCalendarFilter]: (state, { value }) => value,
      },
    ],
    searchResults: [
      [],
      PropTypes.array,
      {
        [actions.setSearchResults]: (state, { results }) => results,
      },
    ],
    searchLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'container',
        [actions.setSearchResults]: () => false,
      },
    ],
    searchAreaResults: [
      [],
      PropTypes.array,
      {
        [actions.setAreaSearchResults]: (state, { results }) => results,
      },
    ],
    searchAreaLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'area',
        [actions.setAreaSearchResults]: () => false,
      },
    ],
  }),

  start: function* () {
    const {
      fetchMaintenancesList,
      fetchAreasList,
      setWebSocketHanlder,
      watchEvents,
      fetchAreasEquipments,
      fetchRoutes,
    } = this.actions;

    yield put(fetchAreasList());
    yield put(fetchMaintenancesList());
    yield put(fetchAreasEquipments());
    yield put(fetchRoutes());

    yield put(setWebSocketHanlder(this.workers.handle));

    yield put(watchEvents());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchMaintenancesList]: workers.fetchMaintenancesList,
    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,
    [actions.fetchAreasList]: workers.fetchAreasList,
    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,
    [actions.fetchRoutes]: workers.fetchRoutes,
    [actions.setLinkedArea]: [
      workers.showOnlyOneArea,
      workers.fetchMaintenancesList,
      workers.fetchAreasEquipments,
    ],
    [actions.setEquipmentInDetail]: [workers.fetchEquipmentInDetail],
    [actions.setFetchedEquipmentInDetail]: [workers.fetchMaintenancesList],
    [actions.filterEntrances]: [workers.fetchMaintenancesList],
    [actions.resetFilter]: [workers.fetchMaintenancesList],
    [actions.setPagination]: workers.fetchMaintenancesList,
    [actions.setFiltersForm]: [
      workers.fetchMaintenancesList,
      workers.fetchAreasEquipments,
    ],
    [actions.download]: workers.download,
  }),

  workers: {
    *download(action) {
      const { payload } = action;
      const { t } = this.props;
      const { finishDownload } = this.actions;
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      let filter = yield this.get('filter');

      if (payload.type != null) {
        try {
          let params;
          if (equipmentInDetail) {
            params = { ...filter, container__id: equipmentInDetail.id };
          } else if (linkedArea) {
            params = { ...filter, container__area__id: linkedArea.id };
          } else {
            params = { ...filter };
          }

          const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
          const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

          const response = yield call(axios.get, DOWNLOADURL, {
            params: {
              type: 'pick',
              file_type: payload.type,
              ...params,
              date_after: moment(date_after).toISOString(),
              date_before: moment(date_before).endOf('day').toISOString(),
              ordering: '-datetime',
            },
            responseType: 'blob',
          });
          fileDownload(
            new Blob([response.data]),
            `${t('collects.download')}.${payload.type}`
          );
        } catch (err) {
          toast.error(
            <NotificationsWrapper
              type={'download_error'}
              description={t('error.download_description')}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'downloadError',
            }
          );
        } finally {
          yield put(finishDownload());
        }
      }
    },

    *showOnlyOneArea(action) {
      const { setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');
      const linkedArea = yield this.get('linkedArea');
      if (areasList.length === 1 && linkedArea !== areasList[0]) {
        yield delay(300);
        yield put(setLinkedArea(areasList[0]));
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const { setFetchedEquipmentInDetailNoFetchs } = this.actions;

      try {
        const { data } = yield call(
          axios.get,
          EQUIPMENTSSURL + `${action.payload.equipment.id}/`
        );
        data.color = decodeColor(data.type);
        yield put(setFetchedEquipmentInDetailNoFetchs(data));
      } catch (error) {
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
    },

    *fetchEquipmentInDetail(action) {
      yield delay(300);

      const { setFetchedEquipmentInDetail, setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');

      if (
        action.payload &&
        action.payload.equipment &&
        action.payload.equipment.id
      ) {
        try {
          const { data } = yield call(
            axios.get,
            EQUIPMENTSSURL + `${action.payload.equipment.id}/`
          );
          data.color = decodeColor(data.type);
          const equipmentArea = areasList.find((el) => el.id === data.area.id);

          if (equipmentArea != null) {
            yield put(setLinkedArea(equipmentArea));
          }
          yield put(setFetchedEquipmentInDetail(data));
        } catch (error) {
          yield put(setFetchedEquipmentInDetail(null));
        }
      } else {
        yield put(setFetchedEquipmentInDetail(null));
      }
    },

    *fetchMaintenancesList() {
      yield delay(500);
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const pagination = yield this.get('pagination');
      const filtersForm = yield this.get('filtersForm');
      const filteredEquipments = yield this.get('filteredEquipments');
      const areasList = yield this.get('areasList');

      let filter = yield this.get('filter');

      const {
        setMaintenancesList,
        setMaintenancesNumber,
        setEquipmentInDetail,
        setLinkedArea,
      } = this.actions;

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let fillLevel;

        switch (filter.fill_level) {
          case 'less':
            fillLevel = {
              container__fill_level__lte: 25,
            };
            break;
          case 'between':
            fillLevel = {
              container__fill_level__gte: 25,
              container__fill_level__lte: 50,
            };
            break;
          case 'between_2':
            fillLevel = {
              container__fill_level__gte: 50,
              container__fill_level__lte: 75,
            };
            break;
          case 'greater':
            fillLevel = {
              container__fill_level__gte: 75,
            };
            break;
          default:
            fillLevel = {};
        }

        const commonParams = {
          ...filter,
          ...fillLevel,
          ...pagination,
          date_after: moment(date_after).toISOString(),
          date_before: moment(date_before).endOf('day').toISOString(),
          event_type: 'pick',
          ordering: '-datetime',
        };

        let params = { ...commonParams };

        if (equipmentInDetail) {
          params = { ...commonParams, container: equipmentInDetail.id };
        } else if (linkedArea) {
          params = { ...commonParams, area: linkedArea.id };
        }

        let eventsRes = yield call(axios.get, EVENTSURL, { params });
        const events = eventsRes.data.results;

        const containerAreaNameExists = !!filter.container__area__name;
        const area = getAreaByName(filter.container__area__name, areasList);

        if (events) {
          if (containerAreaNameExists) {
            if (
              area != null &&
              (linkedArea == null ||
                (linkedArea != null &&
                  linkedArea.name !== filter.container__area__name))
            ) {
              yield put(setLinkedArea(area));
            }
          }

          if (filter.container__uuid != null && filter.container__uuid !== '') {
            if (
              getContainerByUUID(filter.container__uuid, filteredEquipments) !=
                null &&
              (equipmentInDetail == null ||
                (equipmentInDetail != null &&
                  equipmentInDetail.uuid !== filter.container__uuid))
            ) {
              yield put(
                setEquipmentInDetail(
                  getContainerByUUID(filter.container__uuid, filteredEquipments)
                )
              );
            } else if (
              getContainerByUUID(filter.container__uuid, filteredEquipments) ==
                null &&
              equipmentInDetail != null
            ) {
              yield put(setEquipmentInDetail(null));
            }
          } else if (
            (filter.container__uuid == null || filter.container__uuid === '') &&
            equipmentInDetail != null
          ) {
            yield put(setEquipmentInDetail(null));
          }
          yield put(setMaintenancesList(events));
          yield put(setMaintenancesNumber(events.length));
        } else {
          yield put(setMaintenancesList([]));
          yield put(setMaintenancesNumber(0));
          yield put(setEquipmentInDetail(null));
        }
      } catch (error) {
        console.log({ error });
        yield put(setMaintenancesList([]));
        yield put(setMaintenancesNumber(0));
        yield put(setEquipmentInDetail(null));
      }
    },

    *fetchAreasList(action) {
      const {
        setAreasList,
        setLinkedArea,
        setAreaSearchResults,
        setAreasNumber,
      } = this.actions;

      try {
        const response = yield call(axios.get, AREASURL);
        const areasRes = response.data.results;

        if (areasRes.length) {
          // add data to area filter
          const searchAreaArr = areasRes.map((area) => ({
            value: area.name,
            label: area.name,
          }));
          yield put(setAreaSearchResults(searchAreaArr));

          // set up areas and prepare polygons
          let results = areasRes.map((area) => {
            area.lines = {
              type: 'LineString',
              coordinates: area.polygon.coordinates[0],
            };
            return area;
          });
          yield put(setAreasList(results));
          yield put(setAreasNumber(areasRes.length));

          // if there is only one area, focus it
          if (results.length === 1) {
            yield delay(300);
            yield put(setLinkedArea(results[0]));
          }
        } else {
          yield put(setAreasList([]));
          yield put(setAreasNumber(0));
        }
      } catch (error) {
        yield put(setAreasList([]));
        yield put(setAreasNumber(0));
        yield put(setAreaSearchResults([]));
      }
    },

    *fetchAreasEquipments() {
      yield delay(600);
      const linkedArea = yield this.get('linkedArea');
      const filter = yield this.get('filter');

      const {
        setFilteredEquipments,
        setSearchResults,
        setFilteredEquipmentsNumber,
      } = this.actions;

      const installationStatus = ['assembled', 'installed'];

      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            fill_level__gte: 25,
            fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            fill_level__gte: 50,
            fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      try {
        let next = 1;
        const commonParams = {
          ...fillLevel,
          page_size: 500,
        };

        let results = [];
        while (next) {
          let params = {
            ...commonParams,
            page: next,
          };

          if (linkedArea) params = { ...params, area__id: linkedArea.id };

          let containers = [];

          const responses = yield installationStatus.map((status) => {
            params = { ...params, installation_status: status };
            return call(axios.get, EQUIPMENTSSURL, { params });
          });

          responses.forEach((el) =>
            containers.push.apply(containers, el.data.results)
          );

          containers.map((container) => {
            container.color = decodeColor(container.type);
            results.push(container);
            return container;
          });

          const equipmentsList = results.map((el) => ({
            value: el.uuid,
            label: el.name,
          }));

          next = 0;

          yield put(setFilteredEquipments(results));
          yield put(setFilteredEquipmentsNumber(results.length));
          yield put(setSearchResults(equipmentsList));
        }
      } catch (error) {
        yield put(setFilteredEquipments([]));
        yield put(setFilteredEquipmentsNumber(0));
        yield put(setSearchResults([]));
      }
    },

    *fetchRoutes() {
      const { setRoutes } = this.actions;

      try {
        let results = [];

        let params = {
          ordering: '-datetime',
          type: 'pickup',
          page_size: 500,
        };

        const responsePlanned = yield call(axios.get, PLANNEDROUTES, {
          params,
        });

        const plannedRoutes = responsePlanned.data.results;

        const responsePerformed = yield call(axios.get, PERFORMEDROUTES, {
          params,
        });

        const performedRoutes = responsePerformed.data.results;

        for (let planned of plannedRoutes) {
          const lastPerformedRoute = performedRoutes.filter(
            (route) => route.plannedRoute.id === planned.id
          )[0];

          const status = !lastPerformedRoute
            ? '-'
            : lastPerformedRoute.missedContainers.length
            ? true
            : false;

          let aux = {
            name: planned.name,
            day: !planned.schedules.length ? '-' : planned.schedules[0].day,
            lastCollect: status,
            licensePlate: lastPerformedRoute
              ? lastPerformedRoute.truck.licensePlate
              : '-',
          };

          let containers = [];

          if (lastPerformedRoute) {
            for (let container of lastPerformedRoute.plannedRoute.containers) {
              const missed = lastPerformedRoute.missedContainers.filter(
                (miss) => miss.id === container.id
              ).length
                ? true
                : false;

              let containerAux = {
                id: container.id,
                name: container.name,
                address: container.address,
                missed: missed,
              };

              containers.push(containerAux);
            }
          } else {
            for (let container of planned.containers) {
              let containerAux = {
                id: container.id,
                name: container.name,
                address: container.address,
                missed: '-',
              };
              containers.push(containerAux);
            }
          }

          aux = { ...aux, containers: containers };

          results.push(aux);
        }

        yield put(setRoutes(results));
      } catch (error) {
        yield put(setRoutes([]));
      }
    },

    *handle(message) {
      const { fetchMaintenancesList, fetchEquipmentInDetailNoFetchs } =
        this.actions;
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (message.event_type === 'status') {
        let temp = { ...message };
        const message_id =
          message.content && message.content.id ? message.content.id : null;

        toast.info(temp.message, {
          position: toast.POSITION.TOP_RIGHT,
          delay: 200,
          id: message_id ? `uniqueStatusMessage${message_id}` : null,
        });
      } else if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          const message_id =
            message.content && message.content.id ? message.content.id : null;

          if (
            message.content != null &&
            (message.content.type === 'drop' ||
              message.content.type === 'maintenance') &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueDropMessage${message_id}` : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.content &&
            message.content.type === 'pick' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniquePickMessage${message_id}` : null,
              }
            );

            yield put(fetchMaintenancesList());
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (message.content && message.content.type === 'alert') {
            toast.warn(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
                description={
                  message.content != null && message.content.alertType
                    ? message.content.alertType
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueAlertMessage${message_id}` : null,
              }
            );
          } else if (message.content && message.content.type === 'error') {
            if (message.content.status === 'closed') {
              toast.success(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content && message.content.errorType
                      ? `${message.content.errorType}`
                      : ''
                  }
                  status={
                    message.content && message.content.status
                      ? message.content.status
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            } else {
              toast.error(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content != null && message.content.errorType
                      ? message.content.errorType
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            }
          }
        }
      }
    },
  },
});
