import moment from "moment";

export const checkDay = (day) => {
    if (day !== "-") {
        if (!(moment().day(day) < moment().day()))
            return moment().add(7, "d").day(day).format("dddd, DD/MM/YYYY");
        return moment().day(day).format("dddd, DD/MM/YYYY");
    }
    return "-";
};
