/**
 * Container Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import { ADDITIONAL_FEATURES } from "config";
import styled from "styled-components";

export default ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 32px 16px;
  
  ${ADDITIONAL_FEATURES.includes("funding_footer") && 'margin-bottom: 4rem'}
`;
