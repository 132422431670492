/**
 * DashboardWrapper Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic from "./logic";
import Dashboard from "../Dashboard";
import ClientDashboard from "../ClientDashboard";
import { Redirect } from "react-router";
import { Loading } from "element-react";
import { ADDITIONAL_FEATURES } from "config";

class DashboardWrapper extends Component {
  render() {
    const { user, loadingUser } = this.props;
    if (loadingUser) {
      return <Loading fullscreen={true}></Loading>;
    }
    if (user != null) {
      if (user.isAdmin) {
        return <Dashboard />;
      } else if (
        !ADDITIONAL_FEATURES.includes("trader_switch") &&
        user.isTrader
      ) {
        return <Redirect to={"/transactions/"} />;
      } else {
        return <ClientDashboard />;
      }
    }
    return <Redirect to={"/login/"} />;
  }
}

export default Logic(DashboardWrapper);
