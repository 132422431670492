/**
 * StyledSelect Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";
import { Select } from "element-react";
import { Theme } from "globalStyles";
import { lighten } from "polished";
import CrossIcon from "icons/CrossIcon";
import { isMobile } from "react-device-detect";

export default ({ children, ...props }) => {
  if (isMobile) {
    return (
      <StyledMobileSelect
        onChange={(event) => props.onChange(event.target.value)}
        value={props.value != null ? props.value : ""}
      >
        <StyledMobileOption value={""} disabled>
          {props.placeholder}
        </StyledMobileOption>

        {props.options
          ? props.options.map((el, index) => (
              <StyledMobileOption
                key={`StyledMobileSelectOption_${index}`}
                value={el.value}
              >
                {el.label}
              </StyledMobileOption>
            ))
          : null}
      </StyledMobileSelect>
    );
  }
  return (
    <OuterWrapper>
      {props.value != null && props.value !== "" && !props.disabled && (
        <IconWrapper onClick={() => props.onChange("")}>
          <CrossIcon height={18} fill={Theme.textonSecondary}></CrossIcon>
        </IconWrapper>
      )}
      <Wrapper {...props}>
        {props.options != null &&
          props.options.map((el, index) => (
            <StyledOption
              key={`styled-select-${el.value}`}
              value={el.value}
              label={el.label}
            ></StyledOption>
          ))}
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  position: relative;
  height: fit-content;
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 8px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 10;
  height: fit-content;
  cursor: pointer;
`;

const Wrapper = styled(Select)`
  position: relative;
  width: 100%;
  &.el-select .el-input .el-input__icon,
  &.el-select .el-input .el-input__icon.is-reverse {
    display: none;
  }
  & .el-input__icon + .el-input__inner {
    padding-right: 10px;
  }

  &.el-select .el-input.is-disabled .el-input__inner {
    opacity: 0.5;
    background-color: ${Theme.white};
    border: 1px solid ${lighten(0.4, Theme.borderColor)};

    &:hover {
      border: 1px solid ${lighten(0.4, Theme.borderColor)};
      border-color: ${lighten(0.4, Theme.borderColor)};
    }
  }
  & .el-input__inner {
    color: ${Theme.inactiveColor};
    border: none;
    font-family: ${Theme.fontFamily};
    border: 1px solid ${lighten(0.4, Theme.borderColor)};
    transition: border-color 0.5s, color 0.5s;
    width: 100%;

    ${({ theme, ...props }) => theme.desktop`
      width: ${props.width ? props.width : "140px"};
    `}

    :not(:placeholder-shown) {
      color: ${Theme.textonSecondary};
    }
    ::placeholder {
      transition: color 0.5s;
      color: ${Theme.borderColor};
    }
    :focus,
    :hover {
      border: 1px solid ${Theme.borderColor} !important;
      color: ${Theme.textonSecondary};
      ::placeholder {
        color: ${Theme.textonSecondary};
      }
    }
  }
`;

const StyledMobileSelect = styled.select`
  color: ${Theme.textonSecondary};
  border: none;
  font-family: ${Theme.fontFamily};
  border: 1px solid ${lighten(0.4, Theme.borderColor)};
  transition: border-color 0.5s, color 0.5s;
  width: 100%;
  height: 36px;
  flex: 0 0 100%;
  background-color: ${Theme.white};

  padding: 4px 10px;
  font-size: 14px;
  outline: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid ${Theme.borderColor} !important;
  }

  -webkit-appearance: none;
  -moz-appearance: none;

  ::placeholder {
    transition: color 0.5s;
    color: ${Theme.borderColor};
  }
  
  ${({ theme }) => theme.smallDesktop`
    flex: 0 0 140px;
  `}

  ${({ theme }) => theme.largeDesktop`
    flex: 0 0 clamp(140px, 100%, 200px);
  `}
`;

const StyledOption = styled(Select.Option)`
  &.el-select-dropdown__item {
    font-family: ${Theme.fontFamily};
    color: ${Theme.textonSecondary};
  }

  &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover,
  &.el-select-dropdown__item.hover,
  &.el-select-dropdown__item:hover {
    background-color: ${Theme.secondaryColorHover};
  }
  &.el-select-dropdown__item.selected.hover,
  &.el-select-dropdown__item.selected {
    background-color: ${Theme.primaryColor};
  }

  &.el-select-dropdown__item.is-disabled {
    background-color: transparent !important;
  }
`;

const StyledMobileOption = styled.option`
  font-family: ${Theme.fontFamily};
  color: ${Theme.textonSecondary};
  background-color: ${Theme.white};

  &:focus,
  &:active,
  &:hover {
    background-color: ${Theme.secondaryColorHover};
  }
`;
