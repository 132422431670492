/**
 * Clients Scene
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from "react";
import Logic, { INTERVAL_FORM_DEFAULTS } from "./logic";
import styled, { css } from "styled-components";
import AnimatedNumber from "react-animated-number";

import { withTranslation } from "react-i18next";
import { Layout, Card, Carousel, Breadcrumb, Loading } from "element-react";
import { Theme, media } from "globalStyles";

import { Bar } from "react-chartjs-2";

import ContainerMapAreas from "containers/ContainerMapAreas";
import IntervalPicker from "containers/IntervalPicker";

import ResponsiveHandler from "components/ResponsiveHandler";
import StyledButton from "components/StyledButton";
import GraphIndicator from "components/GraphIndicator";
import ToggleFilter from "components/ToggleFilter";
import DoughnutWithText from "components/DoughnutWithText";
import StyledSelect from "components/StyledSelect";

import Maintenance from "icons/Maintenance";
import Deposit from "icons/Deposit";
import Pick from "icons/Pick";
import Alert from "icons/Alert";

import { ADDITIONAL_FEATURES } from "config";
import { getIntervalText } from "utils";
import { GRAPH_COLORS } from "./utils";

const intervalOptions = [
  {
    value: "day",
    label: "day",
  },
  {
    value: "month",
    label: "month",
  },
  {
    value: "year",
    label: "year",
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.carouselFirst = React.createRef();
    this.carouselSecond = React.createRef();
    this.carouselThird = React.createRef();
    this.chartRefs = [];
    // https://stackoverflow.com/a/61346857/15423901
    this.setState = this.setState.bind(this);
  }

  state = {
    visibleCarousel: 0,
    triggerSet: false,
    changedForm: null,
  };

  componentDidMount() {
    this.setState({
      changedForm: this.props.filtersForm,
    });
  }

  mapTooltips = (text, data) => {
    return text;
  };

  timeIntervalSelect = () => {
    const { filtersForm, filter, fillLevelFilterOptions, t } = this.props;
    const { setFiltersForm, resetFiltersForm, setLinkedArea, download, changeFilter } =
      this.actions;

    const { triggerSet, changedForm } = this.state;

    return (
      <SelectPositioner>
        {changedForm != null && (
          <DateFilterWrapper>
            <ToggleFilter
              isDashboard
              right={0}
              text={getIntervalText(changedForm, t)}
              variableToWatch={filtersForm}
            >
              <IntervalPicker
                form={filtersForm}
                intervalOptions={intervalOptions}
                replaceForm={setFiltersForm}
                resetForm={resetFiltersForm}
                defaultForm={INTERVAL_FORM_DEFAULTS}
                triggerSet={triggerSet}
                resetTriggerSet={() => this.setState({ triggerSet: false })}
                state={{ changedForm }}
                setState={this.setState}
              />
            </ToggleFilter>
          </DateFilterWrapper>
        )}
        <StyledSelect
          width={"180px"}
          placeholder={t("fill_level_input_placeholder")}
          onChange={(event) => changeFilter("fill_level", event)}
          value={filter.fill_level}
          options={fillLevelFilterOptions}
          remote={true}
          clearable={true}
        ></StyledSelect>
        <ButtonWrapper>
          <StyledButton
            margin={"0"}
            onClick={() => {
              resetFiltersForm();
              setLinkedArea(null);
            }}
          >
            {t("clear_filters")}
          </StyledButton>
          <StyledButton
            type={"primary"}
            margin={"0"}
            onClick={() => this.setState({ triggerSet: true })}
          >
            {t("apply")}
          </StyledButton>
          <StyledButton
            type={"neutral"}
            margin={"0"}
            onClick={download}
            disabled={true}
          >
            {t("periodic_report")}
          </StyledButton>
        </ButtonWrapper>
      </SelectPositioner>
    );
  };

  incrementCarousel = (which) => {
    switch (which) {
      case 1:
        this.carouselFirst.current.next();
        break;
      case 2:
        this.carouselSecond.current.next();
        break;
      case 3:
        this.carouselThird.current.next();
        break;
      default:
        break;
    }
  };

  decrementCarousel = (which) => {
    switch (which) {
      case 1:
        this.carouselFirst.current.prev();
        break;
      case 2:
        this.carouselSecond.current.prev();
        break;
      case 3:
        this.carouselThird.current.prev();
        break;
      default:
        break;
    }
  };

  userIndicators = () => {
    const { activeUsers, t } = this.props;
    const data = {
      labels: [t("dashboard.active"), t("dashboard.inactive")],
      datasets: [
        {
          data: [activeUsers.activeUsers, activeUsers.inactiveUsers],
          backgroundColor: [Theme.primaryColor, Theme.errorColor],
          hoverBackgroundColor: [Theme.lightPrimaryColor, Theme.darkErrorColor],
        },
      ],
      text: `${activeUsers.inactiveUsers}/${
        activeUsers.inactiveUsers + activeUsers.activeUsers
      }`,
    };
    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>
            {t("dashboard.user_indicators_title")}
          </SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <DoughnutWithText
                data={data}
                style={{ height: "auto" }}
                height={408}
                options={{
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  layout: {
                    padding: {
                      top: 5,
                      right: 10,
                      left: 5,
                      bottom: 5,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                  tooltips: {
                    // Disable the on-canvas tooltip
                    backgroundColor: "#FFFFFF",
                    bodyFontColor: "#000000",
                    titleFontColor: "#000000",
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: "0.5",
                  },
                }}
              />
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  alertsIndicators = () => {
    const { alerts, t } = this.props;
    const data = {
      labels: [t("dashboard.unresolved"), t("dashboard.resolved")],
      datasets: [
        {
          data: [alerts.openAlerts, alerts.closedAlerts],
          backgroundColor: [Theme.errorColor, Theme.primaryColor],
          hoverBackgroundColor: [Theme.darkErrorColor, Theme.lightPrimaryColor],
        },
      ],
      text: `${alerts.openAlerts}/${alerts.closedAlerts + alerts.openAlerts}`,
    };

    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>
            {t("dashboard.alerts_indicators_title")}
          </SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <DoughnutWithText
                data={data}
                style={{ height: "auto" }}
                height={408}
                options={{
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  layout: {
                    padding: {
                      top: 5,
                      right: 10,
                      left: 5,
                      bottom: 5,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                  tooltips: {
                    // Disable the on-canvas tooltip
                    backgroundColor: "#FFFFFF",
                    bodyFontColor: "#000000",
                    titleFontColor: "#000000",
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: "0.5",
                  },
                }}
              />
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  errorsIndicators = () => {
    const { tickets, t } = this.props;

    const data = {
      labels: [t("dashboard.unresolved"), t("dashboard.resolved")],
      datasets: [
        {
          data: [tickets.openErrors, tickets.closedErrors],
          backgroundColor: [Theme.errorColor, Theme.primaryColor],
          hoverBackgroundColor: [Theme.darkErrorColor, Theme.lightPrimaryColor],
        },
      ],
      text: `${tickets.openErrors}/${
        tickets.closedErrors + tickets.openErrors
      }`,
    };

    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>
            {t("dashboard.errors_indicators_title")}
          </SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <DoughnutWithText
                data={data}
                style={{ height: "auto" }}
                height={408}
                options={{
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  layout: {
                    padding: {
                      top: 5,
                      right: 10,
                      left: 5,
                      bottom: 5,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                  tooltips: {
                    // Disable the on-canvas tooltip
                    backgroundColor: "#FFFFFF",
                    bodyFontColor: "#000000",
                    titleFontColor: "#000000",
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: "0.5",
                  },
                }}
              />
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  efficientPickupsIndicators = () => {
    const { efficient, t } = this.props;

    const data = {
      labels: [t("dashboard.efficient"), t("dashboard.inefficient")],
      datasets: [
        {
          data: [efficient.efficientPickups, efficient.inefficientPickups],
          backgroundColor: [Theme.primaryColor, Theme.errorColor],
          hoverBackgroundColor: [Theme.lightPrimaryColor, Theme.darkErrorColor],
        },
      ],
      text: `${efficient.inefficientPickups}/${
        efficient.inefficientPickups + efficient.efficientPickups
      }`,
    };

    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>
            {t("dashboard.efficient_indicators_title")}
          </SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <DoughnutWithText
                data={data}
                style={{ height: "auto" }}
                height={408}
                options={{
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  layout: {
                    padding: {
                      top: 5,
                      right: 10,
                      left: 5,
                      bottom: 5,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                  tooltips: {
                    // Disable the on-canvas tooltip
                    backgroundColor: "#FFFFFF",
                    bodyFontColor: "#000000",
                    titleFontColor: "#000000",
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: "0.5",
                  },
                }}
              />
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  webUsage = () => {
    const { loginCounter, t } = this.props;
    let labels = [];
    let dataAux = [];
    let counter = 0;

    if (loginCounter) {
      for (const loginDay of loginCounter) {
        labels.push(loginDay.date);
        dataAux.push(loginDay.logins);
        counter += loginDay.logins;
      }
    }

    const data = {
      labels: labels,
      datasets: [
        {
          data: dataAux,
          backgroundColor: GRAPH_COLORS,
        },
      ],
      text: `${counter} Logins`,
    };

    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>{t("dashboard.logins_counter")}</SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <DoughnutWithText
                data={data}
                style={{ height: "auto" }}
                height={408}
                options={{
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  layout: {
                    padding: {
                      top: 5,
                      right: 10,
                      left: 5,
                      bottom: 5,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  cutoutPercentage: 80,
                  tooltips: {
                    // Disable the on-canvas tooltip
                    backgroundColor: "#FFFFFF",
                    bodyFontColor: "#000000",
                    titleFontColor: "#000000",
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: "0.5",
                  },
                }}
              />
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  eventsInterval = () => {
    const { lastEventsInterval, t } = this.props;
    let interval = lastEventsInterval ? lastEventsInterval : "00:00:00";

    return (
      <CustomCard height="289px">
        <CustomLayoutSecondaryRow gutter="30">
          <SmallCardTitle>{t("dashboard.events_interval")}</SmallCardTitle>
          <DoughnutFlexInside>
            <GraphWrapper width={"100%"}>
              <TimeStamp>{interval}</TimeStamp>
            </GraphWrapper>
          </DoughnutFlexInside>
        </CustomLayoutSecondaryRow>
      </CustomCard>
    );
  };

  createDropTooltip = (tooltipItem, type) => {
    const { t } = this.props;
    return `${tooltipItem.value} ${t(`graph.${type}`)}`;
  };

  createTooltipTile = (tooltipItem, data) => {
    return tooltipItem[0].label;
  };

  dropsGraph = () => {
    const { drops, loadingDrops, t, numberOfDropsMetrics, responsiveHandlers } =
      this.props;

    let labels = [];
    let counter = [];

    for (const drop of drops) {
      labels.push(drop.date);
      counter.push(drop.count);
    }

    const dataset = [
      {
        label: labels,
        backgroundColor: Theme.secondaryColor,
        borderColor: Theme.secondaryColor,
        fill: false,
        data: counter,
      },
    ];

    return (
      <CustomCard height="100%">
        <SmallCardTitle
          margin={
            responsiveHandlers.isMobile || responsiveHandlers.isTablet
              ? "18px 0 0 0"
              : "18px 12% 0 0"
          }
        >
          {t("dashboard.drops_graph_title")}
        </SmallCardTitle>
        <FlexRowNormal>
          <CustomLoading top="120px" loading={loadingDrops}>
            <MainGraphWrapper width={"88%"}>
              {!loadingDrops && (
                <Bar
                  ref={(reference) => (this.chartRefs["drops"] = reference)}
                  data={{
                    labels: labels,
                    datasets: dataset,
                  }}
                  style={{ height: "100%" }}
                  options={{
                    legend: {
                      display: false,
                      position: "top",
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            autoSkip: true,
                            maxRotation:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 0
                                : 70,
                            minRotation: 0,
                            maxTicksLimit:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 12
                                : 31,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      // Disable the on-canvas tooltip
                      backgroundColor: "#FFFFFF",
                      bodyFontColor: "#000000",
                      titleFontColor: "#000000",
                      borderColor: "rgba(0,0,0,1)",
                      borderWidth: "0.5",
                      callbacks: {
                        title: (tooltipItem, data) => {
                          return this.createTooltipTile(tooltipItem, data);
                        },
                        label: (tooltipItem, data) => {
                          return this.createDropTooltip(tooltipItem, "drops");
                        },
                      },
                    },
                    animation: {
                      onComplete: (e) => {
                        if (e.numSteps > 0 && this.state.dropsReady === false) {
                          this.setState({ dropsReady: true });
                        }
                      },
                    },
                  }}
                />
              )}
            </MainGraphWrapper>
            {numberOfDropsMetrics != null && (
              <GraphIndicatorsWrapper>
                <GraphIndicator
                  name={t("dashboard.indicators_max")}
                  value={numberOfDropsMetrics.max}
                />
                <Divider></Divider>
                <GraphIndicator
                  name={t("dashboard.indicators_average")}
                  value={numberOfDropsMetrics.average}
                />
              </GraphIndicatorsWrapper>
            )}
          </CustomLoading>
        </FlexRowNormal>
      </CustomCard>
    );
  };

  picksGraph = () => {
    const {
      picks,
      loadingPicks,
      t,
      responsiveHandlers,
      // numberOfPicksMetrics,
    } = this.props;

    let labels = [];
    let counter = [];

    for (const pick of picks) {
      labels.push(pick.date);
      counter.push(pick.count);
    }

    const dataset = [
      {
        label: labels,
        backgroundColor: Theme.secondaryColor,
        borderColor: Theme.secondaryColor,
        fill: false,
        data: counter,
      },
    ];

    return (
      <CustomCard height="100%">
        <SmallCardTitle margin={"18px 0 0 0"}>
          {t("dashboard.picks_graph_title")}
        </SmallCardTitle>
        <FlexRowNormal>
          <CustomLoading top="120px" loading={loadingPicks}>
            <FillLevelGraphWrapper width={"100%"}>
              {!loadingPicks && (
                <Bar
                  ref={(reference) => (this.chartRefs["picks"] = reference)}
                  data={{
                    labels: labels,
                    datasets: dataset,
                  }}
                  style={{ height: "100%" }}
                  options={{
                    legend: {
                      display: false,
                      position: "top",
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            autoSkip: true,
                            maxRotation:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 0
                                : 70,
                            minRotation: 0,
                            maxTicksLimit:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 12
                                : 31,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      // Disable the on-canvas tooltip
                      backgroundColor: "#FFFFFF",
                      bodyFontColor: "#000000",
                      titleFontColor: "#000000",
                      borderColor: "rgba(0,0,0,1)",
                      borderWidth: "0.5",
                      callbacks: {
                        title: (tooltipItem, data) => {
                          return this.createTooltipTile(tooltipItem, data);
                        },
                        label: (tooltipItem, data) => {
                          return this.createDropTooltip(tooltipItem, "picks");
                        },
                      },
                    },
                    animation: {
                      onComplete: (e) => {
                        if (e.numSteps > 0) {
                          this.setState({ picksReady: true });
                        }
                      },
                    },
                  }}
                />
              )}
            </FillLevelGraphWrapper>
          </CustomLoading>
        </FlexRowNormal>
      </CustomCard>
    );
  };

  maintenancesGraph = () => {
    const { maintenances, loadingMaintenances, t, responsiveHandlers } =
      this.props;

    let labels = [];
    let counter = [];

    for (const maintenance of maintenances) {
      labels.push(maintenance.date);
      counter.push(maintenance.count);
    }

    const dataset = [
      {
        label: labels,
        backgroundColor: Theme.secondaryColor,
        borderColor: Theme.secondaryColor,
        fill: false,
        data: counter,
      },
    ];

    return (
      <CustomCard height="100%">
        <SmallCardTitle margin={"18px 0 0 0"}>
          {t("dashboard.maintenances_graph_title")}
        </SmallCardTitle>
        <FlexRowNormal>
          <CustomLoading top="120px" loading={loadingMaintenances}>
            <FillLevelGraphWrapper width={"100%"}>
              {!loadingMaintenances && (
                <Bar
                  ref={(reference) =>
                    (this.chartRefs["maintenances"] = reference)
                  }
                  data={{
                    labels: labels,
                    datasets: dataset,
                  }}
                  style={{ height: "100%" }}
                  options={{
                    legend: {
                      display: false,
                      position: "top",
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            autoSkip: true,
                            maxRotation:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 0
                                : 70,
                            minRotation: 0,
                            maxTicksLimit:
                              responsiveHandlers.isMobile ||
                              responsiveHandlers.isTablet
                                ? 12
                                : 31,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      // Disable the on-canvas tooltip
                      backgroundColor: "#FFFFFF",
                      bodyFontColor: "#000000",
                      titleFontColor: "#000000",
                      borderColor: "rgba(0,0,0,1)",
                      borderWidth: "0.5",
                      callbacks: {
                        title: (tooltipItem, data) => {
                          return this.createTooltipTile(tooltipItem, data);
                        },
                        label: (tooltipItem, data) => {
                          return this.createDropTooltip(
                            tooltipItem,
                            "maintenances"
                          );
                        },
                      },
                    },
                    animation: {
                      onComplete: (e) => {
                        if (
                          e.numSteps > 0 &&
                          this.state.maintenancesReady === false
                        ) {
                          this.setState({ maintenancesReady: true });
                        }
                      },
                    },
                  }}
                />
              )}
            </FillLevelGraphWrapper>
          </CustomLoading>
        </FlexRowNormal>
      </CustomCard>
    );
  };

  alertsMetrics = () => {
    const { alerts, t } = this.props;
    return (
      <CardInsideWrapper key={`alerts-metrics`}>
        <StaticCardIconContainer>
          <FlexInsideCard>
            <Alert
              height={`40px`}
              fill={Theme.secondaryColor}
              style={{ margin: "auto" }}
            />
            <SectionInfo
              value={
                !isNaN(alerts.openAlerts + alerts.closedAlerts)
                  ? alerts.openAlerts + alerts.closedAlerts
                  : 0
              }
              style={{
                transition: "0.8s ease-out",
              }}
              duration={300}
              stepPrecision={0.01}
            />
          </FlexInsideCard>
        </StaticCardIconContainer>
        <StaticCardTextContainer>
          <CardTitle margin="20px auto 0px auto">
            {t("dashboard.alerts_metrics_title")}
          </CardTitle>
        </StaticCardTextContainer>
      </CardInsideWrapper>
    );
  };

  dropsMetrics = () => {
    const { globalNumberOfDeposits, t } = this.props;
    return (
      <CardInsideWrapper key={`drops-metrics`}>
        <StaticCardIconContainer>
          <FlexInsideCard>
            <Deposit
              height={`40px`}
              fill={Theme.secondaryColor}
              style={{ margin: "auto" }}
            />
            <SectionInfo
              value={globalNumberOfDeposits}
              style={{
                transition: "0.8s ease-out",
              }}
              duration={300}
              stepPrecision={0.01}
            />
          </FlexInsideCard>
        </StaticCardIconContainer>
        <StaticCardTextContainer>
          <CardTitle margin="20px auto 0px auto">
            {t("dashboard.drops_metrics_title")}
          </CardTitle>
        </StaticCardTextContainer>
      </CardInsideWrapper>
    );
  };

  maintenancesMetrics = () => {
    const { globalNumberOfMaintenances, t } = this.props;
    return (
      <CardInsideWrapper key={`maintenances-metrics`}>
        <StaticCardIconContainer>
          <FlexInsideCard>
            <Maintenance
              height={`40px`}
              fill={Theme.secondaryColor}
              style={{ margin: "auto" }}
            />
            <SectionInfo
              value={globalNumberOfMaintenances}
              style={{
                transition: "0.8s ease-out",
              }}
              duration={300}
              stepPrecision={0.01}
            />
          </FlexInsideCard>
        </StaticCardIconContainer>
        <StaticCardTextContainer>
          <CardTitle margin="20px auto 0px auto">
            {t("dashboard.maintenances_metrics_title")}
          </CardTitle>
        </StaticCardTextContainer>
      </CardInsideWrapper>
    );
  };

  collectsMetrics = () => {
    const { globalNumberOfCollects, t } = this.props;
    return (
      <CardInsideWrapper key={`collects-metrics`}>
        <StaticCardIconContainer>
          <FlexInsideCard>
            <Pick
              height={`40px`}
              fill={Theme.secondaryColor}
              style={{ margin: "auto" }}
            />
            <SectionInfo
              value={globalNumberOfCollects}
              style={{
                transition: "0.8s ease-out",
              }}
              duration={300}
              stepPrecision={0.01}
            />
          </FlexInsideCard>
        </StaticCardIconContainer>
        <StaticCardTextContainer>
          <CardTitle margin="20px auto 0px auto">
            {t("dashboard.collects_metrics_title")}
          </CardTitle>
        </StaticCardTextContainer>
      </CardInsideWrapper>
    );
  };

  mostUsedContainersStatic = () => {
    const { mostUsedContainers, t } = this.props;
    
    return (
      <StaticCardsWrapper height="289px">
        <SmallCardTitle style={{ paddingTop: 0 }}>
          {t("dashboard.most_used_containers")}
        </SmallCardTitle>
        <MostUsedContainersWrapper>
          {mostUsedContainers && mostUsedContainers.length ? (
            mostUsedContainers.map((el, index) => {
              return (
                <React.Fragment key={`container-${index}`}>
                  {index !== 0 && (
                    <AlwaysHorizontalDivider></AlwaysHorizontalDivider>
                  )}
                  <InsideMostUsedContainer key={`usedContainers-metrics-${index}`} onClick={() => this.selectMostUsedContainer(el)}>
                    <StaticCardIconContainer>
                      <FlexInsideCard>
                        <Deposit
                          height={`40px`}
                          fill={GRAPH_COLORS[index]}
                          style={{ margin: "auto" }}
                        />
                        <SectionInfo
                          value={el.count}
                          style={{
                            transition: "0.8s ease-out",
                          }}
                          duration={300}
                          stepPrecision={0.01}
                        />
                      </FlexInsideCard>
                    </StaticCardIconContainer>
                    <StaticCardTextContainer>
                      <CardTitle margin="20px auto 0px auto">
                        {el.name}
                      </CardTitle>
                    </StaticCardTextContainer>
                  </InsideMostUsedContainer>
                </React.Fragment>
              );
            })
          ) : (
            <InsideMostUsedContainer>
              <CardTitle>{t("no_data")}</CardTitle>
            </InsideMostUsedContainer>
          )}
        </MostUsedContainersWrapper>
      </StaticCardsWrapper>
    );
  };

  selectMostUsedContainer = (container) => {
    const { setEquipmentInDetail } = this.actions;
    const { areaEquipments } = this.props;
    
    const getEquipment = areaEquipments.filter((equipment) => equipment.uuid === container.containerUuid)[0];
    setEquipmentInDetail(getEquipment);
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      const { filterEntrances } = this.actions;
      filterEntrances();
    }
  };

  render() {
    const { setLinkedArea, setEquipmentInDetail } = this.actions;
    const {
      areasList,
      linkedArea,
      areaEquipments,
      equipmentInDetail,
      loadingAreas,
      loadingEquipments,
      loadingEquipmentInDetail,
      areaEquipmentsNumber,
      t,
      responsiveHandlers,
    } = this.props;

    const metricsCards = [
      ADDITIONAL_FEATURES.includes("deposits") && this.dropsMetrics(),
      ADDITIONAL_FEATURES.includes("collects") && this.collectsMetrics(),
      ADDITIONAL_FEATURES.includes("maintenances") &&
        this.maintenancesMetrics(),
    ].filter((el) => el);
    
    const indicatorsDonuts = [
      this.userIndicators(),
      ADDITIONAL_FEATURES.includes("alerts") && this.alertsIndicators(),
      this.errorsIndicators(),
      ADDITIONAL_FEATURES.includes("inefficient_pickups_metrics") &&
        this.efficientPickupsIndicators(),
      ADDITIONAL_FEATURES.includes("login_metrics") && this.webUsage(),
      this.eventsInterval(),
    ].filter((el) => el);

    const statsGraphs = [
      ADDITIONAL_FEATURES.includes("deposits") && this.dropsGraph(),
      ADDITIONAL_FEATURES.includes("collects") && this.picksGraph(),
      ADDITIONAL_FEATURES.includes("maintenances") && this.maintenancesGraph(),
    ].filter((el) => el);
    const filteredEquipments = areaEquipments
      ? areaEquipments.reduce(
          (x, y) => (x.findIndex((e) => e.id === y.id) < 0 ? [...x, y] : x),
          []
        )
      : areaEquipments;
    return (
      <Wrapper>
        <CustomLayoutSecondaryRow marginTop="0px" gutter="30">
          <BreakcrumbWrapper>
            <CustomBreakcrumb style={{ margin: "0 0px auto" }} separator="/">
              <Breadcrumb.Item>
                <span onClick={() => setLinkedArea(null)}>
                  {t("dashboard.title")}
                </span>
              </Breadcrumb.Item>
              {linkedArea && (
                <Breadcrumb.Item>
                  <span onClick={() => setEquipmentInDetail(null)}>
                    {linkedArea.name}
                  </span>
                </Breadcrumb.Item>
              )}
              {equipmentInDetail && (
                <Breadcrumb.Item>
                  <span>{equipmentInDetail.name}</span>
                </Breadcrumb.Item>
              )}
            </CustomBreakcrumb>
            <RightNavWrapper>{this.timeIntervalSelect()}</RightNavWrapper>
          </BreakcrumbWrapper>
        </CustomLayoutSecondaryRow>

        <CustomLayoutSecondaryRow
          style={{ marginBottom: "24px", marginTop: "24px" }}
          gutter="30"
        >
          <ContainerMapAreas
            areas={areasList}
            linkedArea={linkedArea}
            setLinkedArea={(area) => {
              if (area !== linkedArea) {
                setLinkedArea(area);
              }
            }}
            areaEquipments={filteredEquipments}
            loading={
              loadingAreas || loadingEquipments || loadingEquipmentInDetail
            }
            equipmentInDetail={equipmentInDetail}
            setEquipmentInDetail={(equipment) => {
              if (equipment !== equipmentInDetail) {
                setEquipmentInDetail(equipment);
              }
            }}
            globalNumberOfContainers={areaEquipmentsNumber}
          />
        </CustomLayoutSecondaryRow>
        <GraphsWrapper gutter="30">
          <CardWrapper paddingSides={"5px"} span="3">
            <StaticCardsWrapper height="289px">
              {metricsCards.map((el, index) => {
                return (
                  <React.Fragment key={`metrics-${index}`}>
                    {index !== 0 && (
                      <AlwaysHorizontalDivider></AlwaysHorizontalDivider>
                    )}
                    {el}
                  </React.Fragment>
                );
              })}
            </StaticCardsWrapper>
          </CardWrapper>
          <CardWrapper paddingSides={"5px"} span="4">
            {this.mostUsedContainersStatic()}
          </CardWrapper>
          <CardWrapper paddingSides={"5px"} span="5">
            <CustomLayoutSecondaryRow marginTop="0px" gutter="20">
              <CustomCarousel
                trigger="click"
                interval={10000}
                arrow={responsiveHandlers.isMobile ? "always" : "hover"}
                ref={this.carouselFirst}
              >
                {indicatorsDonuts.map((item, index) => {
                  return (
                    <CarousedItemCustom key={`indicators_${index}`}>
                      {item}
                    </CarousedItemCustom>
                  );
                })}
              </CustomCarousel>
            </CustomLayoutSecondaryRow>
          </CardWrapper>

          <CardWrapper paddingSides={"5px"} span="12">
            <GraphCustomCarousel
              trigger="click"
              autoplay={false}
              interval={10000}
              arrow={
                statsGraphs.length > 1
                  ? responsiveHandlers.isMobile
                    ? "always"
                    : "hover"
                  : "never"
              }
              indicatorPosition={statsGraphs.length > 1 ? "outside" : "none"}
              ref={this.carouselThird}
            >
              {statsGraphs.map((item, index) => {
                return (
                  <CarousedItemCustom key={index}>{item}</CarousedItemCustom>
                );
              })}
            </GraphCustomCarousel>
          </CardWrapper>
        </GraphsWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation()(ResponsiveHandler(Logic(Dashboard)));

const Wrapper = styled.div``;

const BreakcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.smallDesktop`
    flex-direction: row;
  `}
`;

const CustomCard = styled(Card)`
  height: 100%;
  min-height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border + " !important"};
  .el-card__body {
    height: 100%;
  }
`;

const LayoutColLessPadding = styled(Layout.Col)`
  padding-left: ${(props) => props.paddingSides + " !important"};
  padding-right: ${(props) => props.paddingSides + " !important"};
  &:first-child {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
  }
`;

const CardWrapper = styled(LayoutColLessPadding)`
  &.el-col {
    margin-top: 14px;
    height: 100%;
  }

  &.el-col-3,
  &.el-col-4,
  &.el-col-5,
  &.el-col-12,
  &.el-col-16 {
    width: 100%;
    padding: 0 !important;
  }

  ${({ theme }) => theme.smallDesktop`
  &.el-col{
    margin-top: 0;
  }

  &.el-col-3 {
    width: 10%;
    padding-right: 5px !important;
  }
  
  &.el-col-4{
    width: 17.5%;
    padding-right: 5px !important;
  }

  &.el-col-5{
    width: 22.5%;
  }

  &.el-col-12{
    width: 50%;
    padding-left: 5px !important;
  }

  &.el-col-16{
    width: 66.66667%;
    padding-left: 5px !important;
  }
`}
`;

const CustomLayoutSecondaryRow = styled(Layout.Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100%;
  margin-top: ${(props) => props.marginTop};
`;

const GraphsWrapper = styled(CustomLayoutSecondaryRow)`
  display: flex;
  flex-direction: column;
  margin-top: 0;

  ${({ theme }) => theme.smallDesktop`
    margin-top: ${(props) => props.marginTop};
    display: block;
    height: calc(55vh - 68px - 4rem - 52px);
    min-height: 289px;
  `}
`;

const FlexRowNormal = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  flex: 100%;
  height: 100%;
  min-height: 440px;
  ${({ theme }) => theme.smallDesktop`
  min-height: auto;
`}
`;

const GraphIndicatorsWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    width: 20%;
    min-width: 105px;
    flex-direction: column;
    margin-top: -40px;
  `}

  ${({ theme }) => theme.desktop`
    display: flex;
    justify-content: space-between;
    width: 12%;
    min-width: 105px;
    flex-direction: column;
    margin-top: -40px;
  `}
`;

const GraphWrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${(props) => props.width};
  padding: 5px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 240px;
`;

const FillLevelGraphWrapper = styled(GraphWrapper)`
  max-height: 440px;
  ${({ theme }) => theme.smallDesktop`
  max-height: calc(100% - 41px);
    `}
`;

const MainGraphWrapper = styled(GraphWrapper)`
  max-height: 440px;
  width: 100%;
  ${({ theme }) => theme.smallDesktop`
      width: 80%;
      max-height: calc(100% - 41px);
  `}
  ${({ theme }) => theme.desktop`
      width: 88%;
  `};
`;

const CarousedItemCustom = styled(Carousel.Item)`
  height: 100%;
  h3 {
    margin: 0px !important;
    padding-bottom: 5px;
  }
  padding: 0px 0px;
`;

const CustomCarousel = styled(Carousel)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  &.el-carousel {
    height: 100%;
    background: white;
  }
  .el-carousel__container {
    height: 100%;
    min-height: 440px;
    ${({ theme }) => theme.smallDesktop`
      min-height: auto;
    `}
  }
`;

const GraphCustomCarousel = styled(CustomCarousel)`
  .el-carousel__container {
    /* min-height: 558px; */
    min-height: calc(122px + 408px + 40px + 36px);

    ${(props) =>
      props.indicatorPosition === "outside" &&
      css`
        height: calc(100% - 38px);
      `}

    ${({ theme }) => theme.smallDesktop`
      min-height: auto;
    `}
  }

  .el-carousel__indicators {
    margin-bottom: 0 !important;
  }
`;

const FlexInsideCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DoughnutFlexInside = styled.div`
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightNavWrapper = styled(FlexInsideCard)`
  margin-top: 1rem;
  justify-content: flex-start;

  ${({ theme }) => theme.smallDesktop`
    margin-top: 0;
    align-items: center;
    margin-left: auto;

  `}
`;

const SelectPositioner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 8px;
  column-gap: 8px;

  height: auto;
  padding: 0px 0px 0px 0px;
  width: 100%;
  grid-column: span 2;

  ${({ theme }) => theme.desktop`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 8px;
    width: auto;
    grid-column: auto;
  `}
  ${({ theme }) => theme.smallDesktop`
    display: flex;
    column-gap: 8px;
  `}
`;

const SmallCardTitle = styled.div`
  color: ${Theme.black};
  font-family: ${Theme.fontFamily};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin: 0;
  padding: 17px 15px 0px 15px;
  padding: ${(props) => props.margin};
`;

const CardTitle = styled.div`
  color: ${Theme.darkInactiveColor};
  font-family: ${Theme.fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  text-align: center;
  margin: ${(props) => props.margin};

  ${media.smallDesktop`
    font-size: 12px;
    margin: 0;
  `}
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomLoading = styled(Loading)`
  display: flex !important;
  width: 100%;
  width: -webkit-fill-available !important;
  flex-direction: column;

  ${media.smallDesktop`
    flex-direction: row;
  `}

  .el-loading-spinner {
    left: 0px !important;
    top: ${(props) => (props.top ? props.top : "40px")};
    display: flex !important;
    justify-content: center;

    .path {
      stroke: ${Theme.primaryColor};
    }
  }
`;

const CustomBreakcrumb = styled(Breadcrumb)`
  .el-breadcrumb__item > span {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
`;

const StaticCardsWrapper = styled.div`
  height: 100%;
  padding: 14px 0;
  min-height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
`;

const StaticCardIconContainer = styled.div`
  grid-row: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StaticCardTextContainer = styled(StaticCardIconContainer)`
  font-size: 2rem;
  grid-row: 2/3;
  font-weight: bold;
  color: ${Theme.darkPrimaryColor};
`;

const MostUsedContainersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  overflow: auto;
  padding: 8px;
`;

const InsideMostUsedContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 78px;
  height: 100%;
  padding: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CardInsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  align-items: center;
  justify-content: center;
`;

const SectionInfo = styled(AnimatedNumber)`
  margin: 0 0 0 8px;
  color: ${Theme.black};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background: transparent !important;
`;

const Divider = styled.div`
  background-color: ${Theme.lightInactiveColor};
  margin: auto auto;
  width: 1px;
  height: 90%;

  ${media.smallDesktop`

  margin: 0 auto;
  width: 90%;
  height: 1px;
  `}
`;

const AlwaysHorizontalDivider = styled.div`
  background-color: ${Theme.lightInactiveColor};

  margin: 0 auto;
  width: 90%;
  height: 1px;
`;

const ButtonWrapper = styled(FlexWrapper)`
  display: grid;

  grid-column: span 2;
  grid-gap: 8px;

  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-left: 0;

  ${({ theme }) => theme.smallDesktop`
    display flex;
    margin-left: 0;
    column-gap: 8px;
    grid-template-columns: auto auto;

  `}
  ${({ theme }) => theme.desktop`
  display flex;
    margin-left: 0;
    column-gap: 8px;
    
  `}
`;

export const DateFilterWrapper = styled.div`
  grid-column: span 2;
  ${({ theme }) => theme.smallDesktop`
  grid-column: span 1;

  `}
`;

const TimeStamp = styled.span`
  font-size: 1.5rem;
  font-weight: 800;
`;
