/**
 * StyledSelect Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled from "styled-components";

import { AnimatedArrow } from "components/AnimatedArrow";
import { Theme } from "globalStyles";

class ToggleFilterTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (this.props.variableToWatch !== oldProps.variableToWatch) {
      this.setState({ open: false });
    }
    if (
      this.props.loadingDownload !== oldProps.loadingDownload &&
      this.props.loadingDownload
    ) {
      this.setState({ open: false });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.open
    ) {
      this.setState({ open: false });
    }
  };

  render() {
    const { open } = this.state;
    const { text, children, type, icon } = this.props;
    const Icon = icon;
    return (
      <Wrapper ref={this.wrapperRef} {...this.props}>
        <ToggleButtonWrapper
          type={type}
          open={open}
          onClick={() => {
            this.setState({ open: !open });
          }}
        >
          {icon != null && (
            <IconWrapper>
              <Icon height={16} width={16} fill={Theme.white}></Icon>
            </IconWrapper>
          )}
          <ToggleText type={type}>{text}</ToggleText>

          <AnimatedArrow transform={open ? "rotate(0)" : "rotate(-180)"} />
        </ToggleButtonWrapper>
        <ReferenceWrapper right={this.props.right}>
          <DynamicWrapper style={{ maxHeight: open ? "1000px" : "0px" }}>
            {children}
          </DynamicWrapper>
        </ReferenceWrapper>
      </Wrapper>
    );
  }
}

export default ToggleFilterTitle;

const Wrapper = styled.div`
  position: relative;

  z-index: 1001;
  user-select: none;
  order: -1;
  order: ${(props) => props.order};
`;

const ReferenceWrapper = styled.div`
  position: absolute;
  box-shadow: 0px 0px 22px 0px rgba(204, 204, 204, 1);
  top: 40px;
  right: 0;
`;

const DynamicWrapper = styled.div`
  height: auto;
  overflow: hidden;
  position: relative;
  left: 0;
`;

const ToggleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Theme.primaryColor};
  margin: ${({ margin }) => margin};
  font-family: ${Theme.fontFamily};
  background-color: transparent;
  cursor: pointer;
`;

const ToggleText = styled.span`
  color: ${(props) => props.theme.black};
  font-size: 14px;
  line-height: 1em;
  margin-right: 5px;

  &:focus,
  &:active {
    color: ${(props) => props.theme.darkPrimaryColor};
  }
`;

const IconWrapper = styled.div`
  background-color: ${Theme.primaryColor};
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1rem;
`;
