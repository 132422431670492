export default (code) => {
  switch (code) {
    case "y":
      return "yellow";
    case "b":
      return "blue";
    case "g":
      return "green";
    case "u":
      return "undifferentiated";
    case "bw":
      return "bio_waste";
    default:
      return "undifferentiated";
  }
};
