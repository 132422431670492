import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 12 13" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        transform="translate(-10 -10)"
        filter="url(#prefix__a)"
        fill="none"
        fillRule="evenodd"
      >
        <g fillRule="nonzero">
          <path
            d="M18.847 18.077h-5.694c-1.72 0-3.119 1.39-3.119 3.096v1.346c0 .223.182.404.407.404H21.56c.224 0 .407-.18.407-.404v-1.346c-.001-1.707-1.4-3.096-3.12-3.096z"
            fill={props.fill || "#FFD200"}
          />
          <path
            d="M16 10c-2.093 0-3.797 1.69-3.797 3.77 0 2.079 1.704 3.769 3.797 3.769s3.797-1.69 3.797-3.77C19.797 11.692 18.093 10 16 10z"
            fill={props.fill || "#FFE777"}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
