/**
 * Router
 * Add new routes here
 */
import toArray from 'lodash/toArray'

// Import page components bellow here
// Keep them organized as the routes object
import NotFound from 'scenes/NotFound'
// import Equipments from 'scenes/Equipments'
// import Clients from 'scenes/Clients'
import Login from 'scenes/Login'
import Deposits from 'scenes/Deposits'
import Maintenances from 'scenes/Maintenances'
import Collects from 'scenes/Collects'
import DashboardWrapper from 'scenes/DashboardWrapper'
import Alerts from 'scenes/Alerts'
import Tickets from 'scenes/Tickets'
import Transactions from 'scenes/Transactions'
import RecoverPassword from 'scenes/RecoverPassword'
import PasswordSet from 'scenes/PasswordSet'
import OccurrencesWrapper from 'scenes/OccurrencesWrapper'
import Containers from './scenes/Containers'

// Define routes here
export const Routes = {
  Login: {
    key: 'login',
    name: 'login',
    path: '/login/',
    component: Login,
    exact: true,
    admin_only: false
  },

  RecoverPassword: {
    key: 'recoverpassword',
    name: 'recoverpassword',
    path: '/password/reset/',
    component: RecoverPassword,
    exact: true,
    admin_only: false
  },

  passwordSet: {
    key: 'passwordset',
    name: 'passwordset',
    path: '/password/set/',
    component: PasswordSet,
    exact: true,
    admin_only: false
  },

  containers: {
    key: 'containers',
    name: 'containers',
    path: '/containers/',
    component: Containers,
    exact: true,
    admin_only: true
  },

  deposits: {
    key: 'deposits',
    name: 'deposits',
    path: '/deposits/',
    component: Deposits,
    exact: true,
    admin_only: true
  },

  collects: {
    key: 'collects',
    name: 'collects',
    path: '/collects/',
    component: Collects,
    exact: true,
    admin_only: true

  },

  maintenances: {
    key: 'maintenances',
    name: 'maintenances',
    path: '/maintenances/',
    component: Maintenances,
    exact: true,
    admin_only: true

  },

  alerts: {
    key: 'alerts',
    name: 'alerts',
    path: '/alerts/',
    component: Alerts,
    exact: true,
    admin_only: true

  },

  tickets: {
    key: 'errors',
    name: 'errors',
    path: '/errors/',
    component: Tickets,
    exact: true,
    admin_only: true

  },

  DashboardWrapper: {
    key: 'dashboard',
    name: 'dashboard',
    path: '/',
    component: DashboardWrapper,
    exact: true,
    admin_only: false

  },

  Transactions: {
    key: 'transactions',
    name: 'transactions',
    path: '/transactions/',
    component: Transactions,
    exact: true,
    admin_only: false

  },

  Occurrences: {
    key: 'occurrences',
    name: 'occurrences',
    path: '/occurrences/',
    component: OccurrencesWrapper,
    crumb: ['inicio']
  },

  notfound: {
    key: 'notfound',
    name: 'Página não encontrada',
    path: '*',
    component: NotFound,
    admin_only: false

  }
}

// Maps don't work on object convert it to an array
export default toArray(Routes)
