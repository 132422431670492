/**
 * Areas Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from 'react';

import { kea } from 'kea';

import { put, call } from 'redux-saga/effects';

import { delay } from 'redux-saga';
import {
  BASE_API_URL,
  API_DATE_FORMAT,
  ADDITIONAL_FEATURES,
  MEDIA_API_URL,
} from 'config';

import PropTypes from 'prop-types';
import axios from 'axios';
import { decodeColor } from 'utils';
import moment from 'moment';

import { toast } from 'react-toastify';
import NotificationsWrapper from 'components/NotificationsWrapper';
import fileDownload from 'js-file-download';

import AppLogic from 'containers/App/logic';

const AREASURL = BASE_API_URL + 'areas/';
const EQUIPMENTSSURL = BASE_API_URL + 'containers/';
const EVENTSBYDAYURL = BASE_API_URL + 'events/by-day/';
const MAINTENANCESBYDAYURL = BASE_API_URL + 'containers/maintenances/by-day/';

const ACTIVEUSERSURLV2 = BASE_API_URL + 'metrics/active-customers/';
const EFFICIENTPICKERSURL = BASE_API_URL + 'metrics/efficient-pickups/';
const NUMBEROFEVENTS = BASE_API_URL + 'metrics/events/';
const NUMBEROFMAINTENANCES = BASE_API_URL + 'metrics/maintenances/';
const TICKETSURL = BASE_API_URL + 'metrics/alerts/system/';
const ALERTSURL = BASE_API_URL + 'metrics/alerts/usage/';
const TOPUSEDCONTAINERSURL = BASE_API_URL + 'metrics/most-used-containers/';
const LOGINCOUNTERURL = BASE_API_URL + 'metrics/customers-logins/';
const LASTEVENTSINTERVALURL =
  BASE_API_URL + 'metrics/last-events-time-interval/';
const FILLLEVEL = BASE_API_URL + 'metrics/fill-level/';
const DOWNLOADURL = BASE_API_URL + 'metrics/get-report';

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'Dashboard'],

  connect: {
    props: [],
    actions: [
      AppLogic,
      ['watchEvents', 'changeWatchingEvents', 'setWebSocketHanlder'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    fetchAreasEquipments: () => true,
    download: () => true,
    finishDownload: () => true,

    fetchDrops: (data) => ({ data }),
    setDrops: (data) => ({ data }),
    setLoadingDrops: (loading) => loading,

    setNumberOfDropsMetrics: (data) => ({ data }),
    setNumberOfPicksMetrics: (data) => ({ data }),
    setNumberOfMaintenancesMetrics: (data) => ({ data }),

    fetchPicks: (data) => ({ data }),
    setPicks: (data) => ({ data }),
    setLoadingPicks: (loading) => loading,

    fetchMaintenances: (data) => ({ data }),
    setMaintenances: (data) => ({ data }),
    setLoadingMaintenances: (loading) => loading,

    setSelectOptions: (options) => ({ options }),

    fetchAreasList: () => true,
    setAreasList: (list) => ({ list }),
    setAreasNumber: (number) => ({ number }),
    setLoadingAreas: (loading) => loading,

    fetchMetrics: () => true,
    setMetrics: (metrics) => ({ metrics }),
    fetchActiveUsers: () => true,
    setActiveUsers: (activeUsers) => ({ activeUsers }),
    setLoadingActiveUsers: (loading) => loading,

    setLinkedArea: (area) => ({ area }),
    setAreaEquipments: (equipments) => ({ equipments }),
    setAreaEquipmentsNumber: (number) => ({ number }),
    setLoadingEquipments: (loading) => loading,

    setEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setLoadingEquipmentInDetail: (loading) => loading,

    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),

    setAlerts: (alerts) => ({ alerts }),
    setTickets: (tickets) => ({ tickets }),
    setEfficient: (efficient) => ({ efficient }),
    setMostUsedContainers: (containers) => ({ containers }),
    setLoginCounter: (logins) => ({ logins }),
    setLastEventsInterval: (interval) => ({ interval }),

    setGlobalNumberOfContainers: (number) => ({ number }),
    setGlobalNumberOfMaintenances: (number) => ({ number }),
    setGlobalNumberOfDeposits: (number) => ({ number }),
    setGlobalNumberOfCollects: (number) => ({ number }),

    fetchGlobalNumberOfContainers: () => true,
    fetchGlobalNumberOfDeposits: () => true,
    fetchGlobalNumberOfCollects: () => true,
    fetchGlobalNumberOfMaintenances: () => true,

    setDropsListToPlot: (list) => ({ list }),
    setDropsListToPlotLabels: (list) => ({ list }),
    setLoadingFillLevelGraph: (loading) => loading,

    fetchDepositsToPlot: () => true,

    updateGraphDrop: (event) => ({ event }),
    updateGraphPick: (event) => ({ event }),
    updateGraphMaintenance: (event) => ({ event }),
    updateGraphAreaFillLevel: (event) => event,

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,
  }),

  reducers: ({ actions }) => ({
    loadingDrops: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingDrops]: (state, loading) => loading,
        [actions.setFiltersForm]: () => true,
      },
    ],

    loadingPicks: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingPicks]: (state, loading) => loading,
        [actions.setFiltersForm]: () => true,
      },
    ],

    loadingMaintenances: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingMaintenances]: (state, loading) => loading,
        [actions.setFiltersForm]: () => true,
      },
    ],

    loadingAreas: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingAreas]: (state, loading) => loading,
      },
    ],

    loadingDownload: [
      false,
      PropTypes.boolean,
      {
        [actions.download]: () => true,
        [actions.finishDownload]: () => false,
      },
    ],

    loadingActiveUsers: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingActiveUsers]: (state, loading) => loading,
      },
    ],

    loadingEquipments: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingEquipments]: (state, loading) => loading,
      },
    ],

    loadingEquipmentInDetail: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingEquipmentInDetail]: (state, loading) => loading,
      },
    ],

    loadingFillLevelGraph: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingFillLevelGraph]: (state, loading) => loading,
        [actions.setFiltersForm]: () => true,
      },
    ],

    metrics: [
      null,
      PropTypes.object,
      {
        [actions.setMetrics]: (state, { metrics }) => metrics,
      },
    ],

    drops: [
      [],
      PropTypes.array,
      {
        [actions.setDrops]: (state, { data }) => data,
      },
    ],

    picks: [
      [],
      PropTypes.array,
      {
        [actions.setPicks]: (state, { data }) => data,
      },
    ],

    maintenances: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenances]: (state, { data }) => data,
      },
    ],

    selectOptions: [
      [],
      PropTypes.array,
      {
        [actions.setSelectOptions]: (state, { options }) => options,
      },
    ],

    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    filter: [
      {
        container__area__name: '',
        container__uuid: '',
        fill_level: '',
        complete: '',
      },
      PropTypes.object,
      {
        [actions.setFilter]: (state, { filter }) => filter,
        [actions.changeFilter]: (state, payload) => {
          return Object.assign({}, state, { [payload.key]: payload.value });
        },
        [actions.resetFilter]: () => ({
          fill_level: '',
        }),
      },
    ],

    fillLevelFilterOptions: [
      [
        {
          value: 'less',
          label: 'x < 25%',
        },
        {
          value: 'between',
          label: '25% < x < 50%',
        },
        {
          value: 'between_2',
          label: '50% < x < 75%',
        },
        {
          value: 'greater',
          label: '75% < x',
        },
      ],
      PropTypes.array,
      {},
    ],

    areasList: [
      [],
      PropTypes.array,
      {
        [actions.setAreasList]: (state, { list }) => list,
      },
    ],

    dropsListToPlot: [
      [],
      PropTypes.array,
      {
        [actions.setDropsListToPlot]: (state, { list }) => list,
      },
    ],

    dropsListToPlotLabels: [
      [],
      PropTypes.array,
      {
        [actions.setDropsListToPlotLabels]: (state, { list }) => list,
      },
    ],

    linkedArea: [
      null,
      PropTypes.object,
      {
        [actions.setLinkedArea]: (state, { area }) => area,
      },
    ],

    areaEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setAreaEquipments]: (state, { equipments }) => equipments,
        [actions.setLinkedArea]: (state, { area }) => {
          return area == null ? null : state;
        },
        [actions.resetFiltersForm]: () => null,
      },
    ],

    areaEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setAreaEquipmentsNumber]: (state, { number }) => number,
      },
    ],

    areasCount: [
      0,
      PropTypes.number,
      {
        [actions.setAreasNumber]: (state, { number }) => number,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setFetchedEquipmentInDetail]: (state, { equipment }) =>
          equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,
        [actions.setLinkedArea]: (state, { area }) => {
          return area == null ? null : state;
        },
        [actions.resetFiltersForm]: () => null,
      },
    ],

    activeUsers: [
      {
        activeUsers: 0,
        inactiveUsers: 0,
      },
      PropTypes.object,
      {
        [actions.setActiveUsers]: (state, { activeUsers }) => activeUsers,
      },
    ],

    numberOfDropsMetrics: [
      {
        total: 0,
        average: 0,
        max: 0,
      },
      PropTypes.object,
      {
        [actions.setNumberOfDropsMetrics]: (state, { data }) => data,
      },
    ],

    numberOfPicksMetrics: [
      {
        total: 0,
        average: 0,
        max: 0,
      },
      PropTypes.object,
      {
        [actions.setNumberOfPicksMetrics]: (state, { data }) => data,
      },
    ],

    numberOfMaintenancesMetrics: [
      {
        total: 0,
        average: 0,
        max: 0,
      },
      PropTypes.object,
      {
        [actions.setNumberOfMaintenancesMetrics]: (state, { data }) => data,
      },
    ],

    tickets: [
      {
        openTickets: 0,
        closedTickets: 0,
      },
      PropTypes.object,
      {
        [actions.setTickets]: (state, { tickets }) => tickets,
      },
    ],

    efficient: [
      {
        efficientPickups: 0,
        inefficientPickups: 0,
      },
      PropTypes.object,
      {
        [actions.setEfficient]: (state, { efficient }) => efficient,
      },
    ],

    alerts: [
      {
        openAlerts: 0,
        closedAlerts: 0,
      },
      PropTypes.object,
      {
        [actions.setAlerts]: (state, { alerts }) => alerts,
      },
    ],

    mostUsedContainers: [
      null,
      PropTypes.array,
      {
        [actions.setMostUsedContainers]: (state, { containers }) => containers,
      },
    ],

    loginCounter: [
      null,
      PropTypes.array,
      {
        [actions.setLoginCounter]: (state, { logins }) => logins,
      },
    ],

    lastEventsInterval: [
      null,
      PropTypes.string,
      {
        [actions.setLastEventsInterval]: (state, { interval }) => interval,
      },
    ],

    globalNumberOfContainers: [
      0,
      PropTypes.number,
      {
        [actions.setGlobalNumberOfContainers]: (state, { number }) => number,
      },
    ],

    globalNumberOfDeposits: [
      0,
      PropTypes.number,
      {
        [actions.setGlobalNumberOfDeposits]: (state, { number }) => number,
      },
    ],

    globalNumberOfCollects: [
      0,
      PropTypes.number,
      {
        [actions.setGlobalNumberOfCollects]: (state, { number }) => number,
      },
    ],

    globalNumberOfMaintenances: [
      0,
      PropTypes.number,
      {
        [actions.setGlobalNumberOfMaintenances]: (state, { number }) => number,
      },
    ],
  }),

  start: function* () {
    const {
      watchEvents,
      setWebSocketHanlder,
      fetchAreasList,
      fetchGlobalNumberOfContainers,
      fetchAreasEquipments,
      fetchMetrics,
    } = this.actions;

    yield put(fetchAreasList());
    yield put(fetchMetrics());
    yield put(fetchGlobalNumberOfContainers());
    yield put(setWebSocketHanlder(this.workers.handle));
    yield put(watchEvents());
    yield put(fetchAreasEquipments());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  selectors: ({ selectors }) => ({
    /** Returns and array of the current loading areas */
    picksValues: [
      () => [selectors.picks],
      (picks) => {
        if (picks.length === 0) {
          return { min: 0, average: 0, max: 0 };
        }
        var min = picks.length
          ? picks.reduce((accumulator, currentValue) => {
              return accumulator < currentValue ? accumulator : currentValue;
            })
          : 0;
        var max = picks.length
          ? picks.reduce((accumulator, currentValue) => {
              return accumulator > currentValue ? accumulator : currentValue;
            })
          : 0;
        let total = picks.length
          ? picks.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            })
          : 0;
        let avg = picks.length ? total / picks.length.toFixed(2) : 0;
        avg = parseInt(avg * 100) / 100;
        return { min: min, average: avg, max: max, total: total };
      },
      PropTypes.object,
    ],
    dropsValues: [
      () => [selectors.drops],
      (drops) => {
        if (drops.length === 0) {
          return { min: 0, average: 0, max: 0 };
        }
        var min = drops.length
          ? drops.reduce((accumulator, currentValue) => {
              return accumulator < currentValue ? accumulator : currentValue;
            })
          : 0;
        var max = drops.length
          ? drops.reduce((accumulator, currentValue) => {
              return accumulator > currentValue ? accumulator : currentValue;
            })
          : 0;
        let total = drops.length
          ? drops.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            })
          : 0;
        let avg = drops.length ? total / drops.length.toFixed(2) : 0;
        avg = parseInt(avg * 100) / 100;
        return { min: min, average: avg, max: max, total: total };
      },
      PropTypes.object,
    ],
    maintenancesValues: [
      () => [selectors.maintenances],
      (maintenances) => {
        if (maintenances.length === 0) {
          return { min: 0, average: 0, max: 0 };
        }
        var min = maintenances.length
          ? maintenances.reduce((accumulator, currentValue) => {
              return accumulator < currentValue ? accumulator : currentValue;
            })
          : 0;
        var max = maintenances.length
          ? maintenances.reduce((accumulator, currentValue) => {
              return accumulator > currentValue ? accumulator : currentValue;
            })
          : 0;
        let total = maintenances.length
          ? maintenances.reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            })
          : 0;
        let avg = maintenances.length
          ? total / maintenances.length.toFixed(2)
          : 0;
        avg = parseInt(avg * 100) / 100;
        return { min: min, average: avg, max: max, total: total };
      },
      PropTypes.object,
    ],
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchAreasList]: workers.fetchAreasList,
    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,
    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,
    [actions.setLinkedArea]: [
      workers.showOnlyOneArea,
      workers.fetchAreasEquipments,
      workers.fetchDrops,
      workers.fetchPicks,
      workers.fetchMaintenances,
      workers.fetchActiveUsers,
      workers.fetchMetrics,
      workers.fetchGlobalNumberOfMaintenances,
      workers.fetchGlobalNumberOfDeposits,
      workers.fetchGlobalNumberOfCollects,
      workers.fetchDepositsToPlot,
      workers.fetchGlobalNumberOfContainers,
    ],
    [actions.setFetchedEquipmentInDetail]: [
      workers.fetchDrops,
      workers.fetchPicks,
      workers.fetchMaintenances,
      workers.fetchActiveUsers,
      workers.fetchMetrics,
      workers.fetchGlobalNumberOfMaintenances,
      workers.fetchGlobalNumberOfDeposits,
      workers.fetchGlobalNumberOfCollects,
      workers.fetchDepositsToPlot,
    ],
    [actions.fetchDrops]: workers.fetchDrops,
    [actions.fetchMaintenances]: workers.fetchMaintenances,
    [actions.fetchPicks]: workers.fetchPicks,
    [actions.fetchMetrics]: [
      workers.fetchActiveUsers,
      workers.fetchDrops,
      workers.fetchPicks,
      workers.fetchMaintenances,
      workers.fetchMetrics,
      workers.fetchDepositsToPlot,
      workers.fetchGlobalNumberOfDeposits,
      workers.fetchGlobalNumberOfCollects,
      workers.fetchGlobalNumberOfMaintenances,
    ],
    [actions.fetchActiveUsers]: workers.fetchActiveUsers,
    [actions.setEquipmentInDetail]: workers.fetchEquipmentInDetail,
    [actions.fetchGlobalNumberOfContainers]:
      workers.fetchGlobalNumberOfContainers,
    [actions.fetchGlobalNumberOfMaintenances]:
      workers.fetchGlobalNumberOfMaintenances,
    [actions.fetchGlobalNumberOfDeposits]: workers.fetchGlobalNumberOfDeposits,
    [actions.fetchGlobalNumberOfCollects]: workers.fetchGlobalNumberOfCollects,
    // [actions.watchEvents]: workers.watchEvents,
    [actions.updateGraphDrop]: workers.updateGraphDrop,
    [actions.updateGraphPick]: workers.updateGraphPick,
    [actions.updateGraphMaintenance]: workers.updateGraphMaintenance,
    [actions.updateGraphAreaFillLevel]: workers.updateGraphAreaFillLevel,
    [actions.fetchDepositsToPlot]: workers.fetchDepositsToPlot,
    [actions.setFiltersForm]: [
      workers.fetchActiveUsers,
      workers.fetchDrops,
      workers.fetchPicks,
      workers.fetchMaintenances,
      workers.fetchMetrics,
      workers.fetchDepositsToPlot,
      workers.fetchGlobalNumberOfDeposits,
      workers.fetchGlobalNumberOfCollects,
      workers.fetchGlobalNumberOfMaintenances,
      workers.fetchAreasEquipments,
    ],
    [actions.resetFiltersForm]: [
      workers.fetchActiveUsers,
      workers.fetchDrops,
      workers.fetchPicks,
      workers.fetchMaintenances,
      workers.fetchMetrics,
      workers.fetchDepositsToPlot,
      workers.fetchGlobalNumberOfDeposits,
      workers.fetchGlobalNumberOfCollects,
      workers.fetchGlobalNumberOfMaintenances,
    ],
    [actions.download]: workers.download,
  }),

  workers: {
    *download(action) {
      const { finishDownload } = this.actions;
      try {
        const response = yield call(axios.get, DOWNLOADURL);

        const { data } = response;
        const instance = axios.create();

        const downloadFile = yield call(
          instance.get,
          `${MEDIA_API_URL}${data.url}`,
          {
            responseType: 'blob',
          }
        );

        fileDownload(new Blob([downloadFile.data]), `report-dashboard.pdf`);
      } catch (err) {
        const { t } = this.props;

        console.log({ err });
        toast.error(
          <NotificationsWrapper
            type={'download_error'}
            description={t('error.download_description')}
          />,
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: 'downloadError',
          }
        );
      } finally {
        yield put(finishDownload());
      }
    },

    *fetchDepositsToPlot(action) {
      const {
        setDropsListToPlotLabels,
        setDropsListToPlot,
        setLoadingFillLevelGraph,
      } = this.actions;

      if (!ADDITIONAL_FEATURES.includes('deposits')) {
        return false;
      }

      yield put(setLoadingFillLevelGraph(true));

      const equipmentInDetail = yield this.get('equipmentInDetail');
      const linkedArea = yield this.get('linkedArea');
      const filtersForm = yield this.get('filtersForm');

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          datetime_after: moment(date_after).toISOString(),
          datetime_before: moment(date_before).endOf('day').toISOString(),
          area__id: linkedArea != null ? linkedArea.id : null,
        };

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const request = yield call(axios.get, FILLLEVEL, { params });

        if (request.data) {
          const { timeline } = request.data;

          const averageFillLevel = timeline.map((r) =>
            r.averageFillLevel.toFixed(2)
          );
          const fillLevelTimestamp = timeline.map((r) =>
            moment(r.timestamp).format('DD/MM/YYYY HH:mm')
          );

          yield put(setDropsListToPlot(averageFillLevel));
          yield put(setDropsListToPlotLabels(fillLevelTimestamp));
        }
      } catch (err) {
        console.log({ err });
        yield put(setDropsListToPlot([]));
        yield put(setDropsListToPlotLabels([]));
      } finally {
        yield put(setLoadingFillLevelGraph(false));
      }
    },

    *showOnlyOneArea(action) {
      const { setLinkedArea } = this.actions;
      const areasList = yield this.get('areasList');
      const linkedArea = yield this.get('linkedArea');
      if (areasList.length === 1 && linkedArea !== areasList[0]) {
        yield delay(300);
        yield put(setLinkedArea(areasList[0]));
      }
    },

    *fetchGlobalNumberOfContainers(action) {
      const { setGlobalNumberOfContainers } = this.actions;
      let linkedArea = yield this.get('linkedArea');
      try {
        if (linkedArea) {
          let { data } = yield call(axios.get, EQUIPMENTSSURL, {
            params: {
              container__area__id: linkedArea.id,
            },
          });
          yield put(setGlobalNumberOfContainers(data.results.length));
        } else {
          const { data } = yield call(axios.get, EQUIPMENTSSURL);
          yield put(setGlobalNumberOfContainers(data.results.length));
        }
      } catch (error) {
        setGlobalNumberOfContainers(0);
      }
    },

    *fetchGlobalNumberOfMaintenances(action) {
      const { setGlobalNumberOfMaintenances, setNumberOfMaintenancesMetrics } =
        this.actions;

      const linkedArea = yield this.get('linkedArea');
      const filtersForm = yield this.get('filtersForm');
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (!ADDITIONAL_FEATURES.includes('maintenances')) {
        return false;
      }

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          datetime_after: moment(date_after).toISOString(),
          datetime_before: moment(date_before).endOf('day').toISOString(),
        };

        if (linkedArea) {
          params = {
            ...params,
            area__id: linkedArea != null ? linkedArea.id : null,
          };
        }

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const response = yield call(axios.get, NUMBEROFMAINTENANCES, {
          params,
        });

        if (response.data) {
          yield put(
            setGlobalNumberOfMaintenances(response.data.totalMaintenances)
          );

          const { averageMaintenances, maxMaintenances, totalMaintenances } =
            response.data;

          yield put(
            setNumberOfMaintenancesMetrics({
              total: totalMaintenances,
              average: averageMaintenances,
              max: maxMaintenances,
            })
          );
        }
      } catch (error) {
        console.log({ error });
        yield put(setGlobalNumberOfMaintenances(0));
        yield put(
          setNumberOfMaintenancesMetrics({
            total: 0,
            average: 0,
            max: 0,
          })
        );
      }
    },

    *fetchGlobalNumberOfDeposits(action) {
      const { setGlobalNumberOfDeposits, setNumberOfDropsMetrics } =
        this.actions;

      const linkedArea = yield this.get('linkedArea');
      const filtersForm = yield this.get('filtersForm');
      const equipmentInDetail = yield this.get('equipmentInDetail');

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          type: 'drop',
          datetime_after: moment(date_after).toISOString(),
          datetime_before: moment(date_before).endOf('day').toISOString(),
        };

        if (linkedArea) {
          params = {
            ...params,
            area__id: linkedArea != null ? linkedArea.id : null,
          };
        }

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const response = yield call(axios.get, NUMBEROFEVENTS, { params });

        if (response.data) {
          yield put(setGlobalNumberOfDeposits(response.data.totalEvents));

          const { averageEvents, maxEvents, totalEvents } = response.data;

          yield put(
            setNumberOfDropsMetrics({
              total: totalEvents,
              average: averageEvents,
              max: maxEvents,
            })
          );
        }
      } catch (err) {
        console.log({ err });
        yield put(setGlobalNumberOfDeposits(0));
        yield put(
          setNumberOfDropsMetrics({
            total: 0,
            average: 0,
            max: 0,
          })
        );
      }
    },

    *fetchGlobalNumberOfCollects(action) {
      const { setGlobalNumberOfCollects, setNumberOfPicksMetrics } =
        this.actions;

      const linkedArea = yield this.get('linkedArea');
      const filtersForm = yield this.get('filtersForm');
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (!ADDITIONAL_FEATURES.includes('collects')) {
        return false;
      }

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          type: 'pick',
          datetime_after: moment(date_after).toISOString(),
          datetime_before: moment(date_before).endOf('day').toISOString(),
        };

        if (linkedArea) {
          params = {
            ...params,
            area__id: linkedArea != null ? linkedArea.id : null,
          };
        }

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const response = yield call(axios.get, NUMBEROFEVENTS, { params });

        if (response.data) {
          yield put(setGlobalNumberOfCollects(response.data.totalEvents));

          const { averageEvents, maxEvents, totalEvents } = response.data;

          yield put(
            setNumberOfPicksMetrics({
              total: totalEvents,
              average: averageEvents,
              max: maxEvents,
            })
          );
        }
      } catch (err) {
        yield put(setGlobalNumberOfCollects(0));
        yield put(
          setNumberOfPicksMetrics({
            total: 0,
            average: 0,
            max: 0,
          })
        );
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const {
        setFetchedEquipmentInDetailNoFetchs,
        setLoadingEquipmentInDetail,
      } = this.actions;

      yield put(setLoadingEquipmentInDetail(true));

      try {
        const { data } = yield call(
          axios.get,
          EQUIPMENTSSURL + `${action.payload.equipment.id}/`
        );

        data.color = decodeColor(data.type);

        yield put(setFetchedEquipmentInDetailNoFetchs(data));
      } catch (error) {
        console.log({ error });
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
      yield put(setLoadingEquipmentInDetail(false));
    },

    *fetchEquipmentInDetail(action) {
      const {
        setFetchedEquipmentInDetail,
        setLoadingEquipmentInDetail,
        setLinkedArea,
      } = this.actions;
      const areasList = yield this.get('areasList');

      yield put(setLoadingEquipmentInDetail(true));

      try {
        const { data } = yield call(
          axios.get,
          EQUIPMENTSSURL + `${action.payload.equipment.id}/`
        );

        data.color = decodeColor(data.type);

        const equipmentArea = areasList.find((el) => el.id === data.area.id);

        if (equipmentArea != null) {
          yield put(setLinkedArea(equipmentArea));
        }

        yield put(setFetchedEquipmentInDetail(data));
      } catch (error) {
        yield put(setFetchedEquipmentInDetail(null));
      }

      yield put(setLoadingEquipmentInDetail(false));
    },

    *fetchAreasList(action) {
      const { setAreasList, setAreasNumber, setLoadingAreas, setLinkedArea } =
        this.actions;
      yield put(setLoadingAreas(true));
      try {
        const response = yield call(axios.get, AREASURL);
        const areasRes = response.data.results;

        if (areasRes.length) {
          const results = areasRes.map((area) => {
            area.lines = {
              type: 'LineString',
              coordinates: area.polygon.coordinates[0],
            };
            return area;
          });
          yield put(setAreasList(results));
          yield put(setAreasNumber(areasRes.length));

          if (results.length === 1) {
            yield delay(300);
            yield put(setLinkedArea(results[0]));
          }
        } else {
          yield put(setAreasList([]));
          yield put(setAreasNumber(0));
        }
      } catch (error) {
        yield put(setAreasList([]));
        yield put(setAreasNumber(0));
      }
      yield put(setLoadingAreas(false));
    },

    *fetchAreasEquipments(action) {
      const linkedArea = yield this.get('linkedArea');
      const filter = yield this.get('filter');

      const {
        setAreaEquipments,
        setAreaEquipmentsNumber,
        setLoadingEquipments,
      } = this.actions;
      yield put(setLoadingEquipments(true));

      const installationStatus = ['assembled', 'installed'];

      let fillLevel;

      switch (filter.fill_level) {
        case 'less':
          fillLevel = {
            fill_level__lte: 25,
          };
          break;
        case 'between':
          fillLevel = {
            fill_level__gte: 25,
            fill_level__lte: 50,
          };
          break;
        case 'between_2':
          fillLevel = {
            fill_level__gte: 50,
            fill_level__lte: 75,
          };
          break;
        case 'greater':
          fillLevel = {
            fill_level__gte: 75,
          };
          break;
        default:
          fillLevel = {};
      }

      try {
        let next = 1;
        const commonParams = {
          ...fillLevel,
          page_size: 500,
        };

        let results = [];
        while (next) {
          let params = {
            ...commonParams,
            page: next,
          };

          if (linkedArea) params = { ...params, area__id: linkedArea.id };

          let containers = [];

          const responses = yield installationStatus.map((status) => {
            params = { ...params, installation_status: status };
            return call(axios.get, EQUIPMENTSSURL, { params });
          });

          responses.forEach((el) =>
            containers.push.apply(containers, el.data.results)
          );

          containers.map((container) => {
            container.color = decodeColor(container.type);
            results.push(container);
            return container;
          });

          next = 0;

          yield put(setAreaEquipments(results));
          yield put(setAreaEquipmentsNumber(results.length));
        }
      } catch (error) {
        yield put(setAreaEquipments([]));
        yield put(setAreaEquipmentsNumber(0));
      } finally {
        yield put(setLoadingEquipments(false));
      }
    },

    *fetchDrops(action) {
      yield delay(1500);
      const { setDrops, setLoadingDrops } = this.actions;
      yield put(setLoadingDrops(true));

      let linkedArea = yield this.get('linkedArea');
      let equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          event_type: 'drop',
          date_after: moment(date_after).toISOString(),
          date_before: moment(date_before).endOf('day').toISOString(),
          area: linkedArea != null ? linkedArea.id : null,
        };

        if (equipmentInDetail) {
          params = { ...params, container: equipmentInDetail.id };
        }

        const response = yield call(axios.get, EVENTSBYDAYURL, { params });

        if (response.data) {
          const { results } = response.data;

          yield put(setDrops(results));
        }
      } catch (err) {
        yield put(setDrops([]));
      } finally {
        yield put(setLoadingDrops(false));
      }
    },

    *fetchMaintenances(action) {
      yield delay(1500);
      const { setMaintenances, setLoadingMaintenances } = this.actions;

      if (!ADDITIONAL_FEATURES.includes('maintenances')) {
        return false;
      }

      yield put(setLoadingMaintenances(true));

      let linkedArea = yield this.get('linkedArea');
      let equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        let params = {
          date_after: moment(date_after).toISOString(),
          date_before: moment(date_before).endOf('day').toISOString(),
          area: linkedArea != null ? linkedArea.id : null,
        };

        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const response = yield call(axios.get, MAINTENANCESBYDAYURL, {
          params,
        });

        if (response.data) {
          const { results } = response.data;

          yield put(setMaintenances(results));
        }
      } catch (err) {
        yield put(setMaintenances([]));
      } finally {
        yield put(setLoadingMaintenances(false));
      }
    },

    *fetchPicks(action) {
      yield delay(1500);
      const { setPicks, setLoadingPicks } = this.actions;

      if (!ADDITIONAL_FEATURES.includes('collects')) {
        return false;
      }

      yield put(setLoadingPicks(true));

      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
      const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

      let params = {
        event_type: 'pick',
        date_after: moment(date_after).toISOString(),
        date_before: moment(date_before).endOf('day').toISOString(),
        area: linkedArea != null ? linkedArea.id : null,
      };

      if (equipmentInDetail) {
        params = { ...params, container: equipmentInDetail.id };
      }

      try {
        const response = yield call(axios.get, EVENTSBYDAYURL, {
          params,
        });

        if (response.data) {
          const { results } = response.data;

          yield put(setPicks(results));
        }
      } catch (err) {
        yield put(setPicks([]));
      } finally {
        yield put(setLoadingPicks(false));
      }
    },

    *fetchMetrics(action) {
      yield delay(1500);
      const {
        setTickets,
        setAlerts,
        setEfficient,
        setMostUsedContainers,
        setLoginCounter,
        setLastEventsInterval,
      } = this.actions;
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      let systemAlerts = {
        openErrors: 0,
        closedErrors: 0,
      };

      let usageAlerts = {
        openAlerts: 0,
        closedAlerts: 0,
      };

      let totalEfficientPickups = {
        efficientPickups: 0,
        inefficientPickups: 0,
      };

      let usedContainers = [];

      let loginCounter = [];

      let interval = '';

      const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
      const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

      let params = {
        datetime_after: moment(date_after).toISOString(),
        datetime_before: moment(date_before).endOf('day').toISOString(),
      };

      if (linkedArea) {
        params = {
          ...params,
          area__id: linkedArea != null ? linkedArea.id : null,
        };
      }

      if (equipmentInDetail) {
        params = { ...params, container__id: equipmentInDetail.id };
      }

      try {
        const response = yield call(axios.get, TICKETSURL, { params });

        if (response.data) {
          const { openSystemAlerts, closedSystemAlerts } = response.data;

          systemAlerts = {
            openErrors: openSystemAlerts,
            closedErrors: closedSystemAlerts,
          };
        }
      } catch (err) {
        console.log({ err });
      }

      try {
        const response = yield call(axios.get, ALERTSURL, { params });

        if (response.data) {
          const { openUsageAlerts, closedUsageAlerts } = response.data;

          usageAlerts = {
            openAlerts: openUsageAlerts,
            closedAlerts: closedUsageAlerts,
          };
        }
      } catch (err) {
        console.log({ err });
      }

      try {
        const response = yield call(axios.get, EFFICIENTPICKERSURL, { params });

        if (response.data) {
          const { efficientPickups, inefficientPickups } = response.data;

          totalEfficientPickups = {
            efficientPickups: efficientPickups,
            inefficientPickups: inefficientPickups,
          };
        }
      } catch (err) {
        console.log({ err });
      }

      try {
        const response = yield call(axios.get, TOPUSEDCONTAINERSURL, {
          params,
        });

        if (response.data) {
          const { topContainers } = response.data;

          for (const filteredContainer of topContainers) {
            const filteredEquipmentResponse = yield call(
              axios.get,
              EQUIPMENTSSURL,
              { params: { uuid: filteredContainer.containerUuid } }
            );

            const { results } = filteredEquipmentResponse.data;

            const finalContainer = {
              ...filteredContainer,
              name: results[0].name,
            };

            usedContainers.push(finalContainer);
          }
        }
      } catch (err) {
        setMostUsedContainers(null);
      }

      try {
        const response = yield call(axios.get, LOGINCOUNTERURL, { params });

        if (response.data) {
          const { timeline } = response.data;
          loginCounter = timeline;
        }
      } catch (err) {
        setLoginCounter(null);
      }

      try {
        const response = yield call(axios.get, LASTEVENTSINTERVALURL, {
          params,
        });

        if (response.data) {
          const { timeInterval } = response.data;
          interval = timeInterval;
        }
      } catch (err) {
        yield put(setLastEventsInterval(null));
      }

      yield put(setTickets(systemAlerts));
      yield put(setAlerts(usageAlerts));
      yield put(setEfficient(totalEfficientPickups));
      yield put(setMostUsedContainers(usedContainers));
      yield put(setLoginCounter(loginCounter));
      yield put(setLastEventsInterval(interval));
    },

    *fetchActiveUsers(action) {
      yield delay(1500);
      const { setActiveUsers, setLoadingActiveUsers } = this.actions;
      yield put(setLoadingActiveUsers(true));
      const linkedArea = yield this.get('linkedArea');
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      let final = {
        activeUsers: 0,
        inactiveUsers: 0,
      };

      try {
        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        const commonParams = {
          datetime_after: moment(date_after).toISOString(),
          datetime_before: moment(date_before).endOf('day').toISOString(),
        };

        let params = { ...commonParams };

        if (linkedArea) {
          params = { ...params, area__id: linkedArea.id };
        }
        if (equipmentInDetail) {
          params = { ...params, container__id: equipmentInDetail.id };
        }

        const response = yield call(axios.get, ACTIVEUSERSURLV2, {
          params,
        });

        if (response.data) {
          final.activeUsers =
            response.data.activeCustomers > 0
              ? response.data.activeCustomers
              : 0;
          final.inactiveUsers =
            response.data.inactiveCustomers > 0
              ? response.data.inactiveCustomers
              : 0;
        }
      } catch (err) {
        console.log({ err });
      } finally {
        yield put(setActiveUsers(final));
        yield put(setLoadingActiveUsers(false));
      }
    },

    *updateGraphDrop(action) {
      const { fetchDrops, fetchGlobalNumberOfDeposits } = this.actions;
      yield put(fetchDrops());
      yield put(fetchGlobalNumberOfDeposits());
    },

    *updateGraphPick(action) {
      const { fetchPicks, fetchGlobalNumberOfCollects } = this.actions;
      yield put(fetchPicks());
      yield put(fetchGlobalNumberOfCollects());
    },

    *updateGraphMaintenance(action) {
      const { fetchMaintenances, fetchGlobalNumberOfMaintenances } =
        this.actions;
      yield put(fetchMaintenances());
      yield put(fetchGlobalNumberOfMaintenances());
    },

    *updateGraphAreaFillLevel(action) {
      const { fetchDepositsToPlot } = this.actions;
      yield put(fetchDepositsToPlot());
    },

    *handle(message) {
      const {
        updateGraphDrop,
        fetchGlobalNumberOfDeposits,
        fetchGlobalNumberOfCollects,
        fetchGlobalNumberOfMaintenances,
        updateGraphPick,
        updateGraphMaintenance,
        fetchMetrics,
        fetchAreasEquipments,
        fetchEquipmentInDetailNoFetchs,
      } = this.actions;
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (message.event_type === 'status') {
        let temp = { ...message };
        const message_id =
          message.content && message.content.id ? message.content.id : null;

        toast.info(temp.message, {
          position: toast.POSITION.TOP_RIGHT,
          delay: 200,
          id: message_id ? `uniqueStatusMessage${message_id}` : null,
        });
      } else if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          const message_id =
            message.content && message.content.id ? message.content.id : null;
          if (
            message.content != null &&
            message.content.type === 'drop' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueDropMessage${message_id}` : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            yield put(fetchAreasEquipments());
            yield put(updateGraphDrop(message.content));
            yield put(fetchGlobalNumberOfDeposits());
          } else if (
            message.content != null &&
            message.content.type === 'pick' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniquePickMessage${message_id}` : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            yield put(fetchAreasEquipments());
            yield put(updateGraphPick(message.content));
            yield put(fetchGlobalNumberOfCollects());
            yield put(fetchMetrics());
          } else if (
            message.content != null &&
            message.content.type === 'maintenance' &&
            message.type === 'new'
          ) {
            toast.info(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content && message.content.user
                    ? message.content.user
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id
                  ? `uniqueMaintenanceMessage${message_id}`
                  : null,
              }
            );
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
            yield put(fetchAreasEquipments());
            yield put(updateGraphMaintenance(message.content));
            yield put(fetchGlobalNumberOfMaintenances());
          } else if (message.content && message.content.type === 'alert') {
            toast.warn(
              <NotificationsWrapper
                title={
                  message.content != null &&
                  message.content.container != null &&
                  message.content.container.name
                    ? message.content.container.name
                    : ''
                }
                type={
                  message.content != null && message.content.type
                    ? message.content.type
                    : ''
                }
                user={
                  message.content != null && message.content.user
                    ? message.content.user
                    : ''
                }
                description={
                  message.content != null && message.content.alertType
                    ? message.content.alertType
                    : ''
                }
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: message_id ? `uniqueAlertMessage${message_id}` : null,
              }
            );
            yield put(fetchMetrics());
          } else if (message.content && message.content.type === 'error') {
            if (message.content.status === 'closed') {
              toast.success(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content && message.content.errorType
                      ? `${message.content.errorType}`
                      : ''
                  }
                  status={
                    message.content && message.content.status
                      ? message.content.status
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            } else {
              toast.error(
                <NotificationsWrapper
                  title={
                    message.content != null &&
                    message.content.container != null &&
                    message.content.container.name
                      ? message.content.container.name
                      : ''
                  }
                  type={
                    message.content != null && message.content.type
                      ? message.content.type
                      : ''
                  }
                  user={
                    message.content != null && message.content.user
                      ? message.content.user
                      : ''
                  }
                  description={
                    message.content != null && message.content.errorType
                      ? message.content.errorType
                      : ''
                  }
                />,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: message_id
                    ? `uniqueErrorMessage${message_id}`
                    : null,
                }
              );
            }
            yield put(fetchMetrics());
          }
        } else if (
          message.event_type === 'fill-level' &&
          message.type === 'new'
        ) {
          const { updateGraphAreaFillLevel } = this.actions;

          yield put(updateGraphAreaFillLevel(message.content));
        }
      }
    },
  },
});
