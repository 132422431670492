/**
 * StyledMobileTable Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default ({ children, ...props }) => {
  const { data, template: Template, margin } = props;
  const { t } = useTranslation();

  return (
    <Wrapper margin={margin}>
      {(data == null || data.length <= 0) && (
        <NoDataText>{t("no_data")}</NoDataText>
      )}
      {data &&
        data.map((el, index) => (
          <Template
            {...props}
            key={index}
            data={el}
            onClick={() => props.onRowClick(el)}
            active={props.active(el)}
          ></Template>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin: ${(props) => props.margin};
`;

const NoDataText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #5e7382;
  margin: 5rem 0;
`;
