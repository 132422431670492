import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.817 2.787L12.094 1 10.37 2.787l1.724 1.787 1.723-1.787zM11.281 5.212l1.724 1.787-1.724 1.787-1.723-1.787 1.723-1.787z"
        fill={props.fill || "#000"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.581 23c-.92 0-1.692-.734-1.758-1.672L6 9.522h13l-.823 11.806c-.066.938-.838 1.672-1.758 1.672H8.58zm6.142-10.838a.592.592 0 00-.617.57l-.313 6.399c-.017.34.25.63.587.63.311 0 .57-.25.587-.57l.313-6.4a.595.595 0 00-.557-.629zm-3.83.57a.592.592 0 00-.616-.57.595.595 0 00-.557.63l.313 6.399c.016.32.276.57.587.57a.594.594 0 00.587-.63l-.314-6.4z"
        fill={props.fill || "#000"}
      />
      <path
        d="M16.156 2.336l2.298 2.382-2.298 2.383-2.298-2.383 2.298-2.382z"
        fill={props.fill || "#000"}
      />
    </svg>
  )
}

export default SvgComponent
