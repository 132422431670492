import Portuguese from "./portuguese.json";
import English from "./english.json";

export const idiom = ["pt"];

export default {
  en: English,
  pt: Portuguese,
};

export { default as i18n } from "./i18n";
