/**
 * DepositsTemplate Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Theme } from "globalStyles";
import moment from "moment";

export default ({ children, ...props }) => {
  const { data } = props;
  const { t } = useTranslation();

  const getUserName = () => {
    if (data.tag) {
      if (
        data.tag.user != null &&
        data.tag.user.fullName != null &&
        data.tag.user.fullName !== ""
      ) {
        return <EllipsisDiv>{data.tag.user.fullName}</EllipsisDiv>;
      }
    }
    return <EllipsisDiv>{"-"}</EllipsisDiv>;
  };

  return (
    <Wrapper error={!data.complete ? 1 : 0} active={props.active ? 1 : 0} onClick={props.onClick}>
      <DateTime>{moment(data.datetime).format("DD/MM/YYYY HH:mm:ss")}</DateTime>
      <User>{getUserName()}</User>
      <Container>
        {data.container && data.container.name ? data.container.name : "-"}
      </Container>
      <Status error={!data.complete ? 1 : 0}>
        {data.complete ? t("table.completed") : t("table.uncompleted")}
      </Status>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 8px;
  column-gap: 8px;

  border-bottom: 1px solid ${Theme.inactiveColor};

  ${(props) =>
    props.error &&
    css`
      border-left: 3px solid ${Theme.errorColor};
      background-color: ${Theme.lightColor};
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.activeRowColor};
      background-color: ${Theme.activeRowColor};
    `}
`;

const DateTime = styled.div`
  font-weight: 300;
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: right;
  font-size: 12px;
  color: ${Theme.iconDarkColor};
`;
const User = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 16px;
`;
const Container = styled.div`
  font-size: 12px;
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 700;
  color: ${Theme.iconDarkColor};
`;
const Status = styled.div`
  color: ${Theme.successColor};
  grid-column: 2/3;
  grid-row: 2/3;
  text-align: right;
  font-size: 12px;
    margin: auto 0;

  ${(props) =>
    props.error &&
    css`
      color: ${Theme.errorColor};
    `}
`;

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
