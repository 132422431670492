/**
 * ResponsiveHandler Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from "react";
import { Theme } from "globalStyles";

const ResponsiveHandler = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      isMobile: window.innerWidth < Theme.breakpoints.tablet.min,
      isTablet: !!(
        window.innerWidth >= Theme.breakpoints.tablet.min &&
        window.innerWidth < Theme.breakpoints.smallDesktop.min
      ),
      isDesktop: window.innerWidth >= Theme.breakpoints.smallDesktop.min,
      isSmallDesktop: window.innerWidth < Theme.breakpoints.desktop.min,
      width: window.innerWidth,
      height: window.innerHeight,
    };

    recalculateDimensions = () => {
      const dimensions = {
        isMobile: window.innerWidth < Theme.breakpoints.tablet.min,
        isTablet: !!(
          window.innerWidth >= Theme.breakpoints.tablet.min &&
          window.innerWidth < Theme.breakpoints.smallDesktop.min
        ),
        isDesktop: window.innerWidth >= Theme.breakpoints.smallDesktop.min,
        isSmallDesktop: window.innerWidth < Theme.breakpoints.desktop.min,

        width: window.innerWidth,
        height: window.innerHeight,
      };
      for (let i = 0; i < Object.keys(dimensions).length; i++) {
        if (
          dimensions[Object.keys(dimensions)[i]] !==
          this.state[Object.keys(dimensions)[i]]
        ) {
          this.setState({ ...dimensions });
          return;
        }
      }
    };

    componentDidMount() {
      window.addEventListener("resize", this.recalculateDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.recalculateDimensions);
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <WrappedComponent
          {...this.props}
          responsiveHandlers={{ ...this.state }}
        />
      );
    }
  };
};

export default ResponsiveHandler;
