/**
 * Areas Container Logic
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from 'react';

import { kea } from 'kea';

import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { BASE_API_URL, API_DATE_FORMAT } from 'config';
//import { } from 'utils'
//import { } from './utils'

import PropTypes from 'prop-types';
//import * as Check from 'validations'
import axios from 'axios';
import moment from 'moment';
import { decodeColor } from 'utils';

import AppLogic from 'containers/App/logic';
import { toast } from 'react-toastify';

import NotificationsWrapper from 'components/NotificationsWrapper';

const EVENTSURL = BASE_API_URL + 'tickets/';
const EQUIPMENTSSURL = BASE_API_URL + 'min-containers/';

export const INTERVAL_FORM_DEFAULTS = {
  start: {
    value: moment().startOf('month'),
  },
  end: {
    value: moment(),
  },
  interval: {
    value: 'day',
  },
};

export default kea({
  path: () => ['scenes', 'ClientOccurrences'],

  connect: {
    props: [AppLogic, ['user']],
    actions: [
      AppLogic,
      ['setWebSocketHanlder', 'changeWatchingEvents', 'watchEvents'],
    ],
  },

  actions: () => ({
    changeFiltersForm: (key, value) => ({ key, value }),
    setFiltersForm: (form) => ({ form }),
    resetFiltersForm: () => true,
    setLoadingPlot: () => true,

    fetchAreasEquipments: () => true,
    fetchMaintenancesList: () => true,
    setMaintenancesList: (list) => ({ list }),
    setMaintenancesNumber: (number) => ({ number }),
    fetchEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetailNoFetchs: (equipment) => ({ equipment }),

    search: (query, cb, type) => ({ query, cb, type }),

    fetchAreasList: () => true,
    setAreasList: (list) => ({ list }),
    setAreasNumber: (number) => ({ number }),
    setLoadingAreas: (loading) => loading,

    setLinkedArea: (area) => ({ area, type: 'container', query: '' }),
    setFilteredEquipments: (equipments) => ({ equipments }),
    updateFilteredEquipments: (equipments) => ({ equipments }),
    setFilteredEquipmentsNumber: (number) => ({ number }),

    setFilter: (filter) => ({ filter }),
    changeFilter: (key, value) => ({ key, value }),
    resetFilter: () => true,
    filterEntrances: () => true,
    setPagination: (pag) => ({ pag }),

    setEquipmentInDetail: (equipment) => ({ equipment }),
    setFetchedEquipmentInDetail: (equipment) => ({
      equipment,
      type: 'container',
      query: '',
    }),

    setUsingMonthCalendar: (value) => ({ value }),
    setShowCalendarFilter: (value) => ({ value }),

    setDropsListToPlot: (list) => ({ list }),
    fetchDepositsToPlot: () => true,

    download: (type) => ({ type }),
    finishDownload: () => true,

    setSearchResults: (results) => ({ results }),
    setUserSearchResults: (results) => ({ results }),
    setAreaSearchResults: (results) => ({ results }),

    deleteOccurence: (id) => ({ id }),
    setDeleteResponse: (response) => ({ response }),

    reset: () => true,
  }),

  reducers: ({ actions }) => ({
    isDeleting: [
      false,
      PropTypes.bool,
      {
        [actions.deleteOccurence]: () => true,
        [actions.setDeleteResponse]: () => false,
      },
    ],
    deleteResponse: [
      null,
      PropTypes.any,
      {
        [actions.setDeleteResponse]: (state, { response }) => response,
      },
    ],

    filtersForm: [
      INTERVAL_FORM_DEFAULTS,
      PropTypes.object,
      {
        [actions.changeFiltersForm]: (state, payload) => ({
          ...state,
          [payload.key]: { value: payload.value },
        }),
        [actions.setFiltersForm]: (state, { form }) => ({ ...form }),
        [actions.resetFiltersForm]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
        [actions.resetFilter]: (state, payloada) => ({
          ...INTERVAL_FORM_DEFAULTS,
        }),
      },
    ],

    maintenancesList: [
      [],
      PropTypes.array,
      {
        [actions.setMaintenancesList]: (state, { list }) => list,
        [actions.reset]: () => [],
      },
    ],

    pagination: [
      { offset: 1, limit: 10 },
      PropTypes.object,
      {
        [actions.setPagination]: (state, { pag }) => pag,
        [actions.setFiltersForm]: (state) => ({ ...state, offset: 1 }),
        [actions.setEquipmentInDetail]: (state) => ({ ...state, offset: 1 }),
        [actions.reset]: () => ({ offset: 1, limit: 10 }),
      },
    ],

    linkedArea: [
      null,
      PropTypes.object,
      {
        [actions.setLinkedArea]: (state, { area }) => area,
        [actions.reset]: () => null,
      },
    ],

    dropsListToPlot: [
      null,
      PropTypes.array,
      {
        [actions.setDropsListToPlot]: (state, { list }) => list,
        [actions.reset]: () => null,
      },
    ],

    areasList: [
      [],
      PropTypes.array,
      {
        [actions.setAreasList]: (state, { list }) => list,
        [actions.reset]: () => [],
      },
    ],

    loadingAreas: [
      false,
      PropTypes.boolean,
      {
        [actions.setLoadingAreas]: (state, loading) => loading,
        [actions.reset]: () => false,
      },
    ],
    loadingDownload: [
      false,
      PropTypes.boolean,
      {
        [actions.download]: () => true,
        [actions.finishDownload]: () => false,
        [actions.reset]: () => false,
      },
    ],
    loadingPlot: [
      true,
      PropTypes.boolean,
      {
        [actions.setLoadingPlot]: (state, loading) => true,
        [actions.fetchDepositsToPlot]: (state, loading) => true,
        [actions.setDropsListToPlot]: (state, loading) => false,
        [actions.reset]: () => true,
      },
    ],

    filteredEquipments: [
      null,
      PropTypes.array,
      {
        [actions.setFilteredEquipments]: (state, { equipments }) => equipments,
        [actions.updateFilteredEquipments]: (state, { equipments }) => [
          ...state,
          ...equipments,
        ],
        [actions.setLinkedArea]: (state, { area }) => {
          return area == null ? null : state;
        },
        [actions.reset]: () => null,
      },
    ],

    filteredEquipmentsNumber: [
      0,
      PropTypes.number,
      {
        [actions.setFilteredEquipmentsNumber]: (state, { number }) => number,
        [actions.reset]: () => 0,
      },
    ],

    equipmentInDetail: [
      null,
      PropTypes.object,
      {
        [actions.setEquipmentInDetail]: (state, { equipment }) => equipment,
        [actions.setFetchedEquipmentInDetailNoFetchs]: (state, { equipment }) =>
          equipment,
        [actions.setLinkedArea]: (state, { area }) => {
          return area === null ? null : state;
        },
        [actions.resetFiltersForm]: () => null,
        [actions.reset]: () => null,
      },
    ],

    usingMonthCalendar: [
      true,
      PropTypes.bool,
      {
        [actions.setUsingMonthCalendar]: (state, { value }) => value,
      },
    ],

    showCalendarFilter: [
      false,
      PropTypes.bool,
      {
        [actions.setShowCalendarFilter]: (state, { value }) => value,
      },
    ],
    searchResults: [
      [],
      PropTypes.array,
      {
        [actions.setSearchResults]: (state, { results }) => results,
      },
    ],
    searchLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'container',
        [actions.setSearchResults]: () => false,
      },
    ],
    searchUserResults: [
      [],
      PropTypes.array,
      {
        [actions.setUserSearchResults]: (state, { results }) => results,
      },
    ],
    searchUserLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'username',
        [actions.setUserSearchResults]: () => false,
      },
    ],
    searchAreaResults: [
      [],
      PropTypes.array,
      {
        [actions.setAreaSearchResults]: (state, { results }) => results,
      },
    ],
    searchAreaLoading: [
      false,
      PropTypes.bool,
      {
        [actions.search]: (state, { query, cb, type }) => type === 'area',
        [actions.setAreaSearchResults]: () => false,
      },
    ],
  }),

  start: function* () {
    const {
      fetchMaintenancesList,
      fetchAreasEquipments,
      reset,
      watchEvents,
      setWebSocketHanlder,
    } = this.actions;

    yield put(reset());

    yield put(fetchMaintenancesList());
    yield put(fetchAreasEquipments());
    yield put(setWebSocketHanlder(this.workers.handle));
    yield put(watchEvents());
  },

  stop: function* () {
    const { changeWatchingEvents } = this.actions;
    yield put(changeWatchingEvents(false));
  },

  takeLatest: ({ actions, workers }) => ({
    [actions.fetchMaintenancesList]: workers.fetchMaintenancesList,
    [actions.fetchAreasEquipments]: workers.fetchAreasEquipments,

    [actions.fetchEquipmentInDetailNoFetchs]:
      workers.fetchEquipmentInDetailNoFetchs,
    [actions.setPagination]: workers.fetchMaintenancesList,

    [actions.setEquipmentInDetail]: [
      workers.fetchEquipmentInDetailNoFetchs,
      workers.fetchMaintenancesList,
    ],

    [actions.setFiltersForm]: [workers.fetchMaintenancesList],
    [actions.resetFiltersForm]: [workers.fetchMaintenancesList],
    [actions.deleteOccurence]: workers.deleteOccurence,
  }),

  workers: {
    *fetchMaintenancesList(action) {
      yield delay(500);
      const equipmentInDetail = yield this.get('equipmentInDetail');
      const filtersForm = yield this.get('filtersForm');

      const {
        setMaintenancesList,
        setMaintenancesNumber,
        setEquipmentInDetail,
      } = this.actions;

      let params;
      try {
        if (equipmentInDetail) {
          params = { container__id: equipmentInDetail.id };
        } else {
          params = {};
        }

        const date_after = filtersForm.start.value.format(API_DATE_FORMAT);
        const date_before = filtersForm.end.value.format(API_DATE_FORMAT);

        console.log('params', params);

        let response = yield call(axios.get, EVENTSURL, {
          params: {
            ...params,
            date_after: moment(date_after).toISOString(),
            date_before: moment(date_before).endOf('day').toISOString(),
            ordering: '-datetime',
          },
        });

        if (response.data.results) {
          yield put(setMaintenancesList(response.data.results));
          yield put(setMaintenancesNumber(response.data.count));
        } else {
          yield put(setMaintenancesList([]));
          yield put(setMaintenancesNumber(0));
          yield put(setEquipmentInDetail(null));
        }
      } catch (error) {
        console.log({ error });
        yield put(setMaintenancesList([]));
        yield put(setMaintenancesNumber(0));
        yield put(setEquipmentInDetail(null));
      }
    },

    *handle(message) {
      const { triggerFetch, fetchEquipmentInDetailNoFetchs } = this.actions;
      const equipmentInDetail = yield this.get('equipmentInDetail');

      if (message.event_type === 'event' && message.content) {
        if (message.event_type === 'event') {
          if (
            (message.content.type === 'alert' ||
              message.content.type === 'pick' ||
              message.content.type === 'drop') &&
            message.type === 'new'
          ) {
            yield put(triggerFetch());
            if (
              equipmentInDetail != null &&
              message.content.container != null &&
              equipmentInDetail.id === message.content.container.id
            ) {
              yield put(fetchEquipmentInDetailNoFetchs(equipmentInDetail));
            }
          } else if (
            message.sender &&
            message.sender === 'transaction' &&
            message.type === 'new'
          ) {
            yield put(triggerFetch());
          }
        }
      }
    },

    *deleteOccurence(action) {
      const { payload } = action;
      const { setDeleteResponse, fetchMaintenancesList } = this.actions;
      const { t } = this.props;
      if (payload.id != null) {
        try {
          const response = yield call(
            axios.delete,
            `${EVENTSURL}${payload.id}/`
          );
          if (response) {
            yield put(setDeleteResponse('success'));
            toast.success(
              <NotificationsWrapper
                title={t('notification.occurrence_success')}
                type={'occurrence'}
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: payload.id ? `deleted_${payload.id}` : null,
              }
            );
            yield put(fetchMaintenancesList());
          }
        } catch (err) {
          console.log(err);
          yield put(setDeleteResponse('error'));
          toast.error(
            <NotificationsWrapper
              title={t('notification.occurrence_error')}
              type={'occurrence'}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              toastId: payload.id ? `deleted_${payload.id}` : null,
            }
          );
        }
      }
    },

    *fetchAreasEquipments(action) {
      yield delay(600);
      const { setFilteredEquipments, setFilteredEquipmentsNumber } =
        this.actions;

      try {
        let next = 1;
        let number = 0;

        while (next) {
          let params = {
            limit: 500,
            offset: (next - 1) * 500,
          };

          let results = [];
          const { data } = yield call(axios.get, EQUIPMENTSSURL, {
            params,
          });

          if (data.results && data.results.length) {
            number = number + data.results.length;

            data.results.map((container) => {
              container.color = decodeColor(container.type);
              results.push(container);
              return container;
            });
          }
          next = data.next ? next + 1 : 0;

          yield put(setFilteredEquipments(results));
          yield put(setFilteredEquipmentsNumber(number));
        }
      } catch (error) {
        console.log({ error });
        yield put(setFilteredEquipments([]));
        yield put(setFilteredEquipmentsNumber(0));
      }
    },

    *fetchEquipmentInDetailNoFetchs(action) {
      const { setFetchedEquipmentInDetailNoFetchs } = this.actions;

      try {
        if (
          action != null &&
          action.payload != null &&
          action.payload.equipment != null &&
          action.payload.equipment.id != null
        ) {
          const { data } = yield call(
            axios.get,
            EQUIPMENTSSURL + `${action.payload.equipment.id}/`
          );
          data.color = decodeColor(data.type);
          yield put(setFetchedEquipmentInDetailNoFetchs(data));
        }
      } catch (error) {
        console.log({ error });
        yield put(setFetchedEquipmentInDetailNoFetchs(null));
      }
    },
  },
});
